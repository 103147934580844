import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {Col} from "react-bootstrap";


import dateFormat from "dateformat";


const TEXTS: any = {
    PL: {game_hour_label: "G"},
    EN: {game_hour_label: "H"},
}


type ResultHeaderDateProps = {
    GAME_DATE?: Date;
};


export const ResultHeaderDateComponent: React.FC<ResultHeaderDateProps> = ({
    GAME_DATE
}): JSX.Element | null => {


    const {activeLanguage} = useTypedSelector((state) => state.loc);

    const {game_hour_label: GAME_HOUR_LABEL} = TEXTS[activeLanguage];


    if (!GAME_DATE) return null;


    return (
        <Col xs={9} lg={7} className={"league-game-date"}>
            <span className={"league-game-day"}>{dateFormat(GAME_DATE, "dd.mm.yyyy")}</span>
            <span className={"league-game-hour"}>{GAME_HOUR_LABEL}. {dateFormat(GAME_DATE, "HH:MM")}</span>
        </Col>
    );

};


export default ResultHeaderDateComponent;

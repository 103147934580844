import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import UseLeagueStatistics from "@Hooks/Api/Requests/Games/Leagues/League/Statistics";


import TeamTop5PlayersHeaderComponent from "@TeamsPages/Team/Information/TeamTopPlayers/Header";
import TeamTop5PlayersListComponent from "@TeamsPages/Team/Information/TeamTopPlayers/PlayersList";


import MainStatisticsTypeType from "@GlobalData/Statistics/MainTypes/Type";
import StatisticsType from "@GlobalData/Statistics/Types/Type";


import "./index.scss";


export interface TeamInformationTeamTop5ComponentProps {
    teamID: number;
    selectedLeagueId: number;
}


export const TeamInformationTeamTop5Component: React.FC<TeamInformationTeamTop5ComponentProps> = ({
    teamID, selectedLeagueId,
}) : JSX.Element | null => {


    const [selectedStatisticsMainType, setSelectedStatisticsMainType] = useState<MainStatisticsTypeType>("MEDIUM");
    const [selectedStatisticsType, setSelectedStatisticsType] = useState<StatisticsType>("TPTS");


    useEffect(() => {
        return () => {
            setSelectedStatisticsMainType("MEDIUM");
            setSelectedStatisticsType("TPTS");
        };
    }, [teamID, selectedLeagueId]);


    const [LeagueStatistics, LeagueStatisticsError] = UseLeagueStatistics(
        selectedLeagueId, selectedStatisticsMainType, selectedStatisticsType, teamID, 5
    );


    return (
        <Col xs={12} lg={4} className={"team-details__team-information__team-top-players"}>
            <Row className={"team-details__team-information__team-top-players__content"}>

                <TeamTop5PlayersHeaderComponent
                    selectedStatisticsMainType={selectedStatisticsMainType}
                    selectedStatisticsMainTypeHandler={setSelectedStatisticsMainType}
                    selectedLeagueId={selectedLeagueId}
                />

                <TeamTop5PlayersListComponent
                    selectedLeagueId={selectedLeagueId}
                    LeagueStatistics={LeagueStatistics}
                    LeagueStatisticsError={LeagueStatisticsError}
                    selectedStatisticsType={selectedStatisticsType}
                    selectedStatisticsTypeHandler={setSelectedStatisticsType}
                />

            </Row>
        </Col>
    );

};


export default TeamInformationTeamTop5Component;

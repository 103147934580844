import React, { useEffect, useState } from "react";


import { UseSetPopupMessageAction } from "@StatesActions";


import _restApiRequest from "@Services/HTTP/Requests/Request";
import REST_API_ENDPOINTS_URLS from "@ApiData";


import { Col, Row } from "react-bootstrap";

import { AiFillCloseCircle, AiFillEdit, AiFillSave } from "react-icons/all";


import {
    FormComponent,
    FormTextFieldComponent
} from "@FormsElements";

import { UseFormsDictionaryPhrasesSet, UseFormFieldErrorMessage } from "@Hooks/Texts/Sets/Forms/Fields/Field";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


import {
    isFirstName, isLastName, isNickName,
    isEmail,
} from "@Services/Utilities/Tools/Validators";


import { UseErrorMessage, UseSuccessMessage } from "@Hooks/Texts";


import { NO_AVATAR_IMAGE } from "@Images";


import { LeaguePlayerInterface } from "@Interfaces/Api";
import FormCheckboxFieldComponent from "@CommonFormsElements/Fields/Checkbox";


export interface PlayerProfileMainDataComponentProps {
    Player: LeaguePlayerInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const PlayerProfileMainDataComponent: React.FC<PlayerProfileMainDataComponentProps> = ({
    Player, updatePlayerTrigger
}) : JSX.Element | null => {


    const FIRST_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("FIRST_NAME");
    const FIRST_NAME_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("FIRST_NAME");
    const LAST_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("LAST_NAME");
    const LAST_NAME_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("LAST_NAME");
    const NICK_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("NICK_NAME");
    const NICK_NAME_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("NICK_NAME");
    const EMAIL_ADDRESS_FIELD_LABEL = UseFormsDictionaryPhrasesSet("EMAIL_ADDRESS");
    const EMAIL_ADDRESS_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("EMAIL_ADDRESS");

    const DEFAULT_ERROR_MESSAGE = UseErrorMessage("DEFAULT");
    const INVALID_FILE_TYPE_MESSAGE = UseErrorMessage("INVALID_FILE_TYPE");
    const DEFAULT_UPDATE_SUCCESS_MESSAGE = UseSuccessMessage("DEFAULT_UPDATE_DATA_RESPONSE");


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const API_ROOT_URL = REST_API_ENDPOINTS_URLS.API_ROOT_URL;


    interface FormErrorsInterface {
        playerFirstName: FormFieldValueErrorInterface | null;
        playerLastName: FormFieldValueErrorInterface | null;
        playerNickName: FormFieldValueErrorInterface | null;
        playerProfileImage: FormFieldValueErrorInterface | null;
        playerEmailAddress: FormFieldValueErrorInterface | null;
        playerPseudonymizationStatus: FormFieldValueErrorInterface | null;
    }

    const FormErrorsDefaults: FormErrorsInterface = {
        playerFirstName: null, playerLastName: null, playerNickName: null,
        playerProfileImage: null,
        playerEmailAddress: null, playerPseudonymizationStatus: null
    };

    const [FormErrors, setFormErrors] = useState(FormErrorsDefaults);


    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [nickName, setNickName] = useState<string>("");

    const [profileImage, setProfileImage] = useState<string>("");
    const [profileImageFile, setProfileImageFile] = useState<File | null>(null);

    const [emailAddress, setEmailAddress] = useState<string>("");
    const [socialID, setSocialID] = useState<string>("");

    const [playerPseudonymizationStatus, setPlayerPseudonymizationStatus] = useState(false);


    const changeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isFirstName(value) && FormErrors.playerFirstName) {
            setFormErrors({ ...FormErrors, playerFirstName: null });
        }
        setFirstName(value);
    };

    const changeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isLastName(value) && FormErrors.playerLastName) {
            setFormErrors({ ...FormErrors, playerLastName: null });
        }
        setLastName(value);
    };

    const changeNickName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isNickName(value) && FormErrors.playerNickName) {
            setFormErrors({ ...FormErrors, playerNickName: null });
        }
        setNickName(value);
    };

    const changeEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isEmail(value) && FormErrors.playerEmailAddress) {
            setFormErrors({ ...FormErrors, playerEmailAddress: null });
        }
        setEmailAddress(value);
    };


    useEffect(() => {

        if (!Player) return;

        const {
            player_first_name: playerFirstName, player_last_name: playerLastName,
            player_nick_name: playerNickName, player_profile_image: playerProfileImage,
            player_email_address: playerEmailAddress,
            player_pseudonymization_status: playerPseudonymizationStatus,
        } = Player;

        setFirstName(playerFirstName);
        setLastName(playerLastName);
        setPlayerPseudonymizationStatus(!!playerPseudonymizationStatus);

        if (!!playerNickName) setNickName(playerNickName);
        if (!!playerProfileImage) setProfileImage(playerProfileImage);

        if (playerEmailAddress) setEmailAddress(playerEmailAddress);

    }, [Player]);

    useEffect(() => {
        return () => {
            setFirstName("");
            setLastName("");
            setNickName("");
            setProfileImage("");
            setEmailAddress("");
            setPlayerPseudonymizationStatus(false);
        };
    }, []);


    const [profileImageChangeStatus, setProfileImageChangeStatus] = useState<boolean>(false);

    const changeProfileImageFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files[0]) return;
        const file = e.target.files[0];
        const fileType = file.type;
        if (fileType !== "image/jpeg" && fileType !== "image/png") {
            return UseSetPopupMessage({ type: "ERROR", text: INVALID_FILE_TYPE_MESSAGE });
        }
        setProfileImageFile(file);
    };

    const uploadProfileImageFileHandler = async () => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const { player_id: playerID } = Player;

        if (!profileImageFile || !playerID || !API_URL) return;

        const formData = new FormData();

        formData.append("player_profile_image", profileImageFile, profileImageFile.name);

        try {

            const response = await _restApiRequest(
                `${API_URL}/teams/players/${playerID}/update-player-data/`,
                formData, "PATCH"
            )

            if (response.ok) {
                setProfileImageChangeStatus(false);
                updatePlayerTrigger(true);
                UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_UPDATE_SUCCESS_MESSAGE });
            } else {
                UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });
            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });
        }

    };

    useEffect(() => {
        setProfileImageFile(null);
    }, [profileImageChangeStatus]);


    if (!Player) return null;


    const saveFieldValueHandler = async (fieldName: string, fieldValue: string, fieldEditableStatusHandler?: (status: boolean) => void) => {

        let error = false;
        const FormErrors: FormErrorsInterface = { ... FormErrorsDefaults };

        switch (fieldName) {

            case "player_first_name":
                if (!isFirstName(fieldValue)) {
                    error = true;
                    FormErrors.playerFirstName = FIRST_NAME_FIELD_ERROR_MESSAGE;
                }
                break;

            case "player_last_name":
                if (!isLastName(fieldValue)) {
                    error = true;
                    FormErrors.playerLastName = LAST_NAME_FIELD_ERROR_MESSAGE;
                }
                break;

            case "player_nick_name":
                if (!isNickName(fieldValue)) {
                    error = true;
                    FormErrors.playerNickName = NICK_NAME_FIELD_ERROR_MESSAGE;
                }
                break;

            case "player_email_address":
                if (!isEmail(fieldValue)) {
                    error = true;
                    FormErrors.playerNickName = EMAIL_ADDRESS_FIELD_ERROR_MESSAGE;
                }
                break;

        }

        setFormErrors(FormErrors);

        if (error) return;

        if (await saveFieldValueRequestHandler(fieldName, fieldValue)) {
            UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_UPDATE_SUCCESS_MESSAGE });
            updatePlayerTrigger(true);
            return fieldEditableStatusHandler ? fieldEditableStatusHandler(false) : undefined;
        }

        return UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });

    };

    const saveFieldValueRequestHandler = async (fieldName: string, fieldValue: string) => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const formData = new FormData();
        formData.append(fieldName, fieldValue);

        const { player_id: playerID } = Player;

        try {

            const response = await _restApiRequest(
                `${API_URL}/teams/players/${playerID}/update-player-data/`,
                formData, "PATCH"
            );

            return response.ok;

        } catch (e) {
            return false;
        }

    };


    const {
        player_email_address: playerEmailAddress,
    } = Player;


    const changePlayerPseudonymizationStatus = async () => {

        if (!playerPseudonymizationStatus) {
            if (!isNickName(nickName)) {
                return setFormErrors({ ...FormErrors, playerPseudonymizationStatus: { errorMessage: "Aby dokonać pseudonimizacji musisz najpierw ustawić swoje pseudo" } });
            }
        }

        await saveFieldValueHandler("player_pseudonymization_status", (+!playerPseudonymizationStatus).toString());

        setFormErrors({ ...FormErrors, playerPseudonymizationStatus: null });

    }


    return (
        <Col xs={12} className={"user-profile-page-data section-data"}>
            <Row className={"user-profile-page-data-content section-data-content"}>

                <Col xs={12} className={"user-main-data"}>
                    <Row className={"user-main-data-content"}>

                        <Col xs={5} lg={2} className={"user-profile-image"}>
                            <Row as={"picture"} className={"user-profile-image-picture"}>
                                <img src={profileImage ? `${API_ROOT_URL}${profileImage}`  : NO_AVATAR_IMAGE} alt={``} />
                                <span onClick={() => setProfileImageChangeStatus(!profileImageChangeStatus)} className={"change-profile-image"}>
                                    {profileImageChangeStatus ? <AiFillCloseCircle /> : <AiFillEdit />}
                                </span>
                            </Row>
                            {profileImageChangeStatus &&
                            <Row className={"user-profile-image-uploader"}>
                                <FormTextFieldComponent
                                    fieldType={"file"}
                                    fieldValueHandler={changeProfileImageFile}
                                />
                                {profileImageFile &&
                                <Col xs={12} lg={12} className={"file-information"}>
                                    <span className={"save-file"} onClick={uploadProfileImageFileHandler}>
                                        <AiFillSave />
                                    </span>
                                    <span className={"file-name"}>{profileImageFile.name.length > 15 ? (profileImageFile.name.substring(0, 14) + "..." ) : profileImageFile.name}</span>
                                </Col>
                                }
                            </Row>
                            }
                        </Col>

                        <FormComponent xs={{span: 7, offset: 0}} lg={{span: 3, offset: 2}} cssClasses={"user-form strong-text-values user-main-information"}>

                            <FormTextFieldComponent
                                fieldName={"player_first_name"}
                                fieldValue={firstName}
                                fieldValueHandler={changeFirstName}
                                fieldEditableStatus={false}
                                fieldLabel={FIRST_NAME_FIELD_LABEL}
                                fieldSaveDataHandler={saveFieldValueHandler}
                                fieldError={FormErrors.playerFirstName}
                            />

                            <FormTextFieldComponent
                                fieldName={"player_last_name"}
                                fieldValue={lastName}
                                fieldValueHandler={changeLastName}
                                fieldEditableStatus={false}
                                fieldLabel={LAST_NAME_FIELD_LABEL}
                                fieldSaveDataHandler={saveFieldValueHandler}
                                fieldError={FormErrors.playerLastName}
                            />

                            <FormTextFieldComponent
                                fieldName={"player_nick_name"}
                                fieldValue={nickName}
                                fieldValueHandler={changeNickName}
                                fieldEditableStatus={false}
                                fieldLabel={NICK_NAME_FIELD_LABEL}
                                fieldSaveDataHandler={saveFieldValueHandler}
                                fieldError={FormErrors.playerNickName}
                            />

                            <FormCheckboxFieldComponent
                                fieldName={"player_pseudonymization_status"}
                                fieldLabel={"Pseudonimizuj"}
                                fieldCheckedStatus={playerPseudonymizationStatus}
                                fieldCheckedStatusHandler={changePlayerPseudonymizationStatus}
                                fieldError={FormErrors.playerPseudonymizationStatus}
                                fieldLegendText={playerPseudonymizationStatus ? "Pseudonimizacja aktywna. Twoje dane w profilu zawodnika, informacjach meczowych, itp. zostaną zastąpione pseudonimem" : null}
                            />

                        </FormComponent>

                        <FormComponent xs={12} lg={{span: 3, offset: 1}} cssClasses={"user-form user-contact-information strong-text-values border-top lg-no-border-top"}>

                            <FormTextFieldComponent
                                fieldName={"player_email_address"}
                                fieldValue={emailAddress}
                                fieldValueHandler={changeEmailAddress}
                                fieldEditableStatus={false}
                                fieldLabel={EMAIL_ADDRESS_FIELD_LABEL}
                                fieldSaveDataHandler={!!playerEmailAddress ? undefined : saveFieldValueHandler}
                                fieldError={FormErrors.playerEmailAddress}
                                xs={8} lg={12}
                                fieldCssClasses={"no-border-bottom no-margin-bottom"}
                            />

                            {/*<FormTextFieldComponent*/}
                            {/*    fieldName={"player_social_id"}*/}
                            {/*    fieldValue={socialID}*/}
                            {/*    fieldValueHandler={changeSocialID}*/}
                            {/*    fieldEditableStatus={false}*/}
                            {/*    fieldLabel={SOCIAL_ID_FIELD_LABEL}*/}
                            {/*    fieldSaveDataHandler={!!playerSocialID ? undefined : saveFieldValueHandler}*/}
                            {/*    fieldError={FormErrors.playerSocialID}*/}
                            {/*    fieldHashStatus={true}*/}
                            {/*    xs={4} lg={12}*/}
                            {/*/>*/}

                        </FormComponent>

                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerProfileMainDataComponent;

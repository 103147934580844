import React from "react";


import { Row, Col, Form } from "react-bootstrap";


export interface FormComponentProps {
    cssClasses?: string | [];
    children: React.ReactNode;
    xs?: number | {span: number; offset: number}; lg?: number | {span: number; offset: number};
}

export const FormComponent: React.FC<FormComponentProps> = ({
    cssClasses = "",
    children,
    xs = 12, lg = 12
}) : JSX.Element | null => {


    if (!children) return null;


    cssClasses = typeof cssClasses === "object" ? cssClasses.join(" ") : cssClasses;
    cssClasses = `form form-component` + (!!cssClasses ? ` ${cssClasses}` : cssClasses);


    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };


    return (
        <Col xs={xs} lg={lg} as={Form} onSubmit={submitHandler} className={cssClasses}>
            <Row className={"form-content"}>

                {children}

            </Row>
        </Col>
    );

};


export default FormComponent;

import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import TransfersListPlayersListPlayerComponent from "@GamesPages/Transfers/List/Players/List/Player";


import {
    ListByLeaguesRequestType
} from "@Interfaces/Api";


import "./index.scss";


export interface TransfersListPlayersListComponentProps {
    Players: ListByLeaguesRequestType[];
}


export const TransfersListPlayersListComponent: React.FC<TransfersListPlayersListComponentProps> = ({
    Players
}) : JSX.Element | null => {


    if (!Players) {
        return null;
    }


    const PlayersList = Players.map((Player, i) =>
        <TransfersListPlayersListPlayerComponent
            key={i}
            Player={Player}
        />
    );


    return (
        <Col xs={12} className={"transfers-list__list__players__list"}>
            <Row className={"transfers-list__list__players__list__content"}>

                {PlayersList}

            </Row>
        </Col>
    );

};


export default TransfersListPlayersListComponent;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    NO_AVATAR_IMAGE
} from "@Images";


import {
    LeagueRoundBestPlayerPlayerType
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundBestPlayerPlayerImageComponentProps {
    Player: LeagueRoundBestPlayerPlayerType;
}


export const RoundBestPlayerPlayerImageComponent: React.FC<RoundBestPlayerPlayerImageComponentProps> = ({
    Player
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Player) {
        return null;
    }


    const {
        player_slug: playerSlug, player_profile_image: playerProfileImage
    } = Player;


    const playerURL = PlayersRoute.routeURL + "/" + playerSlug;


    return (
        <Col xs={3} lg={{ span: 6, offset: 3 }} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__image"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__image__content"}>

                <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                    <img src={playerProfileImage || NO_AVATAR_IMAGE} alt={""} />
                </NavLink>

            </Row>
        </Col>
    );

};


export default RoundBestPlayerPlayerImageComponent;

import { UseLanguage } from "@States";


import PlayersDictionary, { PlayersDictionaryPhrasesSetType } from "@Dictionary/Pages/Leagues/Players";


export const UsePlayersDictionaryPhrasesSet = (phraseName: PlayersDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return PlayersDictionary[ActiveLanguage][phraseName];

};


export default UsePlayersDictionaryPhrasesSet;

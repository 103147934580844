import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    LeagueRoundBestPlayerStatisticsType
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundBestPlayerPlayerStatsComponentProps {
    Statistics: LeagueRoundBestPlayerStatisticsType;
}


export const RoundBestPlayerPlayerStatsComponent: React.FC<RoundBestPlayerPlayerStatsComponentProps> = ({
    Statistics
}) : JSX.Element | null => {


    const POINTS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("POINTS_WORD_SHORT_TEXT");
    const STATISTICS_REBOUNDS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_REBOUNDS_SHORT_TEXT");
    const STATISTICS_ASSISTS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ASSISTS_SHORT_TEXT");
    const STATISTICS_STEALS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_STEALS_SHORT_TEXT");
    const STATISTICS_BLOCKS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_BLOCKS_SHORT_TEXT");
    const STATISTICS_EVAL_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EVAL_TEXT");


    if (!Statistics) {
        return null;
    }


    const {
        PTS, REB, AST, STL, BLK, EVAL
    } = Statistics;


    return (
        <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__statistics"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__name__information__statistics"}>

                <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__statistics__header"}>
                    <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__statistics__header__content"}>
                        <Col xs={2} className={"player-points statistic-value"}>
                            <span>{POINTS_WORD_SHORT_TEXT}</span>
                        </Col>
                        <Col xs={2} className={"player-rebounds statistic-value"}>
                            <span>{STATISTICS_REBOUNDS_SHORT_TEXT}</span>
                        </Col>
                        <Col xs={2} className={"player-assists statistic-value"}>
                            <span>{STATISTICS_ASSISTS_SHORT_TEXT}</span>
                        </Col>
                        <Col xs={2} className={"player-steals statistic-value"}>
                            <span>{STATISTICS_STEALS_SHORT_TEXT}</span>
                        </Col>
                        <Col xs={2} className={"player-blocks statistic-value"}>
                            <span>{STATISTICS_BLOCKS_SHORT_TEXT}</span>
                        </Col>
                        <Col xs={2} className={"player-eval statistic-value"}>
                            <span>{STATISTICS_EVAL_TEXT}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__statistics__data"}>
                    <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__statistics__header__data"}>
                        <Col xs={2} className={"player-points statistic-value"}>
                            <span>{PTS}</span>
                        </Col>
                        <Col xs={2} className={"player-rebounds statistic-value"}>
                            <span>{REB}</span>
                        </Col>
                        <Col xs={2} className={"player-assists statistic-value"}>
                            <span>{AST}</span>
                        </Col>
                        <Col xs={2} className={"player-steals statistic-value"}>
                            <span>{STL}</span>
                        </Col>
                        <Col xs={2} className={"player-blocks statistic-value"}>
                            <span>{BLK}</span>
                        </Col>
                        <Col xs={2} className={"player-eval statistic-value"}>
                            <span>{EVAL}</span>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Col>
        );

};


export default RoundBestPlayerPlayerStatsComponent;

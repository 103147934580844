import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import LanguageCodeType from "@GlobalData/Localization/Languages";


export const UseLanguage = () : LanguageCodeType => {

    const {activeLanguage} = useTypedSelector(
        (state) => state.loc
    );

    return activeLanguage || "PL";

};


export default UseLanguage;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import dateFormat from "dateformat";


import {
    LeagueGameInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamNextGamesListGameComponentProps {
    LeagueGame: LeagueGameInterface;
    teamID: number;
    orderNr: number;
}


export const TeamNextGamesListGameComponent: React.FC<TeamNextGamesListGameComponentProps> = ({
    LeagueGame, teamID, orderNr
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    if (!LeagueGame) {
        return null;
    }


    const {
        league_game_date: leagueGameDate,
        league_game_arena_data: LeagueGameArena,
        league_game_league_home_team_data: leagueGameLeagueHomeTeamData,
        league_game_league_away_team_data: leagueGameLeagueAwayTeamData
    } = LeagueGame;

    let leagueGameDay = "-";
    let leagueGameHour = "-";

    if (leagueGameDate) {
        leagueGameDay = dateFormat(leagueGameDate, "dd-mm");
        leagueGameHour = dateFormat(leagueGameDate, "HH:MM");
    }

    let leagueGameArenaName = "-";

    if (LeagueGameArena) {
        const {
            league_arena_name: leagueArenaName, league_arena_village: leagueArenaVillage
        } = LeagueGameArena;
        leagueGameArenaName = `${leagueArenaName}, ${leagueArenaVillage}`;
    }

    let opponentTeamName: string | null = null;
    let opponentTeamSlug: string | null = null;

    if (leagueGameLeagueHomeTeamData) {
        const { team_league_team_name: teamLeagueName, team_league_team_data: teamData  } = leagueGameLeagueHomeTeamData;
        if (teamData){
            const { team_id: teamId, team_name: teamName, team_slug: teamSlug } = teamData;
            if (teamId !== teamID) {
                opponentTeamName = teamLeagueName || teamName;
                opponentTeamSlug = teamSlug;
            }
        }
    }

    if (leagueGameLeagueAwayTeamData) {
        const { team_league_team_name: teamLeagueName, team_league_team_data: teamData  } = leagueGameLeagueAwayTeamData;
        if (teamData){
            const { team_id: teamId, team_name: teamName, team_slug: teamSlug } = teamData;
            if (teamId !== teamID) {
                opponentTeamName = teamLeagueName || teamName;
                opponentTeamSlug = teamSlug;
            }
        }
    }


    return (
        <Col xs={12} className={"team-details__team-information__team-next-games__games-list__game"}>
            <Row className={"team-details__team-information__team-next-games__games-list__game__content"}>

                <Col xs={1} className={"team-details__team-information__team-next-games__games-list__game__game-order-nr"}>
                    <span>{orderNr}</span>
                </Col>

                <Col xs={1} className={"team-details__team-information__team-next-games__games-list__game__game-date-day"}>
                    <span>{leagueGameDay}</span>
                </Col>

                <Col xs={1} className={"team-details__team-information__team-next-games__games-list__game__game-date-hour"}>
                    <span>{leagueGameHour}</span>
                </Col>

                <Col xs={5} className={"team-details__team-information__team-next-games__games-list__game__game-arena"}>
                    <span>{leagueGameArenaName}</span>
                </Col>

                {!!opponentTeamSlug &&
                <Col xs={4} className={"team-details__team-information__team-next-games__games-list__game__game-opponent"}>
                    <NavLink to={`/${TeamsRoute.routeURL}/${opponentTeamSlug}`} title={TeamsRoute.routeTitle}>
	                    <span>{opponentTeamName}</span>
                    </NavLink>
                </Col>
                }

            </Row>
        </Col>
    );

};


export default TeamNextGamesListGameComponent;

import RoutesInterface from "@RoutesInterface";


export const Routes: RoutesInterface = {
    PL: {
        HOME_PAGE: {
            routeName: "home-page", routeLabel: "Strona Główna", routeTitle: "Przejdź do strony głównej", routeURL: ""
        },
        REGULATIONS: {
            routeName: "regulations", routeLabel: "Regulamin", routeTitle: "Przejdź do regulaminu", routeURL: "regulamin"
        },
        PRIVACY_POLICY: {
            routeName: "privacy-policy", routeLabel: "Polityka Prywatności", routeTitle: "Przejdź do polityki prywatności", routeURL: "polityka-prywatnosci"
        },
        LOGIN: {
            routeName: "user-login", routeLabel: "Logowanie", routeTitle: "Przejdź do logowania", routeURL: "logowanie"
        },
        REGISTER: {
            routeName: "user-register", routeLabel: "Rejestracja", routeTitle: "Przejdź do rejestracji", routeURL: "rejestracja"
        },
        PASSWORD_REMIND: {
            routeName: "user-password-remind", routeLabel: "Przypomnij hasło", routeTitle: "Przejdź do przypomnienia hasła", routeURL: "logowanie/przypomnij-haslo"
        },
        PASSWORD_SET: {
            routeName: "user-password-set", routeLabel: "Ustaw hasło", routeTitle: "Przejdź do ustawiania hasła", routeURL: "logowanie/ustaw-haslo"
        },
        PROFILE: {
            routeName: "user-profile", routeLabel: "Mój Profil", routeTitle: "Przejdź do profilu", routeURL: "profil"
        },
        TEAMS: {
            routeName: "teams", routeLabel: "Drużyny", routeURL: "druzyny", routeTitle: "Przejdź do strony drużyny"
        },
        PLAYERS: {
            routeName: "players", routeLabel: "Zawodnicy", routeURL: "zawodnicy", routeTitle: "Przejdź do strony zawodnika"
        },
        SCHEDULES: {
            routeName: "schedules", routeLabel: "Terminarze", routeURL: "schedules", routeTitle: "Przejdź do terminarzy"
        },
        GAMES: {
            routeName: "games", routeLabel: "Mecze", routeURL: "mecze", routeTitle: "Przejdź do meczu"
        },
        TABLES: {
            routeName: "tables", routeLabel: "Tabele", routeURL: "tabele", routeTitle: "Przejdź do tabel"
        },
        E404: {
            routeName: "e404", routeLabel: "Nie znaleziono", routeURL: "404", routeTitle: "Nie znaleziono"
        }
    },
    EN: {
        HOME_PAGE: {
            routeName: "home-page", routeLabel: "Home Page", routeTitle: "Go to Home Page", routeURL: ""
        },
        REGULATIONS: {
            routeName: "regulations", routeLabel: "Regulations", routeTitle: "Go to Regulations Page", routeURL: "regulamin"
        },
        PRIVACY_POLICY: {
            routeName: "privacy-policy", routeLabel: "Privacy Policy", routeTitle: "Go to Privacy Policy Page", routeURL: "polityka-prywatności"
        },
        LOGIN: {
            routeName: "user-login", routeLabel: "Login", routeTitle: "Go to login Page", routeURL: "logowanie"
        },
        REGISTER: {
            routeName: "user-register", routeLabel: "Registration", routeTitle: "Go to Register Page", routeURL: "rejestracja"
        },
        PASSWORD_REMIND: {
            routeName: "user-password-remind", routeLabel: "Remind password", routeTitle: "Go to password remind Page", routeURL: "logowanie/przypomnij-haslo"
        },
        PASSWORD_SET: {
            routeName: "user-password-set", routeLabel: "Set password", routeTitle: "Go to password set Page", routeURL: "logowanie/ustaw-haslo"
        },
        PROFILE: {
            routeName: "user-profile", routeLabel: "My Profile", routeTitle: "Go to profile Page", routeURL: "profil"
        },
        TEAMS: {
            routeName: "teams", routeLabel: "Teams", routeURL: "druzyny", routeTitle: "Go to Team Page"
        },
        PLAYERS: {
            routeName: "players", routeLabel: "Players", routeURL: "zawodnicy", routeTitle: "Go to Player Page"
        },
        SCHEDULES: {
            routeName: "schedules", routeLabel: "Schedules", routeURL: "schedules", routeTitle: "Go to Schedules Page"
        },
        GAMES: {
            routeName: "games", routeLabel: "Games", routeURL: "mecze", routeTitle: "Go to Game Page"
        },
        TABLES: {
            routeName: "tables", routeLabel: "Tables", routeURL: "tabele", routeTitle: "Go to Tables Page"
        },
        E404: {
            routeName: "e404", routeLabel: "Not Found", routeURL: "404", routeTitle: "Not Found"
        }
    }
};


export default Routes;

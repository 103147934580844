import React from "react";


import { Col, Row } from "react-bootstrap";


import GameTablesTeamHeaderLogoComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Header/Logo";
import GameTablesTeamHeaderNameComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Header/Name";
import GameTablesTeamHeaderCrewComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Header/Crew";


import "./index.scss";


import ApiGameSummaryTeamCrewInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Crew";
import ApiGameSummaryTeamDataInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Data";


interface GameTablesTeamHeaderComponentProps {
    TeamData: ApiGameSummaryTeamDataInterface;
    TeamCrew: ApiGameSummaryTeamCrewInterface;
}


export const GameTablesTeamHeaderComponent: React.FC<GameTablesTeamHeaderComponentProps> = ({
    TeamData, TeamCrew
}) : JSX.Element | null => {


    if (!TeamData) return null;


    const {
        team_name: teamName, team_slug: teamSlug, team_logo: teamLogo,
        team_first_color: teamFirstColor, team_second_color: teamSecondColor,
    } = TeamData;


    const teamColor = teamFirstColor || teamSecondColor;


    const {
        team_coach: teamCoach, team_coach_assistant: teamCoachAssistant
    } = TeamCrew;


    return (
        <Col xs={12} className={"game-tables-team-header game-tables-team-header-component"}>
            <Row className={"game-tables-team-header-content"}>

                <GameTablesTeamHeaderLogoComponent
                    teamName={teamName}
                    teamSlug={teamSlug}
                    teamLogoURL={teamLogo}
                />

                <GameTablesTeamHeaderNameComponent
                    teamName={teamName}
                    teamSlug={teamSlug}
                    teamColor={teamColor}
                />

                <GameTablesTeamHeaderCrewComponent
                    teamCoach={teamCoach}
                    teamCoachAssistant={teamCoachAssistant}
                />

            </Row>
        </Col>
    );

};


export default GameTablesTeamHeaderComponent;

import React from "react";


import {Col, Row} from "react-bootstrap";


import LeaguesStatisticsHeaderStatisticComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics/Header/Statistic";


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import StatisticsTypes from "@GlobalData/Statistics/Types";


interface LeaguesStatisticsHeaderProps {
    selectedStatisticsType: StatisticsType | string;
    selectedStatisticsTypeHandler(type: StatisticsType): void;
}


export const LeaguesStatisticsHeaderComponent: React.FC<LeaguesStatisticsHeaderProps> = ({
    selectedStatisticsType = "TPTS",
    selectedStatisticsTypeHandler
}) : JSX.Element | null => {


    const StatisticsTableHeader = Object.entries(StatisticsTypes).map(([statisticsTypeName, statisticsTypeData]) =>
        <LeaguesStatisticsHeaderStatisticComponent
            key={statisticsTypeName}
            statisticType={statisticsTypeData}
            statisticTypeName={statisticsTypeName}
            selectedStatisticsType={selectedStatisticsType}
            selectedStatisticsTypeHandler={selectedStatisticsTypeHandler}
        />
    );


    return (
        <Col xs={3} lg={12} className={"league-statistics-header league-statistics-header-component"}>
            <Row className={"league-statistics-header-content"}>
                {StatisticsTableHeader}
            </Row>
        </Col>
    );

};


export default LeaguesStatisticsHeaderComponent;

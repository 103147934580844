import React from "react";


import {Col, Row} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";


export interface PlayerAvatarComponentProps {
    avatarURL?: string | null;
    playerName: string;
}


export const PlayerAvatarComponent: React.FC<PlayerAvatarComponentProps> = ({
    avatarURL, playerName
}) : JSX.Element | null => {


    return (
        <Col xs={4} lg={3} className={"player-avatar"}>
            <Row as={"picture"} className={"player-avatar-picture"}>
                <img src={avatarURL || noAvatarImage} alt={playerName} />
            </Row>
        </Col>
    );

};


export default PlayerAvatarComponent;

import React from "react";

import UseApiRequestStatus from "@States/Application/ApiRequest";


import {Form} from "react-bootstrap";


import FormFieldComponent from "@Components/Elements/Forms/_Common/Fields/_Field";


import FormFieldValueErrorInterface from "@Components/Elements/Forms/_Common/_Types/Errors/ValueError";
import SelectFieldOptionType from "@CommonFormsTypes/Fields/Select";


export interface FormSelectFieldComponentProps {
    fieldName?: string;
    fieldOptions: SelectFieldOptionType[];
    fieldValue: string | number;
    fieldValueHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    fieldLabel?: string;
    fieldError?: FormFieldValueErrorInterface | null;
    fieldCssClasses?: string | string[];
    fieldDisabledStatus?: boolean;
}


export const FormSelectFieldComponent: React.FC<FormSelectFieldComponentProps> = ({
    fieldName = "",
    fieldValue = "", fieldValueHandler, fieldOptions,
    fieldLabel = "",
    fieldError = null,
    fieldCssClasses = "",
    fieldDisabledStatus = false
}) : JSX.Element | null => {


    const apiRequestStatus = UseApiRequestStatus();


    const FieldOptions = fieldOptions.map((option) =>
        <option key={option.value} value={option.value}>{option.label}</option>
    );


    return (
        <FormFieldComponent
            fieldType={"select"}
            fieldLabel={fieldLabel}
            fieldError={fieldError}
            fieldCssClasses={fieldCssClasses}
        >

            <Form.Control
                as={"select"}
                name={fieldName}
                value={fieldValue}
                onChange={fieldValueHandler}
                disabled={fieldDisabledStatus || apiRequestStatus}
                className={"form-field-select" + (!!fieldError ? " error" : "")}
            >
                {FieldOptions}
            </Form.Control>

        </FormFieldComponent>
    )

};


export default FormSelectFieldComponent;

import React from "react";


import {Col, Row} from "react-bootstrap";


import PlayerProfileContentTypeType from "@Components/Views/Pages/User/Authenticated/Profile/Player/_ContentType";


export interface PlayerProfileNavigationMenuElementComponentProps {
    elementText: string;
    elementContentType: PlayerProfileContentTypeType;
    selectedContentType: PlayerProfileContentTypeType;
    selectedContentTypeHandler: (contentType: PlayerProfileContentTypeType) => void;
}


export const PlayerProfileNavigationMenuElementComponent: React.FC<PlayerProfileNavigationMenuElementComponentProps> = ({
    elementContentType, elementText,
    selectedContentType, selectedContentTypeHandler,
}) : JSX.Element | null => {


    return (
        <Col xs={12} className={"user-profile-page-menu-element"}>
            <Row className={"user-profile-page-menu-element-content"}>

                <Col
                    xs={12}
                    onClick={() => selectedContentTypeHandler(elementContentType)}
                    className={"user-profile-page-menu-element-button" + (selectedContentType === elementContentType ? " selected" : "")}
                >
                    <span>{elementText}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerProfileNavigationMenuElementComponent;

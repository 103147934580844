import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";
import ApiGameSummaryQuarterType from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter/Type";

import TeamsSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams";
import TeamSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams/Team";
import TeamStatisticsInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams/Team/Statistics";
import TeamSummaryStatisticsInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams/Team/SummaryStatistics";

import PlayerSummaryStatisticsInterface from "@GameResultPageTypes/Summary/GUI/_Main/Players/Player/Statistics";


export const prepareTeamsSummaryStatistics = (
    GameSummaryStatistics: ApiGameSummaryInterface,
    SelectedQuarters: ApiGameSummaryQuarterType[]
) => {


    if (!GameSummaryStatistics || !SelectedQuarters) return null;


    const Teams: TeamsSummaryInterface = {};


    const {
        quarters: GameQuarters, squads: GameSquads
    } = GameSummaryStatistics;


    if (!GameQuarters || !GameSquads) return null;


    for (let [teamID, TeamSquad] of Object.entries(GameSquads)) {

        /**
         * Team Statistics
         */
        let TEAM_O_REB = 0; let TEAM_D_REB = 0; let TEAM_T_REB = 0;
        let TEAM_TO = 0;
        let TEAM_FOUL = 0;

        /**
         * Team Summary Statistics
         */
        let S_PT1 = 0; let S_PT2 = 0; let S_PT3 = 0;
        let S_PT1A = 0; let S_PT2A = 0; let S_PT3A = 0;
        let S_AST = 0; let S_BLK = 0; let S_STL = 0;
        let S_O_REB = 0; let S_D_REB = 0; let S_TO = 0;
        let S_FOUL = 0; let S_FOUL_TECH = 0; let S_FOUL_NS = 0; let S_FOUL_DSQ = 0;

        for (let quarterNumber of SelectedQuarters) {

            if (!quarterNumber) continue;

            const Quarter = GameQuarters[quarterNumber];

            if (!Quarter) continue;

            const { teams: QuarterTeams } = Quarter;

            const Team = QuarterTeams[+teamID];

            if (!Team) continue;

            const { quarter_results: TeamQuarterResults } = Team;

            const {
                TEAM_O_REB: T_O_REB, TEAM_D_REB: T_D_REB, TEAM_REB: T_T_REB,
                "1PT": PT1, "1PTA": PT1A, "2PT": PT2, "2PTA": PT2A, "3PT": PT3, "3PTA": PT3A,
                AST, BLK, STL, PLAYER_O_REB, PLAYER_D_REB,
                TO, TTO, FOUL, FOUL_TECH, FOUL_NS, FOUL_DSQ, CO_FOUL, CO_FOUL_TECH, CO_FOUL_DSQ,
            } = TeamQuarterResults;

            if (T_O_REB) TEAM_O_REB += T_O_REB;
            if (T_D_REB) TEAM_D_REB += T_D_REB;
            if (T_T_REB) TEAM_T_REB += T_T_REB;

            if (TTO) TEAM_TO += TTO;

            if (CO_FOUL) TEAM_FOUL += CO_FOUL;
            if (CO_FOUL_TECH) TEAM_FOUL += CO_FOUL_TECH;
            if (CO_FOUL_DSQ) TEAM_FOUL += CO_FOUL_DSQ;

            if (PT1) S_PT1 += PT1;
            if (PT1A) S_PT1A += PT1A;
            if (PT2) S_PT2 += PT2;
            if (PT2A) S_PT2A += PT2A;
            if (PT3) S_PT3 += PT3;
            if (PT3A) S_PT3A += PT3A;

            if (AST) S_AST += AST;
            if (BLK) S_BLK += BLK;
            if (STL) S_STL += STL;
            if (PLAYER_O_REB) S_O_REB += PLAYER_O_REB;
            if (PLAYER_D_REB) S_D_REB += PLAYER_D_REB;
            if (TO) S_TO += TO;
            if (FOUL) S_FOUL += FOUL;
            if (FOUL_TECH) S_FOUL_TECH += FOUL_TECH;
            if (FOUL_NS) S_FOUL_NS += FOUL_NS;
            if (FOUL_DSQ) S_FOUL_DSQ += FOUL_DSQ;

        }

        const TeamStatistics: TeamStatisticsInterface = {
            T_O_REB: TEAM_O_REB, T_D_REB: TEAM_D_REB, T_T_REB: TEAM_T_REB,
            T_TO: TEAM_TO, T_FOUL: TEAM_FOUL
        }

        const { team_players: TeamSquadPlayers } = TeamSquad;

        const MINUTES = Object.values(TeamSquadPlayers).map((Player) => {
            const { player_seconds: playerSeconds } = Player;
            return playerSeconds || 0;
        });

        const S_MIN =
            MINUTES.length > 1 ?
                MINUTES.reduce((value1, value2) => value1 + value2)
                :
                MINUTES.length ? MINUTES[0] : 0
        ;

        const EFFECTIVITY = Object.values(TeamSquadPlayers).map((Player) => {
            const { player_effectivity: playerEffectivity } = Player;
            return playerEffectivity || 0;
        });

        const S_EFF =
            EFFECTIVITY.length > 1 ?
                EFFECTIVITY.reduce((value1, value2) => value1 + value2)
                :
                EFFECTIVITY.length ? EFFECTIVITY[0] : 0
        ;

        const TeamSummaryStatistics: TeamSummaryStatisticsInterface = {
            PT1: S_PT1, PT1A: S_PT1A, PT2: S_PT2, PT2A: S_PT2A, PT3: S_PT3, PT3A: S_PT3A,
            AST: S_AST, BLK: S_BLK, O_REB: S_O_REB, D_REB: S_D_REB, T_REB: TEAM_T_REB, STL: S_STL,
            TO: S_TO, FOUL: S_FOUL, FOUL_TECH: S_FOUL_TECH, FOUL_NS: S_FOUL_NS, FOUL_DSQ: S_FOUL_DSQ, TEAM_FOUL: TEAM_FOUL,
            SECONDS: S_MIN, EFF: S_EFF,
        }

        const TeamSquadsPlayers: {
            [key: number]: PlayerSummaryStatisticsInterface
        } = {};

        for (let [playerID, PlayerData] of Object.entries(TeamSquadPlayers)) {

            const {
                player_seconds: SECONDS, player_first_squad: FIRST_5,
                player_number: NUMBER, player_effectivity: EFFECTIVITY,
            } = PlayerData;

            let P_PT1 = 0; let P_PT2 = 0; let P_PT3 = 0;
            let P_PT1A = 0; let P_PT2A = 0; let P_PT3A = 0;
            let P_AST = 0; let P_BLK = 0; let P_STL = 0;
            let P_O_REB = 0; let P_D_REB = 0; let P_TO = 0;
            let P_FOUL = 0; let P_FOUL_TECH = 0; let P_FOUL_NS = 0; let P_FOUL_DSQ = 0;

            for (let quarterNumber of SelectedQuarters) {

                if (!quarterNumber) continue;

                const Quarter = GameQuarters[quarterNumber];

                if (!Quarter) continue;

                const {teams: QuarterTeams} = Quarter;

                const Team = QuarterTeams[+teamID];

                const { team_players: TeamPlayers } = Team;

                const PlayerQuarter = TeamPlayers[+playerID];

                if (!PlayerQuarter) continue;

                const { quarter_results: PlayerQuarterStatistics } = PlayerQuarter;

                if (!PlayerQuarterStatistics) continue;

                const {
                    "1PT": PT1, "1PTA": PT1A, "2PT": PT2, "2PTA": PT2A, "3PT": PT3, "3PTA": PT3A,
                    AST, BLK, STL, PLAYER_O_REB, PLAYER_D_REB,
                    TO, FOUL, FOUL_TECH, FOUL_NS, FOUL_DSQ,
                } = PlayerQuarterStatistics;

                if (PT1) P_PT1 += PT1;
                if (PT1A) P_PT1A += PT1A;
                if (PT2) P_PT2 += PT2;
                if (PT2A) P_PT2A += PT2A;
                if (PT3) P_PT3 += PT3;
                if (PT3A) P_PT3A += PT3A;

                if (AST) P_AST += AST;
                if (BLK) P_BLK += BLK;
                if (STL) P_STL += STL;
                if (PLAYER_O_REB) P_O_REB += PLAYER_O_REB;
                if (PLAYER_D_REB) P_D_REB += PLAYER_D_REB;
                if (TO) P_TO += TO;
                if (FOUL) P_FOUL += FOUL;
                if (FOUL_TECH) P_FOUL_TECH += FOUL_TECH;
                if (FOUL_NS) P_FOUL_NS += FOUL_NS;
                if (FOUL_DSQ) P_FOUL_DSQ += FOUL_DSQ;

                const PlayerStatistics: PlayerSummaryStatisticsInterface = {
                    ID: +playerID,
                    PT1: P_PT1, PT1A: P_PT1A, PT2: P_PT2, PT2A: P_PT2A, PT3: P_PT3, PT3A: P_PT3A,
                    AST: P_AST, BLK: P_BLK, D_REB: P_D_REB, O_REB: P_O_REB, STL: P_STL,
                    TO: P_TO, FOUL: P_FOUL, FOUL_TECH: P_FOUL_TECH, FOUL_NS: P_FOUL_NS, FOUL_DSQ: P_FOUL_DSQ,
                    SECONDS: SECONDS, EFF: EFFECTIVITY, FIRST_5: FIRST_5, NUM: NUMBER
                };

                TeamSquadsPlayers[+playerID] = PlayerStatistics;

            }

        }

        for (let [playerID, PlayerData] of Object.entries(TeamSquadPlayers)) {

            if (!TeamSquadsPlayers[+playerID]) {

                const { player_seconds: playerSeconds, player_number: playerNumber } = PlayerData;

                if (playerSeconds === 0) {

                    const PlayerStatistics: PlayerSummaryStatisticsInterface = {
                        ID: +playerID,
                        PT1: 0, PT1A: 0, PT2: 0, PT2A: 0, PT3: 0, PT3A: 0,
                        AST: 0, BLK: 0, D_REB: 0, O_REB: 0, STL: 0,
                        TO: 0, FOUL: 0, FOUL_TECH: 0, FOUL_NS: 0, FOUL_DSQ: 0,
                        SECONDS: 0, EFF: 0, FIRST_5: false, NUM: playerNumber
                    };

                    TeamSquadsPlayers[+playerID] = PlayerStatistics;

                }

            }

        }

        const Team: TeamSummaryInterface = {
            teamStatistics: TeamStatistics,
            teamSummaryStatistics: TeamSummaryStatistics,
            teamPlayers: TeamSquadsPlayers
        }

        Teams[+teamID] = Team;

    }

    return Teams;


};


export default prepareTeamsSummaryStatistics;

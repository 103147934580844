import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import _restApiRequest from "@Services/HTTP/Requests/Request";
import REST_API_ENDPOINTS_URLS from "@ApiData";


import {Col, Row} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import MessageComponent from "@Components/Elements/Messages/Content";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";

import HttpResponseErrorMessages from "@GlobalData/Messages/HTTP/Response/Errors";
import SystemErrorMessages from "@GlobalData/Messages/System/Errors";
import LeaguesMessages from "@GlobalData/Messages/GUI/Leagues";


import LeagueStatisticsTypesComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics/Types";
import LeaguesStatisticsHeaderComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics/Header";
import LeaguesStatisticsTableContentComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics/Table";
import LeagueStatisticsNumberComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics/Number";


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


export interface LeagueStatisticsComponentProps {
    selectedLeagueID: number;
}

export const LeagueStatisticsComponent: React.FC<LeagueStatisticsComponentProps> = ({
    selectedLeagueID
}) : JSX.Element | null => {


    const LEAGUE_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.LEAGUES.LEAGUE.MAIN_URL;


    const activeLanguage = UseLanguage();


    const [selectedStatisticsMainType, setSelectedStatisticsMainType] = useState<"MEDIUM" | "TOTAL">("MEDIUM");
    const [selectedStatisticsType, setSelectedStatisticsType] = useState<StatisticsType>("TPTS");


    const [leagueStatistics, setLeagueStatistics] = useState<PlayerLeagueStatisticsInterface[] | null>(null);
    const [leagueStatisticsError, setLeagueStatisticsError] = useState<ErrorMessageInterface | null>(null);

    const [leagueStatisticsNumber, setLeagueStatisticsNumber] = useState(10);

    useEffect(() => {

        if (!selectedLeagueID || !LEAGUE_API_URL) return;

        setLeagueStatistics(null);

        const getLeagueStatistics = async () => {

            setLeagueStatisticsError(null);

            try {

                const apiResponse = await _restApiRequest(
                    `${LEAGUE_API_URL}/${selectedLeagueID}/league_statistics/?sort_type=${selectedStatisticsType}&statistics_type=${selectedStatisticsMainType}&results_limit=${leagueStatisticsNumber}`
                );

                if (apiResponse.ok) {
                    const responseContent = await apiResponse.json();
                    if (responseContent?.error) {
                        if (responseContent?.code === 404) {
                            setLeagueStatistics([]);
                        } else {
                            setLeagueStatisticsError({errorCode: apiResponse.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                        }
                    } else {
                        setLeagueStatistics(responseContent);
                    }
                } else {
                    setLeagueStatisticsError({errorCode: apiResponse.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setLeagueStatisticsError({errorCode: 500, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

        };
        getLeagueStatistics();

    }, [selectedLeagueID, selectedStatisticsMainType, selectedStatisticsType, leagueStatisticsNumber]);

    useEffect(() => {
        return () => {
            setSelectedStatisticsMainType("MEDIUM");
            setSelectedStatisticsType("TPTS");
            setLeagueStatistics(null);
            setLeagueStatisticsError(null);
            setLeagueStatisticsNumber(10);
        };
    }, []);


    if (!selectedLeagueID) {
        return null;
    }

    if (!leagueStatistics) {
        return (
            <SingleContentLoaderComponent xs={12} lg={4} cssClasses={"start"} />
        );
    }


    if (leagueStatisticsError) {
        return (
            <MessageComponent xs={12} lg={4} messageText={leagueStatisticsError.errorMessage} cssClasses={"no-margin-top align-content-top"} />
        );
    }


    return (
        <Col xs={12} lg={4} className={"leagues-tables-statistics leagues-tables-statistics-component"}>
            <Row className={"leagues-tables-statistics-content"}>

                <LeagueStatisticsTypesComponent
                    selectedType={selectedStatisticsMainType}
                    selectedTypeHandler={setSelectedStatisticsMainType}
                />

                {!!leagueStatistics?.length &&
                <LeaguesStatisticsHeaderComponent
                    selectedStatisticsType={selectedStatisticsType}
                    selectedStatisticsTypeHandler={setSelectedStatisticsType}
                />
                }

                {!!leagueStatistics &&
                <LeaguesStatisticsTableContentComponent
                    LeaguesStatistics={leagueStatistics}
                    selectedStatisticsMainType={selectedStatisticsMainType}
                />
                }

                {!!leagueStatistics?.length &&
	            <LeagueStatisticsNumberComponent
                    statisticsNumber={leagueStatisticsNumber}
                    statisticsNumberHandler={setLeagueStatisticsNumber}
                />
                }

            </Row>
        </Col>
    );

};


export default LeagueStatisticsComponent;

import _ from "lodash";


import LeagueGamePlayerInterface from "@Interfaces/Api/Leagues/Games/Game/Player";


import SortedBySeasonGamesType from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason";
import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";


export const sortPlayerGamesByLeagues = (
    playerGames: LeagueGamePlayerInterface[] | null
) : SortedBySeasonGamesLeagueInterface[] | null => {


    if (!playerGames) return null;


    if (playerGames.length === 0) return [];


    const PlayerGamesObject: SortedBySeasonGamesType = {};


    for (let playerGame of playerGames) {

        const {
            league_game_league_game: playerGameDetails,
            league_game_team_player_player_id: playerID,
            league_game_team_player_team_id: playerTeamID,
            league_game_team_player_team_data: playerTeamData,
            league_game_team_player_real_seconds: playerSeconds
        } = playerGame;

        if (!playerGameDetails || !playerID || !playerTeamID || !playerSeconds) continue;

        const {
            league_game_league_data: playerGameLeague
        } = playerGameDetails;

        if (!playerGameLeague) continue;

        const {
            league_id: playerGameLeagueID,
            league_name: playerGameLeagueName,
            league_short_name: playerGameLeagueShortName,
            league_start_date: playerGameLeagueStartDate,
            league_end_date: playerGameLeagueEndDate,
            league_season_data: playerGameLeagueSeason
        } = playerGameLeague;

        if (!PlayerGamesObject[playerGameLeagueID]) {

            const gamesLeagueGames = {
                [playerTeamID]: {
                    teamData: playerTeamData,
                    teamGames: []
                }
            };

            PlayerGamesObject[playerGameLeagueID] = {
                gamesLeagueID: playerGameLeagueID,
                gamesLeagueName: playerGameLeagueShortName || playerGameLeagueName,
                gamesLeagueSeason: playerGameLeagueSeason?.league_season_name || null,
                gamesLeagueStartDate: playerGameLeagueStartDate,
                gamesLeagueEndDate: playerGameLeagueEndDate,
                gamesLeagueGames: gamesLeagueGames
            };

        }

        const PlayerLeagueGames = PlayerGamesObject[playerGameLeagueID].gamesLeagueGames;
        if (!PlayerLeagueGames) continue;

        const PlayerLeagueTeamGames = PlayerLeagueGames[playerTeamID];
        if (!PlayerLeagueTeamGames) continue;

        PlayerLeagueTeamGames.teamGames.push(playerGame);

    }


    return _.orderBy(PlayerGamesObject, "gamesLeagueEndDate", "desc");


};


export default sortPlayerGamesByLeagues;

import UseLanguage from "@LocalizationStates/Language";


import Routes from "@Routes";

import RoutesType from "@Routes/_Types/Routes";
import RouteInterface from "@Routes/_Types/Route";


export const UseRoute = (routeName: RoutesType) : RouteInterface => {

    const activeLanguage = UseLanguage();

    return Routes[activeLanguage][routeName];

};


export default UseRoute;

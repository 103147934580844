import React, {useEffect, useState} from "react";


import { UseSetPopupMessageAction } from "@StatesActions";

import { UseCommonDictionaryPhrasesSet } from "@Hooks/Texts";


import { Row, Col } from "react-bootstrap";


import { FormSubmitFieldComponent, FormTextFieldComponent } from "@FormsElements";

import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TeamFileInterface from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/_Types/TeamFile";


import "./index.scss";


export interface TeamFilesListAddFileFormComponentProps {
    fileUploadHandler: (File: TeamFileInterface) => Promise<boolean>;
    fileFormShowStatusHandler: (status: boolean) => void;
}


export const TeamFilesListAddFileFormComponent: React.FC<TeamFilesListAddFileFormComponentProps> = ({
    fileUploadHandler, fileFormShowStatusHandler
}) : JSX.Element | null => {


    const PopupMessage = UseSetPopupMessageAction();


    const ADD_WORD = UseCommonDictionaryPhrasesSet("ADD_WORD");
    const DOCUMENT_NAME_TEXT = UseCommonDictionaryPhrasesSet("DOCUMENT_NAME_TEXT");
    const NO_FILE_TO_UPLOAD_TEXT = UseCommonDictionaryPhrasesSet("NO_FILE_TO_UPLOAD_TEXT");
    const FILE_UPLOAD_SUCCESS_TEXT = UseCommonDictionaryPhrasesSet("FILE_UPLOAD_SUCCESS_TEXT");
    const FILE_UPLOAD_ERROR_TEXT = UseCommonDictionaryPhrasesSet("FILE_UPLOAD_ERROR_TEXT");


    const [teamFileName, setTeamFileName] = useState<string>("");
    const [teamFileFile, setTeamFileFile] = useState<File | null>(null);

    const [formError, setFormError] = useState<string | null>(null);


    useEffect(() => {
        return () => {
            setTeamFileName("");
            setTeamFileFile(null);
            setFormError(null);
        };
    }, []);


    const changeTeamFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileName = e.target.value;
        setTeamFileName(fileName);
    }

    const changeTeamFileFile = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!e.target.files || !e.target.files[0]) return;

        const file = e.target.files[0];

        // const fileType = file.type;
        // ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
        // if (fileType !== "image/jpeg" && fileType !== "image/png") {
        //     UseSetPopupMessage({ type: "ERROR", text: INVALID_FILE_TYPE_MESSAGE });
        //     return;
        // }

        setTeamFileFile(file);

        if (formError) {
            setFormError(null);
        }

    };


    const handleFileUpload = async () => {

        if (!teamFileFile) {
            return setFormError(NO_FILE_TO_UPLOAD_TEXT);
        }

        const TeamFile: TeamFileInterface = {
            fileName: teamFileName,
            fileFile: teamFileFile
        };

        const uploadStatus = await fileUploadHandler(TeamFile);

        if (uploadStatus) {
            PopupMessage({ type: "SUCCESS", text: FILE_UPLOAD_SUCCESS_TEXT });
            return fileFormShowStatusHandler(false);
        }

        setFormError(FILE_UPLOAD_ERROR_TEXT);

    };


    return (
        <Col className={"player-profile__captain-zone__team__team-files__files-list__add-file__form"}>
            <Row className={"player-profile__captain-zone__team__team-files__files-list__add-file__form__content"}>

                <FormTextFieldComponent
                    fieldValue={teamFileName}
                    fieldValueHandler={changeTeamFileName}
                    fieldPlaceholder={DOCUMENT_NAME_TEXT}
                    lg={4}
                />

                <FormTextFieldComponent
                    fieldType={"file"}
                    fieldValueHandler={changeTeamFileFile}
                    lg={2}
                />

                <Col xs={12} lg={4} className={"information-form-field"}>
                    <span>{teamFileFile ? teamFileFile.name : NO_FILE_TO_UPLOAD_TEXT}</span>
                </Col>

                <FormSubmitFieldComponent
                    buttonText={ADD_WORD}
                    buttonClickHandler={handleFileUpload}
                    lg={2}
                />

                {!!formError &&
                <ErrorMessageComponent messageText={formError} cssClasses={"no-margin"} />
                }

            </Row>
        </Col>
    )

};


export default TeamFilesListAddFileFormComponent;

import React from "react";


import DefaultMessageComponent from "@Components/Elements/Messages/Message/_Default";


export type SuccessMessageComponentProps = {
    messageText: string;
};


export const SuccessMessageComponent: React.FC<SuccessMessageComponentProps> = ({
    messageText
}) : JSX.Element | null => {


    if (!messageText) return null;


    return (
        <DefaultMessageComponent messageText={messageText} messageType={"SUCCESS"} />
    );

};


export default SuccessMessageComponent;

import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import ResultsListRoundHeaderComponent from "@ResultsPages/Rounds/Round/Header";
import ResultsListRoundGamesComponent from "@ResultsPages/Rounds/Round/Games";
import ResultsListRoundBestPlayerComponent from "@ResultsPages/Rounds/Round/BestPlayer";


import {
    LeagueRoundInterface
} from "@Interfaces/Api";


import getLeagueRoundLabel from "@Services/Utilities/Games/Leagues/Rounds/Rounds/GetLeagueRoundLabel";


import "./index.scss";


export interface ResultsListRoundComponentProps {
    LeagueRound: LeagueRoundInterface;
}


export const ResultsListRoundComponent: React.FC<ResultsListRoundComponentProps> = ({
    LeagueRound
}) : JSX.Element | null => {


    if (!LeagueRound) {
        return null;
    }


    const {
        league_round_id: leagueRoundID,
        league_round_games: LeagueRoundGames,
        league_round_best_player_data: LeagueRoundBestPlayer
    } = LeagueRound;


    let leagueRoundLabel = getLeagueRoundLabel(LeagueRound);


    return (
        <Col id={`round-${leagueRoundID}-results-list`} xs={12} className={"results-list-component leagues-schedules__league-rounds-list__league_round"} style={{ minHeight: window.innerWidth > 992 && LeagueRoundBestPlayer ? 450 : "none" }}>
            <Row className={"results-list-component-content leagues-schedules__rounds-results-list__league_round__content"}>

                <ResultsListRoundHeaderComponent leagueRoundLabel={leagueRoundLabel} />

                <ResultsListRoundGamesComponent LeagueRoundGames={LeagueRoundGames} />

                <ResultsListRoundBestPlayerComponent BestPlayer={LeagueRoundBestPlayer} />

            </Row>
        </Col>
    );

};


export default ResultsListRoundComponent;

import UserModel from "@Models/Users/User";


import AuthenticationActionTypes from "@AuthenticationAction-Types";
import AuthenticationAction from "@AuthenticationActions";


interface AuthenticationReducerStateInterface {
    loggedUser: UserModel | null;
}

const PLKA_LEAGUE_USER_KEY = `PLKA_${1}_LOGGED_USER`;
const storedUser = localStorage.getItem(PLKA_LEAGUE_USER_KEY);


const AuthenticationReducerState: AuthenticationReducerStateInterface = {
    loggedUser: storedUser ? JSON.parse(storedUser) : null
};


export const AuthenticationReducer = (
    state = AuthenticationReducerState,
    action: AuthenticationAction
) => {

    switch (action.type) {

        case AuthenticationActionTypes.LOG_USER_IN:
            localStorage.setItem(PLKA_LEAGUE_USER_KEY, JSON.stringify(action.user))
            return {...state, loggedUser: action.user};

        case AuthenticationActionTypes.LOG_USER_OUT:
            localStorage.removeItem(PLKA_LEAGUE_USER_KEY);
            return {...state, loggedUser: null};

        default:
            return state;

    }

};


export default AuthenticationReducer;

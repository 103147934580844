import React from "react";


import { UseLeaguesDictionaryPhrasesSet } from "@Hooks/Texts";


import { Row, Col } from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import LeaguesStatisticsTablePlayerComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics/Table/Player";


import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


export interface LeaguesStatisticsTableContentComponentProps {
    LeaguesStatistics: PlayerLeagueStatisticsInterface[];
    selectedStatisticsMainType: "MEDIUM" | "TOTAL";
}


export const LeaguesStatisticsTableContentComponent: React.FC<LeaguesStatisticsTableContentComponentProps> = ({
    LeaguesStatistics, selectedStatisticsMainType
}) => {


    const NO_GAMES_STATISTICS_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_GAMES_STATISTICS_MESSAGE_TEXT");


    if (!LeaguesStatistics) return null;


    const LeagueStatistics = LeaguesStatistics.map((player, i) =>
        <LeaguesStatisticsTablePlayerComponent
            key={i}
            place={i + 1}
            player={player}
            selectedStatisticsMainType={selectedStatisticsMainType}
        />
    );


    if (LeagueStatistics.length === 0) {
        return <MessageComponent messageText={NO_GAMES_STATISTICS_MESSAGE_TEXT} />;
    }


    return (
        <Col xs={9} lg={12} className={"league-statistics-table-content league-statistics-table-content-component"}>
            <Row className={"league-statistics-table-content-content"}>

                {LeagueStatistics}

            </Row>
        </Col>
    )

};


export default LeaguesStatisticsTableContentComponent;

import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";

import _ from "lodash";


import {Col, Form, Row} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";

import PlayersSortLetterComponent from "@Components/Views/Pages/Games/Teams/List/Content/TeamsAndPlayers/Players/Letter";


const TEXTS: any = {
    PL: {
        search_players_label_text: "wyszukaj zawodnika",
        search_players_input_text: "wpisz imię lub nazwisko zawodnika",
        search_players_no_results_message_text: "Brak zawodników spełniajacych kryteria"
    },
    EN: {
        search_players_label_text: "search for player",
        search_players_input_text: "type player's name or surname",
        search_players_no_results_message_text: "no players matching search criteria"
    }
};


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface TeamsAndPlayersPlayersComponentProps {
    teamsList: LeagueTeamInterface[];
}


const PL_LETTERS: {[key: string]: string} = {"ą": "a", "ć": "c", "ę": "e", "ł": "l", "ó": "o", "ś": "s", "ż": "z", "ź": "z"}


export const TeamsAndPlayersPlayersComponent: React.FC<TeamsAndPlayersPlayersComponentProps> = ({
    teamsList
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        search_players_label_text: SEARCH_PLAYERS_LABEL_TEXT,
        search_players_input_text: SEARCH_PLAYERS_INPUT_TEXT,
        search_players_no_results_message_text: SEARCH_PLAYERS_NO_RESULTS_MESSAGE_TEXT
    } = TEXTS[activeLanguage];


    const [playersFilterValue, setPlayersFilterValue] = useState<string>("");


    useEffect(() => {
        setPlayersFilterValue("");
    }, [teamsList]);


    useEffect(() => {
        return () => {
            setPlayersFilterValue("");
        };
    }, []);


    if (!teamsList) return null;


    const Players: {
        [key: string]: LeaguePlayerInterface[]
    } = {};


    for (let Team of teamsList) {

        const {team_players: teamPlayers} = Team;

        if (!teamPlayers) continue;

        for (let Player of teamPlayers) {

            const {
                player_first_name: playerFirstName,
                player_last_name: playerLastName,
                player_nick_name: playerNickName,
                player_pseudonymization_status: playerPseudoStatus
            } = Player;

            if (playerPseudoStatus) {
                if (!playerNickName?.toLowerCase()?.includes(playersFilterValue.toLowerCase())) {
                    continue;
                }
            } else {
                if (
                    !playerFirstName?.toLowerCase()?.includes(playersFilterValue.toLowerCase())
                    &&
                    !playerLastName?.toLowerCase()?.includes(playersFilterValue.toLowerCase())
                ) {
                    continue;
                }
            }

            let sortLetter = playerPseudoStatus && playerNickName ? playerNickName[0].toLowerCase() : playerLastName[0].toLowerCase();
            if (Object.keys(PL_LETTERS).includes(sortLetter)) {
                sortLetter = PL_LETTERS[sortLetter];
            }

            if (!Players[sortLetter]) Players[sortLetter] = [];

            Players[sortLetter].push(Player);

        }

    }


    const PlayersList = Object.entries(Players).map(([letter, players]) =>
        <PlayersSortLetterComponent key={letter} letter={letter} players={players} />
    );


    const SortedPlayersList = _.sortBy(PlayersList, ["key"]);


    return (
        <Col xs={12} className={"teams-and-players-players teams-and-players-players-component"}>
            <Row className={"teams-and-players-players-content"}>

                <Col xs={12} className={"players-filters"}>
                    <Row className={"players-filters-content"}>
                        <Col xs={6} lg={"auto"} className={"players-filters-label"}>
                            <span>{SEARCH_PLAYERS_LABEL_TEXT}</span>
                        </Col>
                        <Col xs={6} lg={"auto"} className={"players-filters-filter"}>
                            <Form.Control
                                value={playersFilterValue}
                                onChange={(e) => setPlayersFilterValue(e.target.value)}
                                placeholder={SEARCH_PLAYERS_INPUT_TEXT}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} className={"players-list"}>
                    <Row className={"players-list-content"}>
                        {!!SortedPlayersList.length ?
                            SortedPlayersList
                            :
                            <MessageComponent messageText={SEARCH_PLAYERS_NO_RESULTS_MESSAGE_TEXT} />
                        }
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersPlayersComponent;

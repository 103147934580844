import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterFoulEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterFoulEventDataComponent: React.FC<QuarterFoulEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.FOUL) {
        return null;
    }

    if (!Event.eventFouledPlayerID || !Event.eventFoulingPlayerID) {
        return null;
    }

    const eventFouledPlayer = GamePlayers[Event.eventFouledPlayerID];
    const eventFoulingPlayer = GamePlayers[Event.eventFoulingPlayerID];

    if (!eventFouledPlayer || !eventFoulingPlayer) {
        return null;
    }

    const FoulEvent = UseProgressEvent("FOUL");
    const FouledEvent = UseProgressEvent("FOULED");

    if (!FoulEvent || !FouledEvent) {
        return null;
    }

    const {
        eventName: { singular: foulEventName }
    } = FoulEvent;

    const {
        eventName: { singular: fouledEventName },
        eventIcon: fouledEventIcon
    } = FouledEvent;


    const {
        player_first_name: fouledPlayerFirstName,
        player_last_name: fouledPlayerLastName,
        player_nick_name: fouledPlayerNickName,
        player_slug: fouledPlayerSlug,
        player_pseudonymization_status: fouledPlayerPseudonymizationStatus,
    } = eventFouledPlayer;

    let fouledPlayerName = "";

    if (Event.eventFouledPlayerNumber) {
        fouledPlayerName += `${Event.eventFouledPlayerNumber} `;
    }

    if (fouledPlayerPseudonymizationStatus) {
        fouledPlayerName += fouledPlayerNickName;
    } else {
        fouledPlayerName += `${fouledPlayerFirstName[0]}. ${fouledPlayerLastName}`
    }

    const {
        player_first_name: foulingPlayerFirstName,
        player_last_name: foulingPlayerLastName,
        player_nick_name: foulingPlayerNickName,
        player_slug: foulingPlayerSlug,
        player_pseudonymization_status: foulingPlayerPseudonymizationStatus,
    } = eventFoulingPlayer;

    let foulingPlayerName = "";

    if (Event.eventFoulingPlayerNumber) {
        foulingPlayerName += `${Event.eventFoulingPlayerNumber} `;
    }

    if (foulingPlayerPseudonymizationStatus) {
        foulingPlayerName += foulingPlayerNickName;
    } else {
        foulingPlayerName += `${foulingPlayerFirstName[0]}. ${foulingPlayerLastName}`
    }


    let HomeTeamData: React.ReactNode = "";
    let AwayTeamData: React.ReactNode = "";


    let eventExtraCssClasses: string[] = [];

    let foulEventExtraLabelText = "";
    const foulEventExtraLabelCSSClasses: string[] = [];

    if (Event.eventFoulType === "T") {
        eventExtraCssClasses.push("technical");
    } else if (Event.eventFoulType === "NS") {
        eventExtraCssClasses.push("unsportsmanlike");
    } else if (Event.eventFoulType === "D") {
        eventExtraCssClasses.push("disqualification");
    }

    if (Event.eventFoulingPlayerFoulsNumber && Event.eventFoulingPlayerFoulsNumber > 4) {
        foulEventExtraLabelCSSClasses.push("player-out");
        eventExtraCssClasses.push("player-out");
    }

    const teamQuarterFoulsNumber = Event.eventFoulingPlayerTeamQuarterFoulsNumber || 0;

    if (homeTeamID === Event.eventFouledPlayerTeamID) {
        HomeTeamData = <>
            <NavLink to={`/${PlayersRoute.routeURL}/${fouledPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name fouled-player"}>{fouledPlayerName}</span>
            </NavLink>
            <span className={"event-label fouled-player"}>{fouledEventName} ({Event.eventFouledPlayerFouledNumber})</span>
        </>;
        AwayTeamData = <>
            {fouledEventIcon && <img src={fouledEventIcon} className={"event-icon"} alt={""} />}
            <NavLink to={`/${PlayersRoute.routeURL}/${foulingPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name fouling-player"}>{foulingPlayerName}</span>
            </NavLink>
            <span className={"event-label" + (foulEventExtraLabelCSSClasses ? ` ${foulEventExtraLabelCSSClasses.join(" ")}` : "" )}>{foulEventName} (O: {Event.eventFoulingPlayerFoulsNumber} {!!teamQuarterFoulsNumber && `, D:${teamQuarterFoulsNumber}`}) {foulEventExtraLabelText}</span>
            {Event?.eventDescription?.text && <span className={"event-description"}>- {Event?.eventDescription?.text}</span>}
        </>;
    } else if (awayTeamID === Event.eventFouledPlayerTeamID) {
        AwayTeamData = <>
            <NavLink to={`/${PlayersRoute.routeURL}/${fouledPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name fouled-player"}>{fouledPlayerName}</span>
            </NavLink>
            <span className={"event-label fouled-player"}>{fouledEventName} ({Event.eventFouledPlayerFouledNumber})</span>
        </>;
        HomeTeamData = <>
            {fouledEventIcon && <img src={fouledEventIcon} className={"event-icon"} alt={""} />}
            <NavLink to={`/${PlayersRoute.routeURL}/${foulingPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name fouling-player"}>{foulingPlayerName}</span>
            </NavLink>
            <span className={"event-label"  + (foulEventExtraLabelCSSClasses ? ` ${foulEventExtraLabelCSSClasses.join(" ")}` : "" )}>{foulEventName} (O: {Event.eventFoulingPlayerFoulsNumber} {!!teamQuarterFoulsNumber && `, D:${teamQuarterFoulsNumber}`}) {foulEventExtraLabelText}</span>
            {Event?.eventDescription?.text && <span className={"event-description"}>- {Event?.eventDescription?.text}</span>}
        </>;
    }


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--foul-event" + (eventExtraCssClasses ? ` ${eventExtraCssClasses.join(" ")}` : "")}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} lg={4} style={{ order: 1 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        <span className={"event-player-name"}>{HomeTeamData}</span>
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={["order-2"]}
                />

                <Col xs={4} lg={4} style={{ order: 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        <span className={"event-player-name"}>{AwayTeamData}</span>
                    </Row>
                </Col>

            </Row>
        </Col>
    )

};


export default QuarterFoulEventDataComponent;

import React from "react";


import {
    HomePageSlideInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface HomePageSlideComponentProps {
    HomePageSlide: HomePageSlideInterface;
}


export const HomePageSlideComponent: React.FC<HomePageSlideComponentProps> = ({
    HomePageSlide
}) : JSX.Element | null => {


    if (!HomePageSlide) {
        return null;
    }


    const {
        home_page_slide_title: homePageSlideTitle,
        home_page_slide_url: homePageSlideURL,
        home_page_slide_image: homePageSlideImage,
        home_page_slide_active_status: homePageSlideActiveStatus
    } = HomePageSlide;


    if (!homePageSlideActiveStatus) {
        return null;
    }


    const homePageSlideCLickHandler = (e: React.MouseEvent) => {
        if (homePageSlideURL) {
            return null;
        }
        return e.preventDefault();
    };


    const homePageSlideCSSClasses = [];

    if (!homePageSlideURL) {
        homePageSlideCSSClasses.push("no-url")
    }


    return (
        <div className={"home-page__slider__slide"}>
            <a href={homePageSlideURL} title={homePageSlideTitle} className={homePageSlideCSSClasses.join(" ")} onClick={homePageSlideCLickHandler} target={"_blank"} rel={"noreferrer noopener"} >
                <img src={homePageSlideImage} alt={homePageSlideTitle} />
            </a>
        </div>
    );

};


export default HomePageSlideComponent;

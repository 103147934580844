export * from "@GuiInterfaces/Common/Files/FilesTypes";


import {
    AiFillFileZip, FaFile, FaFileImage, FaFilePdf
} from "react-icons/all";

import FilesTypesInterface from "@GuiInterfaces/Common/Files/FilesTypes";


export const FilesTypes: FilesTypesInterface = {
    "PDF": FaFilePdf,
    "IMAGE": FaFileImage,
    "ZIP": AiFillFileZip,
    "UNKNOWN": FaFile
};

import React, {useState} from "react";

import { UseApiRequestStatus } from "@ApplicationStates";

import {
    UseCommonDictionaryPhrasesSet, UseFormsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {Col, Row} from "react-bootstrap";


import FormFieldComponent from "@Components/Elements/Forms/_Common/Fields/_Field";


import FormFieldValueErrorInterface from "@Components/Elements/Forms/_Common/_Types/Errors/ValueError";

import {
    AiFillCloseCircle, AiFillEdit, AiFillSave
} from "react-icons/all";


export interface FormTextareaFieldComponentProps {
    fieldName?: string;
    fieldValue: string;
    fieldValueHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    fieldLabel?: string;
    fieldPlaceholder?: string;
    fieldError?: FormFieldValueErrorInterface | null;
    fieldCssClasses?: string | string[];
    fieldEditableStatus?: boolean;
    fieldSaveDataHandler?: (fName: string, fValue: string, callback: (status: boolean) => void) => void;
    xs?: number | {span: number, offset?: number}; lg?: number | {span: number, offset?: number};
}


export const FormTextareaFieldComponent: React.FC<FormTextareaFieldComponentProps> = ({
    fieldName= "",
    fieldValue, fieldValueHandler,
    fieldLabel = "", fieldPlaceholder = "",
    fieldError = null,
    fieldCssClasses = "",
    fieldEditableStatus = true, fieldSaveDataHandler = null,
    xs = 12, lg = 12
}) : JSX.Element | null => {


    const EDIT = UseFormsDictionaryPhrasesSet("EDIT");
    const SAVE = UseFormsDictionaryPhrasesSet("SAVE");
    const ABORT = UseFormsDictionaryPhrasesSet("ABORT");
    const LACK_WORD = UseCommonDictionaryPhrasesSet("LACK_WORD");

    const [formFieldEditableStatus, setFormFieldEditableStatus] = useState(fieldEditableStatus);


    const apiRequestStatus = UseApiRequestStatus();

    return (
        <FormFieldComponent
            xs={xs} lg={lg}
            fieldType={"textarea"}
            fieldLabel={fieldLabel}
            fieldError={fieldError}
            fieldCssClasses={fieldCssClasses}
        >

            {formFieldEditableStatus ?

            <Col
                as={"textarea"}
                xs={!!fieldSaveDataHandler && !fieldLabel ? 10 : 12}
                name={fieldName}
                value={fieldValue}
                onChange={fieldValueHandler}
                placeholder={fieldPlaceholder}
                disabled={!!apiRequestStatus}
                className={"form-field-input form-control" + (!!fieldError ? " error" : "")}
            />

            :

            <Col xs={!!fieldSaveDataHandler && !fieldLabel ? 10 : 12} className={"form-field-text"}>
                <span className={"form-field-not-editable-value" + (!fieldValue ? " empty" : "")}>
                    {fieldValue || LACK_WORD}
                </span>
            </Col>

            }

            {!!fieldSaveDataHandler &&
            <Col xs={formFieldEditableStatus ? 12 : 2} className={"form-field-actions" + (formFieldEditableStatus ? " active" : "")}>
                <Row className={"form-field-actions-content"}>
                    {!formFieldEditableStatus &&
                    <Col xs={"auto"} className={"form-field-action edit-action"}>
                        <span onClick={() => setFormFieldEditableStatus(true)} title={EDIT}>
                            <AiFillEdit/>
                        </span>
                    </Col>
                    }
                    {!!formFieldEditableStatus &&
                    <Col xs={"auto"} className={"form-field-action abort-action"}>
                        <span onClick={() => setFormFieldEditableStatus(false)}>
                            <span>{ABORT}</span>
                            <AiFillCloseCircle/>
                        </span>
                    </Col>
                    }
                    {!!formFieldEditableStatus &&
                    <Col xs={"auto"} className={"form-field-action save-action"}>
                        <span onClick={() => fieldSaveDataHandler(fieldName, fieldValue, setFormFieldEditableStatus)}>
                            <span>{SAVE}</span>
                            <AiFillSave/>
                        </span>
                    </Col>
                    }
                </Row>
            </Col>
            }

        </FormFieldComponent>
    );

};


export default FormTextareaFieldComponent;

import React, {useEffect, useRef} from "react";


import {
    UseRoute
} from "@Hooks/Routes";

import {
    UseErrorMessage, UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    UseArticleDetail
} from "@Hooks/Api";


import {
    match, Redirect
} from "react-router-dom";


import {
    MainContentLoaderComponent
} from "@LoadersElements";


import ErrorMessageComponent from "@MessagesElements/Message/Error";


import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import ArticleDetailContentComponent from "@Pages/Common/ArticlesPage/ArticleDetail/ArticleContent";


import scrollToDomElement from "@Utilities/Scrolling";


import "./index.scss";


export interface ArticleDetailPageComponentProps {
    match: match<{ articleSlug: string; }>;
}


export const ArticleDetailPageComponent: React.FC<ArticleDetailPageComponentProps> = ({
    match
}) : JSX.Element | null => {


    const HomePageRoute = UseRoute("HOME_PAGE");
    const E404Route = UseRoute("E404");


    const DEFAULT_DATA_REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");
    const NEWS_PAGE_HEADER_TEXT = UseGlobalsDictionaryPhrasesSet("NEWS_PAGE_HEADER_TEXT");


    const articleSlug = match?.params?.articleSlug;

    const [Article, ArticleError] = UseArticleDetail(articleSlug);


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef.current) {
            return
        }
        scrollToDomElement(ComponentRef.current, 250)
    }, [ComponentRef, Article]);


    if (!articleSlug) {
        return <Redirect to={`/${HomePageRoute.routeURL}`} />;
    }

    if (ArticleError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR_MESSAGE} />
    }

    if (!Article) {
        return <MainContentLoaderComponent />;
    }


    const {
        article_published_status: articlePublishedStatus,
        article_archive_status: articleArchivedStatus,
    } = Article;


    if (!articlePublishedStatus || articleArchivedStatus) {
        return <Redirect to={`/${E404Route.routeURL}`} />;
    }


    return (
        <PageContainerComponent forwardRef={ComponentRef} cssClasses={"article-detail-page article-detail"}>

            <DefaultPageHeaderComponent headerText={NEWS_PAGE_HEADER_TEXT} />

            <PageContentComponent>

                <ArticleDetailContentComponent Article={Article} />

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default ArticleDetailPageComponent;

import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        statistics_types_label_text: "Statystyki",
        statistics_type_medium_text: "Średnie",
        statistics_type_total_text: "Sumy",
    },
    EN: {
        statistics_types_label_text: "Statistics",
        statistics_type_medium_text: "Medium",
        statistics_type_total_text: "Total",
    }
};


export interface LeagueStatisticsTypesComponentProps {
    selectedType: "MEDIUM" | "TOTAL";
    selectedTypeHandler: (type: "MEDIUM" | "TOTAL") => void;
}


export const LeagueStatisticsTypesComponent: React.FC<LeagueStatisticsTypesComponentProps> = ({
    selectedType, selectedTypeHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        statistics_types_label_text: STATISTICS_TYPES_LABEL_TEXT,
        statistics_type_medium_text: STATISTICS_TYPE_MEDIUM_TEXT,
        statistics_type_total_text: STATISTICS_TYPE_TOTAL_TEXT
    } = TEXTS[activeLanguage];


    return (
        <Col xs={12} className={"league-statistics-types league-statistics-types-component"}>
            <span className={"types-label"}>{STATISTICS_TYPES_LABEL_TEXT}</span>
            <span className={"types-type" + (selectedType === "MEDIUM" ? " selected" : "")} onClick={() => selectedTypeHandler("MEDIUM")}>{STATISTICS_TYPE_MEDIUM_TEXT}</span>
            <span className={"types-separator"}>/</span>
            <span className={"types-type" + (selectedType === "TOTAL" ? " selected" : "")} onClick={() => selectedTypeHandler("TOTAL")}>{STATISTICS_TYPE_TOTAL_TEXT}</span>
        </Col>
    );

};


export default LeagueStatisticsTypesComponent;

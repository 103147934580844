import React, {useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";

import {BiDownArrow, BiUpArrow} from "react-icons/all";


import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";


const TEXTS: any = {
    PL: {played_games_header_text: "Mecz po meczu"},
    EN: {played_games_header_text: "Game by game"}
}


interface PlayedGamesHeaderComponentProps {
    selectedLeagueID: number | null;
    selectedLeagueIDHandler(leagueID: number): void;
    selectedLeague: SortedBySeasonGamesLeagueInterface | null;
    leaguesGames: SortedBySeasonGamesLeagueInterface[];
}


export const PlayedGamesHeaderComponent: React.FC<PlayedGamesHeaderComponentProps> = ({
    selectedLeagueID, selectedLeagueIDHandler, selectedLeague,
    leaguesGames
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        played_games_header_text: PLAYED_GAMES_HEADER_TEXT
    } = TEXTS[activeLanguage];


    const [leaguesListOpenStatus, setLeaguesListOpenStatus] = useState(false);


    if (!selectedLeagueID || !selectedLeague) return null;


    const changeSelectedLeague = (leagueID: number) => {
        setLeaguesListOpenStatus(false);
        selectedLeagueIDHandler(leagueID);
    };


    const LeaguesList = leaguesGames.map((League, i) => {

        if (League.gamesLeagueID === selectedLeagueID) return null;

        return (
            <Col xs={12} onClick={() => changeSelectedLeague(League.gamesLeagueID)} key={i} className={"played-games-league"}>
                <span>{League.gamesLeagueName}</span>
            </Col>
        );

    });


    return (
        <Col xs={`auto`} className={"played-games-header played-games-header-component"}>
            <Row className={"played-games-header-content"}>

                <Col xs={"auto"} className={"played-games-header-label"}>
                    <span>{PLAYED_GAMES_HEADER_TEXT} - </span>
                </Col>

                <Col xs={"auto"} className={"played-games-leagues-list"}>

                    <Row className={"played-games-active-league"}>
                        <Col xs={12} className={"played-games-league"}>
                            <span>{selectedLeague.gamesLeagueName}</span>
                            {!!selectedLeague.gamesLeagueSeason && <span>{selectedLeague.gamesLeagueSeason}</span>}
                        </Col>
                    </Row>

                    {LeaguesList.length && leaguesListOpenStatus &&
	                <Row className={"played-games-leagues"}>
                        {LeaguesList}
	                </Row>
                    }

                </Col>

                {LeaguesList.length > 1 &&
	            <Col className={"played-games-leagues-switcher" + (leaguesListOpenStatus ? " opened" : "")}>
                    <span onClick={() => setLeaguesListOpenStatus(!leaguesListOpenStatus)}>
                        {leaguesListOpenStatus ? <BiUpArrow/> : <BiDownArrow/>}
                    </span>
	            </Col>
                }

                <Col className={"played-games-leagues-bg-1"}></Col>
                <Col className={"played-games-leagues-bg-2"}></Col>

            </Row>
        </Col>
    );

};


export default PlayedGamesHeaderComponent;

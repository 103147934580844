import React from "react";


import { Col } from "react-bootstrap";


import { LeagueSeasonInterface } from "@Interfaces/Api";


export interface SchedulesAdminLeagueSeasonComponentProps {
    LeagueSeason: LeagueSeasonInterface | null | undefined;
}


export const SchedulesAdminLeagueSeasonComponent: React.FC<SchedulesAdminLeagueSeasonComponentProps> = ({
    LeagueSeason
}) : JSX.Element | null => {


    if (!LeagueSeason) {
        return (
            <Col xs={2}>
                <span>{`Brak Sezonu`}</span>
            </Col>
        );
    }


    const {
        league_season_name: seasonName
    } = LeagueSeason;


    return (
        <Col xs={2}>
            <span>{seasonName}</span>
        </Col>
    );


};


export default SchedulesAdminLeagueSeasonComponent;

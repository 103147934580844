import $ from "jquery";


export const scrollToDomSelector = (selector: string) => {

    if (!selector) {
        return;
    }

    const DomElement = $(selector);

    if (DomElement) {

        const position: number | undefined = DomElement?.offset()?.top;

        if (!position) return;

        $("body, html").animate(
            {
                scrollTop: position
            },
            1000
        );
    }

};


export default scrollToDomSelector;

import React from "react";


import { UseCommonDictionaryPhrasesSet } from "@Hooks/Texts";


import { Button, Col } from "react-bootstrap";


import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";
import ApiGameSummaryTeamPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Players";


export interface GameThrowsTeamSelectAllComponentProps {
    GameTeamPlayers: ApiGameSummaryTeamPlayersInterface;
    GamePlayers: ApiGameSummaryPlayersInterface;
    SelectedPlayers: number[];
    selectedPlayersHandler(playerId: number | number[]): void;
}


export const GameThrowsTeamSelectAllComponent: React.FC<GameThrowsTeamSelectAllComponentProps> = ({
    GameTeamPlayers,
    SelectedPlayers, selectedPlayersHandler
}) : JSX.Element | null => {


    const SELECT_ALL_MAN = UseCommonDictionaryPhrasesSet("SELECT_ALL_MAN");
    const UN_SELECT_ALL_MAN = UseCommonDictionaryPhrasesSet("UN_SELECT_ALL_MAN");


    let allPlayersSelected = true;

    for (let playerID of Object.keys(GameTeamPlayers)) {

        if (!SelectedPlayers.includes(+playerID)) {
            allPlayersSelected = false;
        }

    }

    const changeAllPlayersSelectedHandler = () => {

        let updatedPlayers = [...SelectedPlayers];

        for (let playerID of Object.keys(GameTeamPlayers)) {

            if (allPlayersSelected) {
                updatedPlayers = updatedPlayers.filter((id) => id !== +playerID);
            } else {
                updatedPlayers.push(+playerID);
            }

        }

        selectedPlayersHandler(updatedPlayers);

    };


    return (
        <Col xs={12} lg={5} className={"throws-team-select-all-button"}>

            <Button className={""} onClick={changeAllPlayersSelectedHandler}>
                <span>{allPlayersSelected ? UN_SELECT_ALL_MAN : SELECT_ALL_MAN}</span>
            </Button>

        </Col>
    );

};


export default GameThrowsTeamSelectAllComponent;

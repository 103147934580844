import React, { useEffect, useState } from "react";


import _ from "lodash";


import {
    API_LEAGUES_LIST_ENDPOINT_URL, API_LEAGUE_ROUNDS_LIST_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _getDataFromApi from "@Services/API/Requests";


import {
    UseErrorMessage, UseLeaguesDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Container } from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";


import {
    DefaultPageHeaderComponent
} from "@ContentElements";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import ResultsPageDataSwitcherComponent from "@Components/Views/Pages/Games/Results/Switcher";
import ResultsPageResultsListComponent from "@Components/Views/Pages/Games/Results/Rounds";


import scrollToDomSelector from "@Services/Utilities/Tools/Animations/Scrolling/ScrollToDomSelector";

import {
    getClosestToToday
} from "@Services/Utilities/Games/Leagues/Rounds/Rounds/GetClosestToToday";


import {
    LeagueInterface, LeagueRoundInterface
} from "@Interfaces/Api";


import "./index.scss";
import {UseLeaguesList} from "@Services/Utilities/ApiHooks";
import MessageComponent from "@MessagesElements/Content";


export interface LeagueResultsPageComponentProps {}


export const LeagueResultsPageComponent: React.FC<LeagueResultsPageComponentProps> = () : JSX.Element | null => {


    const DEFAULT_DATA_REQUEST_ERROR = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");
    const LEAGUE_SCHEDULE_TEXT = UseLeaguesDictionaryPhrasesSet("LEAGUE_SCHEDULE_TEXT");
    const NO_LEAGUES_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_LEAGUES_MESSAGE_TEXT");

    /**
     * Leagues
     */

    /** */
    const [Leagues, setLeagues] = useState<LeagueInterface[] | null>(null);
    const [LeaguesError, setLeaguesError] = useState<ErrorMessageInterface | null>(null);

    /** */
    useEffect(() => {
        /** Get Leagues in active Season */
        UseLeaguesList(
            setLeagues, setLeaguesError, true
        );
    }, []);

    /** */
    const [selectedLeagueID, setSelectedLeagueID] = useState<number>(0);

    const selectedLeagueHandler = (leagueID: number) => {
        setSelectedLeagueID(leagueID);
        localStorage.setItem("PLKA_SELECTED_LEAGUE_ID", leagueID.toString());
    }

    /** */
    useEffect(() => {

        const storedSelectedLeagueID = +(localStorage.getItem("PLKA_SELECTED_LEAGUE_ID") || 0)

        if (!Leagues) {
            return;
        }

        if (storedSelectedLeagueID) {

            const selectedLeaguePresentStatus = Leagues.filter((League) => {
                const { league_id: leagueID } = League;
                if (leagueID === storedSelectedLeagueID) {
                    return true;
                }
                return false;
            })[0];

            if (!selectedLeaguePresentStatus) {

                if (Leagues && Leagues[0]) {
                    const {
                        league_id: defaultSelectedLeagueID
                    } = Leagues[0];
                    return selectedLeagueHandler(defaultSelectedLeagueID);
                }

                return selectedLeagueHandler(0);

            } else {
                const { league_id: leagueID } = selectedLeaguePresentStatus;
                return selectedLeagueHandler(leagueID);
            }

        } else {
            if (Leagues && Leagues[0]) {
                const {
                    league_id: defaultSelectedLeagueID
                } = Leagues[0];
                return selectedLeagueHandler(defaultSelectedLeagueID);
            }
        }

    }, [Leagues]);

    /**
     * /Leagues
     */


    /**
     * League Rounds
     */

    const [SelectedLeagueRounds, setSelectedLeagueRounds] = useState<LeagueRoundInterface[] | null>(null);
    const [SelectedLeagueRoundsError, setSelectedLeagueRoundsError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!selectedLeagueID || !Leagues) {
            setSelectedLeagueRounds(null);
            setSelectedLeagueRoundsError(null);
            return;
        }

        setSelectedLeagueRoundID(0);

        const getLeagueRounds = async () => {
            await _getDataFromApi(
                API_LEAGUE_ROUNDS_LIST_ENDPOINT_URL.replace("{LEAGUE_ID}", selectedLeagueID.toString()),
                setSelectedLeagueRounds, setSelectedLeagueRoundsError
            );
        };

        getLeagueRounds();

    }, [selectedLeagueID, Leagues]);

    const [selectedLeagueRoundID, setSelectedLeagueRoundID] = useState<number>(0);

    useEffect(() => {

        if (!SelectedLeagueRounds) {
            return
        }

        const LeagueRoundClosestToToday = getClosestToToday(SelectedLeagueRounds);

        if (LeagueRoundClosestToToday) {

            const {
                league_round_id: leagueRoundID
            } = LeagueRoundClosestToToday;

            setSelectedLeagueRoundID(leagueRoundID);

        }

    }, [SelectedLeagueRounds]);

    useEffect(() => {

        if (!selectedLeagueRoundID) {
            return;
        }

        scrollToDomSelector(`#round-${selectedLeagueRoundID}-results-list`);

    }, [selectedLeagueRoundID]);


    /**
     *
     */

    useEffect(() => {
        return () => {
            setLeagues(null);
            setLeaguesError(null);
            setSelectedLeagueRounds(null);
            setSelectedLeagueRoundsError(null);
            setSelectedLeagueID(0);
            setSelectedLeagueRoundID(0);
        };
    }, []);


    if (LeaguesError || SelectedLeagueRoundsError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR} />;
    }

    if (Leagues && !Leagues.length) {
        return <MessageComponent messageText={NO_LEAGUES_MESSAGE_TEXT} cssClasses={"margin-top-30 padding-15"} />;
    }

    if (!Leagues || !SelectedLeagueRounds) {
        return <MainContentLoaderComponent />;
    }

    if (!Leagues.length) {
        return <MessageComponent messageType={"warning"} messageText={NO_LEAGUES_MESSAGE_TEXT} cssClasses={"margin-top-30 bordered padding-15"} />;
    }




    const selectedLeagueRoundHandler = (leagueRoundID: number) => {
        setSelectedLeagueRoundID(leagueRoundID);
    }


    const SelectedLeagueRoundsList = _.sortBy(
        SelectedLeagueRounds,
        ["league_round_order_nr", "league_round_type", "league_round_number"]
    );


    return (
        <Container fluid={true} id={"results-page-component"} className={"page-container-component leagues-schedules"}>

            <DefaultPageHeaderComponent headerText={LEAGUE_SCHEDULE_TEXT} />

            <ResultsPageDataSwitcherComponent
                selectedLeagueID={selectedLeagueID}
                selectedLeagueHandler={selectedLeagueHandler}
                selectedLeagueRoundID={selectedLeagueRoundID}
                selectedLeagueRoundIDHandler={selectedLeagueRoundHandler}
                LeaguesList={Leagues}
                SelectedLeagueRoundsList={SelectedLeagueRoundsList}
            />

            <ResultsPageResultsListComponent
                LeagueRounds={SelectedLeagueRoundsList}
            />

        </Container>
    );

};


export default LeagueResultsPageComponent;

import React from "react";

import UseApiRequestStatus from "@States/Application/ApiRequest";


import { Button } from "react-bootstrap";


import FormFieldComponent from "@Components/Elements/Forms/_Common/Fields/_Field";


import ColumnProps from "@BootstrapInterfaces/Column";


export interface FormSubmitFieldComponentProps extends ColumnProps{
    buttonText: string;
    buttonClickHandler: () => void;
    buttonDisabledStatus?: boolean;
    size?: "sm" | "lg";
}


export const FormSubmitFieldComponent: React.FC<FormSubmitFieldComponentProps> = ({
    buttonText, buttonClickHandler, buttonDisabledStatus = false,
    size = "sm", xs= 12, md = null, lg = 12, xl = null
}) : JSX.Element | null => {


    const apiRequestStatus = UseApiRequestStatus();


    return (
        <FormFieldComponent xs={xs} md={md || xs} lg={lg} xl={xl || lg} fieldType={"submit"}>

            <Button disabled={apiRequestStatus || buttonDisabledStatus} onClick={buttonClickHandler} variant={"primary"} size={size} className={"form-field-button form-field-button-component submit-field-button"}>
                <span>{buttonText}</span>
            </Button>

        </FormFieldComponent>
    );

};


export default FormSubmitFieldComponent;

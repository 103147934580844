import React from "react";


import { UseExternalURL, UseGlobalsDictionaryPhrasesSet } from "@Services/Hooks";


import { Col } from "react-bootstrap";


import { PLKA_HALF_COLOR_LOGO } from "@Images";


import "./index.scss";


export interface HeaderPLKALogoComponentProps {}


export const HeaderPLKALogoComponent: React.FC<HeaderPLKALogoComponentProps> = () : JSX.Element | null => {


    const PROJECT_SITE_TITLE = UseGlobalsDictionaryPhrasesSet("PLKA_PROJECT_TITLE");
    const PROJECT_SITE_URL = UseExternalURL("PLKA_PROJECT_SITE");


    return (
        <Col xs={3} lg={1} className={"header-plka-logo-component"}>

            <a href={PROJECT_SITE_URL} title={PROJECT_SITE_TITLE} target={"_blank"} rel={"noopener norefferer"}>
                <img src={PLKA_HALF_COLOR_LOGO} alt={PROJECT_SITE_TITLE} />
            </a>

        </Col>
    );

};


export default HeaderPLKALogoComponent;

import { Dispatch } from "redux";

import ApplicationActionTypes from "@ApplicationAction-Types";
import ApplicationAction from "@ApplicationActions";

import PopupMessageInterface from "@ApplicationActions/PopupMessage/_Types/Message";


const UseSetPopupMessage = (message: PopupMessageInterface | null) => {

    return (dispatch: Dispatch<ApplicationAction>) => {
        dispatch({
            type: ApplicationActionTypes.POPUP_MESSAGE_ACTION,
            message: message
        });
    };

};


export default UseSetPopupMessage;

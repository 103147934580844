import React from "react";


import {Col} from "react-bootstrap";


export interface ContactPageInformationComponentProps {
    dataLabel?: string;
    dataValue: string;
    dataURL?: string;
    lg?: number;
}


export const ContactPageInformationComponent: React.FC<ContactPageInformationComponentProps> = ({
    dataLabel, dataValue, dataURL, lg = 3
}) : JSX.Element | null => {


    return (
        <Col xs={12} lg={lg} className={"contact-page-information"}>
            {dataLabel && <span className={"data-label"}>{dataLabel}:</span>}
            <a href={dataURL} className={"data-value"} title={dataLabel} onClick={(e) => dataURL ? null : e.preventDefault()}>{dataValue}</a>
        </Col>
    );

};


export default ContactPageInformationComponent;

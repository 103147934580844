import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamStatisticsPreviousGameComponent from "@TeamsPages/Team/Statistics/Games/PrevGame";
import TeamStatisticsNextGameComponent from "@TeamsPages/Team/Statistics/Games/NextGame";


import {
    LeagueTeamSeasonGamesInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamStatisticsGamesComponentProps {
    teamID: number;
    TeamGames: LeagueTeamSeasonGamesInterface;
}

export const TeamStatisticsGamesComponent: React.FC<TeamStatisticsGamesComponentProps> = ({
    teamID, TeamGames
}) : JSX.Element | null => {


    if (!teamID || !TeamGames) {
        return null;
    }


    const {
        previous: PreviousGame, next: NextGame
    } = TeamGames;


    return (
        <Col xs={12} lg={4} className={"team-details__team-statistics__games"}>
            <Row className={"team-details__team-statistics__statistics__games__content"}>

                {!!PreviousGame &&
                <TeamStatisticsPreviousGameComponent teamID={teamID} Game={PreviousGame} />
                }

                {!!NextGame &&
                <TeamStatisticsNextGameComponent teamID={teamID} Game={NextGame} />
                }

            </Row>
        </Col>
    );

};


export default TeamStatisticsGamesComponent;

import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import GameProgressHeaderComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Progress/Header";
import GameProgressQuartersComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Progress/Quarters";
import GameProgressFooterComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Footer";


import ApiGameSummaryQuarterType from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";


import "./index.scss";


interface GameInformationEventsComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface | null;
    gameQuarterDurationTime: number;
    gameExtraTimeDurationTime: number;
}


export const GameDetailsGameProgressComponent: React.FC<GameInformationEventsComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics,
    gameQuarterDurationTime, gameExtraTimeDurationTime
}) : JSX.Element | null => {


    if (!GameSummaryStatistics || !homeTeamID || !awayTeamID) return null;


    const { squads: GameTeams } = GameSummaryStatistics;


    const HomeTeam = GameTeams[homeTeamID];
    const AwayTeam = GameTeams[awayTeamID];


    return (
        <Col xs={12} className={"game-result-details__game-progress"}>
            <Row className={"game-result-details__game-progress__content"}>

                <GameProgressHeaderComponent
                    HomeTeam={HomeTeam}
                    AwayTeam={AwayTeam}
                />

                <GameProgressQuartersComponent
                    homeTeamID={homeTeamID}
                    awayTeamID={awayTeamID}
                    SelectedQuarters={SelectedQuarters}
                    GameSummaryData={GameSummaryStatistics}
                    gameQuarterDurationTime={gameQuarterDurationTime}
                    gameExtraTimeDurationTime={gameExtraTimeDurationTime}
                />

                <GameProgressFooterComponent />

            </Row>
        </Col>
    );

};


export default GameDetailsGameProgressComponent;

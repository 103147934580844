import React from "react";


import { Col, Row } from "react-bootstrap";


import GameTablesTeamTableHeaderComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Table/Header";
import GameTablesTeamTableSquadComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Table/Team";
import GameTablesTeamTableStatsComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Table/TeamStats";
import GameTablesTeamTableSummaryComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Table/Summary";


import TeamSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams/Team";
import GameSummaryPlayersInformationInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Players";


import "./index.scss";


interface GameTablesTeamTableComponentProps {
    teamID: number;
    GamePlayers: GameSummaryPlayersInformationInterface;
    TeamSummaryData: TeamSummaryInterface;
    teamCaptainID?: number | null;
}


export const GameTablesTeamTableComponent: React.FC<GameTablesTeamTableComponentProps> = ({
    teamID,
    GamePlayers, teamCaptainID, TeamSummaryData
}) : JSX.Element | null => {


    if (!teamID || !GamePlayers || !TeamSummaryData) return null;


    const {
        teamStatistics: TeamStatistics,
        teamSummaryStatistics: TeamSummaryStatistics,
        teamPlayers: TeamPlayersStatistics
    } = TeamSummaryData;


    return (
        <Col xs={12} className={"game-tables-team-table"}>
            <Row className={"game-tables-team-table-content"}>

                <GameTablesTeamTableHeaderComponent />

                {!!TeamPlayersStatistics &&
	            <GameTablesTeamTableSquadComponent
		            TeamPlayers={TeamPlayersStatistics}
                    GamePlayers={GamePlayers}
		            teamCaptainID={teamCaptainID}
	            />
                }

                {!!TeamStatistics &&
                <GameTablesTeamTableStatsComponent
	                TeamStatistics={TeamStatistics}
                />
                }

                {!!TeamSummaryStatistics &&
	            <GameTablesTeamTableSummaryComponent
		            TeamSummaryStatistics={TeamSummaryStatistics}
		            TeamStatistics={TeamStatistics}
	            />
                }

            </Row>
        </Col>
    );

};


export default GameTablesTeamTableComponent;

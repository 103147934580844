import React from "react";


import DefaultMessageComponent from "@Components/Elements/Messages/Message/_Default";


export type ErrorMessageComponentProps = {
    messageText: string;
    cssClasses?: string;
    xs?: number | {span: number, offset: number};
    lg?: number | {span: number, offset: number};
};


export const ErrorMessageComponent: React.FC<ErrorMessageComponentProps> = ({
    messageText, cssClasses, xs = 12, lg = 12
}) : JSX.Element | null => {


    if (!messageText) return null;


    return (
        <DefaultMessageComponent xs={xs} lg={lg} messageText={messageText} messageType={"ERROR"} cssClasses={cssClasses} />
    );

};


export default ErrorMessageComponent;

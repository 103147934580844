import UseLanguage from "@LocalizationStates/Language";


import SuccessMessages from "@Dictionary/Messages/Success";
import SuccessMessageType from "@Dictionary/Messages/Success/Messages/Message";


export const UseSuccessMessage = (messageType: SuccessMessageType) => {

    const ActiveLanguage = UseLanguage();

    return SuccessMessages[ActiveLanguage][messageType];

};


export default UseSuccessMessage;

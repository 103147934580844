export const PLAYED_GAMES_PL = "Mecze";
export const PLAYED_GAMES_EN = "GAmes";
export const PLAYED_GAMES_SHORT_LABEL_PL = "M";
export const PLAYED_GAMES_SHORT_LABEL_EN = "G";
export const PLAYED_GAMES_LABEL_PL = "Mec";
export const PLAYED_GAMES_LABEL_EN = "Gam";
export const PLAYED_GAMES_TITLE_PL = "Rozegrane Mecze";
export const PLAYED_GAMES_TITLE_EN = "Played Games";

export const START5_GAMES_PL = "Pierwszy Skład";
export const START5_GAMES_EN = "First Squad";
export const START5_GAMES_SHORT_LABEL_PL = "S5";
export const START5_GAMES_SHORT_LABEL_EN = "S5";
export const START5_GAMES_LABEL_PL = "Pierwsza 5";
export const START5_GAMES_LABEL_EN = "Start 5";
export const START5_GAMES_TITLE_PL = "Mecze w pierwszej 5";
export const START5_GAMES_TITLE_EN = "Games in start 5";

export const NOT_ACTIVATED_STATUS_PL = "Nieaktywowany";
export const NOT_ACTIVATED_STATUS_EN = "Not activated";
export const INACTIVE_STATUS_PL = "Nieaktywny";
export const INACTIVE_STATUS_EN = "Inactive";
export const REGULATIONS_NOT_ACCEPTED_STATUS_PL = "Nie zaakceptowano regulaminów";
export const REGULATIONS_NOT_ACCEPTED_STATUS_EN = "Regulations not accepted";

export const MY_DATA_LABEL_PL = "Moje Dane";
export const MY_DATA_LABEL_EN = "My Information";
export const MY_DESCRIPTION_LABEL_PL = "Mój opis";
export const MY_DESCRIPTION_LABEL_EN = "My Description";
export const FORMAL_AGREEMENTS_LABEL_PL = "Zgody formalne";
export const FORMAL_AGREEMENTS_LABEL_EN = "Formal agreements";

export const CAPTAIN_ZONE_LABEL_PL = "Strefa kapitana";
export const CAPTAIN_ZONE_LABEL_EN = "Captain Zone";
export const CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL_PL = "Wyślij email z zaproszeniem do aktywacji konta";
export const CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL_EN = "Send email with invite to activate account";
export const CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL_PL = "Wyślij email z przypomnieniem o akceptacji regulaminów";
export const CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL_EN = "Send email with remind about regulations acceptation";
export const CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL_PL = "Usuń zawodnika z drużyny";
export const CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL_EN = "Remove player from team";

export const GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT_PL = "Przejdź do profilu zawodnika";
export const GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT_EN = "Go to player profile page";

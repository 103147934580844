import React from "react";


import {
    Col, Row
} from "react-bootstrap";

import {
    AiFillCaretDown
} from "react-icons/all";


import getLeagueRoundLabel from "@Services/Utilities/Games/Leagues/Rounds/Rounds/GetLeagueRoundLabel";


import {
    LeagueRoundInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundsSwitcherSelectedRoundComponentProps {
    SelectedRound: LeagueRoundInterface | null;
    selectedRoundIDChangeHandler: (selectedRoundID: number) => void;
}


export const RoundsSwitcherSelectedRoundComponent: React.FC<RoundsSwitcherSelectedRoundComponentProps> = ({
    SelectedRound, selectedRoundIDChangeHandler
}) => {


    let selectedRoundLabel = "-";

    if (SelectedRound) {
        selectedRoundLabel = getLeagueRoundLabel(SelectedRound);
    }


    return (
        <Col xs={12} className={"leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round--selected-round"} onClick={() => selectedRoundIDChangeHandler(-1)}>
            <Row className={"leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round--selected-round__content"}>
                <span className={"leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round--selected-round__label text-overflow"}>{selectedRoundLabel}</span>
                <span className={"leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round--selected-round__icon"}>
                    <AiFillCaretDown/>
                </span>
            </Row>
        </Col>
    );

};


export default RoundsSwitcherSelectedRoundComponent;

import { UseLanguage } from "@States";

import { LeagueRoundTypeType } from "@Interfaces/Api";


import LeagueRoundsLabels from "@Texts/Games/Schedule/Rounds";


export const UseLeagueRoundLabel = (leagueRoundType: LeagueRoundTypeType) => {

    const activeLanguage = UseLanguage();

    return LeagueRoundsLabels[leagueRoundType][activeLanguage];

};


export default UseLeagueRoundLabel;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet, UseErrorMessage
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import FreeAgentRequestsListHeaderComponent from "@Pages/User/Authenticated/Profile/Player/Content/FreeAgentsRequests/List/Header";
import FreeAgentRequestsListElementComponent from "@Pages/User/Authenticated/Profile/Player/Content/FreeAgentsRequests/List/Request";


import {
    FreeAgentRequestInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface FreeAgentRequestsListComponentProps {
    FreeAgentRequests: FreeAgentRequestInterface[] | null;
    FreeAgentRequestsError: ErrorMessageInterface | null;
    requestUpdateHandler: (requestID: number, requestUpdatedStatus: 1 | 2 | 3 | 4) => Promise<boolean>;
}


export const FreeAgentRequestsListComponent: React.FC<FreeAgentRequestsListComponentProps> = ({
    FreeAgentRequests, FreeAgentRequestsError, requestUpdateHandler
}) : JSX.Element | null => {


    const REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const NO_REQUESTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_REQUESTS_PHRASE_TEXT");


    if (FreeAgentRequestsError) {
        return <ErrorMessageComponent messageText={REQUEST_ERROR_MESSAGE} cssClasses={"no-margin-top"} />;
    }

    if (!FreeAgentRequests) {
        return <SingleContentLoaderComponent />;
    }

    if (!FreeAgentRequests?.length) {
        return <MessageComponent messageText={NO_REQUESTS_PHRASE_TEXT} cssClasses={"padding-15 bordered"} />;
    }


    const FreeAgentRequestsList = FreeAgentRequests.map((Request, i) =>
        <FreeAgentRequestsListElementComponent
            key={i}
            FreeAgentRequest={Request}
            requestUpdateHandler={requestUpdateHandler}
        />
    );

    return (
        <Col xs={12} className={"free-agent-requests__list"}>
            <Row className={"free-agent-requests__list__content"}>

                <FreeAgentRequestsListHeaderComponent />

                {FreeAgentRequestsList}

            </Row>
        </Col>
    )

};


export default FreeAgentRequestsListComponent;

import React from "react";


import { Col, Row } from "react-bootstrap";


import GameThrowsTeamComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team";


import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";
import ApiGameSummaryTeamsInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams";

import ThrowsEventsInterface from "@Components/Views/Pages/Games/Games/Game/_Types/Summary/GUI/Throws/Teams";


interface GameThrowsTeamsComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    GameSquads: ApiGameSummaryTeamsInterface;
    GamePlayers: ApiGameSummaryPlayersInterface;
    GameTeamsThrows: ThrowsEventsInterface;
    SelectedPlayers: number[];
    selectedPlayersHandler(playerId: number | number[]): void;
}


export const GameThrowsTeamsComponent: React.FC<GameThrowsTeamsComponentProps> = ({
    homeTeamID, awayTeamID,
    GameSquads,
    GamePlayers,
    GameTeamsThrows,
    SelectedPlayers, selectedPlayersHandler,
}) : JSX.Element | null => {


    if (!GameTeamsThrows || !GamePlayers || !GameSquads) return null;


    if (!homeTeamID || !awayTeamID) return null;


    const HomeTeam = GameSquads[homeTeamID];
    const AwayTeam = GameSquads[awayTeamID];


    if (!HomeTeam || !AwayTeam) return null;


    const {
        homeTeam: HomeTeamThrows, awayTeam: AwayTeamThrows
    } = GameTeamsThrows;


    const { team_players: HomeTeamPlayers, team_data: HomeTeamData } = HomeTeam;
    const { team_players: AwayTeamPlayers, team_data: AwayTeamData } = AwayTeam;


    return (
        <Col xs={12} className={"game-information-throws-teams game-information-throws-teams-component"}>
            <Row className={"game-information-throws-teams-content"}>

                <GameThrowsTeamComponent
                    GameTeam={HomeTeamData}
                    GameTeamPlayers={HomeTeamPlayers}
                    gameTeamType={"HOME"}
                    GameTeamThrows={HomeTeamThrows}
                    GamePlayers={GamePlayers}
                    SelectedPlayers={SelectedPlayers}
                    selectedPlayersHandler={selectedPlayersHandler}
                />

                <GameThrowsTeamComponent
                    GameTeam={AwayTeamData}
                    GameTeamPlayers={AwayTeamPlayers}
                    gameTeamType={"AWAY"}
                    GameTeamThrows={AwayTeamThrows}
                    GamePlayers={GamePlayers}
                    SelectedPlayers={SelectedPlayers} selectedPlayersHandler={selectedPlayersHandler}
                />

            </Row>
        </Col>
    );

};


export default GameThrowsTeamsComponent;

import React, { useState } from "react";


import {
    UseSetPopupMessageAction
} from "@StatesActions";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import {
    AiOutlineMinus, AiOutlinePlus
} from "react-icons/all";


import FreeAgentRequestFormComponent from "@Pages/User/Authenticated/Profile/Player/Content/FreeAgentsRequests/Create/Form";


import {
    FreeAgentRequestInterface
} from "@Interfaces/Api";


import "./index.scss";



export interface CreateFreeAgentRequestComponentProps {
    FreeAgentRequests: FreeAgentRequestInterface[] | null;
    apiRequestHandler: (freeAgentRequestLeagueID: string) => Promise<boolean>;
}


export const CreateFreeAgentRequestComponent: React.FC<CreateFreeAgentRequestComponentProps> = ({
    FreeAgentRequests, apiRequestHandler
}) : JSX.Element | null => {


    const UsePopupMessage = UseSetPopupMessageAction();


    const ABORT_WORD_TEXT = UseCommonDictionaryPhrasesSet("ABORT_WORD")

    const ADD_TO_TRANSFER_LIST_PHRASE_TEXT_PL = UseCommonDictionaryPhrasesSet("ADD_TO_TRANSFER_LIST_PHRASE_TEXT")
    const ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT")
    const ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT")


    const [createFreeAgentRequestOpenStatus, setCreateFreeAgentRequestOpenStatus] = useState(false);


    if (!FreeAgentRequests || !apiRequestHandler) {
        return null;
    }


    const freeAgentRequestSubmitHandler = async (freeAgentRequestLeagueID: string) => {

        const response = await apiRequestHandler(freeAgentRequestLeagueID)

        if (response) {
            UsePopupMessage({ type: "SUCCESS", text: ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT });
        } else {
            UsePopupMessage({ type: "ERROR", text: ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT });
        }

        setCreateFreeAgentRequestOpenStatus(false);

    };


    return (
        <Col xs={12} className={"free-agent-requests__create-request"}>
            <Row className={"free-agent-requests__create-request__content"}>

                <Col xs={12} className={"free-agent-requests__create-request__header"}>
                    <Row className={"free-agent-requests__create-request__header__content"}>

                        <Col className={"free-agent-requests__create-request__header__label"} onClick={() => setCreateFreeAgentRequestOpenStatus(!createFreeAgentRequestOpenStatus)}>
                            <span>{!createFreeAgentRequestOpenStatus ? ADD_TO_TRANSFER_LIST_PHRASE_TEXT_PL : ABORT_WORD_TEXT}</span>
                            {!createFreeAgentRequestOpenStatus ? <AiOutlinePlus /> : <AiOutlineMinus />}
                        </Col>

                    </Row>
                </Col>

                {createFreeAgentRequestOpenStatus &&
                <FreeAgentRequestFormComponent
                    FreeAgentRequests={FreeAgentRequests}
                    submitHandler={freeAgentRequestSubmitHandler}
                />
                }

            </Row>
        </Col>
    );

};


export default CreateFreeAgentRequestComponent;

export const formatMinutesFromSeconds = (exactSeconds?: number) : string => {

    if (!exactSeconds) return "00:00";

    let minutes: string | number = Math.floor(exactSeconds / 60);
    let seconds: string | number = Math.floor(exactSeconds % 60);

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return `${minutes}:${seconds}`;

};


export default formatMinutesFromSeconds;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import {
    LeagueTablesTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeagueTablesGroupTableTeamComponentProps {
    tablePosition: number;
    Team: LeagueTablesTeamInterface;
}


export const LeagueTablesGroupTableTeamComponent: React.FC<LeagueTablesGroupTableTeamComponentProps> = ({
    tablePosition, Team
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    const POINTS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("POINTS_WORD_SHORT_TEXT");


    if (!Team) {
        return null;
    }


    const {
        team_points: teamPoints, team_played_games: teamPlayedGames, team_wins: teamWins, team_lost: teamDefeats,
        team_small_points_in: teamSmallPointsIn, team_small_points_out: teamSmallPointsOut, team_small_points_diff: teamSmallPointsDiff,
        team_league_team_data: TeamData,
        team_league_team_name: teamLeagueName, team_league_team_short_name: teamLeagueShortName
    } = Team;

    const {
        team_slug: teamSlug, team_logo: teamLogo,
        team_name: teamMainName, team_short_name: teamMainShortName
    } = TeamData;

    const teamName = teamLeagueName || teamMainName;
    const teamShortName = teamLeagueShortName || teamMainShortName;


    const teamURL = TeamsRoute.routeURL + "/" + teamSlug;


    return (
        <Col xs={12} className={"league-tables__group__table__team"}>
            <Row className={"league-tables__group__table__team__content"}>

                <Col xs={1} className={"team-table-position"}>
                    <span>{tablePosition}</span>
                </Col>

                <Col xs={1} className={"team-logo"}>
                    {teamLogo && TeamsRoute?.routeURL &&
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle}>
                        <img src={teamLogo} alt={teamShortName || ""}/>
                    </NavLink>
                    }
                </Col>

                <Col xs={4} className={"team-name"}>
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

                <Col xs={1} className={"team-table-points"}>
                    <span>{teamPoints}</span>
                    <span className={"points-label"}>{POINTS_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={1} className={"team-played-games"}>
                    <span>{teamPlayedGames}</span>
                </Col>

                <Col xs={1} className={"team-games-balance"}>
                    <span>{teamWins} - {teamDefeats}</span>
                </Col>

                <Col xs={2} className={"team-small-points-balance"}>
                    <span>{teamSmallPointsIn} - {teamSmallPointsOut}</span>
                </Col>

                <Col xs={1} className={"team-small-points-difference"}>
                    <span>{teamSmallPointsDiff > 0 ? "+" : ""}{teamSmallPointsDiff}</span>
                </Col>

            </Row>
        </Col>
    );


};


export default LeagueTablesGroupTableTeamComponent;

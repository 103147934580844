export * from "@Dictionary/Pages/User/Authenticated/Types/Phrases/Set";
export * from "@Dictionary/Pages/User/Authenticated/Phrases";


import AuthenticatedUserDictionaryInterface from "@Dictionary/Pages/User/Authenticated/Types";


import {
    USER_ZONE_LABEL_PL, USER_ZONE_LABEL_EN,
    ADDITIONAL_DATA_PHRASE_TEXT_PL, ADDITIONAL_DATA_PHRASE_TEXT_EN
} from "@Dictionary/Pages/User/Authenticated/Phrases";


export const AuthenticatedUserDictionary: AuthenticatedUserDictionaryInterface = {
    PL: {
        USER_ZONE_LABEL: USER_ZONE_LABEL_PL,
        ADDITIONAL_DATA_PHRASE_TEXT: ADDITIONAL_DATA_PHRASE_TEXT_PL,
    },
    EN: {
        USER_ZONE_LABEL: USER_ZONE_LABEL_EN,
        ADDITIONAL_DATA_PHRASE_TEXT: ADDITIONAL_DATA_PHRASE_TEXT_EN,
    }
};


export default AuthenticatedUserDictionary;

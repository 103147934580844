import {
    TeamPlayerStatusInterface
} from "@Interfaces/Api";


export const TeamPlayerStatus: TeamPlayerStatusInterface = {
    1: {
        PL: "Aktywny",
        EN: "Active"
    },
    2: {
        PL: "Nieaktywny",
        EN: "Inactive"
    },
    3: {
        PL: "Transfer",
        EN: "Transferred"
    },
    4: {
        PL: "Usunięty",
        EN: "Removed"
    }
};


export default TeamPlayerStatus;

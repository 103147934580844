import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import DefaultLeaguesSwitcherLeaguesListLeagueComponent from "@Elements/Games/Leagues/Switchers/DefaultLeaguesSwitcher/List/League";


import {
    LeagueNameInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface DefaultLeaguesSwitcherLeaguesListComponentProps {
    Leagues: LeagueNameInterface[];
    selectedLeagueID?: number;
    selectedLeagueIDHandler: ((leagueID: number) => void) | null;
}

export const DefaultLeaguesSwitcherLeaguesListComponent: React.FC<DefaultLeaguesSwitcherLeaguesListComponentProps> = ({
    Leagues,
    selectedLeagueID = 0, selectedLeagueIDHandler = null,
}) : JSX.Element | null => {


    const NO_LEAGUES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUES_PHRASE_TEXT")


    if (!Leagues) {
        return null;
    }


    if (!Leagues?.length) {
        return <MessageComponent messageText={NO_LEAGUES_PHRASE_TEXT} />;
    }


    const LeaguesList = Leagues.map((League, i) =>
        <DefaultLeaguesSwitcherLeaguesListLeagueComponent
            key={i}
            League={League}
            selectedLeagueID={selectedLeagueID}
            selectedLeagueIDHandler={selectedLeagueIDHandler}
        />
    );


    return (
        <Col xs={12} className={"leagues-switcher__leagues-list leagues-switcher--default__leagues-list"}>
            <Row className={"leagues-switcher__leagues-list__content leagues-switcher--default__leagues-list__content"}>

                {LeaguesList}

            </Row>
        </Col>
    );

};


export default DefaultLeaguesSwitcherLeaguesListComponent;

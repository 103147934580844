import React, {useEffect, useState} from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import parse from "html-react-parser";


import {Col, Container, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import SystemPageInterface from "@Interfaces/Api/_Common/Pages/System/Page";


export interface ContactPageTextComponentProps {}


export const ContactPageTextComponent: React.FC<ContactPageTextComponentProps> = () : JSX.Element | null => {


    const CONTACT_PAGE_API_URL = REST_API_ENDPOINTS_URLS.COMMON.CONTACT_PAGE.MAIN_URL;


    const [contactPageTextContent, setContactPageTextContent] = useState<SystemPageInterface | null>(null);
    const [contactPageTextContentError, setContactPageTextContentError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!CONTACT_PAGE_API_URL) return;

        const getContactPageTextContent = async () => {

            try {

                const response = await _restApiRequest(`${CONTACT_PAGE_API_URL}`)

                if (response.ok) {
                    const responseContent = await response.json();
                    setContactPageTextContent(responseContent);
                } else {
                    setContactPageTextContentError({errorCode: response.status, errorMessage: ""});
                }


            } catch (e) {
                setContactPageTextContentError({errorCode: 500, errorMessage: ""});
            }

        };

        getContactPageTextContent();

    }, []);


    useEffect(() => {
        return () => {
            setContactPageTextContent(null);
            setContactPageTextContentError(null);
        };
    }, []);


    if (!contactPageTextContent) return <MainContentLoaderComponent />;


    if (!!contactPageTextContentError) return <ErrorMessageComponent messageText={contactPageTextContentError.errorMessage} />


    const {
        content_page_active_status: contactPageActiveStatus,
        content_page_main_text: contentPageMainText
    } = contactPageTextContent;


    if (!contentPageMainText || !contactPageActiveStatus) return null;


    return (
        <Container fluid={true} id={"contact-page-text-component"} className={"contact-page-text-component contact-page-text page-section-component main-section-component"}>
            <Container className={"main-section-container page-section-container"}>
                <Row className={"main-section-content page-section-content"}>

                    <Col xs={12} className={"contact-page-text-content"}>
                        {parse(contentPageMainText)}
                    </Col>

                </Row>
            </Container>
        </Container>
    );

};


export default ContactPageTextComponent;

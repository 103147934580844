import React, { useState, useEffect } from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";

import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import SponsorsSponsorComponent from "@Components/Views/Parts/Sponsors/Sponsor";


import "./index.scss";


import PartnerInterface from "@Interfaces/Api/_Common/Partners/Partner";


export interface ApplicationSponsorsComponentProps {}


export const ApplicationSponsorsComponent: React.FC<ApplicationSponsorsComponentProps> = () : JSX.Element | null => {


    const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;


    const [SponsorsList, setSponsorsList] = useState<PartnerInterface[] | null>(null);
    const [SponsorsListError, setSponsorsListError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!API_URL) return setSponsorsListError({ errorCode: 500, errorMessage: "" });

        const getSponsorsList = async () => {

            try {

                const response = await _restApiRequest(
                    `${API_URL}/settings/sponsors/`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setSponsorsList(responseContent);
                } else {
                    setSponsorsListError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setSponsorsListError({ errorCode: 500, errorMessage: "" });
            }

        };

        getSponsorsList();

    }, [API_URL]);

    useEffect(() => {
        return () => {
            setSponsorsList(null);
        };
    }, []);


    if (!SponsorsList) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={`Sponsorzy`} />;


    if (!SponsorsList?.length || SponsorsListError) return null;


    const carouselSettings = {
        dots: false, arrows: false, infinite: true, speed: 500,
        slidesToShow: 6, slidesToScroll: 1, initialSlide: 0,
        autoplay: true, autoplaySpeed: 4000, pauseOnHover: true,
        responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 6}},
            {breakpoint: 600, settings: {slidesToShow: 2}},
            {breakpoint: 480, settings: {slidesToShow: 1}}
        ]
    };


    const SponsorsListComponent = SponsorsList.map((Sponsor, i) =>
        <SponsorsSponsorComponent
            key={i} Sponsor={Sponsor}
        />
    );


    return (
        <ContainerSectionComponent id={`service-sponsors-component`} classes={"service-sponsors-section"}>

            <Slider {...carouselSettings} className={"service-sponsors-section__carousel"}>
                {SponsorsListComponent}
            </Slider>

        </ContainerSectionComponent>
    );

};


export default ApplicationSponsorsComponent;

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";

import PopupMessageInterface from "@Actions/Application/PopupMessage/_Types/Message";


export const usePopupMessage = () : PopupMessageInterface | null => {

    const { popupMessage } = useTypedSelector(
        (state) => state.app
    );

    return popupMessage;

};


export default usePopupMessage;

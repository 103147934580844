import React, { useEffect, useState } from "react";


import { UseSetPopupMessageAction } from "@StatesActions";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { NavLink } from "react-router-dom";


import { Col, Row } from "react-bootstrap";

import { AiFillCloseCircle, AiFillEdit, AiFillSave } from "react-icons/all";


import {
    FormComponent,
    FormTextFieldComponent, FormTextareaFieldComponent
} from "@FormsElements";

import { UseFormFieldErrorMessage, UseFormsDictionaryPhrasesSet } from "@Hooks/Texts/Sets/Forms/Fields/Field";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


import { isEmail, isURL, isName } from "@Services/Utilities/Tools/Validators";


import { UseErrorMessage, UseSuccessMessage } from "@Hooks/Texts";


import { IMAGE_PLACEHOLDER } from "@Images";


import { LeagueTeamInterface } from "@Interfaces/Api";


export interface TeamCaptainTeamDataComponentProps {
    team: LeagueTeamInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const TeamCaptainTeamDataComponent:React.FC<TeamCaptainTeamDataComponentProps> = ({
    team, updatePlayerTrigger
}) : JSX.Element | null => {


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const DEFAULT_ERROR_MESSAGE = UseErrorMessage("DEFAULT");
    const INVALID_FILE_TYPE_MESSAGE = UseErrorMessage("INVALID_FILE_TYPE");
    const DEFAULT_UPDATE_SUCCESS_MESSAGE = UseSuccessMessage("DEFAULT_UPDATE_DATA_RESPONSE");


    const TEAM_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("NAME");
    const TEAM_NAME_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("NAME");
    const TEAM_SHORT_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("SHORT_NAME");
    const TEAM_SHORT_NAME_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("SHORT_NAME");
    const TEAM_CITY_FIELD_LABEL = UseFormsDictionaryPhrasesSet("CITY");
    const TEAM_CITY_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("CITY");
    const TEAM_EMAIL_FIELD_LABEL = UseFormsDictionaryPhrasesSet("EMAIL_ADDRESS");
    const TEAM_EMAIL_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("EMAIL_ADDRESS");
    const TEAM_WEBSITE_FIELD_LABEL = UseFormsDictionaryPhrasesSet("WEBSITE");
    const TEAM_WEBSITE_FIELD_ERROR_MESSAGE = UseFormFieldErrorMessage("WEBSITE");
    const TEAM_DESCRIPTION_FIELD_LABEL = UseFormsDictionaryPhrasesSet("DESCRIPTION");
    const TEAM_FIRST_COLOR_FIELD_LABEL = UseFormsDictionaryPhrasesSet("FIRST_COLOR");
    const TEAM_SECOND_COLOR_FIELD_LABEL = UseFormsDictionaryPhrasesSet("SECOND_COLOR");


    interface FormErrorsInterface {
        teamName: FormFieldValueErrorInterface | null;
        teamShortName: FormFieldValueErrorInterface | null;
        teamCity: FormFieldValueErrorInterface | null;
        teamEmail: FormFieldValueErrorInterface | null;
        teamWebsite: FormFieldValueErrorInterface | null;
    }

    const FormErrorsDefaults: FormErrorsInterface = {
        teamName: null, teamShortName: null, teamCity: null,
        teamEmail: null, teamWebsite: null
    };

    const [FormErrors, setFormErrors] = useState<FormErrorsInterface>(FormErrorsDefaults);

    useEffect(() => {
        return () => {
            setFormErrors(FormErrorsDefaults);
        };
    }, []);


    const [captainTeamID, setCaptainTeamID] = useState<number>(0);
    const [captainTeamSlug, setCaptainTeamSlug] = useState<string>("");

    const [captainTeamName, setCaptainTeamName] = useState<string>("");
    const [captainTeamShortName, setCaptainTeamShortName] = useState<string>("");
    const [captainTeamCity, setCaptainTeamCity] = useState<string>("");

    const [captainTeamLogo, setCaptainTeamLogo] = useState<string>("");
    const [captainTeamLogoFile, setCaptainTeamLogoFile] = useState<File | null>(null);

    const [captainTeamDescription, setCaptainTeamDescription] = useState<string>("");
    const [captainTeamWebsite, setCaptainTeamWebsite] = useState<string>("");
    const [captainTeamEmail, setCaptainTeamEmail] = useState<string>("");
    const [captainTeamFirstColor, setCaptainTeamFirstColor] = useState<string>("");
    const [captainTeamSecondColor, setCaptainSecondColor] = useState<string>("");

    useEffect(() => {

        if (!team) return;

        const {
            team_id: teamID, team_slug: teamSlug,
            team_name: teamName, team_short_name: teamShortName, team_city: teamCity,
            team_logo: teamLogo, team_description: teamDescription,
            team_website: teamWebsite, team_email: teamEmail,
            team_first_color: teamFirstColor, team_second_color: teamSecondColor
        } = team;

        setCaptainTeamID(teamID);
        setCaptainTeamSlug(teamSlug);

        setCaptainTeamName(teamName);
        if (teamShortName) setCaptainTeamShortName(teamShortName);
        if (teamCity) setCaptainTeamCity(teamCity);
        if (teamLogo) setCaptainTeamLogo(teamLogo);
        if (teamDescription) setCaptainTeamDescription(teamDescription);
        if (teamWebsite) setCaptainTeamWebsite(teamWebsite);
        if (teamEmail) setCaptainTeamEmail(teamEmail);
        if (teamFirstColor) setCaptainTeamFirstColor(teamFirstColor);
        if (teamSecondColor) setCaptainSecondColor(teamSecondColor);

    }, [team]);


    const changeCaptainTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isName(value, 2) && FormErrors.teamName) {
            setFormErrors({ ...FormErrors, teamName: null });
        }
        setCaptainTeamName(e.target.value);
    };

    const changeCaptainTeamCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isName(value, 3) && FormErrors.teamCity) {
            setFormErrors({ ...FormErrors, teamCity: null });
        }
        setCaptainTeamCity(e.target.value);
    };

    const changeCaptainTeamShortName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isName(value, 2) && FormErrors.teamShortName) {
            setFormErrors({ ...FormErrors, teamShortName: null });
        }
        setCaptainTeamShortName(e.target.value);
    };

    const changeCaptainTeamEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isEmail(value) && FormErrors.teamEmail) {
            setFormErrors({ ...FormErrors, teamEmail: null });
        }
        setCaptainTeamEmail(e.target.value);
    };

    const changeCaptainTeamWebsite = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isURL(value) && FormErrors.teamWebsite) {
            setFormErrors({ ...FormErrors, teamWebsite: null });
        }
        setCaptainTeamWebsite(e.target.value);
    };

    const changeCaptainTeamDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCaptainTeamDescription(e.target.value);
    };

    const changeCaptainTeamFirstColor = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCaptainTeamFirstColor(e.target.value);
    };

    const changeCaptainTeamSecondColor = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCaptainSecondColor(e.target.value);
    };


    const [changeCaptainTeamLogoStatus, setChangeCaptainTeamLogoStatus] = useState<boolean>(false);

    const changeCaptainTeamLogoFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files[0]) return;
        const file = e.target.files[0];
        const fileType = file.type;
        if (fileType !== "image/jpeg" && fileType !== "image/png") {
            UseSetPopupMessage({ type: "ERROR", text: INVALID_FILE_TYPE_MESSAGE });
            return;
        }
        setCaptainTeamLogoFile(file);
    };

    const uploadCaptainTeamLogoFileHandler = async () => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const TEAM_ID = +captainTeamID;

        if (!captainTeamLogoFile || !TEAM_ID || !API_URL) return;

        const formData = new FormData();

        formData.append("team_logo", captainTeamLogoFile, captainTeamLogoFile.name);

        try {

            const response = await _restApiRequest(
            `${API_URL}/teams/teams/${TEAM_ID}/update-team-logo/`,
                formData, "POST",
            )

            if (response.ok) {
                setChangeCaptainTeamLogoStatus(false);
                updatePlayerTrigger(true);
            } else {
                UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });
            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });
        }

    };

    useEffect(() => {
        setCaptainTeamLogoFile(null);
    }, [changeCaptainTeamLogoStatus]);


    if (!captainTeamID || !captainTeamSlug) return null;


    const fieldSaveDataHandler = async (fieldName: string, fieldValue: string, fieldDisableHandler: (status: boolean) => any) => {

        let error = false;
        const FormErrors: FormErrorsInterface = { ...FormErrorsDefaults };

        switch (fieldName) {

            case "team_name":
                if (!isName(fieldValue, 2)) {
                    error = true;
                    FormErrors.teamName = TEAM_NAME_FIELD_ERROR_MESSAGE;
                }
                break;

            case "team_short_name":
                if (!isName(fieldValue, 2, 3)) {
                    error = true;
                    FormErrors.teamShortName = TEAM_SHORT_NAME_FIELD_ERROR_MESSAGE;
                }
                break;

            case "team_city":
                if (!isName(fieldValue, 3)) {
                    error = true;
                    FormErrors.teamCity = TEAM_CITY_FIELD_ERROR_MESSAGE;
                }
                break;

            case "team_email":
                if (!isEmail(fieldValue)) {
                    error = true;
                    FormErrors.teamEmail = TEAM_EMAIL_FIELD_ERROR_MESSAGE;
                }
                break;

            case "team_website":
                if (!isURL(fieldValue)) {
                    error = true;
                    FormErrors.teamWebsite = TEAM_WEBSITE_FIELD_ERROR_MESSAGE;
                }
                break;

        }

        setFormErrors(FormErrors);

        if (error) return;

        if (await updateTeamDataRequestHandler(fieldName, fieldValue)) {
            fieldDisableHandler(false);
            updatePlayerTrigger(true);
            return UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_UPDATE_SUCCESS_MESSAGE });
        }

        UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });

    };


    const updateTeamDataRequestHandler = async (fieldName: string, fieldValue: string) : Promise<boolean> => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const TEAM_ID = +captainTeamID;

        const formData = new FormData();
        formData.append("field_name", fieldName);
        formData.append("field_value", fieldValue);

        try {
            const response = await _restApiRequest(
                `${API_URL}/teams/teams/${TEAM_ID}/update-single-field/`,
                formData, "PATCH"
            );
            return response.ok;
        } catch (e) {}

        return false;

    };


    return (
        <Col xs={12} className={"captain-team-data"}>
            <Row className={"captain-team-data-content"}>

                <Col xs={5} lg={2} className={"team-logo"}>
                    <Row className={"team-logo-picture"}>
                        <NavLink to={`/druzyny/${captainTeamSlug}`} title={``}>
                            <img src={captainTeamLogo || IMAGE_PLACEHOLDER} alt={``}/>
                        </NavLink>
                        <span className={"change-team-logo"} onClick={() => setChangeCaptainTeamLogoStatus(!changeCaptainTeamLogoStatus)}>
                            {changeCaptainTeamLogoStatus ? <AiFillCloseCircle /> : <AiFillEdit />}
                        </span>
                    </Row>
                    {changeCaptainTeamLogoStatus &&
                    <Row className={"change-logo-picture"}>
                        <FormTextFieldComponent
                            fieldType={"file"}
                            fieldValueHandler={changeCaptainTeamLogoFile}
                        />
                        {captainTeamLogoFile &&
                        <Col xs={12} className={"file-information"}>
                            <span className={"save-file"} onClick={uploadCaptainTeamLogoFileHandler}>
                                <AiFillSave />
                            </span>
                            <span className={"file-name"}>{captainTeamLogoFile.name.length > 15 ? (captainTeamLogoFile.name.substring(0, 14) + "..." ) : captainTeamLogoFile.name}</span>
                        </Col>
                        }
                    </Row>
                    }
                </Col>

                <FormComponent xs={{ span: 6, offset: 1 }} lg={{span: 9, offset: 1}} cssClasses={"user-form team-main-data"}>

                    <FormTextFieldComponent
                        fieldName={"team_name"}
                        fieldValue={captainTeamName}
                        fieldValueHandler={changeCaptainTeamName}
                        fieldLabel={TEAM_NAME_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-name-field"}
                        fieldError={FormErrors.teamName}
                        lg={6}
                    />

                    <FormTextFieldComponent
                        fieldName={"team_city"}
                        fieldValue={captainTeamCity}
                        fieldValueHandler={changeCaptainTeamCity}
                        fieldLabel={TEAM_CITY_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-city-field"}
                        lg={{span: 5, offset: 1}}
                        fieldError={FormErrors.teamCity}
                    />

                    <Col xs={12} className={"form-fields-separator"}></Col>

                    <FormTextFieldComponent
                        fieldName={"team_short_name"}
                        fieldValue={captainTeamShortName}
                        fieldValueHandler={changeCaptainTeamShortName}
                        fieldLabel={TEAM_SHORT_NAME_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-city-field"}
                        lg={3}
                        fieldError={FormErrors.teamShortName}
                    />

                    <FormTextFieldComponent
                        fieldType={"email"}
                        fieldName={"team_email"}
                        fieldValue={captainTeamEmail}
                        fieldValueHandler={changeCaptainTeamEmail}
                        fieldLabel={TEAM_EMAIL_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-email-field"}
                        fieldError={FormErrors.teamEmail}
                        lg={{span: 3, offset: 1}}
                    />

                    <FormTextFieldComponent
                        fieldName={"team_website"}
                        fieldValue={captainTeamWebsite}
                        fieldValueHandler={changeCaptainTeamWebsite}
                        fieldLabel={TEAM_WEBSITE_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-website-field"}
                        fieldHyperLink={captainTeamWebsite ? captainTeamWebsite : undefined}
                        fieldError={FormErrors.teamWebsite}
                        lg={{span: 4, offset: 1}}
                    />

                </FormComponent>

                <FormComponent cssClasses={"user-form team-information"}>

                    <FormTextareaFieldComponent
                        fieldName={"team_description"}
                        fieldValue={captainTeamDescription}
                        fieldValueHandler={changeCaptainTeamDescription}
                        fieldLabel={TEAM_DESCRIPTION_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-description-field"}
                        lg={6}
                    />

                    <FormTextFieldComponent
                        fieldType={"color"}
                        fieldName={"team_first_color"}
                        fieldValue={captainTeamFirstColor}
                        fieldValueHandler={changeCaptainTeamFirstColor}
                        fieldLabel={TEAM_FIRST_COLOR_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-first-color-field no-border-bottom no-margin-bottom"}
                        xs={5} lg={{span: 2, offset: 1}}
                    />

                    <FormTextFieldComponent
                        fieldType={"color"}
                        fieldName={"team_second_color"}
                        fieldValue={captainTeamSecondColor}
                        fieldValueHandler={changeCaptainTeamSecondColor}
                        fieldLabel={TEAM_SECOND_COLOR_FIELD_LABEL}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={fieldSaveDataHandler}
                        fieldCssClasses={"team-first-color-field"}
                        xs={{ span: 5, offset: 2 }} lg={{span: 2, offset: 1}}
                    />

                </FormComponent>

            </Row>
        </Col>
    );

}


export default TeamCaptainTeamDataComponent;

import React, { useEffect, useState } from "react";


import { UseApiRequestStatus } from "@ApplicationStates";

import { UseFormsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Form, Row } from "react-bootstrap";

import { AiFillCloseCircle, AiFillEdit, AiFillSave } from "react-icons/all";


import { FormFieldComponent } from "@FormsElements";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


import ColumnProps from "@BootstrapInterfaces/Column";



export interface FormTextFieldComponentProps extends ColumnProps {
    fieldType?: "text" | "email" | "number" | "date" | "password" | "color" | "file";
    fieldName?: string;
    fieldValue?: string | File;
    fieldValueHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    fieldLabel?: string;
    fieldPlaceholder?: string;
    fieldError?: FormFieldValueErrorInterface | null;
    fieldCssClasses?: string | string[];
    fieldEditableStatus?: boolean;
    fieldDisabledStatus?: boolean;
    fieldHashStatus?: boolean;
    fieldSaveDataHandler?: (fName: string, fValue: string, callback: (status: boolean) => void) => void;
    fieldHyperLink?: string;
}


export const FormTextFieldComponent: React.FC<FormTextFieldComponentProps> = ({
    fieldType = "text", fieldName= "",
    fieldValue, fieldValueHandler,
    fieldLabel = "", fieldPlaceholder = "",
    fieldError = null,
    fieldCssClasses = "",
    fieldEditableStatus = true, fieldDisabledStatus = false, fieldSaveDataHandler = null,
    fieldHyperLink, fieldHashStatus = false,
    xs = 12, lg = 12
}) : JSX.Element | null => {


    const EDIT = UseFormsDictionaryPhrasesSet("EDIT");
    const SAVE = UseFormsDictionaryPhrasesSet("SAVE");
    const ABORT = UseFormsDictionaryPhrasesSet("ABORT");


    const [formFieldEditableStatus, setFormFieldEditableStatus] = useState(fieldEditableStatus);
    const [formFieldError, setFormFieldError] = useState(fieldError);

    useEffect(() => {
        return () => {
            setFormFieldEditableStatus(false);
            setFormFieldError(null);
        };
    }, [])

    useEffect(() => {
        setFormFieldError(!formFieldEditableStatus ? null : fieldError);
    }, [formFieldEditableStatus, fieldError]);

    const apiRequestStatus = UseApiRequestStatus();


    const fieldTextStyles: React.CSSProperties = {};

    if (fieldType === "color" && typeof fieldValue === "string") {
        if (!!fieldValue) {
            fieldTextStyles.background = fieldValue;
            fieldTextStyles.color = fieldValue;
        }
    }


    return (
        <FormFieldComponent
            xs={xs} lg={lg}
            fieldType={fieldType}
            fieldLabel={fieldLabel}
            fieldError={formFieldError}
            fieldCssClasses={fieldCssClasses}
        >

            {formFieldEditableStatus ?

            <Col
                as={Form.Control}
                xs={!!fieldSaveDataHandler && !fieldLabel ? 10 : 12}
                type={fieldType}
                name={fieldName}
                value={fieldValue}
                onChange={fieldValueHandler}
                placeholder={fieldPlaceholder}
                disabled={!!apiRequestStatus || fieldDisabledStatus}
                className={"form-field-input" + (!!formFieldError ? " error" : "")}
            />

            :

            <Col xs={!!fieldSaveDataHandler && !fieldLabel ? 10 : 10} className={"form-field-text"}>
            {(!!fieldHyperLink && typeof fieldValue === "string" ?
                <a href={fieldHyperLink} title={``}>
                    <span>{fieldHashStatus ? fieldValue.replaceAll(/\d/g, "*") : fieldValue}</span>
                </a>
                :
                <span
                    className={"form-field-not-editable-value" + (!fieldValue ? " empty" : "")}
                    style={fieldTextStyles}
                >
                    {fieldValue && typeof fieldValue === "string" ? (fieldHashStatus ? fieldValue?.replaceAll(/\d/g, "*") : fieldValue) : "brak"}
                </span>
            )}
            </Col>

            }

            {!!fieldSaveDataHandler &&
            <Col xs={formFieldEditableStatus ? 12 : 2} className={"form-field-actions" + (formFieldEditableStatus ? " active" : "")}>
                <Row className={"form-field-actions-content"}>
                    {!formFieldEditableStatus &&
                    <Col xs={"auto"} className={"form-field-action edit-action"}>
                        <span onClick={() => setFormFieldEditableStatus(true)} title={EDIT}>
                            <AiFillEdit/>
                        </span>
                    </Col>
                    }
                    {!!formFieldEditableStatus &&
                    <Col xs={"auto"} className={"form-field-action abort-action"}>
                        <span onClick={() => setFormFieldEditableStatus(false)}>
                            <span>{ABORT}</span>
                            <AiFillCloseCircle/>
                        </span>
                    </Col>
                    }
                    {!!formFieldEditableStatus && typeof fieldValue === "string" &&
                    <Col xs={"auto"} className={"form-field-action save-action"}>
                        <span onClick={() => fieldSaveDataHandler(fieldName, fieldValue, setFormFieldEditableStatus)}>
                            <span>{SAVE}</span>
                            <AiFillSave/>
                        </span>
                    </Col>
                    }
                </Row>
            </Col>
            }

        </FormFieldComponent>
    );

};


export default FormTextFieldComponent;

import React from "react";


import _ from "lodash";


import { Row, Col } from "react-bootstrap";


import GameTablesTeamTableSquadPlayerComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Table/Team/Player";


import GameSummaryTeamPlayersStatisticsInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams/Team/Players";
import GameSummaryPlayersInformationInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Players";


interface GameTablesTeamTableSquadComponentProps {
    TeamPlayers: GameSummaryTeamPlayersStatisticsInterface;
    GamePlayers: GameSummaryPlayersInformationInterface;
    teamCaptainID?: number | null;
}


export const GameTablesTeamTableSquadComponent: React.FC<GameTablesTeamTableSquadComponentProps> = ({
    TeamPlayers, GamePlayers, teamCaptainID
}) : JSX.Element | null => {


    if (!TeamPlayers) return null;


    const SortedGameTeamSquadTeam = _.orderBy(
        Object.values(TeamPlayers),
        ["NUM"], ["asc"]
    );


    const GameTeamSquad = SortedGameTeamSquadTeam.map((Player, i) =>
        <GameTablesTeamTableSquadPlayerComponent
            key={i}
            Player={Player}
            teamCaptainID={teamCaptainID}
            GamePlayers={GamePlayers}
        />
    );


    if (!GameTeamSquad?.length) return null;


    return (
        <Col xs={12} className={"game-tables-team-table-team game-tables-team-table-team-component"}>
            <Row className={"game-tables-team-table-team-content"}>
                {GameTeamSquad}
            </Row>
        </Col>
    );

};


export default GameTablesTeamTableSquadComponent;

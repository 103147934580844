import useTypedSelector from "@Store/_Hooks/UseTypedSelector";

import UserModel from "@Models/Users/User";


export const UseLoggedUser = () : UserModel | null => {


    const { loggedUser } = useTypedSelector((state) => state.auth);

    return loggedUser || null;

};


export default UseLoggedUser;

import React from "react";


import { Col, Row } from "react-bootstrap";


import FileComponent from "@Pages/Common/DownloadPage/FilesGroups/FIlesGroup/File";


import FileGroupInterface from "@ApiInterfaces/_Common/Files/Group";


import "./index.scss";


export interface FilesGroupComponentProps {
    FilesGroup: FileGroupInterface;
}


export const FilesGroupComponent: React.FC<FilesGroupComponentProps> = ({
    FilesGroup
}) => {


    if (!FilesGroup) {
        return null;
    }


    const {
        file_group_name: fileGroupName,
        file_group_active_status: fileGroupActiveStatus,
        file_group_files: fileGroupFiles
    } = FilesGroup;


    if (!fileGroupActiveStatus || !fileGroupFiles) {
        return null;
    }


    const FilesList = fileGroupFiles.map((File, i) =>
        <FileComponent key={i} File={File} />
    );


    return (
        <Col xs={12} className={"files-for-download__files-groups__file-group"}>
            <Row className={"files-for-download__files-groups__file-group__content"}>

                {fileGroupName &&
				<Col xs={12} className={"files-for-download__files-groups__file-group__header"}>
					<span>{fileGroupName}</span>
				</Col>
                }

                {FilesList}

            </Row>
        </Col>
    );

};


export default FilesGroupComponent;

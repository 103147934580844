import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";



import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";


import {
    UseTeamActiveSeasonLeague
} from "@Hooks/Api/Requests";


import "./index.scss";
import {UseCommonDictionaryPhrasesSet} from "@Hooks/Texts";
import {TeamLeagueInterface} from "@Interfaces/Api";


export interface TeamHeaderLeagueComponentProps {
    TeamActiveSeasonLeague?: TeamLeagueInterface | null;
}


export const TeamHeaderLeagueComponent: React.FC<TeamHeaderLeagueComponentProps> = ({
    TeamActiveSeasonLeague
}) : JSX.Element | null => {


    const LeaguesRoute = UseRoute("TABLES");


    const LEAGUE_WORD_TEXT = UseCommonDictionaryPhrasesSet("LEAGUE_WORD_TEXT");


    if (!TeamActiveSeasonLeague) {
        return null;
    }


    const {
        team_league_league_data: TeamLeagueData
    } = TeamActiveSeasonLeague;


    if (!TeamLeagueData) {
        return null;
    }


    const {
        league_id: leagueID, league_name: leagueName, league_season_data: LeagueSeasonData
    } = TeamLeagueData;


    if (!LeagueSeasonData) {
        return null;
    }


    const {
        league_season_id: leagueSeasonID, league_season_name: leagueSeasonName
    } = LeagueSeasonData;


    return (
        <Col xs={12} lg={{span: 3, offset: 1}} className={"team-page__team-header__team-league"}>
            <Row className={"team-page__team-header__team-league__content"}>

                <Col xs={"auto"} lg={12} className={"team-page__team-header__team-league__label"}>
                    <span>{LEAGUE_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} lg={12} className={"team-page__team-header__team-league__name"}>
                    <NavLink
                        to={{
                            pathname: `/${LeaguesRoute.routeURL}`, state: {
                                selectedSeason: leagueSeasonID,
                                selectedLeague: leagueID
                            }
                        }}
                        title={LeaguesRoute.routeTitle}
                    >
                        <span className={"team-page__team-header__team-league__name__league"}>{leagueName}</span>
                        <span className={"team-page__team-header__team-league__name__season"}>{leagueSeasonName}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamHeaderLeagueComponent;

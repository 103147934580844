import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


export interface LeagueTeamSquadHeaderComponentProps {}


export const LeagueTeamSquadHeaderComponent: React.FC<LeagueTeamSquadHeaderComponentProps> = ({}) : JSX.Element | null => {


    const LEAGUE_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("LEAGUE_PLAYERS_PHRASE_TEXT");


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__header"}>
            <span>{LEAGUE_PLAYERS_PHRASE_TEXT}</span>
        </Col>
    );

};


export default LeagueTeamSquadHeaderComponent;

import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Row, Col, Form} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        best_scores_label_text: "Rekordy w sezonie"
    },
    EN: {
        best_scores_label_text: "Best scores in season"
    }
};


import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";


export interface BestScoresHeaderComponentProps {
    selectedLeague: SortedBySeasonGamesLeagueInterface;
    selectedLeagueHandler(leagueID: number): void;
    leaguesList: SortedBySeasonGamesLeagueInterface[];
}


export const BestScoresHeaderComponent: React.FC<BestScoresHeaderComponentProps> = ({
    selectedLeague, selectedLeagueHandler,
    leaguesList
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        best_scores_label_text: BEST_SCORES_LABEL_TEXT
    } = TEXTS[activeLanguage];


    if (!leaguesList || leaguesList.length === 0) return null;


    const LeaguesList = leaguesList.map((league) =>
        <option key={league.gamesLeagueID} value={league.gamesLeagueID}>{league.gamesLeagueName} ({league.gamesLeagueSeason})</option>
    );

    return (
        <Col xs={12} lg={`auto`} className={"best-scores-header best-scores-header-component"}>
            <Row className={"best-scores-header-content"}>

                <Col xs={6} lg={"auto"} className={"best-scores-header-label"}>
                    <span>{BEST_SCORES_LABEL_TEXT}</span>
                </Col>

                <Col xs={6} lg={"auto"} className={"best-scores-header-select"}>
                    <Form.Control
                        as={"select"}
                        value={selectedLeague.gamesLeagueID}
                        onChange={(e) => selectedLeagueHandler(+e.target.value)}
                        disabled={LeaguesList.length < 2}
                    >
                        {LeaguesList}
                    </Form.Control>
                </Col>

            </Row>
        </Col>
    );

};


export default BestScoresHeaderComponent;

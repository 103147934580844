import React, {useEffect, useState} from "react";

import _ from "lodash";


import SeasonsAndLeaguesComponent from "@Components/Views/Pages/Games/Teams/List/Content/SeasonsAndLeagues";
import TeamsAndPlayersComponent from "@Components/Views/Pages/Games/Teams/List/Content/TeamsAndPlayers";


interface SeasonLeagueInterface {
    leagueID: number;
    leagueName: string;
    leagueShortName?: string;
}

interface LeaguesSeasonInterface {
    [key: number]: {
        seasonID: number;
        seasonName: string | null;
        seasonActiveStatus: boolean;
        seasonLeagues: {
            [key: string]: SeasonLeagueInterface
        }
    }
}


export interface TeamsAndPlayersPageContentComponentProps {
    leaguesBySeasons: LeaguesSeasonInterface;
}


export const TeamsAndPlayersPageContentComponent: React.FC<TeamsAndPlayersPageContentComponentProps> = ({
    leaguesBySeasons
}) : JSX.Element | null => {


    const [selectedSeasonID, setSelectedSeasonID] = useState<number>(+(localStorage.getItem("PLKA_TEAMS_SEASON_ID") || 0));
    const [selectedLeagueID, setSelectedLeagueID] = useState<number>(+(localStorage.getItem("PLKA_TEAMS_LEAGUE_ID") || 0));

    useEffect(() => {

        if (!leaguesBySeasons) return;


        const selectedSeasonPresentCheck = Object.keys(leaguesBySeasons).filter(
            (seasonID) => +seasonID === selectedSeasonID
        ).length > 0;

        if (selectedSeasonPresentCheck) return;

        const SelectedSeason = Object.values(leaguesBySeasons).filter(
            (Season) => !!Season.seasonActiveStatus
        )[0];

        if (SelectedSeason) {
            localStorage.setItem("PLKA_TEAMS_SEASON_ID", SelectedSeason.seasonID.toString())
            setSelectedSeasonID(SelectedSeason.seasonID);
        }

    }, []);


    useEffect(() => {

        const Leagues = leaguesBySeasons[selectedSeasonID]?.seasonLeagues;

        if (!Leagues) return setSelectedLeagueID(0);

        const LeaguesList = _.sortBy(
            Object.values(Leagues), ["leagueActiveStatus", "leagueOrderNr", "leagueName"]
        );

        if (!!LeaguesList && LeaguesList.length && !selectedLeagueID) {
            setSelectedLeagueID(LeaguesList[0].leagueID);
        } else if (!selectedLeagueID) {
            setSelectedLeagueID(0);
        }

    }, [selectedSeasonID]);


    const selectedSeasonIDHandler = (seasonID: number) => {
        localStorage.setItem("PLKA_TEAMS_SEASON_ID", seasonID.toString())
        setSelectedSeasonID(seasonID);
    };

    const selectedLeagueIDHandler = (leagueID: number) => {
        localStorage.setItem("PLKA_TEAMS_LEAGUE_ID", leagueID.toString())
        setSelectedLeagueID(leagueID);
    };


    return (
        <>

            <SeasonsAndLeaguesComponent
                leaguesBySeasons={leaguesBySeasons}
                selectedSeasonID={selectedSeasonID}
                selectedSeasonIDHandler={selectedSeasonIDHandler}
                selectedLeagueID={selectedLeagueID}
                selectedLeagueIDHandler={selectedLeagueIDHandler}
            />

            <TeamsAndPlayersComponent
                selectedLeagueID={selectedLeagueID}
            />

        </>
    );


};


export default TeamsAndPlayersPageContentComponent;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    LeagueGameInterface, TeamLeagueInterface
} from "@Interfaces/Api";


export interface TeamStatisticsPreviousGameComponentProps {
    teamID: number;
    Game: LeagueGameInterface;
}


export const TeamStatisticsPreviousGameComponent: React.FC<TeamStatisticsPreviousGameComponentProps> = ({
    teamID, Game
}) : JSX.Element | null => {


    const GamesRoute = UseRoute("GAMES");

    const PREVIOUS_WORD_TEXT = UseCommonDictionaryPhrasesSet("PREVIOUS_WORD_TEXT");
    const GAME_WORD_TEXT = UseCommonDictionaryPhrasesSet("GAME_WORD");


    if (!teamID || !Game) {
        return null;
    }


    const {
        league_game_league_away_team_data: AwayTeam, league_game_league_home_team_data: HomeTeam,
        league_game_id: gameID,
        league_game_away_team_points: homeTeamPoints, league_game_home_team_points: awayTeamPoints,
    } = Game;

    if (!HomeTeam || !AwayTeam) {
        return null;
    }


    const { team_league_team_data: HomeTeamData } = HomeTeam;
    const { team_league_team_data: AwayTeamData } = AwayTeam;

    if (!HomeTeamData || !AwayTeamData) {
        return null;
    }

    const { team_id: homeTeamID } = HomeTeamData;
    const { team_id: awayTeamID } = AwayTeamData;

    let OpponentTeam: TeamLeagueInterface | null = null;

    let teamPoints: number | null = null;
    let opponentPoints: number | null = null;

    if (homeTeamID === teamID) {
        OpponentTeam = AwayTeam;
        teamPoints = homeTeamPoints;
        opponentPoints = awayTeamPoints;

    } else if (awayTeamID === teamID) {
        OpponentTeam = HomeTeam;
        teamPoints = awayTeamPoints;
        opponentPoints = homeTeamPoints;
    } else {
        return null;
    }


    const { team_league_team_name: opponentTeamName } = OpponentTeam;


    return (
        <Col xs={6} className={"team-details__team-statistics__games__game team-details__team-statistics__games__game--prev-game"}>
            <Row className={"team-details__team-statistics__games__game__content"}>

                <Col xs={12} className={"team-details__team-statistics__games__game__label"}>
                    <span>{PREVIOUS_WORD_TEXT} {GAME_WORD_TEXT}</span>
                </Col>

                <Col xs={12} className={"team-details__team-statistics__games__game__data"}>
                    <NavLink to={`/${GamesRoute.routeURL}/${gameID}`}title={GamesRoute.routeTitle} className={"team-details__team-statistics__games__game__data__game-link"}>
                        <span className={"team-details__team-statistics__games__game__data__game-link__opponent-name text-truncate"}>{opponentTeamName}</span>
                        <span className={"team-details__team-statistics__games__game__data__game-link__points"}>{teamPoints} : {opponentPoints}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamStatisticsPreviousGameComponent;

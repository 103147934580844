import React from "react";


import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {Col} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        accurate_throw_text: "Trafione rzuty",
        not_accurate_throw_text: "Nietrafione rzuty",
    },
    EN: {
        accurate_throw_text: "Accurate throws",
        not_accurate_throw_text: "Not accurate throws",
    }
};


interface GameThrowsTeamThrowsLegendComponentProps {

}


export const GameThrowsTeamThrowsLegendComponent: React.FC<GameThrowsTeamThrowsLegendComponentProps> = ({}) : JSX.Element | null => {


    const {activeLanguage} = useTypedSelector((state) => state.loc);

    const {
        accurate_throw_text: ACCURATE_THROWS_TEXT,
        not_accurate_throw_text: NOT_ACCURATE_THROWS_TEXT,
    } = TEXTS[activeLanguage];


    return (
        <Col xs={12} className={"team-throws-legend"}>

            <span className={"accurate-throws"}>{ACCURATE_THROWS_TEXT}</span>
            <span className={"not-accurate-throws"}>{NOT_ACCURATE_THROWS_TEXT}</span>

        </Col>
    );

};


export default GameThrowsTeamThrowsLegendComponent;

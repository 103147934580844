import { UseLanguage } from "@States";


import GlobalsDictionary, { GlobalsDictionaryPhrasesSetType } from "@Dictionary/Globals";


export const UseGlobalsDictionaryPhrasesSet = (phraseName: GlobalsDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return GlobalsDictionary[ActiveLanguage][phraseName];

};


export default UseGlobalsDictionaryPhrasesSet;

import React from "react";


import { UseGlobalsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col } from "react-bootstrap";


import { FilesTypes } from "@Interfaces/Gui";


import FileInterface from "@ApiInterfaces/_Common/Files/File";


import "./index.scss";


export interface FileComponentProps {
    File: FileInterface;
}


export const FileComponent: React.FC<FileComponentProps> = ({
    File
}) : JSX.Element | null => {


    const DOWNLOAD_FILE_TEXT = UseGlobalsDictionaryPhrasesSet("DOWNLOAD_FILE_TEXT");


    if (!File) {
        return null;
    }


    const {
        file_name: fileName,
        file_url: fileURL,
        file_type: fileType,
        file_active_status: fileActiveStatus,
        file_description: fileDescription
    } = File;


    if (!fileActiveStatus) {
        return null;
    }


    const FileIcon = FilesTypes[fileType] || FilesTypes["UNKNOWN"];


    return (
        <Col xs={12} className={"files-for-download__files-groups__file-group__file"}>

            <a href={fileURL} title={DOWNLOAD_FILE_TEXT} className={"file-download-link"} target={"_blank"}>
                <span className={"file-name"}>{fileName}</span>
                <FileIcon />
            </a>

            {!!fileDescription &&
			<span className={"file-description"}>{fileDescription}</span>
            }

        </Col>
    );

};


export default FileComponent;

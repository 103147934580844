import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import MessageComponent from "@MessagesElements/Content";


import GameInformationSummaryTableComponent from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable";
import GameSummaryTableDataComponent from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable/Table";
import GameSummaryTableRowComponent from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable/Table/Row";


import prepareLeadersPlayersScores from "@GameResultPage/_Utitlities/Summary/Leaders";


import {
    formatMinutesFromSeconds
} from "@Services/Utilities/Tools/Formatters";

import {
    formatSummaryTablePlayerName, formatSummaryTableScore
} from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable";


import ApiGameSummaryQuarterType from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";

import TeamsSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams";

import LeadersInformationPlayerScoreInterface from "@GameResultPage/_Utitlities/Summary/Leaders/Types/Scores/Score";


interface GameInformationLeadersComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface;
    TeamsSummaryData: TeamsSummaryInterface | null;
}


export const GameInformationLeadersComponent: React.FC<GameInformationLeadersComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics, TeamsSummaryData
}) : JSX.Element | null => {


    const LEADERS = UseGamesDictionaryPhrasesSet("LEADERS");

    const POINTS_TITLE = UseGamesDictionaryPhrasesSet("POINTS_TITLE");
    const POINTS_LABEL = UseGamesDictionaryPhrasesSet("POINTS_LABEL");
    const REBOUNDS_TITLE = UseGamesDictionaryPhrasesSet("REBOUNDS_TITLE");
    const OFFENSIVE_REBOUNDS_TITLE = UseGamesDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
    const DEFENSIVE_REBOUNDS_TITLE = UseGamesDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
    const ASSISTS_TITLE = UseGamesDictionaryPhrasesSet("ASSISTS_TITLE");
    const BLOCKS_TITLE = UseGamesDictionaryPhrasesSet("BLOCKS_TITLE");
    const STEALS_TITLE = UseGamesDictionaryPhrasesSet("STEALS_TITLE");
    const TURNOVERS_TITLE = UseGamesDictionaryPhrasesSet("TURNOVERS_TITLE");

    const GAME_THROWS_EFFECTIVITY_TITLE = UseGamesDictionaryPhrasesSet("GAME_THROWS_EFFECTIVITY_TITLE");
    const PT3_THROWS_EFFECTIVITY_TITLE = UseGamesDictionaryPhrasesSet("PT3_THROWS_EFFECTIVITY_TITLE");
    const PT2_THROWS_EFFECTIVITY_TITLE = UseGamesDictionaryPhrasesSet("PT2_THROWS_EFFECTIVITY_TITLE");
    const PT1_THROWS_EFFECTIVITY_TITLE = UseGamesDictionaryPhrasesSet("PT1_THROWS_EFFECTIVITY_TITLE");

    const GAME_PLAY_TIME_TITLE = UseGamesDictionaryPhrasesSet("GAME_PLAY_TIME_TITLE");
    const PLAYER_OF_THE_GAME = UseGamesDictionaryPhrasesSet("PLAYER_OF_THE_GAME");


    const NO_QUARTERS_SELECTED_MESSAGE = UseGamesDictionaryPhrasesSet("NO_QUARTERS_SELECTED_MESSAGE");


    if (
        !SelectedQuarters || !GameSummaryStatistics || !TeamsSummaryData ||
        !homeTeamID || !awayTeamID
    ) return null;


    if (SelectedQuarters.length === 0) return <MessageComponent messageText={NO_QUARTERS_SELECTED_MESSAGE} />;


    const {
        players: GamePlayers
    } = GameSummaryStatistics;


    const TeamsLeaders = prepareLeadersPlayersScores(
        SelectedQuarters, TeamsSummaryData
    );


    if (!TeamsLeaders) return null;


    const HomeTeamLeaders = TeamsLeaders[homeTeamID];
    const AwayTeamLeaders = TeamsLeaders[awayTeamID];


    if (!HomeTeamLeaders || !AwayTeamLeaders) return null;


    const {
        playerOfTheGame: HomeTeamBestPlayer,
        greatestPlayTime: HomeTeamBestPlayTimePlayer,
        mostPoints: HomeTeamBestPointsPlayer,
        gameThrowsEffectivity: HomeTeamBestGameThrowsPlayer,
        pt3ThrowsEffectivity: HomeTeamBest3ptThrowsPlayer,
        pt2ThrowsEffectivity: HomeTeamBest2ptThrowsPlayer,
        pt1ThrowsEffectivity: HomeTeamBest1ptThrowsPlayer,
        mostRebounds: HomeTeamBestReboundsAmountPlayer,
        mostOffensiveRebounds: HomeTeamBestOffensiveReboundsAmountPlayer,
        mostDefensiveRebounds: HomeTeamBestDefensiveReboundsAmountPlayer,
        mostAssists: HomeTeamBestAssistsAmountPlayer,
        mostBlocks: HomeTeamBestBlocksAmountPlayer,
        mostSteals: HomeTeamBestStealsAmountPlayer,
        mostTurnovers: HomeTeamMostTurnoversAmountPlayer,
    } = HomeTeamLeaders;

    const {
        playerOfTheGame: AwayTeamBestPlayer,
        greatestPlayTime: AwayTeamBestPlayTimePlayer,
        mostPoints: AwayTeamBestPointsPlayer,
        gameThrowsEffectivity: AwayTeamBestGameThrowsPlayer,
        pt3ThrowsEffectivity: AwayTeamBest3ptThrowsPlayer,
        pt2ThrowsEffectivity: AwayTeamBest2ptThrowsPlayer,
        pt1ThrowsEffectivity: AwayTeamBest1ptThrowsPlayer,
        mostRebounds: AwayTeamBestReboundsAmountPlayer,
        mostOffensiveRebounds: AwayTeamBestOffensiveReboundsAmountPlayer,
        mostDefensiveRebounds: AwayTeamBestDefensiveReboundsAmountPlayer,
        mostAssists: AwayTeamBestAssistsAmountPlayer,
        mostBlocks: AwayTeamBestBlocksAmountPlayer,
        mostSteals: AwayTeamBestStealsAmountPlayer,
        mostTurnovers: AwayTeamMostTurnoversAmountPlayer,
    } = AwayTeamLeaders;


    let PlayerOfTheGame: LeadersInformationPlayerScoreInterface | null = null;
    let PlayerOfTheGameTeamID = 0;

    if (HomeTeamBestPlayer || AwayTeamBestPlayer) {
        if (HomeTeamBestPlayer && HomeTeamBestPlayer?.score > (AwayTeamBestPlayer?.score || 0)) {
            PlayerOfTheGame = HomeTeamBestPlayer;
            PlayerOfTheGameTeamID = homeTeamID;
        } else if (AwayTeamBestPlayer && AwayTeamBestPlayer?.score > (HomeTeamBestPlayer?.score || 0)) {
            PlayerOfTheGame = AwayTeamBestPlayer;
            PlayerOfTheGameTeamID = awayTeamID;
        }
    }


    const { squads: GameSquads } = GameSummaryStatistics;

    const HomeTeam = GameSquads[homeTeamID];
    const AwayTeam = GameSquads[awayTeamID];


    return (
        <GameInformationSummaryTableComponent
            gameHomeTeam={HomeTeam}
            gameAwayTeam={AwayTeam}
        >

            <GameSummaryTableDataComponent
                tableTitle={LEADERS}
            >

                {SelectedQuarters.length >= 4 &&
                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PLAYER_OF_THE_GAME}
                    summaryStatisticHomeTeamData={{
                        primaryValue: PlayerOfTheGame && PlayerOfTheGameTeamID === homeTeamID ? formatSummaryTablePlayerName(GamePlayers, PlayerOfTheGame?.id) : "",
                        secondaryValue: PlayerOfTheGame && PlayerOfTheGameTeamID === homeTeamID ? `(Eval ${formatSummaryTableScore(PlayerOfTheGame?.score)})` : ""
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: PlayerOfTheGame && PlayerOfTheGameTeamID=== awayTeamID ? formatSummaryTablePlayerName(GamePlayers, PlayerOfTheGame?.id) : "",
                        secondaryValue: PlayerOfTheGame && PlayerOfTheGameTeamID === awayTeamID ? `(Eval ${formatSummaryTableScore(PlayerOfTheGame?.score)})` : ""
                    }}
                />
                }

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POINTS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestPointsPlayer?.id),
                        secondaryValue: `${formatSummaryTableScore(HomeTeamBestPointsPlayer?.score, POINTS_LABEL)}`
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestPointsPlayer?.id),
                        secondaryValue: `${formatSummaryTableScore(AwayTeamBestPointsPlayer?.score, POINTS_LABEL)}`
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={GAME_THROWS_EFFECTIVITY_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestGameThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestGameThrowsPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestGameThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestGameThrowsPlayer?.score)
                    }}
                    statisticType={"percentage"}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PT3_THROWS_EFFECTIVITY_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBest3ptThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBest3ptThrowsPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBest3ptThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBest3ptThrowsPlayer?.score)
                    }}
                    statisticType={"percentage"}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PT2_THROWS_EFFECTIVITY_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBest2ptThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBest2ptThrowsPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBest2ptThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBest2ptThrowsPlayer?.score)
                    }}
                    statisticType={"percentage"}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PT1_THROWS_EFFECTIVITY_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBest1ptThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBest1ptThrowsPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBest1ptThrowsPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBest1ptThrowsPlayer?.score)
                    }}
                    statisticType={"percentage"}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={REBOUNDS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestReboundsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestReboundsAmountPlayer?.score)
                }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestReboundsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestReboundsAmountPlayer?.score)
                }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={OFFENSIVE_REBOUNDS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestOffensiveReboundsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestOffensiveReboundsAmountPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestOffensiveReboundsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestOffensiveReboundsAmountPlayer?.score)
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={DEFENSIVE_REBOUNDS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestDefensiveReboundsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestDefensiveReboundsAmountPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestDefensiveReboundsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestDefensiveReboundsAmountPlayer?.score)
                }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={ASSISTS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestAssistsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestAssistsAmountPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestAssistsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestAssistsAmountPlayer?.score)
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={BLOCKS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestBlocksAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestBlocksAmountPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestBlocksAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestBlocksAmountPlayer?.score)
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={STEALS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestStealsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamBestStealsAmountPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestStealsAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamBestStealsAmountPlayer?.score)
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TURNOVERS_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamMostTurnoversAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(HomeTeamMostTurnoversAmountPlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamMostTurnoversAmountPlayer?.id),
                        secondaryValue: formatSummaryTableScore(AwayTeamMostTurnoversAmountPlayer?.score)
                    }}
                />

                {SelectedQuarters.length >= 4 &&
                <GameSummaryTableRowComponent
                    summaryStatisticLabel={GAME_PLAY_TIME_TITLE}
                    summaryStatisticHomeTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, HomeTeamBestPlayTimePlayer?.id),
                        secondaryValue: formatMinutesFromSeconds(HomeTeamBestPlayTimePlayer?.score)
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: formatSummaryTablePlayerName(GamePlayers, AwayTeamBestPlayTimePlayer?.id),
                        secondaryValue: formatMinutesFromSeconds(AwayTeamBestPlayTimePlayer?.score)
                    }}
                />
                }

            </GameSummaryTableDataComponent>

        </GameInformationSummaryTableComponent>
    );


};


export default GameInformationLeadersComponent;

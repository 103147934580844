import MessageLanguageInterface from "@GlobalData/Messages/_Interfaces/Language";


const DefaultHttpResponseErrorMessagePL = "Wystąpił błąd pobierania danych";
const DefaultHttpResponseErrorMessageEN = "Data fetching error";


export interface HttpResponseErrorMessagesInterface {
    defaultErrorMessage: MessageLanguageInterface;
}


export const HttpResponseErrorMessages: HttpResponseErrorMessagesInterface = {
    defaultErrorMessage: {
        PL: DefaultHttpResponseErrorMessagePL,
        EN: DefaultHttpResponseErrorMessageEN
    }
};


export default HttpResponseErrorMessages;

import React from "react";


import { Col } from "react-bootstrap";


import "./index.scss";


interface FooterInformationElementComponentProps {
    elementLabel: string;
    elementValue?: number;
    elementIcon?: string;
}


export const FooterInformationElementComponent: React.FC<FooterInformationElementComponentProps> = ({
                                                                                                        elementLabel = "", elementValue = 0, elementIcon
                                                                                                    }) : JSX.Element | null => {
    return (
        <Col className={`service-footer-section__information__list__element`}>

            {!!elementLabel &&
            <h5 className={`element-name`}>{elementLabel}</h5>
            }

            {!!elementIcon &&
            <img src={elementIcon} alt={elementLabel} className={`element-icon`} />
            }

            {(!!elementValue || elementValue === 0) &&
			<h6 className={`element-value`}>
				<span>{elementValue}</span>
			</h6>
            }

        </Col>
    );

};


export default FooterInformationElementComponent;

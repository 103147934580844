import React from "react";


import { Col } from "react-bootstrap";


export interface SchedulesAdminLeagueOrderNrComponentProps {
    leagueOrderNr: number;
}


export const SchedulesAdminLeagueOrderNrComponent: React.FC<SchedulesAdminLeagueOrderNrComponentProps> = ({
    leagueOrderNr
}) : JSX.Element | null => {


    if (!leagueOrderNr) {
        return null;
    }

    return (
        <Col xs={1}>
            <span>{leagueOrderNr}</span>
        </Col>
    );


};


export default SchedulesAdminLeagueOrderNrComponent;

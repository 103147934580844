import React from "react";
import {LeagueInterface} from "@Interfaces/Api";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import {UseErrorMessage} from "@Hooks/Texts";
import {SingleContentLoaderComponent} from "@LoadersElements";
import {Col, Row} from "react-bootstrap";
import LeaguesSchedulesAdminLeagueComponent from "@ResultsPages/_Admin/Leagues/League";


export interface LeaguesScheduleAdminLeaguesComponentProps {
    Leagues: LeagueInterface[] | null;
    LeaguesError: ErrorMessageInterface | null;
}


export const LeaguesScheduleAdminLeaguesComponent: React.FC<LeaguesScheduleAdminLeaguesComponentProps> = ({
    Leagues, LeaguesError
}) : JSX.Element | null => {


    const DEFAULT_DATA_REQUEST_ERROR = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");


    if (LeaguesError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR} cssClasses={"no-margin"} />
    }

    if (!Leagues) {
        return <SingleContentLoaderComponent />;
    }


    const LeaguesList = Leagues.map((League, i) =>
        <LeaguesSchedulesAdminLeagueComponent key={i} League={League} orderNr={i + 1} />
    );


    return (
        <Col>
            <Row>

                <Col xs={12}>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={3}>Liga</Col>
                        <Col xs={2}>Status</Col>
                        <Col xs={2}>Sezon</Col>
                        <Col xs={2}>Status Terminarza</Col>
                        <Col xs={2}></Col>
                    </Row>
                </Col>

                {LeaguesList}

            </Row>
        </Col>
    );

};


export default LeaguesScheduleAdminLeaguesComponent;

import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Alert} from "react-bootstrap";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


const TEXTS: any = {
    PL: {not_found_error_message: "Strony nie znaleziono"},
    EN: {not_found_error_message: "Page not found"},
};


export interface Error404PageComponentProps {}


export const Error404PageComponent: React.FC<Error404PageComponentProps> = () : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {not_found_error_message: NOT_FOUND_ERROR_MESSAGE} = TEXTS[activeLanguage];


    return (
        <ContainerSectionComponent id={"error-404-page-component"} classes={"error-page-component error-404-page-component"}>

            <Col as={Alert} xs={12} className={"error-message 404-error-message"}>
                <span>{NOT_FOUND_ERROR_MESSAGE}</span>
            </Col>

        </ContainerSectionComponent>
    );

};


export default Error404PageComponent;

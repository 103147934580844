export enum InformationSectionTypes {
    TABLES = "game-tables",
    SUMMARY = "game-summary",
    ARRANGE = "game-arrange",
    POSSESSION = "game-possessions",
    LEADERS = "game-leaders",
    THROWS = "game-throws",
    DIAGRAMS = "game-diagrams",
    EVENTS = "game-events"
}


export default InformationSectionTypes;

import {
    LeagueInterface
} from "@Interfaces/Api";


import MappedLeaguesBySeasonsInterface from "@LeaguesPages/_Types/Leagues/BySeasons";


export const _mapLeaguesBySeasons = (Leagues: LeagueInterface[]): MappedLeaguesBySeasonsInterface => {

    const MappedLeaguesBySeasons: MappedLeaguesBySeasonsInterface = {};

    if (Leagues?.length) {

        for (let League of Leagues) {

            const {
                league_season_data: LeagueSeason
            } = League;

            if (!LeagueSeason) continue;

            const {
                league_season_id: seasonID,
                league_season_name: seasonName,
                league_season_active_status: seasonActiveStatus
            } = LeagueSeason;

            if (!MappedLeaguesBySeasons[seasonID]) {
                MappedLeaguesBySeasons[seasonID] = {
                    seasonID, seasonName, seasonActiveStatus, seasonLeagues: {}
                };
            }

            const {
                league_id: leagueID,
                league_name: leagueName, league_short_name: leagueShortName,
                league_active_status: leagueActiveStatus, league_order_nr: leagueOrderNr,
                league_groups: leagueGroups
            } = League;

            MappedLeaguesBySeasons[seasonID].seasonLeagues[leagueID] = {
                leagueID, leagueName, leagueShortName, leagueActiveStatus, leagueOrderNr, leagueGroups
            };

        }

    }

    return MappedLeaguesBySeasons;

};


export default _mapLeaguesBySeasons;

import React from "react";


import { Col } from "react-bootstrap";


import PlayerFieldInformationComponent from "@Components/Views/Pages/Games/Players/Player/MainData/Player/Information/Field";
import PlayerNameComponent from "@Components/Views/Pages/Games/Players/Player/MainData/Player/Information/Name";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayerInformationComponentProps {
    Player?: LeaguePlayerInterface;
}


export const PlayerInformationComponent: React.FC<PlayerInformationComponentProps> = ({
    Player
}) : JSX.Element | null => {


    if (!Player) return null;


    return (
        <Col xs={8} lg={9} className={"player-information"}>

            <PlayerFieldInformationComponent player={Player} />

            <PlayerNameComponent player={Player} />

        </Col>
    );

};


export default PlayerInformationComponent;

export const COACH_PL = "Trener";
export const COACH_EN = "Coach";
export const COACH_ASSISTANT_PL= "Asystent Trenera";
export const COACH_ASSISTANT_EN = "Coach Assistant";

export const REFEREE_PL = "sędzia"
export const REFEREE_EN = "referee"
export const REFEREES_PL = "sędziowie"
export const REFEREES_EN = "referees"
export const COMMISSIONER_PL = "komisarz"
export const COMMISSIONER_EN = "commissioner"
export const COMMISSIONERS_PL = "komisarze"
export const COMMISSIONERS_EN = "commissioners"
export const SPECTATOR_PL = "widz"
export const SPECTATOR_EN = "spectator"
export const SPECTATORS_PL = "widzowie"
export const SPECTATORS_EN = "spectators"
export const SPECTATOR_LABEL_PL = "widzów"
export const SPECTATOR_LABEL_EN = "spectators"

export const ROUND_NUMBER_PL = "numer kolejki";
export const ROUND_NUMBER_EN = "round number";
export const ROUND_NUMBER_SHORT_PL = "";
export const ROUND_NUMBER_SHORT_EN = "";
export const ROUND_NUMBER_LABEL_PL = "";
export const ROUND_NUMBER_LABEL_EN = "";

export const DATE_PL = "data";
export const DATE_EN = "date";
export const DATE_LABEL_PL = "";
export const DATE_LABEL_EN = "";

export const QUARTER_PL = "Kwarta";
export const QUARTER_EN = "Quarter";
export const QUARTER_SHORT_PL = "K";
export const QUARTER_SHORT_EN = "Q";
export const QUARTER_LABEL_PL = "";
export const QUARTER_LABEL_EN = "";

export const EXTRA_TIME_PL = "dogrywka";
export const EXTRA_TIME_EN = "extra time";
export const EXTRA_TIME_SHORT_PL = "dog";
export const EXTRA_TIME_SHORT_EN = "e.t.";
export const EXTRA_TIME_LABEL_PL = "";
export const EXTRA_TIME_LABEL_EN = "";

export const TABLES_PL = "Tabele";
export const TABLES_EN = "Tables";
export const SUMMARY_PL = "Podsumowanie";
export const SUMMARY_EN = "Summary";
export const ARRANGE_PL = "Zestawienie";
export const ARRANGE_EN = "Arrange";
export const POSSESSIONS_PL = "Posiadania";
export const POSSESSIONS_EN = "Possessions";
export const LEADERS_PL = "Liderzy";
export const LEADERS_EN = "Leaders";
export const THROWS_PL = "Rzuty";
export const THROWS_EN = "Throws";
export const DIAGRAMS_PL = "Diagramy";
export const DIAGRAMS_EN = "Diagrams";
export const DIAGRAMS_TITLE_PL = "Mapa Przewag";
export const DIAGRAMS_TITLE_EN = "Advantages Map";
export const PROGRESS_PL = "Przebieg";
export const PROGRESS_EN = "Progress";

export const POINTS_TITLE_PL = "Punkty";
export const POINTS_TITLE_EN = "Points";
export const POINTS_LABEL_PL = "Pkt";
export const POINTS_LABEL_EN = "Pts";
export const POINTS_SHORT_LABEL_PL = "P";
export const POINTS_SHORT_LABEL_EN = "P";
export const REBOUNDS_TITLE_PL = "Zbiórki";
export const REBOUNDS_TITLE_EN = "Rebounds";
export const OFFENSIVE_REBOUNDS_TITLE_PL = "Zbiórki Atak";
export const OFFENSIVE_REBOUNDS_TITLE_EN = "Offensive Rebounds";
export const DEFENSIVE_REBOUNDS_TITLE_PL = "Zbiórki Obrona";
export const DEFENSIVE_REBOUNDS_TITLE_EN = "Defensive Rebounds";
export const ASSISTS_TITLE_PL = "Asysty";
export const ASSISTS_TITLE_EN = "Assists";
export const BLOCKS_TITLE_PL = "Bloki";
export const BLOCKS_TITLE_EN = "Blocks";
export const STEALS_TITLE_PL = "Przechwyty";
export const STEALS_TITLE_EN = "Steals";
export const TURNOVERS_TITLE_PL = "Straty";
export const TURNOVERS_TITLE_EN = "Turnovers";

export const GAME_THROWS_EFFECTIVITY_TITLE_PL = "Skuteczność z gry";
export const GAME_THROWS_EFFECTIVITY_TITLE_EN = "Game Throws Effectivity";
export const PT3_THROWS_EFFECTIVITY_TITLE_PL = "Skuteczność za 3";
export const PT3_THROWS_EFFECTIVITY_TITLE_EN = "3PT Throws Effectivity";
export const PT2_THROWS_EFFECTIVITY_TITLE_PL = "Skuteczność za 2";
export const PT2_THROWS_EFFECTIVITY_TITLE_EN = "2PT Throws Effectivity";
export const PT1_THROWS_EFFECTIVITY_TITLE_PL = "Skuteczność za 1";
export const PT1_THROWS_EFFECTIVITY_TITLE_EN = "1PT Throws Effectivity";

export const GAME_PLAY_TIME_TITLE_PL = "Czas gry";
export const GAME_PLAY_TIME_TITLE_EN = "Play Time";

export const PLAYER_OF_THE_GAME_PL = "Zawodnik meczu";
export const PLAYER_OF_THE_GAME_EN = "Player of the game";

export const POSSESSIONS_NUMBER_PL = "Ilość posiadań";
export const POSSESSIONS_NUMBER_EN = "Possessions Number";
export const TOTAL_POSSESSIONS_PL = "Posiadania";
export const TOTAL_POSSESSIONS_EN = "Possessions";
export const MEDIUM_POSSESSION_TIME_PL = "Średni czas posiadania";
export const MEDIUM_POSSESSION_TIME_EN = "Medium Possession time";
export const POSSESSIONS_FINISHED_BY_THROW_PL = "Zakończone rzutem";
export const POSSESSIONS_FINISHED_BY_THROW_EN = "Finished by throw";
export const POSSESSIONS_FINISHED_BY_ACCURATE_THROW_PL = "Celnym";
export const POSSESSIONS_FINISHED_BY_ACCURATE_THROW_EN = "Accurate";
export const POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW_PL = "Niecelnym";
export const POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW_EN = "Not Accurate";
export const POSSESSIONS_FINISHED_BY_FAULT_PL = "Zakończone błędem";
export const POSSESSIONS_FINISHED_BY_FAULT_EN = "Finished by Fault";
export const POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT_PL = "Kozłowania";
export const POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT_EN = "Dribbling";
export const POSSESSIONS_FINISHED_BY_PASS_FAULT_PL = "Podania";
export const POSSESSIONS_FINISHED_BY_PASS_FAULT_EN = "Passes";
export const POSSESSIONS_FINISHED_BY_TIME_FAULT_PL = "Czasu (3,5,8,24)";
export const POSSESSIONS_FINISHED_BY_TIME_FAULT_EN = "Times (3,5,8,24)";
export const POSSESSIONS_FINISHED_BY_OUT_FAULT_PL = "Aut";
export const POSSESSIONS_FINISHED_BY_OUT_FAULT_EN = "Out";
export const POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT_PL = "Faul (w ataku)";
export const POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT_EN = "Foul (offense)";

export const NO_QUARTERS_SELECTED_MESSAGE_PL = "Nie wybrano kwart";
export const NO_QUARTERS_SELECTED_MESSAGE_EN = "No Quarters Selected";
export const NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE_PL = "Nie wybrano zawodników lub kwart";
export const NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE_EN = "No Players nor Quarters selected";

export const NO_GAME_EVENTS_MESSAGE_PL = "Brak zdarzeń";
export const NO_GAME_EVENTS_MESSAGE_EN = "No Events";

import React from "react";


import {Col, Row} from "react-bootstrap";


import ResultHeaderDateComponent from "@Components/Views/Parts/Results/List/Result/Header/Date";
import ResultHeaderStreamsComponent from "@Components/Views/Parts/Results/List/Result/Header/Streams";


type ResultHeaderProps = {
    gameDate?: Date;
    gameStreams: {FB_STREAM?: string | null, YT_STREAM?: string | null};
};


export const ResultHeaderComponent: React.FC<ResultHeaderProps> = ({
    gameDate, gameStreams
}): JSX.Element | null => {


    return (
        <Col xs={12} className={"results-bar-item-header"}>
            <Row className={"results-bar-item-header-content"}>

                {!!gameDate && <ResultHeaderDateComponent GAME_DATE={gameDate} />}

                <ResultHeaderStreamsComponent
                    GAME_STREAMS={gameStreams}
                />

            </Row>
        </Col>
    );

};


export default ResultHeaderComponent

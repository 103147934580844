import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import ArticleHeaderComponent from "@Pages/Common/ArticlesPage/ArticleDetail/ArticleContent/ArticleHeader";
import ArticleTextComponent from "@Pages/Common/ArticlesPage/ArticleDetail/ArticleContent/ArticleText";


import {
    ArticleInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ArticleDetailContentComponentProps {
    Article: ArticleInterface;
}


export const ArticleDetailContentComponent: React.FC<ArticleDetailContentComponentProps> = ({
    Article
}) : JSX.Element | null => {


    if (!Article) {
        return null;
    }


    return (
        <Col xs={12} className={"article-detail__content"}>
            <Row className={"article-detail__content__content"}>

                <ArticleHeaderComponent Article={Article} />

                <ArticleTextComponent Article={Article} />

            </Row>
        </Col>
    )


};


export default ArticleDetailContentComponent;

import React from "react";

import _ from "lodash";


import {Col, Row} from "react-bootstrap";


import PlayersPlayerComponent from "@Components/Views/Pages/Games/Teams/List/Content/TeamsAndPlayers/Players/Player";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayersSortLetterComponentProps {
    letter: string;
    players: LeaguePlayerInterface[];
}


export const PlayersSortLetterComponent: React.FC<PlayersSortLetterComponentProps> = ({
    letter, players
}) : JSX.Element | null => {


    if (!letter || !players?.length) return null;


    players = _.sortBy(
        players, ["player_last_name", "player_first_name"]
    );


    const Players = players.map((Player) =>
        <PlayersPlayerComponent Player={Player} />
    );


    return (
        <Col key={letter} xs={6} lg={3} className={"players-list-group"}>
            <Row className={"players-list-group-content"}>
                <Col xs={12} className={"players-list-group-header"}>
                    <span>{letter}</span>
                </Col>
                {Players}
            </Row>
        </Col>
    );

};


export default PlayersSortLetterComponent;

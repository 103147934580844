import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


// import ROUTER from "@Router";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import stringTruncate from "truncate";


import NO_IMAGE from "@Images/no-avatar.jpg";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import {LeagueGameStatusType} from "@Interfaces/Api";


const TEXTS: any = {
    PL: {result_word_text: "Wynik"},
    EN: {result_word_text: "Result"},
};


type ResultContentProps = {
    gameID: number;
    gameFinishedStatus: boolean;
    homeTeamData: LeagueTeamInterface;
    homeTeamPoints: string | number | null;
    awayTeamData: LeagueTeamInterface;
    awayTeamPoints: string | number | null;
    gameStatus: LeagueGameStatusType;
};


export const ResultContentComponent: React.FC<ResultContentProps> = ({
    gameID, gameFinishedStatus = false,
    homeTeamData, homeTeamPoints,
    awayTeamData, awayTeamPoints,
    gameStatus
}): JSX.Element | null => {


    const {activeLanguage} = useTypedSelector((state) => state.loc);

    const {result_word_text: RESULT_WORD_TEXT} = TEXTS[activeLanguage];


    if (!homeTeamData || !awayTeamData) return null;


    // const TEAMS_PAGE = "druzyny";


    const {
        team_name: homeTeamName, team_short_name: homeTeamShortName,
        // team_slug: homeTeamSlug,
        team_logo: homeTeamLogo,
    } = homeTeamData;

    const {
        team_name: awayTeamName, team_short_name: awayTeamShortName,
        // team_slug: awayTeamSlug,
        team_logo: awayTeamLogo,
    } = awayTeamData;


    let leagueGameURL = `mecze`;
    // if (!gameFinishedStatus) leagueGameURL += `/live`;
    leagueGameURL += `/${gameID}`;


    return (
        <Col xs={12} className={"results-bar-item-data"}>
            <Row className={"results-bar-item-content-content"}>

                <Col xs={{span: 5, offset: 7}} lg={{span: 3, offset: 9}} className={"league-game-result-label"}>
                    <NavLink to={leagueGameURL} title={``}>
                        <span>{RESULT_WORD_TEXT}</span>
                    </NavLink>
                </Col>

                <Col xs={12} className={"league-game-team league-game-home-team"}>
                    <Row className={"league-game-team-content league-game-home-team-content"}>
                        <Col xs={7} lg={9} className={"league-game-team-data" + ((homeTeamPoints || 0) > (awayTeamPoints || 0) ? " winner" : "")}>
                            <NavLink to={leagueGameURL} title={`Profil drużyny`} className={"game-team-logo"}>
                                <img src={homeTeamLogo || NO_IMAGE} alt={``}/>
                            </NavLink>
                            <NavLink to={leagueGameURL} title={`Profil drużyny`} className={"game-team-name"}>
                                <span>{homeTeamShortName || stringTruncate(homeTeamName, window.innerWidth > 992 ? 30 : 6)}</span>
                            </NavLink>
                        </Col>
                        <Col xs={5} lg={3} className={"league-game-team-points" + ((homeTeamPoints || 0) > (awayTeamPoints || 0) ? " winner" : "")}>
                            <NavLink to={leagueGameURL} title={``}>
                                <span>{homeTeamPoints || (gameStatus !== "FT_WAL" ? `-` : homeTeamPoints)}</span>
                            </NavLink>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} className={"league-game-team league-game-away-team" + ((homeTeamPoints || 0) < (awayTeamPoints || 0) ? " winner" : "")}>
                    <Row className={"league-game-team-content league-game-away-team-content"}>
                        <Col xs={7} lg={9} className={"league-game-team-data"}>
                            <NavLink to={leagueGameURL} title={`Profil drużyny`} className={"game-team-logo"}>
                                <img src={awayTeamLogo || NO_IMAGE} alt={``}/>
                            </NavLink>
                            <NavLink to={leagueGameURL} title={`Profil drużyny`} className={"game-team-name"}>
                                <span>{awayTeamShortName || stringTruncate(awayTeamName, window.innerWidth > 992 ? 30 : 6)}</span>
                            </NavLink>
                        </Col>
                        <Col xs={5} lg={3} className={"league-game-team-points" + ((homeTeamPoints || 0) < (awayTeamPoints || 0) ? " winner" : "")}>
                            <NavLink to={leagueGameURL} title={``}>
                                <span>{awayTeamPoints || (gameStatus !== "FT_WAL" ? `-` : awayTeamPoints)}</span>
                            </NavLink>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default ResultContentComponent;

export const NAME_PL = "Nazwa";
export const NAME_EN = "Name";
export const SHORT_NAME_PL = "Skrócona Nazwa";
export const SHORT_NAME_EN = "Short Name";
export const FIRST_NAME_PL = "Imię";
export const FIRST_NAME_EN = "First Name";
export const LAST_NAME_PL = "Nazwisko";
export const LAST_NAME_EN = "Last Name";
export const NICK_NAME_PL = "Pseudo";
export const NICK_NAME_EN = "Pseudo";

export const EMAIL_ADDRESS_PL = "Email";
export const EMAIL_ADDRESS_EN = "Email";
export const WEBSITE_PL = "Strona WWW";
export const WEBSITE_EN = "WWW Site";
export const SOCIAL_ID_PL = "Pesel";
export const SOCIAL_ID_EN = "Pesel";

export const CITY_PL = "Miejscowość";
export const CITY_EN = "Village";

export const NATIONALITY_PL = "Obywatelstwo";
export const NATIONALITY_EN = "Nationality";
export const DESCRIPTION_PL = "Opis";
export const DESCRIPTION_EN = "Description";
export const WEIGHT_PL = "Waga";
export const WEIGHT_EN = "Weight";
export const HEIGHT_PL = "Wzrost";
export const HEIGHT_EN = "Height";
export const BIRTHDATE_PL = "Data Urodzenia"
export const BIRTHDATE_EN = "Birthdate"

export const COLOR_PL = "Kolor";
export const COLOR_EN = "Color";
export const FIRST_COLOR_PL = "Pierwszy Kolor";
export const FIRST_COLOR_EN = "First Color";
export const SECOND_COLOR_PL = "Drugi Kolor"
export const SECOND_COLOR_EN = "Second Color";
export const NUMBER_PL = "Numer";
export const NUMBER_EN = "Number";
export const POSITION_PL = "Pozycja";
export const POSITION_EN = "Position";

export const USER_TYPE_LABEL_TEXT_PL = "Rodzaj Użytkownika";
export const USER_TYPE_LABEL_TEXT_EN = "User Type";
export const PASSWORD_LABEL_TEXT_PL = "Hasło";
export const PASSWORD_LABEL_TEXT_EN = "Password";
export const PASSWORD_NEW_LABEL_TEXT_PL = "Nowe Hasło";
export const PASSWORD_NEW_LABEL_TEXT_EN = "New Password";
export const PASSWORD_REPEAT_LABEL_TEXT_PL = "Powtórz Hasło";
export const PASSWORD_REPEAT_LABEL_TEXT_EN = "Repeat Password";

export const EDIT_PL = "Edytuj";
export const EDIT_EN = "Edit";
export const SAVE_PL = "Zapisz";
export const SAVE_EN = "Save";
export const ABORT_PL = "Anuluj";
export const ABORT_EN = "Abort";

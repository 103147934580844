import React from "react";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface TeamPlayersListTeamNameComponentProps {
    teamLeagueTeamName: string;
    teamLeagueTeamShortName?: string | null;
}


export const TeamPlayersListTeamNameComponent: React.FC<TeamPlayersListTeamNameComponentProps> = ({
    teamLeagueTeamName, teamLeagueTeamShortName
}) : JSX.Element | null => {


    if (!teamLeagueTeamName) {
        return null;
    }


    return (
        <Col xs={12} className={"team-details__team-players__list__team-name"}>
            <span className={"team-details__team-players__list__team-name__name"}>{teamLeagueTeamName}</span>
            {teamLeagueTeamShortName && <span className={"team-details__team-players__list__team-name__short-name"}>({teamLeagueTeamShortName})</span>}
        </Col>
    );

};


export default TeamPlayersListTeamNameComponent;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import {
    LeagueRoundBestPlayerPlayerType, LeagueRoundBestPlayerTeamType
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundBestPlayerPlayerNameComponentProps {
    Player: LeagueRoundBestPlayerPlayerType;
    Team: LeagueRoundBestPlayerTeamType;
}


export const RoundBestPlayerPlayerNameComponent: React.FC<RoundBestPlayerPlayerNameComponentProps> = ({
    Player, Team
}) : JSX.Element | null => {
    const PlayersRoute = UseRoute("PLAYERS");


    if (!Player) {
        return null;
    }


    const {
        player_slug: playerSlug, player_first_name: playerFirstName, player_last_name: playerLastName,
        player_pseudonymization_status: playerPseudonymizationStatus, player_nick_name: playerNickName,
    } = Player;


    let playerName = playerFirstName + " " + playerLastName;

    if (playerPseudonymizationStatus) {
        if (playerNickName) {
            playerName = playerNickName;
        } else {
            playerName = "******";
        }
    }


    const playerURL = PlayersRoute.routeURL + "/" + playerSlug;


    let playerTeamColor = undefined;

    if (Team) {
        const { team_first_color: teamFirstColor } = Team;
        if (teamFirstColor) {
            playerTeamColor = teamFirstColor;
        }
    }


    return (
        <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__name"} style={{ borderColor: playerTeamColor }}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information__name__content"}>

                <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                    <span>{playerName}</span>
                </NavLink>

            </Row>
        </Col>
    );

};


export default RoundBestPlayerPlayerNameComponent;

export * from "@Hooks/Api/Requests/Games/Teams/Leagues/ActiveSeasonLeague";


import { useState, useEffect } from "react";

import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_LEAGUES_SQUADS_ENDPOINT_URL
} from "@Services/API/Endpoints";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    TeamLeagueInterface
} from "@Interfaces/Api";


export const UseTeamLeaguesWithSquads = (teamID: number, updateTrigger = false) : [TeamLeagueInterface[] | null, ErrorMessageInterface | null] => {

    const [TeamLeagues, setTeamLeagues] = useState<TeamLeagueInterface[] | null>(null);
    const [TeamLeaguesError, setTeamLeaguesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!teamID) {
            return setTeamLeaguesError({ errorCode: 400, errorMessage: "No Team ID" });
        }

        if (TeamLeagues && !updateTrigger) {
            return;
        }

        const API_EP_URL = API_TEAM_LEAGUES_SQUADS_ENDPOINT_URL.replace("{TEAM_ID}", teamID.toString());

        const getTeamLeagues = async () => {

            try {

                const response = await _restApiRequest(API_EP_URL);

                if (response.status === 200) {
                    const responseContent = await response.json();
                    setTeamLeagues(responseContent);
                } else if (response.status === 404) {
                    setTeamLeagues([]);
                } else {
                    setTeamLeaguesError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setTeamLeaguesError({ errorCode: 500, errorMessage: "" });
            }

        };

        getTeamLeagues();

    }, [teamID, updateTrigger]);


    return [TeamLeagues, TeamLeaguesError];

};


export default UseTeamLeaguesWithSquads;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    LeagueRoundBestPlayerTeamType
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundBestPlayerPlayerTeamComponentProps {
    Team: LeagueRoundBestPlayerTeamType;
}


export const RoundBestPlayerPlayerTeamComponent: React.FC<RoundBestPlayerPlayerTeamComponentProps> = ({
    Team
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    if (!Team) {
        return null;
    }


    const {
        team_name: playerTeamName, team_slug: playerTeamSlug, team_logo: playerTeamLogo
    } = Team;


    const playerTeamURL = TeamsRoute.routeURL + "/" + playerTeamSlug;


    return (
        <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__team"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__team__content"}>

                {playerTeamLogo &&
		            <Col xs={4} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__team__logo"}>
			            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__team__logo__content"}>
				            <NavLink to={playerTeamURL} title={TeamsRoute.routeTitle}>
					            <img src={playerTeamLogo} alt={""} />
				            </NavLink>
			            </Row>
		            </Col>
                }

                <Col xs={playerTeamLogo ? 8 : 12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__team__name"}>
                    <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__team__name__content"}>
                        <NavLink to={playerTeamURL} title={TeamsRoute.routeTitle}>
                            <span>{playerTeamName}</span>
                        </NavLink>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default RoundBestPlayerPlayerTeamComponent;

import ErrorsMessagesType from "@Dictionary/Messages/Errors/types";


import {
    DEFAULT_ERROR_MESSAGE_PL, DEFAULT_ERROR_MESSAGE_EN,
    DEFAULT_DATA_REQUEST_ERROR_PL, DEFAULT_DATA_REQUEST_ERROR_EN,
    INVALID_FILE_TYPE_PL, INVALID_FILE_TYPE_EN
} from "@Dictionary/Messages/Errors/consts";


export const ErrorsMessages: ErrorsMessagesType = {
    PL: {
        DEFAULT: DEFAULT_ERROR_MESSAGE_PL,
        DEFAULT_SYSTEM: "",
        DEFAULT_REQUEST: "",
        DEFAULT_DATA_REQUEST_ERROR: DEFAULT_DATA_REQUEST_ERROR_PL,
        DEFAULT_RESPONSE: "",
        INVALID_FILE_TYPE: INVALID_FILE_TYPE_PL
    },
    EN: {
        DEFAULT: DEFAULT_ERROR_MESSAGE_EN,
        DEFAULT_SYSTEM: "",
        DEFAULT_REQUEST: "",
        DEFAULT_DATA_REQUEST_ERROR: DEFAULT_DATA_REQUEST_ERROR_EN,
        DEFAULT_RESPONSE: "",
        INVALID_FILE_TYPE: INVALID_FILE_TYPE_EN
    }
};


export default ErrorsMessages;

import React, {useEffect, useState} from "react";


import { UseErrorMessage } from "@Hooks/Texts";

import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";
import ErrorMessageComponent from "@MessagesElements/Message/Error";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";


import GameProgressQuarterComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter";


import _createGameProgressDetails from "@GameResultPageSummary/GameDetailsInformation/Progress/_Utilities/GameProgressDetails";


import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";
import GameProgressInterface from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress";


import "./index.scss";


interface GameProgressEventsComponentProps {
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryData: ApiGameSummaryInterface | null;
    homeTeamID: number;
    awayTeamID: number;
    gameQuarterDurationTime: number;
    gameExtraTimeDurationTime: number;
}


export const GameProgressQuartersComponent: React.FC<GameProgressEventsComponentProps> = ({
    homeTeamID, SelectedQuarters,
    awayTeamID, GameSummaryData,
    gameQuarterDurationTime, gameExtraTimeDurationTime
}) : JSX.Element | null => {


    const DEFAULT_SYSTEM_ERROR_MESSAGE = UseErrorMessage("DEFAULT_SYSTEM");
    const NO_GAME_EVENTS_MESSAGE = UseGamesDictionaryPhrasesSet("NO_GAME_EVENTS_MESSAGE");


    const [GameProgress, setGameProgress] = useState<GameProgressInterface | boolean | null>(null);

    useEffect(() => {

        if (!GameSummaryData || !SelectedQuarters) {
            return;
        }

        setGameProgress(
            _createGameProgressDetails(
                SelectedQuarters, GameSummaryData, homeTeamID, awayTeamID
            )
        );

    }, [homeTeamID, awayTeamID, SelectedQuarters, GameSummaryData]);

    useEffect(() => {
        return () => {
            setGameProgress(null);
        };
    }, []);


    if (!GameSummaryData) return null;


    if (GameProgress === false) {
        return <ErrorMessageComponent messageText={DEFAULT_SYSTEM_ERROR_MESSAGE} />;
    }


    if (!GameProgress) {
        return <SingleContentLoaderComponent />;
    }


    if (!Object.keys(GameProgress).length) {
        return <MessageComponent messageText={NO_GAME_EVENTS_MESSAGE} cssClasses={"margin-top-15 margin-bottom-10"} />;
    }


    const {
        players: GamePlayers
    } = GameSummaryData;


    // @ts-ignore
    const GameProgressQuarters = Object.entries(GameProgress).map(([quarterNumber, QuarterEvents]) =>
        <GameProgressQuarterComponent
            key={quarterNumber}
            quarterNumber={+quarterNumber}
            homeTeamID={homeTeamID}
            awayTeamID={awayTeamID}
            QuarterEvents={QuarterEvents}
            GamePlayers={GamePlayers}
            gameQuarterDurationTime={gameQuarterDurationTime}
            gameExtraTimeDurationTime={gameExtraTimeDurationTime}
        />
    );

    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters"}>
            <Row className={"game-result-details__game-progress__quarters__content"}>

                {GameProgressQuarters}

            </Row>
        </Col>
    );

};


export default GameProgressQuartersComponent;

import React, { useState } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import {
    AiFillEdit, AiFillSave, FcCancel
} from "react-icons/all";


import {
    FormTextFieldComponent
} from "@FormsElements";


import "./index.scss";


export interface TeamLeaguesListLeagueTeamInformationComponentProps {
    teamLeagueTeamName: string;
    teamLeagueTeamShortName: string | null;
    teamLeagueTeamActiveStatus: boolean;
}


export const TeamLeaguesListLeagueTeamInformationComponent: React.FC<TeamLeaguesListLeagueTeamInformationComponentProps> = ({
    teamLeagueTeamName, teamLeagueTeamShortName, teamLeagueTeamActiveStatus
}) : JSX.Element | null => {


    const ACTIVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("ACTIVE_MASC_WORD_TEXT");
    const INACTIVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("INACTIVE_MASC_WORD_TEXT");

    const TEAM_SHORT_NAME_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TEAM_SHORT_NAME_PHRASE_TEXT");
    const TEAM_LEAGUE_NAME_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TEAM_LEAGUE_NAME_PHRASE_TEXT");


    const [teamDataEditableStatus, setTeamDataEditableStatus] = useState(false);


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__team-information"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__team-information__content"}>

                <Col xs={9} lg={4} className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-name"}>
                    <FormTextFieldComponent
                        fieldLabel={TEAM_LEAGUE_NAME_PHRASE_TEXT}
                        fieldValue={teamLeagueTeamName}
                        fieldValueHandler={() => undefined}
                        fieldDisabledStatus={true}
                    />
                </Col>

                <Col xs={3} lg={4} className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-short-name"}>
                    <FormTextFieldComponent
                        fieldLabel={TEAM_SHORT_NAME_PHRASE_TEXT}
                        fieldValue={teamLeagueTeamShortName || ""}
                        fieldValueHandler={() => undefined}
                        fieldDisabledStatus={true}
                    />
                </Col>

                <Col xs={9} lg={2} className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-active-status"}>
                    <span className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-active-status__label"}>
                        Status:
                    </span>
                    <span className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-active-status__status"}>
                        {teamLeagueTeamActiveStatus ? ACTIVE_WORD_TEXT : INACTIVE_WORD_TEXT}
                    </span>
                </Col>

                <Col xs={9} lg={2} className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-actions"}>
                    {!teamDataEditableStatus &&
                    <span className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-actions__edit-action"} onClick={() => setTeamDataEditableStatus(!teamDataEditableStatus)}>
                        <AiFillEdit />
                    </span>
                    }
                    {teamDataEditableStatus &&
                    <span className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-actions__save-action"}>
                        <AiFillSave />
                    </span>
                    }
                    {teamDataEditableStatus &&
                    <span className={"player-profile__captain-zone__team__leagues__list__league__team-information__team-actions__cancel-action"}>
                        <FcCancel />
                    </span>
                    }
                </Col>

            </Row>
        </Col>
    )

};


export default TeamLeaguesListLeagueTeamInformationComponent;

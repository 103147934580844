import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";

import {GLOBAL_DATA} from "../../../../../Contexts/Globals";


import {Col, Row} from "react-bootstrap";

import {FaRegWindowClose, GiHamburgerMenu} from "react-icons/all";


import HeaderNavigationLinkComponent from "@Components/Views/Parts/Header/Navigation/Element";


import ApplicationActionTypes from "@Action-Types/Application";
import {ColumnProps} from "@BootstrapInterfaces/Column";

import "./index.scss";


export interface HeaderNavigationComponentProps extends ColumnProps {}


export const HeaderNavigationComponent: React.FC<HeaderNavigationComponentProps> = ({
    xs = 1, lg = 6,
    as = "nav"
}) :JSX.Element | null => {


    const DISPATCHER = useDispatch();


    const [navigationDisplayStatus, setNavigationDisplayStatus] = useState<boolean>(false);

    const changeNavigationDisplayStatus = (e: React.MouseEvent) => {
        e.preventDefault();
        setNavigationDisplayStatus(!navigationDisplayStatus);
        DISPATCHER({
            type: ApplicationActionTypes.GLOBAL_BLACKOUT_STATUS_ACTION,
            status: !navigationDisplayStatus
        });
    };


    useEffect(() => {
        window.addEventListener("resize", WINDOW_RESIZE_LISTENER);
        return () => {
            window.removeEventListener("resize", WINDOW_RESIZE_LISTENER);
            setNavigationDisplayStatus(false);
        }
    }, []);


    const WINDOW_RESIZE_LISTENER = () => {
        if (window.innerWidth > 991) {
            setNavigationDisplayStatus(false);
            DISPATCHER({
                type: ApplicationActionTypes.GLOBAL_BLACKOUT_STATUS_ACTION,
                status: false
            });
        }
    };


    const NAVIGATION: any = GLOBAL_DATA?.routerData;

    const NavigationLinksList = Object.entries(NAVIGATION).map(([linkName, linkData]) =>
        <HeaderNavigationLinkComponent key={linkName} linkData={linkData} listDisplayHandler={setNavigationDisplayStatus} />
    );


    return (
        <Col as={as} xs={xs} lg={lg} className={"header-navigation-component"}>

            <a
                href={"#main-menu"}
                className={"main-navigation-display-switcher" + (navigationDisplayStatus ? " opened" : "")}
                onClick={e => changeNavigationDisplayStatus(e)}
            >
                <GiHamburgerMenu />
            </a>

            <Row as={"ul"} className={"navigation-links-list" + (navigationDisplayStatus ? " opened" : "")}>

                <a
                    href={"#close-main-menu"}
                    className={"main-navigation-close-handler"}
                    onClick={e => changeNavigationDisplayStatus(e)}
                >
                    <FaRegWindowClose />
                </a>

                {NavigationLinksList}
            </Row>

        </Col>
    );

};


export default HeaderNavigationComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";


import MessageComponent from "@MessagesElements/Content";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TeamLeaguesListLeagueComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League";


import {
    TeamLeagueInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamCaptainTeamLeaguesListComponentProps {
    teamID: number;
    TeamLeagues: TeamLeagueInterface[] | null;
    TeamLeaguesError: ErrorMessageInterface | null;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}

export const TeamCaptainTeamLeaguesListComponent: React.FC<TeamCaptainTeamLeaguesListComponentProps> = ({
    teamID, TeamLeagues, TeamLeaguesError, updateTeamLeaguesTriggerHandler
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const NO_LEAGUES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUES_PHRASE_TEXT");


    if (TeamLeaguesError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (!TeamLeagues) {
        return <SingleContentLoaderComponent />;
    }

    if (!TeamLeagues.length) {
        return <MessageComponent messageText={NO_LEAGUES_PHRASE_TEXT} cssClasses={"bordered padding-5"} />;
    }


    const TeamLeaguesList = TeamLeagues.map((League, i) =>
        <TeamLeaguesListLeagueComponent
            key={i}
            teamID={teamID}
            TeamLeague={League}
            updateTeamLeaguesTriggerHandler={updateTeamLeaguesTriggerHandler}
        />
    );


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__content"}>

                {TeamLeaguesList}

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamLeaguesListComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface AddTeamSquadPlayerFormLegendComponentProps {}


export const AddTeamSquadPlayerFormLegendComponent: React.FC<AddTeamSquadPlayerFormLegendComponentProps> = ({}) : JSX.Element | null => {


    const ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT");


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form__legend"}>
            <span className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form__legend__text"}>
                {ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT}
            </span>
        </Col>
    );

};


export default AddTeamSquadPlayerFormLegendComponent;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    generatePlayerName
} from "@Services/Utilities/Games";

import {
    NO_AVATAR_IMAGE
} from "@Images";


import "./index.scss";


import {
    ListByLeaguesRequestType
} from "@Interfaces/Api";


export interface TransfersListPlayersListPlayerComponentProps {
    Player: ListByLeaguesRequestType;
}


export const TransfersListPlayersListPlayerComponent: React.FC<TransfersListPlayersListPlayerComponentProps> = ({
    Player
}) => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Player) {
        return null;
    }


    const {
        player_slug: playerSlug, player_profile_image: playerProfileImage
    } = Player;


    const playerName = generatePlayerName(Player);

    const playerImage = playerProfileImage || NO_AVATAR_IMAGE;

    const playerURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;


    return (
        <Col xs={3} lg={1} className={"transfers-list__list__players__list__player"}>
            <Row className={"transfers-list__list__players__list__player__content"}>

                <Col xs={12} className={"transfers-list__list__players__list__player__image"}>
                    <NavLink to={playerURL} title={PlayersRoute.routeTitle} className={"transfers-list__list__players__list__player__image__link"}>
                        <img src={playerImage} className={"transfers-list__list__players__list__player__image__link__image"} />
                    </NavLink>
                </Col>

                <Col xs={12} className={"transfers-list__list__players__list__player__name"}>
                    <NavLink to={playerURL} title={PlayersRoute.routeTitle} className={"transfers-list__list__players__list__player__name__link"}>
                        <span className={"transfers-list__list__players__list__player__name__link__text"}>{playerName}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TransfersListPlayersListPlayerComponent;

import { UseLanguage } from "@States";


import NotAuthenticatedUserDictionary, { NotAuthenticatedUserDictionaryPhrasesSetType } from "@Dictionary/Pages/User/NotAuthenticated";


export const UseNotAuthenticatedUserDictionaryPhrasesSet = (phraseName: NotAuthenticatedUserDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return NotAuthenticatedUserDictionary[ActiveLanguage][phraseName];

};


export default UseNotAuthenticatedUserDictionaryPhrasesSet;

import React from "react";


import { Col } from "react-bootstrap";


import { LeagueInterface } from "@Interfaces/Api";


import "./index.scss";


export interface GameDetailsAdminHeaderLeagueComponentProps {
    GameLeague: LeagueInterface;
}


export const GameDetailsAdminHeaderLeagueComponent: React.FC<GameDetailsAdminHeaderLeagueComponentProps> = ({
    GameLeague
}) : JSX.Element | null => {


    if (!GameLeague) {
        return null;
    }


    const {
        league_name: leagueName, league_short_name: leagueShortName
    } = GameLeague;


    return (
        <Col xs={12} className={"game-details-admin__header__league"}>
            <span>{leagueName} ({leagueShortName})</span>
        </Col>
    )

};


export default GameDetailsAdminHeaderLeagueComponent;

import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import "./index.scss";


import GameResultDiagramsHeaderComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Components/Header";
import GameResultDiagramsLegendComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Components/Legend";
import GameResultDiagramsDiagramComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Components/Diagram";


import countDiagramsScaleStep from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Utilities/CountScaleStep";
import formatGameResultsDiagramValues from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Utilities/FormatDiagramResults";
import countDiagramsScaleValues from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Utilities/CountScaleValues";


import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";

import GameResultDetailsDiagramsTeamsInterface from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Types/Teams";


interface GameResultDetailsDiagramsComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface;
}


export const GameResultDetailsDiagramsComponent: React.FC<GameResultDetailsDiagramsComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics
}) : JSX.Element | null => {


    const NO_QUARTERS_SELECTED_MESSAGE = UseGamesDictionaryPhrasesSet("NO_QUARTERS_SELECTED_MESSAGE");


    if (
        !homeTeamID || !awayTeamID ||
        !SelectedQuarters || !GameSummaryStatistics
    ) return null;


    if (!SelectedQuarters.length) return <MessageComponent messageText={NO_QUARTERS_SELECTED_MESSAGE} />;


    const { quarters: GameQuarters, squads: GameSquads } = GameSummaryStatistics;


    if (!GameSquads || !GameQuarters) return null;


    const HomeTeam = GameSquads[homeTeamID];
    const AwayTeam = GameSquads[awayTeamID];


    if (!HomeTeam || !AwayTeam) return null;


    const {
        team_data: {
            team_name: homeTeamName, team_slug: homeTeamSlug, team_short_name: homeTeamShortName,
            team_first_color: homeTeamFirstColor, team_second_color: homeTeamSecondColor
        }
    } = HomeTeam;

    const {
        team_data: {
            team_name: awayTeamName, team_slug: awayTeamSlug, team_short_name: awayTeamShortName,
            team_first_color: awayTeamFirstColor, team_second_color: awayTeamSecondColor
        }
    } = AwayTeam;


    const homeTeamColor = homeTeamFirstColor || homeTeamSecondColor || "#DA5E15";
    const awayTeamColor = awayTeamFirstColor || awayTeamSecondColor || "#007731";


    const DiagramTeams: GameResultDetailsDiagramsTeamsInterface = {
        [homeTeamID]: { teamName: homeTeamName, teamSlug: homeTeamSlug, teamShortName: homeTeamShortName, teamColor: homeTeamColor },
        [awayTeamID]: { teamName: awayTeamName, teamSlug: awayTeamSlug, teamShortName: awayTeamShortName, teamColor: awayTeamColor }
    };


    let DiagramValuesData = formatGameResultsDiagramValues(
        SelectedQuarters, GameQuarters,
        homeTeamID, awayTeamID
    );


    if (!DiagramValuesData) return null;


    const [DiagramsValues, biggestAdvantage] = DiagramValuesData;


    const diagramPointsStep = countDiagramsScaleStep(biggestAdvantage);

    let diagramMaximumValue = biggestAdvantage + (diagramPointsStep - biggestAdvantage % diagramPointsStep);

    if (diagramMaximumValue < 5) diagramMaximumValue = 5;


    const scalesValues = countDiagramsScaleValues(
        diagramMaximumValue, diagramPointsStep
    );


    const BarComponent = Object.entries(DiagramsValues).map(([quarterNumber, QuarterData], i) =>
        <GameResultDiagramsDiagramComponent
            key={quarterNumber}
            homeTeamID={homeTeamID} awayTeamID={awayTeamID} Teams={DiagramTeams}
            quarterNumber={quarterNumber} QuarterData={QuarterData} selectedQuartersLength={SelectedQuarters.length}
            scalesValues={scalesValues} stepValue={diagramPointsStep}
        />
    );


    return (
        <Col xs={12} className={"game-result-details__diagrams game-result-details-diagrams"}>

            <GameResultDiagramsHeaderComponent />

            <GameResultDiagramsLegendComponent
                homeTeamName={homeTeamName} homeTeamSlug={homeTeamSlug} homeTeamColor={homeTeamColor}
                awayTeamName={awayTeamName} awayTeamSlug={awayTeamSlug} awayTeamColor={awayTeamColor}
            />

            <Row className={"game-result-details__diagrams__charts" + (SelectedQuarters.length === 5 ? " with-extra-time" : "")}>
                {BarComponent}
            </Row>

        </Col>
    );


};


export default GameResultDetailsDiagramsComponent;

import React from "react";


import { Col, Row } from "react-bootstrap";


import GameSummaryQuarterComponent from "@GameResultPageSummary/GameMainInformation/GameQuarters/Quarter";


import {
    LeagueGameQuartersInterface, LeagueGameQuarterNumberType
} from "@Interfaces/Api";


import "./index.scss";


type GameSummaryQuartersProps = {
    LeagueGameQuarters: LeagueGameQuartersInterface | null;
    SelectedQuarters: LeagueGameQuarterNumberType[];
    selectedQuartersHandler(quarter: LeagueGameQuarterNumberType): void;
};


export const GameSummaryQuartersComponent: React.FC<GameSummaryQuartersProps> = ({
    LeagueGameQuarters = null,
    SelectedQuarters = [], selectedQuartersHandler
}) : JSX.Element | null => {


    const gameQuarters: LeagueGameQuarterNumberType[] = [1, 2, 3, 4, 5];


    const GameQuarters = gameQuarters.map((quarter) => {

        if (+quarter === 5) return null;

        return (<GameSummaryQuarterComponent
            key={quarter}
            GameQuarter={LeagueGameQuarters ? LeagueGameQuarters[quarter] : null}
            gameQuarterNumber={quarter}
            SelectedQuarters={SelectedQuarters}
            selectedQuartersHandler={selectedQuartersHandler}
        />)

    });


    return (
        <Col xs={12} lg={{span: 4, offset: 2}} className={"game-result-quarters game-result-quarters-component"}>
            <Row className={"game-result-quarters-content"}>

                {GameQuarters}

            </Row>
        </Col>
    );

};


export default GameSummaryQuartersComponent;

import React from "react";


import {Col, Row} from "react-bootstrap";


import { IMAGE_PLACEHOLDER } from "@Images";


import "./index.scss";


export interface TeamHeaderLogoComponentProps {
    teamLogo?: string | null;
}


export const TeamHeaderLogoComponent: React.FC<TeamHeaderLogoComponentProps> = ({
    teamLogo
}) : JSX.Element | null => {


    return (
        <Col xs={4} lg={2} className={"team-page__team-header__team-logo"}>
            <Row as={"picture"}>
                {!!teamLogo &&
                <img src={teamLogo} alt={``} />
                }
            </Row>
        </Col>
    );

};


export default TeamHeaderLogoComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface LeagueTablesGroupTableHeaderComponentProps {}


export const LeagueTablesGroupTableHeaderComponent: React.FC<LeagueTablesGroupTableHeaderComponentProps> = ({}) : JSX.Element | null => {


    const PLACEMENT_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("PLACEMENT_WORD_SHORT_TEXT");
    const POINTS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("POINTS_WORD_SHORT_TEXT");
    const TEAM_WORD_TEXT = UseCommonDictionaryPhrasesSet("TEAM_WORD_TEXT");
    const GAMES_WORD_TEXT = UseCommonDictionaryPhrasesSet("GAMES_WORD_TEXT");
    const WIN_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("WIN_WORD_SHORT_TEXT");
    const DEFEAT_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("DEFEAT_WORD_SHORT_TEXT");
    const POINTS_IN_SHORT_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("POINTS_IN_SHORT_PHRASE_TEXT");
    const POINTS_OUT_SHORT_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("POINTS_OUT_SHORT_PHRASE_TEXT");
    const STATISTICS_DIFFERENCE_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_DIFFERENCE_WORD_TEXT");


    return (
        <Col xs={12} className={"league-tables__group__table__header"}>
            <Row className={"league-tables__group__table__header__content"}>

                <Col xs={1} className={"team-table-position"}>
                    <span>{PLACEMENT_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={1} className={"team-logo"}></Col>

                <Col xs={4} className={"team-name"}>
                    <span>{TEAM_WORD_TEXT}</span>
                </Col>

                <Col xs={1} className={"team-table-points"}>
                    <span>{POINTS_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={1} className={"team-played-games"}>
                    <span>{GAMES_WORD_TEXT}</span>
                </Col>

                <Col xs={1} className={"team-games-balance"}>
                    <span>{WIN_WORD_SHORT_TEXT} - {DEFEAT_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={2} className={"team-small-points-balance"}>
                    <span>{POINTS_IN_SHORT_PHRASE_TEXT} - {POINTS_OUT_SHORT_PHRASE_TEXT}</span>
                </Col>

                <Col xs={1} className={"team-small-points-difference"}>
                    <span>{STATISTICS_DIFFERENCE_WORD_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );


};


export default LeagueTablesGroupTableHeaderComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import {
    FaCheck
} from "react-icons/all";


import "./index.scss";


export interface TeamPlayersListFiltersComponentProps {
    showPastPlayers: boolean;
    showPastPlayersHandler: (status: boolean) => void;
}


export const TeamPlayersListFiltersComponent: React.FC<TeamPlayersListFiltersComponentProps> = ({
    showPastPlayers, showPastPlayersHandler
}) : JSX.Element | null => {


    const SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT");
    const HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT");


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__players-list__list-filters"}>
            <Row className={"player-profile__captain-zone__team__players__players-list__list-filters__content"}>

                <Col xs={"auto"} className={"player-profile__captain-zone__team__players__players-list__list-filters__show-past-players"}>
                    <span onClick={() => showPastPlayersHandler(!showPastPlayers)} className={"player-profile__captain-zone__team__players__players-list__list-filters__show-past-players__switcher"}>
                        <span className={"player-profile__captain-zone__team__players__players-list__list-filters__show-past-players__switcher__text"}>
                            {showPastPlayers ? HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT : SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT}
                        </span>
                        <span className={"player-profile__captain-zone__team__players__players-list__list-filters__show-past-players__switcher__text inline-checkbox-element"}>
                            {showPastPlayers && <FaCheck />}
                        </span>
                    </span>
                </Col>

            </Row>
        </Col>
    )

};


export default TeamPlayersListFiltersComponent;

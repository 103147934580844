import React from "react";


import { UseSetPopupMessageAction } from "@StatesActions";


import { Col, Container, Row } from "react-bootstrap";

import { AiFillCloseCircle } from "react-icons/all";


export interface GlobalPopupComponentProps {
    text: string;
    type?: "ERROR" | "SUCCESS" | "DEFAULT" | "INFO";
}

export const GlobalPopupComponent: React.FC<GlobalPopupComponentProps> = ({
    text, type = "DEFAULT"
}) : JSX.Element | null => {


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const popupCssClasses = ["global-message-popup-message"];

    switch (type) {
        case "SUCCESS":
            popupCssClasses.push("success");
            break;
        case "ERROR":
            popupCssClasses.push("error");
            break;
    }


    return (
        <Container fluid={true} className={"global-message-popup"}>
            <Container className={"global-message-popup-container"}>
                <Row className={"global-message-popup-content"}>

                    <Col xs={"auto"} className={popupCssClasses.join(" ")}>
                        <span>{text}</span>
                        <span className={"close-popup"} onClick={() => UseSetPopupMessage(null)}>
                            <AiFillCloseCircle />
                        </span>
                    </Col>

                </Row>
            </Container>
        </Container>
    );

};


export default GlobalPopupComponent;

import React from "react";


import { UseRoute } from "@Hooks/Routes";

import { UseLinksDictionaryPhrasesSet } from "@Hooks/Texts";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import ApiGameSummaryTeamDataInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Data";


interface GameThrowsTeamNameComponentProps {
    GameTeam: ApiGameSummaryTeamDataInterface;
    gameTeamType?: "HOME" | "AWAY";
}


export const GameThrowsTeamNameComponent: React.FC<GameThrowsTeamNameComponentProps> = ({
    GameTeam, gameTeamType
}) : JSX.Element | null => {


    const GO_TO_TEAM_PROFILE_PAGE_TITLE = UseLinksDictionaryPhrasesSet("GO_TO_TEAM_PROFILE_PAGE_TITLE");


    const TeamsRouteURL = UseRoute("TEAMS").routeURL;


    if (!GameTeam) return null;


    const {
        team_name: teamName, team_slug: teamSlug,
        team_first_color: teamFirstColor, team_second_color: teamSecondColor
    } = GameTeam;

    const teamColor = teamFirstColor || teamSecondColor;


    let cssClasses = "throws-team-name throws-team-name-component";
    if (gameTeamType === "HOME") cssClasses += " home-team-name";
    if (gameTeamType === "AWAY") cssClasses += " away-team-name";


    return (
        <Col xs={12} className={cssClasses} style={{backgroundColor: teamColor || undefined}}>

            <NavLink to={`/${TeamsRouteURL}/${teamSlug}`} title={GO_TO_TEAM_PROFILE_PAGE_TITLE} className={"team-link"}>
                <span>{teamName}</span>
            </NavLink>

            <span className={"bg"}></span>

        </Col>
    );


};


export default GameThrowsTeamNameComponent;

export *  from "@Dictionary/Sets/Statistics/Types/Phrases/Set";
export *  from "@Dictionary/Sets/Statistics/Phrases";


import StatisticsDictionaryInterface from "@Dictionary/Sets/Statistics/Types";


import {
    NUMBER_LABEL_PL, NUMBER_LABEL_EN, NUMBER_SHORT_LABEL_PL, NUMBER_SHORT_LABEL_EN, NUMBER_TITLE_PL, NUMBER_TITLE_EN,
    NAME_LABEL_PL, NAME_LABEL_EN, NAME_SHORT_LABEL_PL, NAME_SHORT_LABEL_EN, NAME_TITLE_PL, NAME_TITLE_EN,
    POINTS_LABEL_PL, POINTS_LABEL_EN, POINTS_SHORT_LABEL_PL, POINTS_SHORT_LABEL_EN, POINTS_TITLE_PL, POINTS_TITLE_EN,
    POINTS_COUNTER_LABEL_PL, POINTS_COUNTER_LABEL_EN, POINTS_COUNTER_SHORT_LABEL_PL, POINTS_COUNTER_SHORT_LABEL_EN, POINTS_COUNTER_TITLE_PL, POINTS_COUNTER_TITLE_EN,
    POINTS_COFFIN_LABEL_PL, POINTS_COFFIN_LABEL_EN, POINTS_COFFIN_SHORT_LABEL_PL, POINTS_COFFIN_SHORT_LABEL_EN, POINTS_COFFIN_TITLE_PL, POINTS_COFFIN_TITLE_EN,
    MINUTES_LABEL_PL, MINUTES_LABEL_EN, MINUTES_SHORT_LABEL_PL, MINUTES_SHORT_LABEL_EN, MINUTES_TITLE_PL, MINUTES_TITLE_EN,
    THROWS_LABEL_PL, THROWS_LABEL_EN, THROWS_SHORT_LABEL_PL, THROWS_SHORT_LABEL_EN, THROWS_TITLE_PL, THROWS_TITLE_EN,
    THROWS_1PT_LABEL_PL, THROWS_1PT_LABEL_EN, THROWS_1PT_SHORT_LABEL_PL, THROWS_1PT_SHORT_LABEL_EN, THROWS_1PT_TITLE_PL, THROWS_1PT_TITLE_EN,
    THROWS_1PTA_LABEL_PL, THROWS_1PTA_LABEL_EN, THROWS_1PTA_SHORT_LABEL_PL, THROWS_1PTA_SHORT_LABEL_EN, THROWS_1PTA_TITLE_PL, THROWS_1PTA_TITLE_EN,
    THROWS_1PT_EFF_LABEL_PL, THROWS_1PT_EFF_LABEL_EN, THROWS_1PT_EFF_SHORT_LABEL_PL, THROWS_1PT_EFF_SHORT_LABEL_EN, THROWS_1PT_EFF_TITLE_PL, THROWS_1PT_EFF_TITLE_EN,
    THROWS_2PT_LABEL_PL, THROWS_2PT_LABEL_EN, THROWS_2PT_SHORT_LABEL_PL, THROWS_2PT_SHORT_LABEL_EN, THROWS_2PT_TITLE_PL, THROWS_2PT_TITLE_EN,
    THROWS_2PTA_LABEL_PL, THROWS_2PTA_LABEL_EN, THROWS_2PTA_SHORT_LABEL_PL, THROWS_2PTA_SHORT_LABEL_EN, THROWS_2PTA_TITLE_PL, THROWS_2PTA_TITLE_EN,
    THROWS_2PT_EFF_LABEL_PL, THROWS_2PT_EFF_LABEL_EN, THROWS_2PT_EFF_SHORT_LABEL_PL, THROWS_2PT_EFF_SHORT_LABEL_EN, THROWS_2PT_EFF_TITLE_PL, THROWS_2PT_EFF_TITLE_EN,
    THROWS_3PT_LABEL_PL, THROWS_3PT_LABEL_EN, THROWS_3PT_SHORT_LABEL_PL, THROWS_3PT_SHORT_LABEL_EN, THROWS_3PT_TITLE_PL, THROWS_3PT_TITLE_EN,
    THROWS_3PTA_LABEL_PL, THROWS_3PTA_LABEL_EN, THROWS_3PTA_SHORT_LABEL_PL, THROWS_3PTA_SHORT_LABEL_EN, THROWS_3PTA_TITLE_PL, THROWS_3PTA_TITLE_EN,
    THROWS_3PT_EFF_LABEL_PL, THROWS_3PT_EFF_LABEL_EN, THROWS_3PT_EFF_SHORT_LABEL_PL, THROWS_3PT_EFF_SHORT_LABEL_EN, THROWS_3PT_EFF_TITLE_PL, THROWS_3PT_EFF_TITLE_EN,
    THROWS_GAME_LABEL_PL, THROWS_GAME_LABEL_EN, THROWS_GAME_SHORT_LABEL_PL, THROWS_GAME_TITLE_EN, THROWS_GAME_TITLE_PL, THROWS_GAME_SHORT_LABEL_EN,
    THROWS_GAME_A_LABEL_PL, THROWS_GAME_A_LABEL_EN, THROWS_GAME_A_SHORT_LABEL_PL, THROWS_GAME_A_SHORT_LABEL_EN, THROWS_GAME_A_TITLE_PL, THROWS_GAME_A_TITLE_EN,
    THROWS_GAME_EFF_LABEL_PL, THROWS_GAME_EFF_LABEL_EN, THROWS_GAME_EFF_SHORT_LABEL_PL, THROWS_GAME_EFF_SHORT_LABEL_EN, THROWS_GAME_EFF_TITLE_PL, THROWS_GAME_EFF_TITLE_EN,
    DEFENSIVE_REBOUNDS_LABEL_PL, DEFENSIVE_REBOUNDS_LABEL_EN, DEFENSIVE_REBOUNDS_TITLE_PL, DEFENSIVE_REBOUNDS_TITLE_EN, DEFENSIVE_REBOUNDS_SHORT_LABEL_PL, DEFENSIVE_REBOUNDS_SHORT_LABEL_EN,
    OFFENSIVE_REBOUNDS_LABEL_PL, OFFENSIVE_REBOUNDS_LABEL_EN, OFFENSIVE_REBOUNDS_SHORT_LABEL_PL, OFFENSIVE_REBOUNDS_SHORT_LABEL_EN, OFFENSIVE_REBOUNDS_TITLE_PL, OFFENSIVE_REBOUNDS_TITLE_EN,
    TOTAL_REBOUNDS_LABEL_PL, TOTAL_REBOUNDS_LABEL_EN, TOTAL_REBOUNDS_SHORT_LABEL_PL, TOTAL_REBOUNDS_SHORT_LABEL_EN, TOTAL_REBOUNDS_TITLE_PL, TOTAL_REBOUNDS_TITLE_EN,
    ASSISTS_LABEL_PL, ASSISTS_LABEL_EN, ASSISTS_TITLE_PL, ASSISTS_TITLE_EN, ASSISTS_SHORT_LABEL_PL, ASSISTS_SHORT_LABEL_EN,
    BLOCKS_LABEL_PL, BLOCKS_LABEL_EN, BLOCKS_SHORT_LABEL_PL, BLOCKS_SHORT_LABEL_EN, BLOCKS_TITLE_PL, BLOCKS_TITLE_EN,
    STEALS_LABEL_PL, STEALS_LABEL_EN, STEALS_TITLE_PL, STEALS_TITLE_EN, STEALS_SHORT_LABEL_PL, STEALS_SHORT_LABEL_EN,
    TURNOVERS_LABEL_PL, TURNOVERS_LABEL_EN, TURNOVERS_SHORT_LABEL_PL, TURNOVERS_SHORT_LABEL_EN, TURNOVERS_TITLE_PL, TURNOVERS_TITLE_EN,
    EFFECTIVITY_LABEL_PL, EFFECTIVITY_LABEL_EN, EFFECTIVITY_SHORT_LABEL_PL, EFFECTIVITY_SHORT_LABEL_EN, EFFECTIVITY_TITLE_PL, EFFECTIVITY_TITLE_EN,
    EVAL_LABEL_PL, EVAL_LABEL_EN, EVAL_SHORT_LABEL_PL, EVAL_SHORT_LABEL_EN, EVAL_TITLE_PL, EVAL_TITLE_EN,
    FOUL_PL, FOUL_EN, FOULS_PL, FOULS_EN, FOULS_LABEL_PL, FOULS_LABEL_EN, FOULS_TITLE_PL, FOULS_TITLE_EN,
    FOUL_UNSPORTSMANLIKE_PL, FOUL_UNSPORTSMANLIKE_EN, FOULS_UNSPORTSMANLIKE_PL, FOULS_UNSPORTSMANLIKE_EN,
    FOUL_TECHNICAL_PL, FOUL_TECHNICAL_EN, FOULS_TECHNICAL_PL, FOULS_TECHNICAL_EN,
    FOUL_DISQUALIFICATION_PL, FOUL_DISQUALIFICATION_EN, FOULS_DISQUALIFICATION_PL, FOULS_DISQUALIFICATION_EN,
    TEAM_OR_TRAINER_PL, TEAM_OR_TRAINER_EN,
    TOTAL_PL, TOTAL_EN
} from "@Dictionary/Sets/Statistics/Phrases";


export const StatisticsDictionary: StatisticsDictionaryInterface = {
    PL: {
        NUMBER_LABEL: NUMBER_LABEL_PL, NUMBER_SHORT_LABEL: NUMBER_SHORT_LABEL_PL, NUMBER_TITLE: NUMBER_TITLE_PL,
        NAME_LABEL: NAME_LABEL_PL, NAME_SHORT_LABEL: NAME_SHORT_LABEL_PL, NAME_TITLE: NAME_TITLE_PL,
        POINTS_LABEL: POINTS_LABEL_PL, POINTS_SHORT_LABEL: POINTS_SHORT_LABEL_PL, POINTS_TITLE: POINTS_TITLE_PL,
        POINTS_COUNTER_LABEL: POINTS_COUNTER_LABEL_PL, POINTS_COUNTER_SHORT_LABEL: POINTS_COUNTER_SHORT_LABEL_PL, POINTS_COUNTER_TITLE: POINTS_COUNTER_TITLE_PL,
        POINTS_COFFIN_LABEL: POINTS_COFFIN_LABEL_PL, POINTS_COFFIN_SHORT_LABEL: POINTS_COFFIN_SHORT_LABEL_PL, POINTS_COFFIN_TITLE: POINTS_COFFIN_TITLE_PL,
        MINUTES_LABEL: MINUTES_LABEL_PL, MINUTES_SHORT_LABEL: MINUTES_SHORT_LABEL_PL, MINUTES_TITLE: MINUTES_TITLE_PL,
        THROWS_LABEL: THROWS_LABEL_PL, THROWS_SHORT_LABEL: THROWS_SHORT_LABEL_PL, THROWS_TITLE: THROWS_TITLE_PL,
        THROWS_1PT_LABEL: THROWS_1PT_LABEL_PL, THROWS_1PT_SHORT_LABEL: THROWS_1PT_SHORT_LABEL_PL, THROWS_1PT_TITLE: THROWS_1PT_TITLE_PL,
        THROWS_1PTA_LABEL: THROWS_1PTA_LABEL_PL, THROWS_1PTA_SHORT_LABEL: THROWS_1PTA_SHORT_LABEL_PL, THROWS_1PTA_TITLE: THROWS_1PTA_TITLE_PL,
        THROWS_1PT_EFF_LABEL: THROWS_1PT_EFF_LABEL_PL, THROWS_1PT_EFF_SHORT_LABEL: THROWS_1PT_EFF_SHORT_LABEL_PL, THROWS_1PT_EFF_TITLE: THROWS_1PT_EFF_TITLE_PL,
        THROWS_2PT_LABEL: THROWS_2PT_LABEL_PL, THROWS_2PT_SHORT_LABEL: THROWS_2PT_SHORT_LABEL_PL, THROWS_2PT_TITLE: THROWS_2PT_TITLE_PL,
        THROWS_2PTA_LABEL: THROWS_2PTA_LABEL_PL, THROWS_2PTA_SHORT_LABEL: THROWS_2PTA_SHORT_LABEL_PL, THROWS_2PTA_TITLE: THROWS_2PTA_TITLE_PL,
        THROWS_2PT_EFF_LABEL: THROWS_2PT_EFF_LABEL_PL, THROWS_2PT_EFF_SHORT_LABEL: THROWS_2PT_EFF_SHORT_LABEL_PL, THROWS_2PT_EFF_TITLE: THROWS_2PT_EFF_TITLE_PL,
        THROWS_3PT_LABEL: THROWS_3PT_LABEL_PL, THROWS_3PT_SHORT_LABEL: THROWS_3PT_SHORT_LABEL_PL, THROWS_3PT_TITLE: THROWS_3PT_TITLE_PL,
        THROWS_3PTA_LABEL: THROWS_3PTA_LABEL_PL, THROWS_3PTA_SHORT_LABEL: THROWS_3PTA_SHORT_LABEL_PL, THROWS_3PTA_TITLE: THROWS_3PTA_TITLE_PL,
        THROWS_3PT_EFF_LABEL: THROWS_3PT_EFF_LABEL_PL, THROWS_3PT_EFF_SHORT_LABEL: THROWS_3PT_EFF_SHORT_LABEL_PL, THROWS_3PT_EFF_TITLE: THROWS_3PT_EFF_TITLE_PL,
        THROWS_GAME_LABEL: THROWS_GAME_LABEL_PL, THROWS_GAME_SHORT_LABEL: THROWS_GAME_SHORT_LABEL_PL, THROWS_GAME_TITLE: THROWS_GAME_TITLE_PL,
        THROWS_GAME_A_LABEL: THROWS_GAME_A_LABEL_PL, THROWS_GAME_A_SHORT_LABEL: THROWS_GAME_A_SHORT_LABEL_PL, THROWS_GAME_A_TITLE: THROWS_GAME_A_TITLE_PL,
        THROWS_GAME_EFF_LABEL: THROWS_GAME_EFF_LABEL_PL, THROWS_GAME_EFF_SHORT_LABEL: THROWS_GAME_EFF_SHORT_LABEL_PL, THROWS_GAME_EFF_TITLE: THROWS_GAME_EFF_TITLE_PL,
        DEFENSIVE_REBOUNDS_LABEL: DEFENSIVE_REBOUNDS_LABEL_PL, DEFENSIVE_REBOUNDS_SHORT_LABEL: DEFENSIVE_REBOUNDS_SHORT_LABEL_PL, DEFENSIVE_REBOUNDS_TITLE: DEFENSIVE_REBOUNDS_TITLE_PL,
        OFFENSIVE_REBOUNDS_LABEL: OFFENSIVE_REBOUNDS_LABEL_PL, OFFENSIVE_REBOUNDS_SHORT_LABEL: OFFENSIVE_REBOUNDS_SHORT_LABEL_PL, OFFENSIVE_REBOUNDS_TITLE: OFFENSIVE_REBOUNDS_TITLE_PL,
        TOTAL_REBOUNDS_LABEL: TOTAL_REBOUNDS_LABEL_PL, TOTAL_REBOUNDS_SHORT_LABEL: TOTAL_REBOUNDS_SHORT_LABEL_PL, TOTAL_REBOUNDS_TITLE: TOTAL_REBOUNDS_LABEL_PL,
        ASSISTS_LABEL: ASSISTS_LABEL_PL, ASSISTS_SHORT_LABEL: ASSISTS_SHORT_LABEL_PL, ASSISTS_TITLE: ASSISTS_TITLE_PL,
        BLOCKS_LABEL: BLOCKS_LABEL_PL, BLOCKS_SHORT_LABEL: BLOCKS_SHORT_LABEL_PL, BLOCKS_TITLE: BLOCKS_TITLE_PL,
        STEALS_LABEL: STEALS_LABEL_PL, STEALS_SHORT_LABEL: STEALS_SHORT_LABEL_PL, STEALS_TITLE: STEALS_TITLE_PL,
        TURNOVERS_LABEL: TURNOVERS_LABEL_PL, TURNOVERS_SHORT_LABEL: TURNOVERS_SHORT_LABEL_PL, TURNOVERS_TITLE: TURNOVERS_TITLE_PL,
        EFFECTIVITY_LABEL: EFFECTIVITY_LABEL_PL, EFFECTIVITY_SHORT_LABEL: EFFECTIVITY_SHORT_LABEL_PL, EFFECTIVITY_TITLE: EFFECTIVITY_TITLE_PL,
        EVAL_LABEL: EVAL_LABEL_PL, EVAL_SHORT_LABEL: EVAL_SHORT_LABEL_PL, EVAL_TITLE: EVAL_TITLE_PL,
        FOUL: FOUL_PL, FOULS: FOULS_PL, FOULS_LABEL: FOULS_LABEL_PL, FOULS_TITLE: FOULS_TITLE_PL,
        FOUL_UNSPORTSMANLIKE: FOUL_UNSPORTSMANLIKE_PL, FOULS_UNSPORTSMANLIKE: FOULS_UNSPORTSMANLIKE_PL,
        FOUL_TECHNICAL: FOUL_TECHNICAL_PL, FOULS_TECHNICAL: FOULS_TECHNICAL_PL,
        FOUL_DISQUALIFICATION: FOUL_DISQUALIFICATION_PL, FOULS_DISQUALIFICATION: FOULS_DISQUALIFICATION_PL,
        TEAM_OR_TRAINER: TEAM_OR_TRAINER_PL,
        TOTAL: TOTAL_PL,
    },
    EN: {
        NUMBER_LABEL: NUMBER_LABEL_EN, NUMBER_SHORT_LABEL: NUMBER_SHORT_LABEL_EN, NUMBER_TITLE: NUMBER_TITLE_EN,
        NAME_LABEL: NAME_LABEL_EN, NAME_SHORT_LABEL: NAME_SHORT_LABEL_EN, NAME_TITLE: NAME_TITLE_EN,
        POINTS_LABEL: POINTS_LABEL_EN, POINTS_SHORT_LABEL: POINTS_SHORT_LABEL_EN, POINTS_TITLE: POINTS_TITLE_EN,
        POINTS_COUNTER_LABEL: POINTS_COUNTER_LABEL_EN, POINTS_COUNTER_SHORT_LABEL: POINTS_COUNTER_SHORT_LABEL_EN, POINTS_COUNTER_TITLE: POINTS_COUNTER_TITLE_EN,
        POINTS_COFFIN_LABEL: POINTS_COFFIN_LABEL_EN, POINTS_COFFIN_SHORT_LABEL: POINTS_COFFIN_SHORT_LABEL_EN, POINTS_COFFIN_TITLE: POINTS_COFFIN_TITLE_EN,
        MINUTES_LABEL: MINUTES_LABEL_EN, MINUTES_SHORT_LABEL: MINUTES_SHORT_LABEL_EN, MINUTES_TITLE: MINUTES_TITLE_EN,
        THROWS_LABEL: THROWS_LABEL_EN, THROWS_SHORT_LABEL: THROWS_SHORT_LABEL_EN, THROWS_TITLE: THROWS_TITLE_EN,
        THROWS_1PT_LABEL: THROWS_1PT_LABEL_EN, THROWS_1PT_SHORT_LABEL: THROWS_1PT_SHORT_LABEL_EN, THROWS_1PT_TITLE: THROWS_1PT_TITLE_EN,
        THROWS_1PT_EFF_LABEL: THROWS_1PT_EFF_LABEL_EN, THROWS_1PT_EFF_SHORT_LABEL: THROWS_1PT_EFF_SHORT_LABEL_EN, THROWS_1PT_EFF_TITLE: THROWS_1PT_EFF_TITLE_EN,
        THROWS_1PTA_LABEL: THROWS_1PTA_LABEL_EN, THROWS_1PTA_SHORT_LABEL: THROWS_1PTA_SHORT_LABEL_EN, THROWS_1PTA_TITLE: THROWS_1PTA_TITLE_EN,
        THROWS_2PT_LABEL: THROWS_2PT_LABEL_EN, THROWS_2PT_SHORT_LABEL: THROWS_2PT_SHORT_LABEL_EN, THROWS_2PT_TITLE: THROWS_2PT_TITLE_EN,
        THROWS_2PTA_LABEL: THROWS_2PTA_LABEL_EN, THROWS_2PTA_SHORT_LABEL: THROWS_2PTA_SHORT_LABEL_EN, THROWS_2PTA_TITLE: THROWS_2PTA_TITLE_EN,
        THROWS_2PT_EFF_LABEL: THROWS_2PT_EFF_LABEL_EN, THROWS_2PT_EFF_SHORT_LABEL: THROWS_2PT_EFF_SHORT_LABEL_EN, THROWS_2PT_EFF_TITLE: THROWS_2PT_EFF_TITLE_EN,
        THROWS_3PT_LABEL: THROWS_3PT_LABEL_EN, THROWS_3PT_SHORT_LABEL: THROWS_3PT_SHORT_LABEL_EN, THROWS_3PT_TITLE: THROWS_3PT_TITLE_EN,
        THROWS_3PTA_LABEL: THROWS_3PTA_LABEL_EN, THROWS_3PTA_SHORT_LABEL: THROWS_3PTA_SHORT_LABEL_EN, THROWS_3PTA_TITLE: THROWS_3PTA_TITLE_EN,
        THROWS_3PT_EFF_LABEL: THROWS_3PT_EFF_LABEL_EN, THROWS_3PT_EFF_SHORT_LABEL: THROWS_3PT_EFF_SHORT_LABEL_EN, THROWS_3PT_EFF_TITLE: THROWS_3PT_EFF_TITLE_EN,
        THROWS_GAME_LABEL: THROWS_GAME_LABEL_EN, THROWS_GAME_SHORT_LABEL: THROWS_GAME_SHORT_LABEL_EN, THROWS_GAME_TITLE: THROWS_GAME_TITLE_EN,
        THROWS_GAME_A_LABEL: THROWS_GAME_A_LABEL_EN, THROWS_GAME_A_SHORT_LABEL: THROWS_GAME_A_SHORT_LABEL_EN, THROWS_GAME_A_TITLE: THROWS_GAME_A_TITLE_EN,
        THROWS_GAME_EFF_LABEL: THROWS_GAME_EFF_LABEL_EN, THROWS_GAME_EFF_SHORT_LABEL: THROWS_GAME_EFF_SHORT_LABEL_EN, THROWS_GAME_EFF_TITLE: THROWS_GAME_EFF_TITLE_EN,
        DEFENSIVE_REBOUNDS_LABEL: DEFENSIVE_REBOUNDS_LABEL_EN, DEFENSIVE_REBOUNDS_SHORT_LABEL: DEFENSIVE_REBOUNDS_SHORT_LABEL_EN, DEFENSIVE_REBOUNDS_TITLE: DEFENSIVE_REBOUNDS_TITLE_EN,
        OFFENSIVE_REBOUNDS_LABEL: OFFENSIVE_REBOUNDS_LABEL_EN, OFFENSIVE_REBOUNDS_SHORT_LABEL: OFFENSIVE_REBOUNDS_SHORT_LABEL_EN, OFFENSIVE_REBOUNDS_TITLE: OFFENSIVE_REBOUNDS_TITLE_EN,
        TOTAL_REBOUNDS_LABEL: TOTAL_REBOUNDS_LABEL_EN, TOTAL_REBOUNDS_SHORT_LABEL: TOTAL_REBOUNDS_SHORT_LABEL_EN, TOTAL_REBOUNDS_TITLE: TOTAL_REBOUNDS_LABEL_EN,
        ASSISTS_LABEL: ASSISTS_LABEL_EN, ASSISTS_SHORT_LABEL: ASSISTS_SHORT_LABEL_EN, ASSISTS_TITLE: ASSISTS_TITLE_EN,
        BLOCKS_LABEL: BLOCKS_LABEL_EN, BLOCKS_SHORT_LABEL: BLOCKS_SHORT_LABEL_EN, BLOCKS_TITLE: BLOCKS_TITLE_EN,
        STEALS_LABEL: STEALS_LABEL_EN, STEALS_SHORT_LABEL: STEALS_SHORT_LABEL_EN, STEALS_TITLE: STEALS_TITLE_EN,
        TURNOVERS_LABEL: TURNOVERS_LABEL_EN, TURNOVERS_SHORT_LABEL: TURNOVERS_SHORT_LABEL_EN, TURNOVERS_TITLE: TURNOVERS_TITLE_EN,
        EFFECTIVITY_LABEL: EFFECTIVITY_LABEL_EN, EFFECTIVITY_SHORT_LABEL: EFFECTIVITY_SHORT_LABEL_EN, EFFECTIVITY_TITLE: EFFECTIVITY_TITLE_EN,
        EVAL_LABEL: EVAL_LABEL_EN, EVAL_SHORT_LABEL: EVAL_SHORT_LABEL_EN, EVAL_TITLE: EVAL_TITLE_EN,
        FOUL: FOUL_EN, FOULS: FOULS_EN, FOULS_LABEL: FOULS_LABEL_EN, FOULS_TITLE: FOULS_TITLE_EN,
        FOUL_UNSPORTSMANLIKE: FOUL_UNSPORTSMANLIKE_EN, FOULS_UNSPORTSMANLIKE: FOULS_UNSPORTSMANLIKE_EN,
        FOUL_TECHNICAL: FOUL_TECHNICAL_EN, FOULS_TECHNICAL: FOULS_TECHNICAL_EN,
        FOUL_DISQUALIFICATION: FOUL_DISQUALIFICATION_EN, FOULS_DISQUALIFICATION: FOULS_DISQUALIFICATION_EN,
        TEAM_OR_TRAINER: TEAM_OR_TRAINER_EN,
        TOTAL: TOTAL_EN,
    }
};


export default StatisticsDictionary;

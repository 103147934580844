import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {Col, Row} from "react-bootstrap";


import PlayerDetailsSectionsTypes from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Sections";


const TEXTS: any = {
    PL: {
        statistics_button_text: "Statystyki",
        played_games_button_text: "Rozegrane mecze",
        best_scores_button_text: "Rekordy",
        description_button_text: "Opis",
        awards_button_text: "Wyróżnienia",
    },
    EN: {
        statistics_button_text: "Statistics",
        played_games_button_text: "Played Games",
        best_scores_button_text: "Best Scores",
        description_button_text: "Description",
        awards_button_text: "Awards",
    }
};


interface PlayerDetailsSectionSwitcherComponentProps {
    selectedSection: PlayerDetailsSectionsTypes;
    selectedSectionHandler(section: PlayerDetailsSectionsTypes): void;
}

export const PlayerDetailsSectionSwitcherComponent: React.FC<PlayerDetailsSectionSwitcherComponentProps> = ({
    selectedSection, selectedSectionHandler
}) : JSX.Element | null => {


    const {activeLanguage} = useTypedSelector((state) => state.loc);

    const {
        statistics_button_text: STATISTICS_BUTTON_TEXT,
        played_games_button_text: PLAYED_GAMES_BUTTON_TEXT,
        best_scores_button_text: BEST_SCORES_BUTTON_TEXT,
        description_button_text: DESCRIPTION_BUTTON_TEXT,
        awards_button_text: AWARDS_BUTTON_TEXT,
    } = TEXTS[activeLanguage];


    return (
        <Col xs={12} className={"player-details-section-switcher player-details-section-switcher-component"}>
            <Row className={"player-details-section-switcher-content"}>

                <Col xs={"auto"} className={"section-switcher section-switcher-component"}>
                    <Row className={"section-switcher-content"}>

                        <Col xs={"auto"} className={"section-switcher-button section-switcher-button-component" + (selectedSection === PlayerDetailsSectionsTypes.PLAYER_STATISTICS ? " selected" : "")}>
                            <span onClick={() => selectedSectionHandler(PlayerDetailsSectionsTypes.PLAYER_STATISTICS)}>{STATISTICS_BUTTON_TEXT}</span>
                            <span className={"bg"}></span>
                        </Col>

                        <Col xs={"auto"} className={"section-switcher-button section-switcher-button-component" + (selectedSection === PlayerDetailsSectionsTypes.PLAYER_PLAYED_GAMES ? " selected" : "")}>
                            <span onClick={() => selectedSectionHandler(PlayerDetailsSectionsTypes.PLAYER_PLAYED_GAMES)}>{PLAYED_GAMES_BUTTON_TEXT}</span>
                            <span className={"bg"}></span>
                        </Col>

                        <Col xs={"auto"} className={"section-switcher-button section-switcher-button-component" + (selectedSection === PlayerDetailsSectionsTypes.PLAYER_BEST_SCORES ? " selected" : "")}>
                            <span onClick={() => selectedSectionHandler(PlayerDetailsSectionsTypes.PLAYER_BEST_SCORES)}>{BEST_SCORES_BUTTON_TEXT}</span>
                            <span className={"bg"}></span>
                        </Col>

                        {/*<Col xs={"auto"} className={"section-switcher-button section-switcher-button-component" + (selectedSection === PlayerDetailsSectionsTypes.PLAYER_DESCRIPTION ? " selected" : "")}>*/}
                        {/*    <span onClick={() => selectedSectionHandler(PlayerDetailsSectionsTypes.PLAYER_DESCRIPTION)}>{DESCRIPTION_BUTTON_TEXT}</span>*/}
                        {/*</Col>*/}

                        {/*<Col xs={"auto"} className={"section-switcher-button section-switcher-button-component" + (selectedSection === PlayerDetailsSectionsTypes.PLAYER_AWARDS ? " selected" : "")}>*/}
                        {/*    <span onClick={() => selectedSectionHandler(PlayerDetailsSectionsTypes.PLAYER_AWARDS)}>{AWARDS_BUTTON_TEXT}</span>*/}
                        {/*</Col>*/}

                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerDetailsSectionSwitcherComponent;

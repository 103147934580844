import { PlayerDescriptionFormErrorsInterface } from "@Pages/User/Authenticated/Profile/Player/Content/Description/Types";


export const PlayerDescriptionFormErrorsDefaults: PlayerDescriptionFormErrorsInterface = {
    playerDescription: null, playerBirthDate: null,
    playerWeight: null, playerHeight: null, playerNumber: null,
    playerNationality: null,
    playerPosition: null,
};


export default PlayerDescriptionFormErrorsDefaults;

import React from "react";


import $ from "jquery";


export const scrollToDomElement = (
    target: React.MutableRefObject<any> | null,
    offset = 100
) : void => {

    if (!target) {
        return;
    }

    const position: number | undefined = $(target)?.offset()?.top;

    if (!position) return;

    $("body, html").animate(
        {
            scrollTop: position - offset
        },
        1000
    );

}


export default scrollToDomElement;

export * from "@Dictionary/Sets/Links/Types/Phrases/Set";
export * from "@Dictionary/Sets/Links/Phrases";


import LinksDictionaryInterface from "@Dictionary/Sets/Links/Types";

import {
    GO_TO_TEAM_PROFILE_PAGE_TITLE_PL, GO_TO_TEAM_PROFILE_PAGE_TITLE_EN
} from "@Dictionary/Sets/Links/Phrases";



export const LinksDictionary: LinksDictionaryInterface = {
    PL: {
        GO_TO_TEAM_PROFILE_PAGE_TITLE: GO_TO_TEAM_PROFILE_PAGE_TITLE_PL
    },
    EN: {
        GO_TO_TEAM_PROFILE_PAGE_TITLE: GO_TO_TEAM_PROFILE_PAGE_TITLE_EN
    }
};


export default LinksDictionary;

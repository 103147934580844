import ApplicationActionTypes from "@Action-Types/Application";
import ApplicationAction from "@Actions/Application";


import GlobalDataStateInterface from "@Reducers/Application/GlobalData";

import PopupMessageInterface from "@Actions/Application/PopupMessage/_Types/Message";


interface ApplicationStateInterface {
    apiRequestStatus: boolean;
    globalBlackoutStatus: boolean;
    popupMessage: PopupMessageInterface | null;
    globalData: GlobalDataStateInterface | null;
}


const ApplicationState: ApplicationStateInterface = {
    apiRequestStatus: false,
    globalBlackoutStatus: false,
    globalData: null,
    popupMessage: null
};


export const ApplicationReducer = (
    state = ApplicationState,
    action: ApplicationAction
) : ApplicationStateInterface => {

    switch (action.type) {

        case ApplicationActionTypes.API_REQUEST_STATUS_ACTION:
            return { ...state, apiRequestStatus: action.status };

        case ApplicationActionTypes.GLOBAL_BLACKOUT_STATUS_ACTION:
            return { ...state, globalBlackoutStatus: action.status };

        case ApplicationActionTypes.GLOBAL_DATA_ACTION:
            return { ...state, globalData: action.data };

        case ApplicationActionTypes.POPUP_MESSAGE_ACTION:
            return { ...state, popupMessage: action.message };

        default:
            return state;

    }

};


export default ApplicationReducer;

import { UseLanguage } from "@States";


import LeaguesDictionary, { LeaguesDictionaryPhrasesSetType } from "@Dictionary/Pages/Leagues/Leagues";


export const UseLeaguesDictionaryPhrasesSet = (phraseName: LeaguesDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return LeaguesDictionary[ActiveLanguage][phraseName];

};


export default UseLeaguesDictionaryPhrasesSet;

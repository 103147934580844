import React, { useEffect, useRef, useState } from "react";


import scrollToDomElement from "@Utilities/Scrolling";


import { Col, Row } from "react-bootstrap";


import GameSummaryTeamLogoComponent from "@GameResultPageSummary/GameMainInformation/TeamLogo";
import GameSummaryTeamPointsComponent from "@GameResultPageSummary/GameMainInformation/TeamPoints";
import GameSummaryQuartersComponent from "@GameResultPageSummary/GameMainInformation/GameQuarters";
import GameSummaryInformationComponent from "@GameResultPageSummary/GameMainInformation/GameInfo";


import "./index.scss";


import {
    LeagueGameInterface,
    LeagueGameQuarterNumberType
} from "@Interfaces/Api";


import TeamsSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams";


type GameSummaryMainInformationProps = {
    GameData: LeagueGameInterface;
    TeamsSummaryData: TeamsSummaryInterface | null;
    SelectedQuarters: LeagueGameQuarterNumberType[];
    selectedQuartersHandler(quarter: LeagueGameQuarterNumberType): void;
};


export const GameSummaryMainInformationComponent: React.FC<GameSummaryMainInformationProps> = ({
    GameData, TeamsSummaryData,
    SelectedQuarters = [], selectedQuartersHandler,
}) : JSX.Element | null => {


    // const loggedUser = UseLoggedUser();


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef?.current) return;
        scrollToDomElement(ComponentRef.current, window.innerWidth > 992 ? 100 : 200);
    }, []);


    const GameResultDefaults = { homeTeamPoints: 0, awayTeamPoints: 0 };


    const [GameResult, setGameResult] = useState<any>(GameResultDefaults);


    useEffect(() => {

        if (!GameData) return;

        const GameResult = { homeTeamPoints: 0, awayTeamPoints: 0 };

        const { league_game_status: gameStatus } = GameData;

        if (gameStatus === "FT_WAL") {

            const {
                league_game_home_team_points: homeTeamPoints,
                league_game_away_team_points: awayTeamPoints
            } = GameData;

            GameResult.homeTeamPoints = homeTeamPoints;
            GameResult.awayTeamPoints = awayTeamPoints;

        } else if (TeamsSummaryData) {

            const { league_game_home_team_data: HomeTeam, league_game_away_team_data: AwayTeam } = GameData

            if (HomeTeam) {
                const { team_id: homeTeamID} = HomeTeam;
                const HomeTeamStatistics = TeamsSummaryData[homeTeamID];
                if (HomeTeamStatistics && HomeTeamStatistics.teamSummaryStatistics) {
                    const { PT1, PT2, PT3 } = HomeTeamStatistics.teamSummaryStatistics;
                    GameResult.homeTeamPoints = PT1 + 2 * PT2 + 3 * PT3;
                }
            }

            if (AwayTeam) {
                const { team_id: awayTeamID} = AwayTeam;
                const AwayTeamStatistics = TeamsSummaryData[awayTeamID];
                if (AwayTeamStatistics && AwayTeamStatistics.teamSummaryStatistics) {
                    const { PT1, PT2, PT3 } = AwayTeamStatistics.teamSummaryStatistics;
                    GameResult.awayTeamPoints = PT1 + 2 * PT2 + 3 * PT3;
                }
            }

        } else {

            const selectedQuartersArray = SelectedQuarters.map((q) => +q);

            const { league_game_quarters: GameQuartersResults } = GameData;

            if (!GameQuartersResults) return;

            for (let [quarterNumber, quarterResult] of Object.entries(GameQuartersResults)) {

                if (!selectedQuartersArray.includes(+quarterNumber)) continue;

                const { HOME_TEAM: homeTeamPoints, AWAY_TEAM: awayTeamPoints } = quarterResult;

                GameResult.homeTeamPoints += homeTeamPoints;
                GameResult.awayTeamPoints += awayTeamPoints;

            }

        }

        setGameResult(GameResult);

    }, [GameData, SelectedQuarters, TeamsSummaryData]);


    if (!GameData) return null;


    const {
        league_game_status: gameStatus,
        league_game_date: gameDate,
        league_game_home_team_data: gameHomeTeam,
        league_game_away_team_data: gameAwayTeam,
        league_game_quarters: gameQuarters,
        league_game_round_data: gameRound
    } = GameData;


    const { homeTeamPoints, awayTeamPoints } = GameResult;


    const gameFinishedStatus = ["FT", "FT_WAL", "AOT"].includes(gameStatus);


    return (
        <Col xs={12} ref={ComponentRef} className={"game-result-main-information game-result-main-information-component"}>
            <Row className={"game-result-main-information-content"}>

                {/*{!!loggedUser &&*/}
                {/*<Col xs={12} id={"upload-game-data"}>*/}
                {/*    <Button as={NavLink} to={`/mecze/${leagueGameID}/game-admin`}>*/}
                {/*        <span>Wgraj Dane Meczu</span>*/}
                {/*        <AiFillEdit />*/}
                {/*    </Button>*/}
                {/*</Col>*/}
                {/*}*/}

                <GameSummaryTeamLogoComponent
                    GameTeam={gameHomeTeam} gameTeamType={"HOME"}
                />

                <GameSummaryTeamPointsComponent
                    GameTeam={gameHomeTeam} gameTeamType={"HOME"}
                    gameTeamPoints={homeTeamPoints}
                    gameTeamResultType={homeTeamPoints > awayTeamPoints ? "WIN" : "LOSE"}
                />

                <GameSummaryTeamPointsComponent
                    GameTeam={gameAwayTeam} gameTeamType={"AWAY"}
                    gameTeamPoints={awayTeamPoints}
                    gameTeamResultType={awayTeamPoints > homeTeamPoints ? "WIN" : "LOSE"}
                />

                <GameSummaryTeamLogoComponent
                    GameTeam={gameAwayTeam} gameTeamType={"AWAY"}
                />

	            <GameSummaryQuartersComponent
                    LeagueGameQuarters={gameQuarters}
                    SelectedQuarters={SelectedQuarters}
                    selectedQuartersHandler={selectedQuartersHandler}
	            />

                <GameSummaryInformationComponent
                    gameDate={gameDate}
                    gameRound={gameRound}
                    leagueGameQuarters={gameQuarters}
                    selectedQuarters={SelectedQuarters}
                    selectedQuartersHandler={selectedQuartersHandler}
                />

            </Row>
        </Col>
    );

};


export default GameSummaryMainInformationComponent;

import React from "react";


import {Row} from "react-bootstrap";


import GameSummaryTableRowLabelComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table/Row/Label";
import GameSummaryTableRowTeamComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table/Row/Team";


interface GameSummaryTableRowTeamInterface {
    label?: string;
    labelSize?: "DEFAULT" | "FULL";
    primaryValue?: React.ReactNode;
    secondaryValue?: React.ReactNode;
}


interface GameSummaryTableRowComponentProps {
    summaryStatisticLabel: string;
    summaryStatisticHomeTeamData?: GameSummaryTableRowTeamInterface;
    summaryStatisticAwayTeamData?: GameSummaryTableRowTeamInterface;
    statisticType?: "number" | "percentage";
}

export const GameSummaryTableRowComponent: React.FC<GameSummaryTableRowComponentProps> = ({
    summaryStatisticLabel,
    summaryStatisticHomeTeamData, summaryStatisticAwayTeamData,
    statisticType = "number"
}) => {


    return (
        <Row className={"game-information-summary-table-row game-information-summary-table-row-component"}>

            <GameSummaryTableRowTeamComponent
                gameTeamData={summaryStatisticHomeTeamData}
                statisticType={statisticType}
            />

            <GameSummaryTableRowLabelComponent
                offset={!summaryStatisticHomeTeamData ? 4 : 0}
                summaryStatisticLabel={summaryStatisticLabel}
            />

            <GameSummaryTableRowTeamComponent
                gameTeamData={summaryStatisticAwayTeamData}
                statisticType={statisticType}
            />

        </Row>
    );

};


export default GameSummaryTableRowComponent;

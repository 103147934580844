import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";


export const formatSummaryTablePlayerName = (
    GamePlayers: ApiGameSummaryPlayersInterface, playerID?: number
) => {

    if (!GamePlayers || !playerID || !GamePlayers[playerID]) return "-"

    const Player = GamePlayers[playerID];

    const {
        player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
        player_pseudonymization_status: playerPseudonymization_status
    } = Player;

    if (playerPseudonymization_status) {
        return `"${playerNickName}"`;
    }

    return `${playerFirstName} ${playerLastName}`

};


export default formatSummaryTablePlayerName;

import React from 'react';
import ReactDOM from 'react-dom';


import reportWebVitals from './reportWebVitals';

import {Provider as StoreProvider} from "react-redux";
import ApplicationStore from "@Store";


import GlobalDataContext, {GLOBAL_DATA} from "@Globals";


import Application from "@/Application";


ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={ApplicationStore}>
            <GlobalDataContext.Provider value={GLOBAL_DATA}>
                <Application />
            </GlobalDataContext.Provider>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


reportWebVitals();

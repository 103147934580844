import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


import { Bubble } from "react-chartjs-2";


import MessageComponent from "@Components/Elements/Messages/Content";


import GameThrowsTeamThrowsLegendComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/Chart/Legend";
import GameThrowsTeamThrow from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/Chart/Throw";


import FIELD_BG from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/_Images/field_bg.png";


import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";

import ThrowsEventsTeamInterface from "@Components/Views/Pages/Games/Games/Game/_Types/Summary/GUI/Throws/Teams/Team";


const CHART_OPTIONS = {
    aspectRatio: 1, maintainAspectRatio: false, responsive: true,
    plugins: {
        legend: {display: false},
        title: {display: false},
        tooltip: {
            callbacks: {
                label(tooltipItem: any): string | string[] {
                    return tooltipItem.label;
                }
            }
        },
    },
    scales: {
        x: {
            grid: {display: false, drawBorder: false},
            ticks: {display: false},
            min: -15, max: 365
        },
        y: {
            grid: {display: false, drawBorder: false},
            ticks: {display: false},
            min: -10, max: 370
        }
    },
    layout: {
        padding: -10
    },
    // animations: {
    //     tension: {
    //         duration: 1000,
    //         easing: 'linear',
    //         from: 1,
    //         to: 0,
    //         loop: true
    //     }
    // },

};


interface GameThrowsTeamThrowsComponentProps {
    GamePlayers: ApiGameSummaryPlayersInterface;
    GameTeamThrows: ThrowsEventsTeamInterface;
}


export const GameThrowsTeamThrowsComponent: React.FC<GameThrowsTeamThrowsComponentProps> = ({
    GamePlayers,
    GameTeamThrows
}) : JSX.Element | null => {


    const NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE = UseGamesDictionaryPhrasesSet("NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE");


    if (!GameTeamThrows) return null;


    const {
        accurate: teamAccurateThrows,
        notAccurate: teamNotAccurateThrows
    } = GameTeamThrows;


    const TeamAccurateThrows = teamAccurateThrows.map(
        (ThrowEvent) => GameThrowsTeamThrow(GamePlayers, ThrowEvent, "ACCURATE")
    ).filter((throwPoint) => !!throwPoint);

    const TeamNotAccurateThrows = teamNotAccurateThrows.map(
        (ThrowEvent) => GameThrowsTeamThrow(GamePlayers, ThrowEvent, "NOT_ACCURATE")
    ).filter((throwPoint) => !!throwPoint);


    const CHART_DATASETS = [...TeamAccurateThrows, ...TeamNotAccurateThrows];


    return (
        <Col xs={12} lg={{span: 7}} className={"throws-team-chart"}>
            <Row className={"throws-team-chart-content"}>

                {(!!CHART_DATASETS && CHART_DATASETS.length > 0) ?

                    <Col xs={12} style={{backgroundImage: `url(${FIELD_BG})`}} className={"team-throws-chart"}>

                        <Bubble
                            data={{datasets: CHART_DATASETS}}
                            options={CHART_OPTIONS}
                            style={{width: 370, height: 330}}
                        />

                        <GameThrowsTeamThrowsLegendComponent />

                    </Col>

                    :

                    <MessageComponent messageText={NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE} />

                }

            </Row>
        </Col>
    );


};


export default GameThrowsTeamThrowsComponent;

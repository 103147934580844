import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import RoundBestPlayerPlayerTeamComponent from "@ResultsPages/Rounds/Round/BestPlayer/Player/Team";
import RoundBestPlayerPlayerImageComponent from "@ResultsPages/Rounds/Round/BestPlayer/Player/Image";
import RoundBestPlayerPlayerInformationComponent from "@ResultsPages/Rounds/Round/BestPlayer/Player/Information";


import {
    LeagueRoundBestPlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundBestPlayerPlayerComponentProps {
    BestPlayer: LeagueRoundBestPlayerInterface | null;
}


export const RoundBestPlayerPlayerComponent: React.FC<RoundBestPlayerPlayerComponentProps> = ({
    BestPlayer
}) : JSX.Element | null => {


    if (!BestPlayer) {
        return null;
    }


    const {
        player_team: playerTeam, player_data: playerData
    } = BestPlayer;


    return (
        <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__content"}>

                <RoundBestPlayerPlayerTeamComponent Team={playerTeam} />

                <RoundBestPlayerPlayerImageComponent Player={playerData} />

                <RoundBestPlayerPlayerInformationComponent BestPlayer={BestPlayer} />

            </Row>
        </Col>
    );

};


export default RoundBestPlayerPlayerComponent;

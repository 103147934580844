import React from "react";


import { Col, Row } from "react-bootstrap";


import GameSummaryTableTeamComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Teams/Team";
import GameSummaryTableTeamSeparatorComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Teams/Separator";


import ApiGameSummaryTeamInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team";


interface GameSummaryTableTeamsComponentProps {
    gameHomeTeam: ApiGameSummaryTeamInterface;
    gameAwayTeam: ApiGameSummaryTeamInterface;
}


export const GameSummaryTableTeamsComponent: React.FC<GameSummaryTableTeamsComponentProps> = ({
    gameHomeTeam, gameAwayTeam
}) : JSX.Element | null => {


    if (!gameHomeTeam || !gameAwayTeam) return null;


    return (
        <Col xs={12} className={"game-information-summary__teams"}>
            <Row className={"game-information-summary__teams__content"}>

                <GameSummaryTableTeamComponent
                    GameTeam={gameHomeTeam}
                    gameTeamType={"HOME"}
                />

                <GameSummaryTableTeamSeparatorComponent />

                <GameSummaryTableTeamComponent
                    GameTeam={gameAwayTeam}
                    gameTeamType={"AWAY"}
                />

            </Row>
        </Col>
    );

};


export default GameSummaryTableTeamsComponent;

import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        best_scores_header_score_text: "Rekord",
        best_scores_header_date_text: "Data",
        best_scores_header_against_text: "Przeciwko",
        best_scores_header_result_text: "Wynik",
    },
    EN: {
        best_scores_header_score_text: "Score",
        best_scores_header_date_text: "Date",
        best_scores_header_against_text: "Against",
        best_scores_header_result_text: "Result",
    }
};


export interface BestScoresTableHeaderComponentProps {}


export const BestScoresTableHeaderComponent: React.FC<BestScoresTableHeaderComponentProps> = () : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        best_scores_header_score_text: BEST_SCORES_HEADER_SCORE_TEXT,
        best_scores_header_date_text: BEST_SCORES_HEADER_DATE_TEXT,
        best_scores_header_against_text: BEST_SCORES_HEADER_AGAINST_TEXT,
        best_scores_header_result_text: BEST_SCORES_HEADER_RESULT_TEXT
    } = TEXTS[activeLanguage];


    return (
        <Col xs={12} lg={{span: 9, offset: 3}} className={"best-scores-table-header best-scores-table-header-component"}>
            <Row className={"best-scores-table-header-content"}>
                <Col xs={3} className={"best-score-value"}>{BEST_SCORES_HEADER_SCORE_TEXT}</Col>
                <Col xs={3} className={"best-score-game-date"}>{BEST_SCORES_HEADER_DATE_TEXT}</Col>
                <Col xs={4} className={"best-score-opponent"}>{BEST_SCORES_HEADER_AGAINST_TEXT}</Col>
                <Col xs={2} className={"best-score-result"}>{BEST_SCORES_HEADER_RESULT_TEXT}</Col>
            </Row>
        </Col>
    );

};


export default BestScoresTableHeaderComponent;

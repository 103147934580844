import React from "react";


import { Col, Row } from "react-bootstrap";


import { SingleContentLoaderComponent } from "@LoadersElements";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TeamFilesListFileComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/List/File";
import TeamFilesListAddFileComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/List/AddFile";


import { LeagueTeamFileInterface } from "@Interfaces/Api";

import TeamFileInterface from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/_Types/TeamFile";


import "./index.scss";


export interface TeamFilesListComponentProps {
    TeamFiles: LeagueTeamFileInterface[] | null;
    TeamFilesError: ErrorMessageInterface | null;
    fileUploadHandler: (File: TeamFileInterface) => Promise<boolean>;
}

export const TeamFilesListComponent: React.FC<TeamFilesListComponentProps> = ({
    TeamFiles, TeamFilesError,
    fileUploadHandler
}) : JSX.Element | null => {


    if (TeamFilesError) return <ErrorMessageComponent messageText={TeamFilesError.errorMessage} />;

    if (!TeamFiles) return <SingleContentLoaderComponent />;


    const TeamFilesList = TeamFiles.map((File) =>
        <TeamFilesListFileComponent key={File.id} File={File} />
    );


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__team-files__files-list"}>
            <Row className={"player-profile__captain-zone__team__team-files__files-list__content"}>

                {TeamFilesList}

                <TeamFilesListAddFileComponent fileUploadHandler={fileUploadHandler} />

            </Row>
        </Col>
        );

};


export default TeamFilesListComponent;

import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Col, Container, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";
import MessageComponent from "@Components/Elements/Messages/Content";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import TeamsAndPlayersTeamsComponent from "@Components/Views/Pages/Games/Teams/List/Content/TeamsAndPlayers/Teams";
import TeamsAndPlayersPlayersComponent from "@Components/Views/Pages/Games/Teams/List/Content/TeamsAndPlayers/Players";


const TEXTS: any = {
    PL: {
        no_league_selected_message_text: "Nie wybrano Ligi",
        teams_label_text: "Drużyny",
        players_label_text: "Zawodnicy",
    },
    EN: {
        no_league_selected_message_text: "No league selected",
        teams_label_text: "Teams",
        players_label_text: "Players",
    }
};


import "./index.scss";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


export interface TeamsAndPlayersComponentProps {
    selectedLeagueID: number;
}

export const TeamsAndPlayersComponent: React.FC<TeamsAndPlayersComponentProps> = ({
    selectedLeagueID
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        no_league_selected_message_text: NO_LEAGUE_SELECTED_MESSAGE_TEXT,
        teams_label_text: TEAMS_LABEL_TEXT, players_label_text: PLAYERS_LABEL_TEXT
    } = TEXTS[activeLanguage];


    const TEAMS_LIST_API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;


    const [leaguesTeams, setLeaguesTeams] = useState<LeagueTeamInterface[] | null>(null);
    const [leaguesTeamsError, setLeaguesTeamsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!selectedLeagueID || !TEAMS_LIST_API_URL) {
            setLeaguesTeams([]);
            return;
        }

        const getLeaguesTeams = async () => {

            setLeaguesTeams(null);

            try {

                const response = await _restApiRequest(
                    `${TEAMS_LIST_API_URL}/leagues/leagues/${selectedLeagueID}/league-teams/`
                );

                if (!response.ok) {
                    setLeaguesTeamsError({errorCode: response.status, errorMessage: ""});
                    return;
                }

                const responseContent: LeagueTeamInterface[] = await response.json();
                setLeaguesTeams(responseContent);

            } catch (e) {
                setLeaguesTeamsError({errorCode: 500, errorMessage: ""});
            }

        };

        getLeaguesTeams();

    }, [selectedLeagueID]);


    useEffect(() => {
        return () => {
            setLeaguesTeams(null);
            setLeaguesTeamsError(null);
        };
    }, []);


    const [selectedSection, setSelectedSection] = useState<"TEAMS" | "PLAYERS">("TEAMS");

    useEffect(() => {
        return () => {
            setSelectedSection("TEAMS");
        }
    }, []);


    const [teamsFilterValue, setTeamsFilterValue] = useState<string>("");

    useEffect(() => {
        return () => {
            setTeamsFilterValue("");
        }
    }, []);


    if (!selectedLeagueID && selectedLeagueID !== 0) return null;

    if (selectedLeagueID === 0) return <MessageComponent messageText={NO_LEAGUE_SELECTED_MESSAGE_TEXT} />;

    if (!!leaguesTeamsError) return <ErrorMessageComponent messageText={leaguesTeamsError.errorMessage} />;


    if (!leaguesTeams) return <MainContentLoaderComponent />


    const FilteredTeams = leaguesTeams.filter((Team) => {
        const {team_name: teamName} = Team;
        return (
            teamName.toLowerCase().includes(teamsFilterValue.toLowerCase())
        );
    });


    return (
        <>

            <Container fluid={true} className={"page-component-header-block teams-and-players-header"}>
                <Container className={"page-component-header-container"}>
                    <Row className={"page-component-header-content"}>

                        <Col xs={12} as={"h1"} className={"page-component-header teams-and-players-switcher"}>
                            <span onClick={() => setSelectedSection("TEAMS")} className={selectedSection === "TEAMS" ? "selected" : ""}>{TEAMS_LABEL_TEXT}</span>
                            <span onClick={() => setSelectedSection("PLAYERS")} className={selectedSection === "PLAYERS" ? "selected" : ""}>{PLAYERS_LABEL_TEXT}</span>
                        </Col>

                    </Row>
                </Container>
            </Container>

            <Container fluid={true} id={"teams-and-players-component"} className={"teams-and-players teams-and-players-component page-section-component main-section-component"}>
                <Container className={"main-section-container page-section-container"}>
                    <Row className={"main-section-content page-section-row"}>

                        {!!selectedLeagueID ?
                            <>

                                {selectedSection === "TEAMS" &&
	                            <TeamsAndPlayersTeamsComponent
		                            teamsList={FilteredTeams}
		                            teamsFilterValue={teamsFilterValue}
		                            teamsFilterValueHandler={setTeamsFilterValue}
	                            />
                                }

                                {selectedSection === "PLAYERS" &&
	                            <TeamsAndPlayersPlayersComponent
		                            teamsList={FilteredTeams}
	                            />
                                }

                            </>

                            :

                            <MessageComponent messageText={NO_LEAGUE_SELECTED_MESSAGE_TEXT} />

                        }



                    </Row>
                </Container>

            </Container>

        </>

        )

};


export default TeamsAndPlayersComponent;

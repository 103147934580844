import React from "react";


import {Col, Row} from "react-bootstrap";

import {AiFillFacebook, AiFillInstagram, BiWorld, SiTiktok} from "react-icons/all";


import "./index.scss";


export interface TeamHeaderSocialsComponentProps {
    teamSocials?: Array<{social_type: string, social_url: string}>;
    teamWebsite?: string | null;
}


export const TeamHeaderSocialsComponent: React.FC<TeamHeaderSocialsComponentProps> = ({
    teamSocials, teamWebsite
}) : JSX.Element | null => {


    if (!teamSocials && !teamWebsite) return null;


    return (
        <Col xs={12} lg={3} className={"team-page__team-header__team-socials"}>
            <Row className={"team-socials-content"}>

                {!!teamWebsite &&
                <Col xs={"auto"} className={"team-social"}>
                    <a href={teamWebsite} title={`WWW`} target={"_blank"} rel={"noreferrer noopener"}>
                        <BiWorld />
                    </a>
                </Col>
                }

                <Col xs={"auto"} className={"team-social"}>
                    <a href={"https://facebook.com"} title={`Facebook`} target={"_blank"} rel={"noreferrer noopener"}>
                        <AiFillFacebook />
                    </a>
                </Col>

                <Col xs={"auto"} className={"team-social"}>
                    <a href={"https://instagram.com"} title={`Instagram`} target={"_blank"} rel={"noreferrer noopener"}>
                        <AiFillInstagram />
                    </a>
                </Col>

                <Col xs={"auto"} className={"team-social"}>
                    <a href={"https://tiktok.com"} title={`Tik Tok`} target={"_blank"} rel={"noreferrer noopener"}>
                        <SiTiktok />
                    </a>
                </Col>

            </Row>
        </Col>
    );


};


export default TeamHeaderSocialsComponent;

import ApplicationStore from "@Store";


import ApplicationActionTypes from "@Store/Action-Types/Application";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


export type RestApiRequestType = (
    requestURL: string,
    requestData?: FormData | null,
    requestMethod?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    showLoader?: boolean,
    requestHeaders?: HeadersInit,
) => Promise<Response>;


export const _restApiRequest: RestApiRequestType = async (
    requestURL, requestData, requestMethod = "GET", showLoader = true, requestHeaders
) => {

    let ApiResponse: Response;

    if (showLoader) {
        ApplicationStore.dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: true
        });
    }

    const ApiRequestInit: RequestInit = {method: requestMethod};

    if (!!requestMethod && requestMethod !== "GET") ApiRequestInit.body = requestData;

    if (!!requestHeaders) ApiRequestInit.headers = {...ApiRequestInit.headers, ...requestHeaders};

    try {

        ApiResponse = await fetch(requestURL, ApiRequestInit);

    } catch (e) {

        const ApiResponseContent: ErrorMessageInterface = {
            errorCode: 500, errorMessage: String(e)
        };

        ApiResponse = new Response(
            JSON.stringify(ApiResponseContent),
            {status: 500, statusText: String(e)}
        );

    }

    if (showLoader) {
        ApplicationStore.dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: false
        });
    }

    return ApiResponse;

};


export default _restApiRequest;

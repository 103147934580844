import React, { useEffect, useState } from "react";


import {
    UseErrorMessage, UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    API_PARTNERS_LIST_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _getDataFromApi from "@Services/API/Requests";


import {
    PageContentComponent
} from "@ContentElements";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import PartnersListPartnerComponent from "@Pages/Common/PartnersPage/PartnersList/Partner";


import { PartnerInterface } from "@Interfaces/Api";


import "./index.scss";


export interface PartnersListComponentProps {}


export const PartnersListComponent: React.FC<PartnersListComponentProps> = () => {


    const DEFAULT_DATA_REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT = UseGlobalsDictionaryPhrasesSet("PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT");


    const [Partners, setPartners] = useState<PartnerInterface[] | null>(null);
    const [PartnersError, setPartnersError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        const getPartners = async () => {
            await _getDataFromApi(
                API_PARTNERS_LIST_ENDPOINT_URL,
                setPartners, setPartnersError
            );
        };

        getPartners();

    }, []);

    useEffect(() => {

    }, []);


    if (PartnersError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR_MESSAGE} />;
    }

    if (!Partners) {
        return <SingleContentLoaderComponent cssClasses={"margin-top-45"} />;
    }


    if (!Partners?.length) {
        return <MessageComponent messageText={PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT} cssClasses={"padding-15 margin-top-15"} />;
    }


    const PartnersList = Partners.map((Partner, i) =>
        <PartnersListPartnerComponent key={i} Partner={Partner} />
    );


    return (
        <PageContentComponent cssClasses={"partners-page__partners-list"}>

            {PartnersList}

        </PageContentComponent>
    )

};


export default PartnersListComponent;

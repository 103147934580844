import React from "react";


import parse from "html-react-parser";


import { Col } from "react-bootstrap";


interface GameSummaryTableRowLabelComponentProps {
    summaryStatisticLabel: string;
    offset?: number;
}


export const GameSummaryTableRowLabelComponent: React.FC<GameSummaryTableRowLabelComponentProps> = ({
    summaryStatisticLabel, offset = 0
}) => {


    return (
        <Col xs={{span: 4, offset: offset}} className={"summary-table-label"}>
            {!!summaryStatisticLabel && <span>{parse(summaryStatisticLabel)}</span>}
        </Col>
    );

};


export default GameSummaryTableRowLabelComponent;

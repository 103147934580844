import React from "react";

import { UseRoute } from "@Hooks/Routes";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import "./index.scss";


import ApiGameSummaryTeamInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team";


interface GameProgressHeaderTeamComponentProps {
    GameTeam: ApiGameSummaryTeamInterface;
    gameTeamType: "HOME" | "AWAY";
}


export const GameProgressHeaderTeamComponent: React.FC<GameProgressHeaderTeamComponentProps> = ({
    GameTeam, gameTeamType
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");

    const {
        routeURL: TEAMS_ROUTE_URL, routeTitle: TEAMS_ROUTE_TITLE
    } = TeamsRoute;


    if (!GameTeam) {
        return (
            <Col xs={5} lg={4} className={"game-result-details__progress__header__no-team"}></Col>
        );
    }


    const {
        team_data: {
            team_name: teamName, team_slug: teamSlug,
            team_first_color: teamFirstColor, team_second_color: teamSecondColor
        }
    } = GameTeam;

    const teamColor = teamFirstColor || teamSecondColor;


    let teamCssClass = "";

    if (gameTeamType === "HOME") {
        teamCssClass = "home-team";
    } else if (gameTeamType === "AWAY") {
        teamCssClass = "away-team";
    }


    return (
        <Col xs={5} lg={4} className={"game-result-details__progress__header__team" + (teamCssClass ? ` ${teamCssClass}` : "")} style={{ borderColor: teamColor || undefined }}>

            <span className={"bg bg1"}></span>
            <span className={"bg bg2"}></span>

            <NavLink to={`/${TEAMS_ROUTE_URL}/${teamSlug}`} title={TEAMS_ROUTE_TITLE} className={"team-name"}>
                <span>{teamName}</span>
            </NavLink>

        </Col>
    );

};


export default GameProgressHeaderTeamComponent;

import React, { useEffect, useState } from "react";


import { Col, Row } from "react-bootstrap";


import {
    Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend,
} from 'chart.js';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);


import GameThrowsTeamsComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams";


import createThrowsMapArray from "@Components/Views/Pages/Games/Games/Game/_Utitlities/Summary/Throws/CreateThrowsMap";


import "./index.scss";


import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";

import ThrowsEventsInterface from "@Components/Views/Pages/Games/Games/Game/_Types/Summary/GUI/Throws/Teams";


interface GameInformationThrowsComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface;
}


export const GameInformationThrowsComponent: React.FC<GameInformationThrowsComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics
}) : JSX.Element | null => {


    if (!SelectedQuarters || !GameSummaryStatistics) return null;


    const {
        quarters: GameQuarters, squads: GameSquads, players: GamePlayers
    } = GameSummaryStatistics;


    if (!GameQuarters || !GameSquads || !GamePlayers) return null;


    const [SelectedPlayers, setSelectedPlayers] = useState<number[]>([]);

    const changeSelectedPlayers = (playerID: number | number[]) => {
        if (typeof playerID === "object") {
            return setSelectedPlayers([...playerID]);
        }
        if (SelectedPlayers.includes(playerID)) {
            return setSelectedPlayers(SelectedPlayers.filter((pID) => pID !== playerID))
        }
        setSelectedPlayers([...SelectedPlayers, playerID]);
    };

    useEffect(() => {

        if (!GamePlayers) return;

        setSelectedPlayers(
            Object.keys(GamePlayers).map((playerID) => +playerID)
        );

    }, [GamePlayers])


    const [GameThrows, setGameThrows] = useState<ThrowsEventsInterface | null>(null);

    useEffect(() => {

        if (!SelectedQuarters || !GameQuarters) return;

        setGameThrows(
            createThrowsMapArray(
                SelectedPlayers,
                SelectedQuarters, GameQuarters,
                homeTeamID, awayTeamID
            )
        );

    }, [SelectedPlayers, SelectedQuarters]);


    useEffect(() => {
        return () => {
            setGameThrows(null);
            setSelectedPlayers([]);
        };
    }, []);


    if (!GameThrows) return null;


    return (
        <Col xs={12} className={"game-result-details__throws"}>
            <Row className={"game-result-details__throws__content"}>

                <GameThrowsTeamsComponent
                    homeTeamID={homeTeamID}
                    awayTeamID={awayTeamID}
                    GameSquads={GameSquads}
                    GamePlayers={GamePlayers}
                    GameTeamsThrows={GameThrows}
                    SelectedPlayers={SelectedPlayers}
                    selectedPlayersHandler={changeSelectedPlayers}
                />

            </Row>
        </Col>
    );

};


export default GameInformationThrowsComponent;

import React from "react";


import { Row, Col } from "react-bootstrap";


import ColorProjectLogoWithLineSeparatorComponent from "@Elements/Misc/PLKA/Separators/ColorLogoWithLineSeparator";
import GameInformationTablesTeamComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team";
import GameTablesTeamLegendComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Legend";


import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";
import TeamsSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams";


import "./index.scss";


interface GameInformationTablesComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    GameSummaryStatistics: ApiGameSummaryInterface;
    TeamsSummaryData: TeamsSummaryInterface | null;
}


export const GameInformationTablesComponent: React.FC<GameInformationTablesComponentProps> = ({
    homeTeamID, awayTeamID,
    GameSummaryStatistics,
    TeamsSummaryData
}) : JSX.Element | null => {


    if (!homeTeamID || !awayTeamID || !GameSummaryStatistics || !TeamsSummaryData) return null;


    const HomeTeamSummaryData = TeamsSummaryData[homeTeamID];
    const AwayTeamSummaryData = TeamsSummaryData[awayTeamID];


    return (
        <Col xs={12} className={"game-information-tables game-information-tables-component"}>
            <Row className={"game-information-tables-content"}>

                {!!homeTeamID &&
	            <GameInformationTablesTeamComponent
                    teamID={homeTeamID}
		            GameSummary={GameSummaryStatistics}
                    TeamSummaryStatistics={HomeTeamSummaryData}
	            />
                }

                {!!awayTeamID &&
	            <GameInformationTablesTeamComponent
                    teamID={awayTeamID}
		            GameSummary={GameSummaryStatistics}
                    TeamSummaryStatistics={AwayTeamSummaryData}
	            />
                }

                <GameTablesTeamLegendComponent />

                <ColorProjectLogoWithLineSeparatorComponent />

            </Row>
        </Col>
    );

};


export default GameInformationTablesComponent;

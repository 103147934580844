import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface RoundBestPlayerHeaderComponentProps {}


export const RoundBestPlayerHeaderComponent: React.FC<RoundBestPlayerHeaderComponentProps> = ({}) : JSX.Element | null => {


    const PLAYER_OF_THE_ROUND_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_OF_THE_ROUND_PHRASE_TEXT");


    return (
        <Col xs={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__header"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__header__content"}>
                <span>{PLAYER_OF_THE_ROUND_PHRASE_TEXT}</span>
            </Row>
        </Col>
    );

};


export default RoundBestPlayerHeaderComponent;

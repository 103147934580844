import React from "react";


import { UseTeamsDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


import GameThrowsTeamPlayerComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/Players/Player";


import ApiGameSummaryTeamPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Players";
import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";


interface GameThrowsTeamPlayersComponentProps {
    GameTeamPlayers: ApiGameSummaryTeamPlayersInterface;
    GamePlayers: ApiGameSummaryPlayersInterface;
    SelectedPlayers: number[];
    selectedPlayersHandler(playerId: number): void;
}


export const GameThrowsTeamPlayersComponent: React.FC<GameThrowsTeamPlayersComponentProps> = ({
    GameTeamPlayers, GamePlayers,
    SelectedPlayers, selectedPlayersHandler
}) :JSX.Element | null => {


    const PLAYERS_HEADER_LABEL = UseTeamsDictionaryPhrasesSet("PLAYERS_HEADER_LABEL");


    if (!GameTeamPlayers) return null;


    const GameHomeTeamPlayers = Object.entries(GameTeamPlayers).map(([playerID, Player], i) =>
        <GameThrowsTeamPlayerComponent
            key={i}
            GamePlayerID={+playerID}
            GamePlayer={Player}
            GamePlayers={GamePlayers}
            SelectedPlayersIDs={SelectedPlayers}
            selectedPlayersIDsHandler={selectedPlayersHandler}
        />
    );


    return (
        <Col xs={12} lg={5} className={"throws-team-players"}>
            <Row className={"throws-team-players-content"}>

                <Col xs={12} className={"team-players-header"}>
                    <span>{PLAYERS_HEADER_LABEL}</span>
                </Col>

                {GameHomeTeamPlayers}

            </Row>
        </Col>
    );


};


export default GameThrowsTeamPlayersComponent;

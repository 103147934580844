import GameQuartersInterface from "@GameResultPageTypes/Summary/GUI/GameQuarters";

export const GameQuarters: GameQuartersInterface = {
    1: {
        quarterName: {
            PL: { singular: "kwarta", plural: "kwarty", genitive: "kwarty" },
            EN: { singular: "quarter", plural: "quarters", genitive: "quarter" }
        },
        quarterNumberLabel: "I"
    },
    2: {
        quarterName: {
            PL: { singular: "kwarta", plural: "kwarty", genitive: "kwarty"  },
            EN: { singular: "quarter", plural: "quarters", genitive: "quarter" }
        },
        quarterNumberLabel: "II"
    },
    3: {
        quarterName: {
            PL: { singular: "kwarta", plural: "kwarty", genitive: "kwarty"  },
            EN: { singular: "quarter", plural: "quarters", genitive: "quarter" }
        },
        quarterNumberLabel: "III"
    },
    4: {
        quarterName: {
            PL: { singular: "kwarta", plural: "kwarty", genitive: "kwarty"  },
            EN: { singular: "quarter", plural: "quarters", genitive: "quarter" }
        },
        quarterNumberLabel: "IV"
    },
    5: {
        quarterName: {
            PL: { singular: "dogrywka", plural: "", genitive: "dogrywki"  },
            EN: { singular: "extra time", plural: "", genitive: "extra time" }
        },
        quarterNumberLabel: ""
    },
};


export default GameQuarters;

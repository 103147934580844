import React from "react";


import { Col, Row } from "react-bootstrap";


import HomePageArticlesListComponent from "@Components/Views/Pages/Common/HomePage/Articles/ArticlesList";


import { UseArticlesList } from "@Hooks/Api";


export interface HomePageArticlesComponentProps {}


import "./index.scss";


export const HomePageArticlesComponent: React.FC<HomePageArticlesComponentProps> = () : JSX.Element | null => {


    const [Articles, ArticlesError] = UseArticlesList();


    if (Articles && !Articles.length) {
        return null;
    }


    return (
        <Col xs={12} className={"home-page__articles"}>
            <Row className={"home-page__articles__content"}>

                <HomePageArticlesListComponent
                    Articles={Articles}
                    ArticlesError={ArticlesError}
                />

            </Row>
        </Col>
    );

};


export default HomePageArticlesComponent;

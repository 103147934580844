import React from "react";


import {Col, Row} from "react-bootstrap";


import PlayerAvatarComponent from "@Components/Views/Pages/Games/Players/Player/MainData/Player/Avatar";
import PlayerInformationComponent from "@Components/Views/Pages/Games/Players/Player/MainData/Player/Information";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayerDataComponentProps {
    player?: LeaguePlayerInterface;
}


export const PlayerDataComponent: React.FC<PlayerDataComponentProps> = ({
    player
}) : JSX.Element | null => {


    if (!player) return null;


    const {
        player_first_name: playerFirstName, player_last_name: playerLastName,
        player_profile_image: playerProfileImage,
    } = player;


    return (
        <Col xs={12} lg={8} className={"player-main-data"}>
            <Row className={"main-data-content"}>

                <PlayerAvatarComponent
                    avatarURL={playerProfileImage}
                    playerName={`${playerFirstName} ${playerLastName}`}
                />

                <PlayerInformationComponent
                    Player={player}
                />

            </Row>
        </Col>
    );


};


export default PlayerDataComponent;

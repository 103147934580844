import React, { useEffect } from "react";


import {
    UseGlobalData
} from "@ApplicationStates";


import TagManager from "react-gtm-module";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import FooterLogoComponent from "@Components/Views/Parts/Footer/Logo";
import FooterInformationComponent from "@Components/Views/Parts/Footer/Information";


import "./index.scss";


interface ApplicationFooterComponentProps {}


export const ApplicationFooterComponent: React.FC<ApplicationFooterComponentProps> = () : JSX.Element | null => {


    const GlobalData = UseGlobalData();


    useEffect(() => {

        if (!GlobalData?.settings) {
            return;
        }

        const { gtag_identifier: GTAG_ID } = GlobalData?.settings;

        // @ts-ignore
        if (!GTAG_ID || !!window?.google_tag_manager) {
            return;
        }

        TagManager.initialize({ gtmId: GTAG_ID.value });
        console.warn("TAG MANAGER INITIALIZED")

    }, [GlobalData]);


    return (
        <ContainerSectionComponent as={"footer"} id={"service-footer-component"} classes={"service-footer-section"}>

            <FooterLogoComponent />

            <FooterInformationComponent />

        </ContainerSectionComponent>
    );

};


export default ApplicationFooterComponent;

import React, {useEffect, useRef, useState} from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Row, Col, Form, Button} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";

import ErrorMessageComponent from "@MessagesElements/Message/Error";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import scrollToDomElement from "@Utilities/Scrolling";


import { LeagueGameInterface } from "@Interfaces/Api";


import "./index.scss";


export interface GameDetailsAdminDataUploaderComponentProps {
    GameDetails: LeagueGameInterface;
    updateGameDetailsTriggerHandler: (status: boolean) => void;
}


export const GameDetailsAdminDataUploaderComponent: React.FC<GameDetailsAdminDataUploaderComponentProps> = ({
    GameDetails, updateGameDetailsTriggerHandler
}) => {


    const formRef = useRef(null);


    const [GameDetailsWlgFile, setGameDetailsWlgFile] = useState<File | undefined>(undefined);
    const [GameDetailsXmlFile, setGameDetailsXmlFile] = useState<File | undefined>(undefined);
    const [GameDetailsQuartersFile, setGameDetailsQuartersFile] = useState<File | undefined>(undefined);
    const [GameDetailsStatisticsAFile, setGameDetailsStatisticsAFile] = useState<File | undefined>(undefined);
    const [GameDetailsStatisticsBFile, setGameDetailsStatisticsBFile] = useState<File | undefined>(undefined);

    useEffect(() => {
        return () => {
            setGameDetailsWlgFile(undefined);
            setGameDetailsXmlFile(undefined);
            setGameDetailsQuartersFile(undefined);
            setGameDetailsStatisticsAFile(undefined);
            setGameDetailsStatisticsBFile(undefined);
        };
    }, []);


    interface UploadFormErrorsInterface {
        DetailsWLGFile: ErrorMessageInterface | null;
        DetailsXMLFile: ErrorMessageInterface | null;
        QuartersFile: ErrorMessageInterface | null;
        StatisticsAFile: ErrorMessageInterface | null;
        StatisticsBFile: ErrorMessageInterface | null;
        ApiResponse: ErrorMessageInterface | null;
    }

    const UploadFormErrorsDefaults: UploadFormErrorsInterface = {
        DetailsWLGFile: null,
        DetailsXMLFile: null,
        QuartersFile: null,
        StatisticsAFile: null,
        StatisticsBFile: null,
        ApiResponse: null,
    };

    const [UploadFormErrors, setUploadFormErrors] = useState(UploadFormErrorsDefaults);


    useEffect(() => {
        return () => {
            setUploadFormErrors(UploadFormErrorsDefaults);
        };
    }, []);


    const changeGameDetailsWlgFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const file = files ? files[0] : undefined;
        if (file && file.name.endsWith("Przebieg.wlg")) {
            setUploadFormErrors({ ...UploadFormErrors, DetailsWLGFile: null });
        }
        setGameDetailsWlgFile(file);
    };

    const changeGameDetailsXmlFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const file = files ? files[0] : undefined;
        if (file && file.name.endsWith("Przebieg.xml")) {
            setUploadFormErrors({ ...UploadFormErrors, DetailsXMLFile: null });
        }
        setGameDetailsXmlFile(file);
    };

    const changeGameDetailsQuartersFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const file = files ? files[0] : undefined;
        if (file && file.name.endsWith("Kwarty.xml")) {
            setUploadFormErrors({ ...UploadFormErrors, QuartersFile: null });
        }
        setGameDetailsQuartersFile(file);
    };

    const changeGameDetailsStatisticsAFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const file = files ? files[0] : undefined;
        if (file && file.name.endsWith("StatystykiA.xml")) {
            setUploadFormErrors({ ...UploadFormErrors, StatisticsAFile: null });
        }
        setGameDetailsStatisticsAFile(file);
    };

    const changeGameDetailsStatisticsBFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const file = files ? files[0] : undefined;
        if (file && file.name.endsWith("StatystykiB.xml")) {
            setUploadFormErrors({ ...UploadFormErrors, StatisticsBFile: null });
        }
        setGameDetailsStatisticsBFile(file);
    };


    if (!GameDetails) return null;


    const {
        league_game_id: gameID, league_game_status: gameStatus
    } = GameDetails;


    if (gameStatus === "FT" || gameStatus === "AOT") {
        return (
            <MessageComponent messageText={`Mecz zakończony - brak mozliwości wgrania danych.`} />
        );
    }


    const uploadGameDetailsFormSubmitHandler = async () => {

        let error = false;

        const FormErrors: UploadFormErrorsInterface = UploadFormErrorsDefaults;

        if (!GameDetailsWlgFile || !GameDetailsWlgFile.name.endsWith("Przebieg.wlg")) {
            FormErrors.DetailsWLGFile = { errorMessage: "Nie wgrano poprawnego pliku", errorCode: 404 };
            error = true;
        }
        if (!GameDetailsXmlFile || !GameDetailsXmlFile.name.endsWith("Przebieg.xml")) {
            FormErrors.DetailsXMLFile = { errorMessage: "Nie wgrano poprawnego pliku", errorCode: 404 };
            error = true;
        }
        if (!GameDetailsQuartersFile || !GameDetailsQuartersFile.name.endsWith("Kwarty.xml")) {
            FormErrors.QuartersFile = { errorMessage: "Nie wgrano poprawnego pliku", errorCode: 404 };
            error = true;
        }
        if (!GameDetailsStatisticsAFile || !GameDetailsStatisticsAFile.name.endsWith("StatystykiA.xml")) {
            FormErrors.StatisticsAFile = { errorMessage: "Nie wgrano poprawnego pliku", errorCode: 404 };
            error = true;
        }
        if (!GameDetailsStatisticsBFile || !GameDetailsStatisticsBFile.name.endsWith("StatystykiB.xml")) {
            FormErrors.StatisticsBFile = { errorMessage: "Nie wgrano poprawnego pliku", errorCode: 404 };
            error = true;
        }

        setUploadFormErrors({ ...FormErrors });

        if (error) {
            if (formRef?.current) {
                scrollToDomElement(formRef.current);
            }
            return;
        }

        uploadGameDetailsRequestHandler();

    };


    const uploadGameDetailsRequestHandler = async () => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const URL = `${API_URL}/leagues/games/${gameID}/upload-league-game-data/`;

        const formData = new FormData();

        formData.append("league_game_id", gameID.toString());

        if (GameDetailsWlgFile) {
            formData.append("game_details_wlg_file", GameDetailsWlgFile, GameDetailsWlgFile?.name);
        }

        if (GameDetailsXmlFile) {
            formData.append("game_details_xml_file", GameDetailsXmlFile, GameDetailsXmlFile?.name);
        }

        if (GameDetailsQuartersFile) {
            formData.append("game_quarters_xml_file", GameDetailsQuartersFile, GameDetailsQuartersFile?.name);
        }

        if (GameDetailsStatisticsAFile) {
            formData.append("game_statistics_a_xml_file", GameDetailsStatisticsAFile, GameDetailsStatisticsAFile?.name);
        }

        if (GameDetailsStatisticsBFile) {
            formData.append("game_statistics_b_xml_file", GameDetailsStatisticsBFile, GameDetailsStatisticsBFile?.name);
        }

        try {

            const response = await _restApiRequest(
                URL, formData, "POST"
            );

            if (response.ok) {
                // const responseContent = await response.json();
                updateGameDetailsTriggerHandler(true);
            } else {
                console.log(await response.text())
                setUploadFormErrors({ ...UploadFormErrors, ApiResponse: { errorCode: 400, errorMessage: "Wystąpił błąd podczas wgrywania danych" } })
            }

        } catch (e) {
            setUploadFormErrors({ ...UploadFormErrors, ApiResponse: { errorCode: 500, errorMessage: "Wystąpił błąd podczas wgrywania danych" } })
        }

        if (formRef?.current) {
            scrollToDomElement(formRef.current);
        }

    }


    return (
        <Col ref={formRef} xs={12} lg={{ span: 6, offset: 3 }} className={"game-details-admin__uploader"}>
            <Row className={"game-details-admin__uploader__content"}>

                <Col xs={12} className={"game-details-admin__uploader__header"}>
                    <span>Wgraj dane meczu</span>
                </Col>

                {UploadFormErrors.ApiResponse &&
                <ErrorMessageComponent messageText={UploadFormErrors.ApiResponse.errorMessage} />
                }

                <Col xs={12} className={"game-details-admin__uploader__form"}>
                    <Row className={"game-details-admin__uploader__form__content"}>

                        <Col xs={12} className={"game-details-admin__uploader__form__field"}>
                            <Form.Group as={Row} controlId={""}>
                                <Form.Label>{`Wgraj plik "Przebieg.wlg"`}</Form.Label>
                                <Form.Control
                                    type={"file"}
                                    value={""}
                                    onChange={changeGameDetailsWlgFile}
                                    accept={".wlg"}
                                />
                                <span>{GameDetailsWlgFile?.name}</span>
                                {UploadFormErrors.DetailsWLGFile &&
                                <Form.Label className={"error"}>{UploadFormErrors.DetailsWLGFile.errorMessage}</Form.Label>
                                }
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={"game-details-admin__uploader__form__field"}>
                            <Form.Group as={Row} controlId={""}>
                                <Form.Label>{`Wgraj plik "Przebieg.xml"`}</Form.Label>
                                <Form.Control
                                    type={"file"}
                                    onChange={changeGameDetailsXmlFile}
                                    accept={".xml"}
                                />
                                <span>{GameDetailsXmlFile?.name}</span>
                                {UploadFormErrors.DetailsXMLFile &&
				                    <Form.Label className={"error"}>{UploadFormErrors.DetailsXMLFile.errorMessage}</Form.Label>
                                }
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={"game-details-admin__uploader__form__field"}>
                            <Form.Group as={Row} controlId={""}>
                                <Form.Label>{`Wgraj plik "Kwarty.xml"`}</Form.Label>
                                <Form.Control
                                    type={"file"}
                                    onChange={changeGameDetailsQuartersFile}
                                    accept={".xml"}
                                />
                                <span>{GameDetailsQuartersFile?.name}</span>
                                {UploadFormErrors.QuartersFile &&
				                    <Form.Label className={"error"}>{UploadFormErrors.QuartersFile.errorMessage}</Form.Label>
                                }
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={"game-details-admin__uploader__form__field"}>
                            <Form.Group as={Row} controlId={""}>
                                <Form.Label>{`Wgraj plik "Statystyki A.xml"`}</Form.Label>
                                <Form.Control
                                    type={"file"}
                                    onChange={changeGameDetailsStatisticsAFile}
                                    accept={".xml"}
                                />
                                <span>{GameDetailsStatisticsAFile?.name}</span>
                                {UploadFormErrors.StatisticsAFile &&
				                    <Form.Label className={"error"}>{UploadFormErrors.StatisticsAFile.errorMessage}</Form.Label>
                                }
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={"game-details-admin__uploader__form__field"}>
                            <Form.Group as={Row} controlId={""}>
                                <Form.Label>{`Wgraj plik "Statystyki B.xml"`}</Form.Label>
                                <Form.Control
                                    type={"file"}
                                    onChange={changeGameDetailsStatisticsBFile}
                                    accept={".xml"}
                                />
                                <span>{GameDetailsStatisticsBFile?.name}</span>
                                {UploadFormErrors.StatisticsBFile &&
				                    <Form.Label className={"error"}>{UploadFormErrors.StatisticsBFile.errorMessage}</Form.Label>
                                }
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={"game-details-admin__uploader__form__button"}>
                            <Row className={"game-details-admin__uploader__form__button__content"}>
                                <Button onClick={uploadGameDetailsFormSubmitHandler}>
                                    <span>Wgraj dane i ustaw mecz jako zakończony</span>
                                </Button>
                            </Row>
                        </Col>

                    </Row>
                </Col>

            </Row>
        </Col>
    );


};


export default GameDetailsAdminDataUploaderComponent;

import React from "react";


import { PartnerInterface } from "@Interfaces/Api";


export interface HeadSponsorComponentProps {
    Sponsor: PartnerInterface;
    bannerLocation: "LEFT" | "RIGHT";
}

export const HeadSponsorComponent: React.FC<HeadSponsorComponentProps> = ({
    Sponsor, bannerLocation
}) : JSX.Element | null => {

    if (!Sponsor) {
        return null;
    }

    const {
        sponsor_name: sponsorName, sponsor_website: sponsorWWW, sponsor_logo: sponsorLogo
    } = Sponsor;

    if (!sponsorLogo) {
        return null;
    }

    return (
        <div className={`plka-advertising-banner ${bannerLocation.toLowerCase()}-banner`}>
            <a href={sponsorWWW || "/"} rel={"noreferrer noopener"} title={sponsorName} target={"_blank"} onClick={(e) => sponsorWWW ? undefined : e.preventDefault()}>
                <img src={sponsorLogo} alt={sponsorName} />
            </a>
        </div>
    );

};


export default HeadSponsorComponent;

import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Container} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import DefaultPageHeaderComponent from "@Components/Elements/Content/Page/Header/Default";

import DefaultMessageComponent from "@Components/Elements/Messages/Message/_Default";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import TeamsAndPlayersPageContentComponent from "@Components/Views/Pages/Games/Teams/List/Content";


import "./index.scss";


const TEXTS: any = {
	PL: {
		page_header_text: "Drużyny / Zawodnicy",
		no_leagues_message_text: "Brak lig"
	},
	EN: {
		page_header_text: "Teams / Players",
		no_leagues_message_text: "No Leagues"
	}
};


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";
import MessageComponent from "@MessagesElements/Content";


export interface TeamsAndPlayersPageComponentProps {}


interface SeasonLeagueInterface {
	leagueID: number;
	leagueName: string;
	leagueShortName?: string;
	leagueActiveStatus: boolean;
	leagueOrderNr?: number;
}

interface LeaguesSeasonInterface {
	[key: number]: {
		seasonID: number;
		seasonName: string | null;
		seasonActiveStatus: boolean;
		seasonLeagues: {
			[key: string]: SeasonLeagueInterface
		}
	}
}


export const TeamsAndPlayersPageComponent: React.FC<TeamsAndPlayersPageComponentProps> = () : JSX.Element | null => {


	const activeLanguage = UseLanguage();

	const {
		page_header_text: PAGE_HEADER_TEXT,
		no_leagues_message_text: NO_LEAGUES_MESSAGE_TEXT,
	} = TEXTS[activeLanguage];


	const LEAGUES_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.LEAGUES.LEAGUES.MAIN_URL;

	const [leaguesList, setLeaguesList] = useState<LeagueInterface[] | null>(null);
	const [leaguesListError, setLeaguesListError] = useState<ErrorMessageInterface | null>(null);

	useEffect(() => {

		if (!LEAGUES_API_URL) return;

		const getLeaguesList = async () => {

			try {

				const response = await _restApiRequest(`${LEAGUES_API_URL}/?all_results=true`);

				if (response.ok) {
					const responseContent = await response.json();
					setLeaguesList(responseContent);
				} else {
					setLeaguesListError({errorCode: 500, errorMessage: ""});
				}

			} catch (e) {
				setLeaguesListError({errorCode: 500, errorMessage: ""});
			}

		};

		getLeaguesList();

	}, []);


	useEffect(() => {
		return () => {
			setLeaguesList(null);
			setLeaguesListError(null);
		};
	}, []);


	if (!!leaguesListError) return <ErrorMessageComponent messageText={leaguesListError.errorMessage} />;


	if (!leaguesList) return <MainContentLoaderComponent />


	if (!leaguesList.length) return <MessageComponent messageText={NO_LEAGUES_MESSAGE_TEXT} cssClasses={"padding-15 margin-top-15"} />;


	const LeaguesBySeasons: LeaguesSeasonInterface = {};

	for (let League of leaguesList) {

		const {league_season_data: LeagueSeason} = League;

		if (!LeagueSeason) continue;

		const {
			league_season_id: seasonID, league_season_name: seasonName, league_season_active_status: seasonActiveStatus
		} = LeagueSeason;

		if (!LeaguesBySeasons[seasonID]) {
			LeaguesBySeasons[seasonID] = {seasonID, seasonName, seasonActiveStatus, seasonLeagues: {}};
		}

		const {
			league_id: leagueID,
			league_name: leagueName, league_short_name: leagueShortName,
			league_active_status: leagueActiveStatus, league_order_nr: leagueOrderNr
		} = League;

		LeaguesBySeasons[seasonID].seasonLeagues[leagueID] = {
			leagueID, leagueName, leagueShortName, leagueActiveStatus, leagueOrderNr
		};

	}


	return (
		<Container fluid={true} id={"teams-and-players-page-component"} className={"teams-and-players-page page-container-component"}>

			<DefaultPageHeaderComponent
				headerText={PAGE_HEADER_TEXT}
			/>

			<TeamsAndPlayersPageContentComponent
				leaguesBySeasons={LeaguesBySeasons}
			/>

		</Container>
	);

};


export default TeamsAndPlayersPageComponent;

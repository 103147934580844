import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import { IMAGE_PLACEHOLDER } from "@Images";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


import "./index.scss";



type GameSummaryTeamLogoProps = {
    GameTeam: LeagueTeamInterface;
    gameTeamType: "HOME" | "AWAY";
};


export const GameSummaryTeamLogoComponent: React.FC<GameSummaryTeamLogoProps> = ({
    GameTeam, gameTeamType
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");

    const {
        routeURL: TEAMS_ROUTE_URL, routeTitle: TEAMS_ROUTE_TITLE
    } = TeamsRoute;


    if (!GameTeam) return null;


    const {
        team_name: teamName, team_slug: teamSlug, team_logo: teamLogo
    } = GameTeam;


    let CSS_CLASSES = "game-result-team-logo game-result-team-logo-component";

    if (gameTeamType === "HOME") CSS_CLASSES += " home-team-logo";
    if (gameTeamType === "AWAY") CSS_CLASSES += " away-team-logo";


    return (
        <Col xs={2} className={CSS_CLASSES}>

            <NavLink to={`/${TEAMS_ROUTE_URL}/${teamSlug}`} title={TEAMS_ROUTE_TITLE}>
                <img src={teamLogo || IMAGE_PLACEHOLDER} alt={teamName} />
            </NavLink>

        </Col>
    );

};


export default GameSummaryTeamLogoComponent;

import { UseGlobalData } from "@ApplicationStates";

import { API_ROOT_URL } from "@Services/API/Endpoints";


export const UseApplicationLogo = () : string | null => {

    const Globals = UseGlobalData();

    if (Globals && Globals.settings) {

        const { league_logo: LeagueLogo } = Globals.settings;

        if (LeagueLogo) {

            const { image: leagueLogoURL } = LeagueLogo;

            if (leagueLogoURL) {
                return API_ROOT_URL + leagueLogoURL;
            }

        }

    }

    return null;

};


export default UseApplicationLogo;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import {
    LeagueSeasonInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamLeaguesListLeagueSeasonComponentProps {
    LeagueSeasonData?: LeagueSeasonInterface | null;
}


export const TeamLeaguesListLeagueSeasonComponent: React.FC<TeamLeaguesListLeagueSeasonComponentProps> = ({
    LeagueSeasonData
}) : JSX.Element | null => {


    const ACTIVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("ACTIVE_MASC_WORD_TEXT");
    const INACTIVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("INACTIVE_MASC_WORD_TEXT");


    if (!LeagueSeasonData) {
        return null;
    }


    const {
        league_season_name: leagueSeasonName,
        league_season_active_status: leagueSeasonActiveStatus
    } = LeagueSeasonData;


    return (
        <Col xs={"auto"} className={"player-profile__captain-zone__team__leagues__list__league__information__league-season"}>
            <span className={"player-profile__captain-zone__team__leagues__list__league__information__league-season__season-label"}>Sezon: </span>
            <span className={"player-profile__captain-zone__team__leagues__list__league__information__league-season__season-name"}>
                {leagueSeasonName}
            </span>
            <span className={"player-profile__captain-zone__team__leagues__list__league__information__league-season__active-status" + (leagueSeasonActiveStatus ? " active" : " inactive")}>
                ({leagueSeasonActiveStatus ? ACTIVE_WORD_TEXT : INACTIVE_WORD_TEXT})
            </span>
        </Col>
    );

};


export default TeamLeaguesListLeagueSeasonComponent;

import { ApiGameSummaryQuarterType } from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter";

import TeamsSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams";
import TeamSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams/Team";


import LeadersScoresInterface from "@GameResultPage/_Utitlities/Summary/Leaders/Types";
import LeadersInformationPlayerScoresInterface from "@GameResultPage/_Utitlities/Summary/Leaders/Types/Scores";


import LeadersInformationPlayerScoresDefaults from "@GameResultPage/_Utitlities/Summary/Leaders/Defaults";


import {
    countEval, countPercentageThrowsEffectivity, countTotalPoints
} from "@Services/Utilities/Games";


export const prepareLeadersPlayersScores = (
    SelectedQuarters: ApiGameSummaryQuarterType[],
    TeamsSummaryData: TeamsSummaryInterface
) : LeadersScoresInterface | null => {


    if (!SelectedQuarters || !TeamsSummaryData) return null;


    const TeamsLeadersScores: LeadersScoresInterface = {};


    for (let [teamID, Team] of Object.entries(TeamsSummaryData)) {

        if (!teamID || ! Team) return null;

        const TeamLeaders = prepareTeamLeadersPlayersScores(Team, SelectedQuarters.length);

        if (!TeamLeaders) return null;

        TeamsLeadersScores[+teamID] = TeamLeaders;

    }


    return TeamsLeadersScores;

};


const prepareTeamLeadersPlayersScores = (
    Team: TeamSummaryInterface,
    selectedQuartersNumber: number
) : LeadersInformationPlayerScoresInterface | null => {


    if (!Team) return null;


    const TeamScores = Object.assign({}, LeadersInformationPlayerScoresDefaults);


    const { teamPlayers: TeamPlayers } = Team;


    for (let Player of Object.values(TeamPlayers)) {

        const {
            ID: playerID,
            PT1: player1PtAC, PT1A: player1PtNAC, PT2: player2PtAC, PT2A: player2PtNAC, PT3: player3PtAC, PT3A: player3PtNAC,
            O_REB: playerOFfRebounds, D_REB: playerDefRebounds,
            AST: playerAssists, BLK: playerBlocks, STL: playerSteals, TO: playerTurnovers,
            SECONDS: playerSeconds
        } = Player;

        const playerTotalRebounds = playerOFfRebounds + playerDefRebounds;

        const playerTotalPoints = countTotalPoints(player1PtAC, player2PtAC, player3PtAC);

        const player1PtAll = player1PtAC + player1PtNAC;
        const player2PtAll = player2PtAC + player2PtNAC;
        const player3PtAll = player3PtAC + player3PtNAC;

        const playerAccGameThrows = player2PtAC + player3PtAC;
        const playerAllGameThrows = player2PtAll + player3PtAll;

        const player1PTEffectivity = countPercentageThrowsEffectivity(player1PtAC, player1PtAll);
        const player2PTEffectivity = countPercentageThrowsEffectivity(player2PtAC, player2PtAll);
        const player3PTEffectivity = countPercentageThrowsEffectivity(player3PtAC, player3PtAll);

        const playerGameThrowsEffectivity = countPercentageThrowsEffectivity(playerAccGameThrows, playerAllGameThrows);

        if (selectedQuartersNumber >= 4) {

            const playerEvalValue = countEval(
                playerTotalPoints, playerTotalRebounds,
                playerAssists, playerSteals, playerBlocks, playerTurnovers,
                player2PtAll, player3PtAll, player2PtAC, player3PtAC,
                player1PtAll, player1PtAC
            );

            if (playerEvalValue && (!TeamScores.playerOfTheGame || playerEvalValue > TeamScores.playerOfTheGame?.score)) {
                TeamScores.playerOfTheGame = { id: playerID, score: playerEvalValue };
            }

            if (playerSeconds && (!TeamScores.greatestPlayTime || playerSeconds > TeamScores.greatestPlayTime?.score)) {
                TeamScores.greatestPlayTime = { id: playerID, score: playerSeconds };
            }

        }

        if (playerTotalPoints && (!TeamScores.mostPoints || playerTotalPoints > TeamScores.mostPoints?.score)) {
            TeamScores.mostPoints = { id: playerID, score: playerTotalPoints };
        }

        if (playerGameThrowsEffectivity && (!TeamScores.gameThrowsEffectivity || playerGameThrowsEffectivity > TeamScores.gameThrowsEffectivity?.score)) {
            TeamScores.gameThrowsEffectivity = { id: playerID, score: playerGameThrowsEffectivity };
        }

        if (player3PTEffectivity && (!TeamScores.pt3ThrowsEffectivity || player3PTEffectivity > TeamScores.pt3ThrowsEffectivity?.score)) {
            TeamScores.pt3ThrowsEffectivity = { id: playerID, score: player3PTEffectivity };
        }

        if (player2PTEffectivity && (!TeamScores.pt2ThrowsEffectivity || player2PTEffectivity && player2PTEffectivity > TeamScores.pt2ThrowsEffectivity?.score)) {
            TeamScores.pt2ThrowsEffectivity = { id: playerID, score: player2PTEffectivity };
        }

        if (player1PTEffectivity && (!TeamScores.pt1ThrowsEffectivity || player1PTEffectivity > TeamScores.pt1ThrowsEffectivity?.score)) {
            TeamScores.pt1ThrowsEffectivity = { id: playerID, score: player1PTEffectivity };
        }

        if (playerTotalRebounds && (!TeamScores.mostRebounds || playerTotalRebounds > TeamScores.mostRebounds?.score)) {
            TeamScores.mostRebounds = { id: playerID, score: playerTotalRebounds };
        }

        if (playerOFfRebounds && (!TeamScores.mostOffensiveRebounds || playerOFfRebounds > TeamScores.mostOffensiveRebounds?.score)) {
            TeamScores.mostOffensiveRebounds = { id: playerID, score: playerOFfRebounds };
        }

        if (playerDefRebounds && (!TeamScores.mostDefensiveRebounds || playerDefRebounds > TeamScores.mostDefensiveRebounds?.score)) {
            TeamScores.mostDefensiveRebounds = { id: playerID, score: playerDefRebounds };
        }

        if (playerAssists && (!TeamScores.mostAssists || playerAssists > TeamScores.mostAssists?.score)) {
            TeamScores.mostAssists = { id: playerID, score: playerAssists };
        }

        if (playerBlocks && (!TeamScores.mostBlocks || playerBlocks > TeamScores.mostBlocks?.score)) {
            TeamScores.mostBlocks = { id: playerID, score: playerBlocks };
        }

        if (playerSteals && (!TeamScores.mostSteals || playerSteals > TeamScores.mostSteals?.score)) {
            TeamScores.mostSteals = { id: playerID, score: playerSteals };
        }

        if (playerTurnovers && (!TeamScores.mostTurnovers || playerTurnovers > TeamScores.mostTurnovers?.score)) {
            TeamScores.mostTurnovers = { id: playerID, score: playerTurnovers };
        }

    }


    return TeamScores;

};


export default prepareLeadersPlayersScores;

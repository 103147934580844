import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {Col, Row} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        season_name_text: "Sezon",
        team_name_text: "Drużyna",
        statistics_games_number_label: "M",
        statistics_games_number_title: "Ilość Meczów",
        statistics_first5_number_label: "S5",
        statistics_first5_number_title: "Ilość wyjść w pierwszej 5",
        statistics_minutes_number_label: "Min",
        statistics_minutes_number_title: "Ilość rzegranych minut",
        statistics_total_points_label: "Pkt",
        statistics_total_points_title: "Ilość zdobytych punktów",
        statistics_accurate_throws_label: "C",
        statistics_accurate_throws_title: "Celne",
        statistics_all_throws_label: "W",
        statistics_all_throws_title: "Wykonane",
        statistics_2_points_label: "Za 2",
        statistics_2_points_title: "Ilość rzutów za 2",
        statistics_3_points_label: "Za 3",
        statistics_3_points_title: "Ilość rzutów za 3",
        statistics_game_points_label: "Z gry",
        statistics_game_points_title: "Ilość rzutów z gry",
        statistics_ft_points_label: "Za 1",
        statistics_ft_points_title: "Ilość rzutów wolnych",
        statistics_rebounds_number_label: "Zb",
        statistics_rebounds_number_title: "Ilość zbiórek",
        statistics_offensive_rebounds_number_label: "A",
        statistics_offensive_rebounds_number_title: "Ilość zbiórek w Ataku",
        statistics_defensive_rebounds_number_label: "O",
        statistics_defensive_rebounds_number_title: "Ilość zbiórek w Obronie",
        statistics_total_rebounds_number_label: "S",
        statistics_total_rebounds_number_title: "Ilość zbiórek łącznie",
        statistics_assists_number_label: "A",
        statistics_assists_number_title: "Ilość Asyst",
        statistics_blocks_number_label: "B",
        statistics_blocks_number_title: "Ilość Bloków",
        statistics_steals_number_label: "P",
        statistics_steals_number_title: "Ilość Przechwytów",
        statistics_turnovers_number_label: "S",
        statistics_turnovers_number_title: "Ilość Strat",
        statistics_fouls_number_label: "F",
        statistics_fouls_number_title: "Ilość Fauli",
        statistics_effectivity_value_label: "+ / -",
        statistics_effectivity_value_title: "Efektywność",
        statistics_eval_value_label: "Eval",
        statistics_eval_value_title: "Ewaluacja"
    },
    EN: {
        season_name_text: "Season",
        team_name_text: "Team",
        statistics_games_number_label: "G",
        statistics_games_number_title: "Played Games",
        statistics_first5_number_label: "S5",
        statistics_first5_number_title: "First 5",
        statistics_minutes_number_label: "M",
        statistics_minutes_number_title: "Played Minutes",
        statistics_total_points_label: "Pts",
        statistics_total_points_title: "Player Points",
        statistics_accurate_throws_label: "AC",
        statistics_accurate_throws_title: "Accurate Throws",
        statistics_all_throws_label: "ALL",
        statistics_all_throws_title: "All Throws",
        statistics_2_points_label: "2P",
        statistics_2_points_title: "2 Point Throws",
        statistics_3_points_label: "3P",
        statistics_3_points_title: "3 Point Throws",
        statistics_game_points_label: "Game",
        statistics_game_points_title: "Game Throws",
        statistics_ft_points_label: "1P",
        statistics_ft_points_title: "1 Point Throws",
        statistics_rebounds_number_label: "R",
        statistics_rebounds_number_title: "Rebounds",
        statistics_offensive_rebounds_number_label: "OR",
        statistics_offensive_rebounds_number_title: "Offensive Rebounds",
        statistics_defensive_rebounds_number_label: "DR",
        statistics_defensive_rebounds_number_title: "Defensive Rebounds",
        statistics_total_rebounds_number_label: "TR",
        statistics_total_rebounds_number_title: "Total Rebounds",
        statistics_assists_number_label: "A",
        statistics_assists_number_title: "Assists",
        statistics_blocks_number_label: "B",
        statistics_blocks_number_title: "Blocks",
        statistics_steals_number_label: "S",
        statistics_steals_number_title: "Steals",
        statistics_turnovers_number_label: "T",
        statistics_turnovers_number_title: "Turnovers",
        statistics_fouls_number_label: "F",
        statistics_fouls_number_title: "Fouls",
        statistics_effectivity_value_label: "+/-",
        statistics_effectivity_value_title: "Effectivity",
        statistics_eval_value_label: "Eval",
        statistics_eval_value_title: "Evaluation"
    }
};


interface StatisticsTableHeadComponentProps {
    tableType: "MEDIUM" | "SUMMARY";
}


export const StatisticsTableHeadComponent: React.FC<StatisticsTableHeadComponentProps> = ({
    tableType
}) : JSX.Element | null => {


    const {activeLanguage} = useTypedSelector((state) => state.loc);

    const {
        season_name_text: SEASON_NAME_TEXT, team_name_text: TEAM_NAME_TEXT,
        statistics_games_number_label: GN_LABEL, statistics_games_number_title: GN_TITLE,
        statistics_first5_number_label: S5_LABEL, statistics_first5_number_title: S5_TITLE,
        statistics_minutes_number_label: MN_LABEL, statistics_minutes_number_title: MN_TITLE,
        statistics_total_points_label: PT_LABEL, statistics_total_points_title: PT_TITLE,
        statistics_accurate_throws_label: ACCURATE_THROWS_LABEL, statistics_accurate_throws_title: ACCURATE_THROWS_TITLE,
        statistics_all_throws_label: ALL_THROWS_LABEL, statistics_all_throws_title: ALL_THROWS_TITLE,
        statistics_2_points_label: P2_LABEL, statistics_2_points_title: P2_TITLE,
        statistics_3_points_label: P3_LABEL, statistics_3_points_title: P3_TITLE,
        statistics_game_points_label: GAME_P_LABEL, statistics_game_points_title: GAME_P_TITLE,
        statistics_ft_points_label: FT_LABEL, statistics_ft_points_title: FT_TITLE,
        statistics_rebounds_number_label: RN_LABEL, statistics_rebounds_number_title: RN_TITLE,
        statistics_offensive_rebounds_number_label: ORN_LABEL, statistics_offensive_rebounds_number_title: ORN_TITLE,
        statistics_defensive_rebounds_number_label: DRN_LABEL, statistics_defensive_rebounds_number_title: DRN_TITLE,
        statistics_total_rebounds_number_label: TRN_LABEL, statistics_total_rebounds_number_title: TRN_TITLE,
        statistics_assists_number_label: AST_LABEL, statistics_assists_number_title: AST_TITLE,
        statistics_blocks_number_label: BLK_LABEL, statistics_blocks_number_title: BLK_TITLE,
        statistics_steals_number_label: STL_LABEL, statistics_steals_number_title: STL_TITLE,
        statistics_turnovers_number_label: TO_LABEL, statistics_turnovers_number_title: TO_TITLE,
        statistics_fouls_number_label: FN_LABEL, statistics_fouls_number_title: FN_TITLE,
        statistics_effectivity_value_label: EFF_LABEL, statistics_effectivity_value_title: EFF_TITLE,
        statistics_eval_value_label: EVAL_LABEL, statistics_eval_value_title: EVAL_TITLE
    } = TEXTS[activeLanguage];


    if (!tableType) return null;


    return (
        <Col xs={12} className={"statistics-table-head statistics-table-row statistics-table-head-row statistics-table-head-component"}>
            <Row className={"statistics-table-head-content statistics-table-row-content"}>

                <Col xs={"auto"} className={"table-head-cell statistics-table-cell season-name-data"}>
                    <span>{SEASON_NAME_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"table-head-cell statistics-table-cell team-name-data"}>
                    <span>{TEAM_NAME_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={GN_TITLE} className={"table-head-cell statistics-table-cell games-number-data"}>
                    <span>{GN_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={S5_TITLE} className={"table-head-cell statistics-table-cell start-5-data"}>
                    <span>{S5_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={MN_TITLE} className={"table-head-cell statistics-table-cell minutes-number-data"}>
                    <span>{MN_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={PT_TITLE} className={"table-head-cell statistics-table-cell points-number-data"}>
                    <span>{PT_LABEL}</span>
                </Col>

	            <Col xs={1} className={"table-head-cell statistics-table-cell pt2-number-data"}>
		            <Row className={"statistics-table-cell-content"}>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={P2_TITLE}>
                            <span>{P2_LABEL}</span>
                        </Col>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={`${ACCURATE_THROWS_TITLE}/${ALL_THROWS_TITLE}`}>
                            <span>{ACCURATE_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{ALL_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
		            </Row>
	            </Col>

	            <Col xs={1} className={"table-head-cell statistics-table-cell pt3-number-data"}>
		            <Row className={"statistics-table-cell-content"}>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={P3_TITLE}>
                            <span>{P3_LABEL}</span>
                        </Col>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={`${ACCURATE_THROWS_TITLE}/${ALL_THROWS_TITLE}`}>
                            <span>{ACCURATE_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{ALL_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
		            </Row>
	            </Col>


	            <Col xs={1} className={"table-head-cell statistics-table-cell pt-game-number-data"}>
		            <Row className={"statistics-table-cell-content"}>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={GAME_P_TITLE}>
                            <span>{GAME_P_LABEL}</span>
                        </Col>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={`${ACCURATE_THROWS_TITLE}/${ALL_THROWS_TITLE}`}>
                            <span>{ACCURATE_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{ALL_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
		            </Row>
	            </Col>

	            <Col xs={1} className={"table-head-cell statistics-table-cell ft-number-data"}>
		            <Row className={"statistics-table-cell-content"}>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={FT_TITLE}>
                            <span>{FT_LABEL}</span>
                        </Col>
			            <Col xs={12} className={"statistics-table-cell-subtitle"} title={`${ACCURATE_THROWS_TITLE}/${ALL_THROWS_TITLE}`}>
                            <span>{ACCURATE_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{ALL_THROWS_LABEL}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
		            </Row>
	            </Col>


                <Col xs={1} className={"table-head-cell statistics-table-cell rebounds-number-data"}>
                    <Row className={"statistics-table-cell-content"}>
                        <Col xs={12} className={"statistics-table-cell-subtitle"} title={RN_TITLE}>
                            <span>{RN_LABEL}</span>
                        </Col>
                        <Col xs={12} className={"statistics-table-cell-subtitle"}>
                            <span title={DRN_TITLE}>{DRN_LABEL}</span>
                            <span className={"separator"}></span>
                            <span title={ORN_TITLE}>{ORN_LABEL}</span>
                            <span className={"separator"}></span>
                            <span title={TRN_TITLE}>{TRN_LABEL}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={"auto"} title={AST_TITLE} className={"table-head-cell statistics-table-cell assists-number-data"}>
                    <span>{AST_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={BLK_TITLE} className={"table-head-cell statistics-table-cell blocks-number-data"}>
                    <span>{BLK_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={STL_TITLE} className={"table-head-cell statistics-table-cell steals-number-data"}>
                    <span>{STL_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={TO_TITLE} className={"table-head-cell statistics-table-cell turnovers-number-value"}>
                    <span>{TO_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={FN_TITLE} className={"table-head-cell statistics-table-cell fouls-number-data"}>
                    <span>{FN_LABEL}</span>
                </Col>

                <Col xs={"auto"} title={EFF_TITLE} className={"table-head-cell statistics-table-cell effectivity-value-data"}>
                    <span>{EFF_LABEL}</span>
                </Col>

                <Col xs={1} title={EVAL_TITLE} className={"table-head-cell statistics-table-cell eval-value-data"}>
                    <span>{EVAL_LABEL}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default StatisticsTableHeadComponent;

import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


import LeaguesTablesComponent from "@Components/Views/Pages/Games/Leagues/Tables";


export interface GamesLeaguesComponentProps {}


export const GamesLeaguesComponent: React.FC<GamesLeaguesComponentProps> = () : JSX.Element | null => {


    return (
        <Switch>

            <Route path={"/tabele"} exact render={props =>
                <LeaguesTablesComponent {...props} />
            }
            />

            <Redirect to={`/tabele`} />

        </Switch>
    );

};


export default GamesLeaguesComponent;

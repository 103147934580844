import { UseLanguage } from "@States";


import TeamsDictionary , { TeamsDictionaryPhrasesSetType } from "@Dictionary/Pages/Leagues/Teams";


export const UseTeamsDictionaryPhrasesSet = (phraseName: TeamsDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return TeamsDictionary[ActiveLanguage][phraseName];

};


export default UseTeamsDictionaryPhrasesSet;

import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Row, Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import MessageComponent from "@Components/Elements/Messages/Content";


import BestScoresHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Header";
import BestScoresTableComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table";


const TEXTS: any = {
    PL: {
        message_no_player_games_text_text: "Brak rozegranych meczów",
        league_not_selected_error_text: "Nie wybrano Ligi"
    },
    EN: {
        message_no_player_games_text_text: "No pleayed games",
        league_not_selected_error_text: "League not selected"
    },
}


import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";


export interface PlayerDetailsBestScoresComponentProps {
    playerGames: SortedBySeasonGamesLeagueInterface[] | null;
}


export const PlayerDetailsBestScoresComponent: React.FC<PlayerDetailsBestScoresComponentProps> = ({
    playerGames
}) : JSX.Element| null => {


    const activeLanguage = UseLanguage();

    const {
        message_no_player_games_text_text: MESSAGE_NO_PLAYER_GAMES_TEXT,
        league_not_selected_error_text: LEAGUE_NOT_SELECTED_ERROR_TEXT
    } = TEXTS[activeLanguage];


    const [selectedLeagueID, setSelectedLeagueID] = useState<number>(0);

    useEffect(() => {
        if (!playerGames || playerGames.length < 1) return;
        setSelectedLeagueID(playerGames[0].gamesLeagueID);
    }, [playerGames]);

    useEffect(() => {
        return () => {
            setSelectedLeagueID(0);
        };
    }, []);


    if (!playerGames) return <MainContentLoaderComponent />


    if (!playerGames?.length) return <MessageComponent messageText={MESSAGE_NO_PLAYER_GAMES_TEXT} cssClasses={"white"} />;


    if (!selectedLeagueID) return <MessageComponent lg={{span: 8, offset: 2}} messageText={LEAGUE_NOT_SELECTED_ERROR_TEXT} cssClasses={"bordered white no-margin-top padding padding-15"} />;


    const SelectedLeague = playerGames.filter((league) => league.gamesLeagueID === selectedLeagueID)[0];


    const {gamesLeagueGames} = SelectedLeague;


    return (
        <Col xs={12} className={"player-details-best-scores player-details-best-scores-component"}>
            <Row className={"player-details-best-scores-content"}>

                <BestScoresHeaderComponent
                    selectedLeague={SelectedLeague}
                    selectedLeagueHandler={setSelectedLeagueID}
                    leaguesList={playerGames}
                />

                {!!gamesLeagueGames && !!SelectedLeague &&
	            <BestScoresTableComponent
		            playerTeams={gamesLeagueGames}
	            />
                }

            </Row>
        </Col>
    );

};


export default PlayerDetailsBestScoresComponent;


import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";
import NumberFormat from "react-number-format";


const TEXTS: any = {
    PL: {
        player_height_word_text: "Wzrost",
        player_weight_word_text: "Waga",
        player_age_word_text: "Wiek",
        player_nationality_word_text: "Obywatelstwo",
    },
    EN: {
        player_height_word_text: "Height",
        player_weight_word_text: "Weight",
        player_age_word_text: "Age",
        player_nationality_word_text: "Nationality",
    },
};


export interface PlayerInformationDescriptionComponentProps {
    playerHeight?: number | null;
    playerWeight?: number | null;
    playerAge?: number | null;
    playerNationality?: string | null;
}


export const PlayerInformationDescriptionComponent: React.FC<PlayerInformationDescriptionComponentProps> = ({
    playerHeight, playerWeight, playerAge, playerNationality
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        player_height_word_text: PLAYER_HEIGHT_WORD_TEXT, player_weight_word_text: PLAYER_WEIGHT_WORD_TEXT,
        player_age_word_text: PLAYER_AGE_WORD_TEXT, player_nationality_word_text: PLAYER_NATIONALITY_WORD_TEXT
    } = TEXTS[activeLanguage];


    return (
        <Col xs={6} lg={6} className={"player-information-description-component player-information-description"}>
            <Row className={"player-information-description-content"}>

                <Col xs={6} className={"player-information-component player-information player-description"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{PLAYER_HEIGHT_WORD_TEXT}</span>
                        <span className={"information-value"}>
                            {!!playerHeight && <span>{playerHeight} cm</span>
                            }
                        </span>
                    </Row>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{PLAYER_WEIGHT_WORD_TEXT}</span>
                        <span className={"information-value"}>
                            {!!playerWeight && <>
                            <NumberFormat value={playerWeight} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                            <span> kg</span>
                            </>}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} className={"player-information-component player-information player-description"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{PLAYER_AGE_WORD_TEXT}</span>
                        <span className={"information-value"}>
                            {!!playerAge && <span>{playerAge} lat</span>}
                        </span>
                    </Row>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{PLAYER_NATIONALITY_WORD_TEXT}</span>
                        <span className={"information-value"}>{playerNationality || ""}</span>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerInformationDescriptionComponent;

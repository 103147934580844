import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface QuarterEventTimeComponentProps {
    eventTime: string | null;
    cssClasses?: string | string[];
    quarterNumber?: number;
    quarterDuration: number;
    extraTimeDuration: number;
}


export const QuarterEventTimeComponent: React.FC<QuarterEventTimeComponentProps> = ({
    eventTime, cssClasses = "",
    quarterNumber = 1, quarterDuration = 10, extraTimeDuration = 3
}) => {

    let reversedEventTime = "";

    const baseDuration = quarterNumber > 4 ? extraTimeDuration : quarterDuration;

    if (eventTime) {

        const eventPreciseTime = eventTime.split(":");

        let eventMinute = parseInt(eventPreciseTime[0]);
        let eventSecond = parseInt(eventPreciseTime[1]);

        if (eventSecond === 0) {
            eventSecond = 60;
            eventMinute -= 1;
        }

        const reversedEventMinute = (baseDuration - 1) - eventMinute;
        const reversedEventSecond = 60 - eventSecond;

        reversedEventTime += reversedEventMinute < 10 ? `0${reversedEventMinute}` : reversedEventMinute;
        reversedEventTime += ":";
        reversedEventTime += reversedEventSecond < 10 ? `0${reversedEventSecond}` : reversedEventSecond;

        if (reversedEventMinute < 0) {
            reversedEventTime = "00:00";
        }

    }


    if (cssClasses && typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }

    return (
        <Col xs={4} lg={4} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__time" + (cssClasses ? ` ${cssClasses}` : "")}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__time__content"}>
                <span className={"event-time"}>{reversedEventTime}</span>
            </Row>
        </Col>
    );


};


export default QuarterEventTimeComponent;

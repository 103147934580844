import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import {
    DefaultPageHeaderComponent,
    PageContainerComponent,
    PageContentComponent
} from "@ContentElements";


import {
    LeagueTeamCoachInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamCrewComponentProps {
    TeamCoach: LeagueTeamCoachInterface;
}


export const TeamCrewComponent: React.FC<TeamCrewComponentProps> = ({
    TeamCoach
}) : JSX.Element | null  => {


    const TEAM_CREW_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TEAM_CREW_PHRASE_TEXT");
    const TEAM_COACH_WORD_TEXT = UseCommonDictionaryPhrasesSet("TEAM_COACH_WORD_TEXT");


    if (!TeamCoach) {
        return null;
    }


    const {
        team_coach_first_name: teamCoachFirstName,
        team_coach_last_name: teamCoachLastName,
    } = TeamCoach;


    return (
        <PageContainerComponent cssClasses={"team-details__team-crew"}>

            <DefaultPageHeaderComponent headerText={TEAM_CREW_PHRASE_TEXT} />

            <PageContentComponent cssClasses={"team-details__team-crew__content"}>

                <Col xs={12} className={"team-details__team-crew__team-crew-person team-details__team-crew__team-crew-person--team-coach"}>
                    <span className={"team-crew-person-label"}>{TEAM_COACH_WORD_TEXT}:</span>
                    <span className={"team-crew-person-name"}>{teamCoachFirstName} {teamCoachLastName}</span>
                </Col>

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default TeamCrewComponent;

import React from "react";


import {Col} from "react-bootstrap";


import GameSummaryTableHeaderComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table/Header";


interface GameSummaryTableDataComponentProps {
    tableTitle?: string;
    children?: React.ReactNode | React.ReactNode[] | null;
}


export const GameSummaryTableDataComponent: React.FC<GameSummaryTableDataComponentProps> = ({
    tableTitle = "",
    children
}) : JSX.Element | null => {


    if (!children) return null;


    return (
        <Col xs={12} className={"game-information-summary-table"}>

            <GameSummaryTableHeaderComponent tableTitle={tableTitle} />

            {children}

        </Col>
    );

};


export default GameSummaryTableDataComponent;

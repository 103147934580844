import React, { useState } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import {
    AiOutlineMinus, AiOutlinePlus
} from "react-icons/all";


import TeamPlayersAddPlayerFormComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayerAdd/PlayerForm";


import "./index.scss";


export interface TeamCaptainTeamPlayersAddComponentProps {
    teamID: number;
    updateTeamPlayersTrigger: (status: boolean) => void;
}


export const TeamCaptainTeamPlayersAddComponent: React.FC<TeamCaptainTeamPlayersAddComponentProps> = ({
    teamID, updateTeamPlayersTrigger
}) => {


    const PLAYER_ADD_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_ADD_PHRASE_TEXT");


    const [addPlayerActiveStatus, setAddPlayerActiveStatus] = useState(false);


    const playerAddStatusTrigger = () => {
        setAddPlayerActiveStatus(false);
        updateTeamPlayersTrigger(true);
    }


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__player-add"}>
            <Row className={"player-profile__captain-zone__team__players__player-add__content"}>

                <Col xs={12} className={"player-profile__captain-zone__team__players__player-add__form-display-switcher"}>
                    <Row className={"player-profile__captain-zone__team__players__player-add__form-display-switcher__content"}>
                        <span onClick={() => setAddPlayerActiveStatus(!addPlayerActiveStatus)} className={"player-profile__captain-zone__team__players__player-add__form-display-switcher__switcher"}>
                            <span className={"player-profile__captain-zone__team__players__player-add__form-display-switcher__switcher__text"}>{PLAYER_ADD_PHRASE_TEXT}</span>
                            <span className={"player-profile__captain-zone__team__players__player-add__form-display-switcher__switcher__icon"}>
                                {addPlayerActiveStatus ? <AiOutlineMinus /> : <AiOutlinePlus />}
                            </span>
                        </span>
                    </Row>
                </Col>

                {addPlayerActiveStatus &&
                <TeamPlayersAddPlayerFormComponent
	                teamID={teamID}
	                playerAddStatusTrigger={playerAddStatusTrigger}
                />
                }

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamPlayersAddComponent;

import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import LeagueGameEventType from "@ApiInterfaces/Leagues/Games/Game/Events/Event/EventType";
import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterThrowEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterThrowEventDataComponent: React.FC<QuarterThrowEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.THROW) {
        return null;
    }

    if (!Event.eventThrowingPlayerID) {
        return null;
    }


    let throwEventSubType: LeagueGameEventType | null = null;

    if (Event.eventThrowType === 1) {
        throwEventSubType = "1PTA";
    } else if (Event.eventThrowType === 2) {
        throwEventSubType = "2PTA";
    } else if (Event.eventThrowType === 3) {
        throwEventSubType = "3PTA";
    } else {
        return null;
    }


    const ThrowEvent = UseProgressEvent(throwEventSubType);

    if (!ThrowEvent) {
        return null;
    }


    const ThrowEventPlayer = GamePlayers[Event.eventThrowingPlayerID];

    if (!ThrowEventPlayer) {
        return null;
    }


    const {
        player_first_name: throwingPlayerFirstName,
        player_last_name: throwingPlayerLastName,
        player_nick_name: throwingPlayerNickName,
        player_slug: throwingPlayerSlug,
        player_pseudonymization_status: throwingPlayerPseudonymizationStatus
    } = ThrowEventPlayer;


    let throwingPlayerName = "";

    if (Event.eventThrowingPlayerNumber) {
        throwingPlayerName += `${Event.eventThrowingPlayerNumber} `;
    }

    if (throwingPlayerPseudonymizationStatus) {
        throwingPlayerName += throwingPlayerNickName;
    } else {
        throwingPlayerName += `${throwingPlayerFirstName[0]}. ${throwingPlayerLastName}`
    }


    const {
        eventName: { singular: throwEventName }
    } = ThrowEvent;


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--throw-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} style={{ order: homeTeamID === Event.eventThrowingPlayerTeamID ? 1 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        <NavLink to={`/${PlayersRoute.routeURL}/${throwingPlayerSlug}`} title={PlayersRoute.routeTitle} className={"event-player-name"}>
                            <span className={"event-player-name blocking-player"}>{throwingPlayerName}</span>
                        </NavLink>
                        <span className={"event-label"}>{throwEventName}</span>
                        <span className={"event-number"}>({Event.eventThrowingPlayerAccurateThrowsNumber}/{Event.eventThrowingPlayerThrowsNumber})</span>
                        {/*{Event?.eventDescription?.text && <span className={"event-description"}>{Event?.eventDescription?.text}</span>}*/}
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={[homeTeamID === Event.eventThrowingPlayerTeamID ? "" : "offset-4", "order-2"]}
                />

            </Row>
        </Col>
    );

};


export default QuarterThrowEventDataComponent;

import React from "react";


import { Col } from "react-bootstrap";


export interface SchedulesAdminLeagueStatusComponentProps {
    leagueActiveStatus: boolean;
    leagueStatus: 1 | 2 | 3;
}


export const SchedulesAdminLeagueStatusComponent: React.FC<SchedulesAdminLeagueStatusComponentProps> = ({
    leagueActiveStatus, leagueStatus
}) : JSX.Element | null => {


    const ACTIVE_LABEL = "Aktywna";
    const INACTIVE_LABEL = "Nieaktywna";


    const STATUSES = {
        1: "W trakcie", 2: "Zakończona", 3: "Zarchiwizowana"
    }


    const activeStatusLabel = leagueActiveStatus ? ACTIVE_LABEL : INACTIVE_LABEL;
    const statusLabel = STATUSES[leagueStatus];


    return (
        <Col xs={2}>
            <span>{activeStatusLabel}</span>
            <span>{statusLabel}</span>
        </Col>
    );


};


export default SchedulesAdminLeagueStatusComponent;

import { UseLanguage } from "@States";


import { CommonDictionary, CommonDictionaryPhrasesSetType } from "@Dictionary";

export const UseCommonDictionaryPhrasesSet = (phraseName: CommonDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return CommonDictionary[ActiveLanguage][phraseName];

};


export default UseCommonDictionaryPhrasesSet;

import React from "react";


import { UseGlobalsDictionaryPhrasesSet } from "@Hooks/Texts";


import {
    DefaultPageHeaderComponent, PageContainerComponent
} from "@ContentElements";


import ArticlesListComponent from "@Components/Views/Pages/Common/ArticlesPage/ArticlesList";


import "./index.scss";


export interface NewsPageComponentProps {}


export const ArticlesPageComponent: React.FC<NewsPageComponentProps> = () : JSX.Element | null => {


    const ARTICLES_PAGE_HEADER_TEXT = UseGlobalsDictionaryPhrasesSet("NEWS_PAGE_HEADER_TEXT");


    return (
        <PageContainerComponent id={"articles-list-page"} cssClasses={"articles-list-page"}>

            <DefaultPageHeaderComponent headerText={ARTICLES_PAGE_HEADER_TEXT} />

            <ArticlesListComponent />

        </PageContainerComponent>
    );

};


export default ArticlesPageComponent;

import React from "react";


import {
    UseApplicationLogo, UsePLKALogo
} from "@Hooks/GUI";


import {
    Col, Row
} from "react-bootstrap";


import {
    ArticleInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ArticleImageComponentProps {
    Article: ArticleInterface;
}

export const ArticleImageComponent: React.FC<ArticleImageComponentProps> = ({
    Article
}) : JSX.Element | null => {


    if (!Article) {
        return null;
    }


    const {
        article_title: articleTitle,
        article_image: articleImage,
    } = Article;


    let ArticleImage;

    if (articleImage) {

        ArticleImage = articleImage;

    } else {

        const LeagueLogo = UseApplicationLogo();

        if (LeagueLogo) {
            ArticleImage = LeagueLogo
        } else {
            ArticleImage = UsePLKALogo();
        }

    }


    return (
        <Col xs={12} className={"article-detail__content__header__image-and-title__image"}>
            <Row as={"picture"} className={"article-detail__content__header__image-and-title__image__picture"}>
                <img src={ArticleImage} alt={articleTitle} />
            </Row>
        </Col>
    );

};


export default ArticleImageComponent;

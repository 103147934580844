export enum LeagueGameEventPlayerTurnoverKinds {
    AUT_PL = "Aut", AUT_EN = "Out of bounds",
    PASS_PL = "Podanie", PASS_EN = "Bad pass",
    BALL_RETURN_PL = "Powrót Piłki", BALL_RETURN_EN = "Backcourt violation",
    OFFENSE_FOUL_PL = "Faul w ataku", OFFENSE_FOUL_EN = "Offensive foul",
    BALL_CARRIED_PL = "Piłka niesiona", BALL_CARRIED_EN = "Carrying the ball",
    STEPS_PL = "Kroki", STEPS_EN = "Travelling",
    DRIBBLE_PL = "Drybling", DRIBBLE_EN = "Ball handling",
    DOUBLE_DRIBBLE_PL = "Podwójne kozłowanie", DOUBLE_DRIBBLE_EN = "Double Dribble",
}


export default LeagueGameEventPlayerTurnoverKinds;

import React from "react";


import {Container, Row} from "react-bootstrap";


export const PageContentContainerComponent = ({children = null, cssClasses = ""}) => {
	
	
	if (!children) return null;
	
	
	cssClasses = (cssClasses ? `${cssClasses} ` : "") + "page-component-content-block";
	
	
	return (
		<Container fluid={true} className={cssClasses}>
			<Container className={"page-component-content-container"}>
				<Row className={"page-component-content"}>
					{children}
				</Row>
			</Container>
		</Container>
	);
	
};


export default PageContentContainerComponent;

import React, { useEffect, useState } from "react";


import {
    UseCommonDictionaryPhrasesSet, UseErrorMessage
} from "@Hooks/Texts";

import {
    UseLeaguesList
} from "@Services/Utilities/ApiHooks";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";

import {
    FormFieldValueErrorInterface
} from "@CommonFormsTypes";
import {
    FormComponent, FormSelectFieldComponent, FormSubmitFieldComponent
} from "@FormsElements";


import {
    LeagueInterface, FreeAgentRequestInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface FreeAgentRequestFormComponentProps {
    FreeAgentRequests: FreeAgentRequestInterface[] | null;
    submitHandler: (freeAgentRequestLeagueID: string) => void;
}

export const FreeAgentRequestFormComponent: React.FC<FreeAgentRequestFormComponentProps> = ({
    FreeAgentRequests, submitHandler
}) : JSX.Element | null => {


    const REQUEST_ERROR_MESSAGE_TEXT = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const ADD_WORD_TEXT = UseCommonDictionaryPhrasesSet("ADD_WORD");

    const LEAGUE_WORD_TEXT = UseCommonDictionaryPhrasesSet("LEAGUE_WORD_TEXT");
    const CHOOSE_LEAGUE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("CHOOSE_LEAGUE_PHRASE_TEXT");
    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");


    const ADD_TO_TRANSFER_LIST_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("ADD_TO_TRANSFER_LIST_PHRASE_TEXT");
    const NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT");

    const [ActiveSeasonLeagues, setActiveSeasonLeagues] = useState<LeagueInterface[] | null>(null);
    const [ActiveSeasonLeaguesError, setActiveSeasonLeaguesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!FreeAgentRequests) {
            return;
        }

        const getActiveSeasonLeagues = async () => {
            try {
                UseLeaguesList(
                    setActiveSeasonLeagues, setActiveSeasonLeaguesError
                );
            } catch (e) {
                setActiveSeasonLeaguesError({ errorCode: 500, errorMessage: "" });
            }
        };

        getActiveSeasonLeagues();

    }, [FreeAgentRequests]);

    useEffect(() => {
        return () => {
            setActiveSeasonLeagues(null);
            setActiveSeasonLeaguesError(null);
        };
    }, []);


    interface FormErrorsInterface {
        freeAgentRequestLeagueID?: FormFieldValueErrorInterface | null;
    }

    const FormErrorsDefaults: FormErrorsInterface = {
        freeAgentRequestLeagueID: null
    };

    const [FreeAgentRequestErrors, setFreeAgentRequestErrors] = useState(FormErrorsDefaults);

    const [freeAgentRequestLeagueID, setFreeAgentRequestLeagueID] = useState("0");
    // const [freeAgentRequestStartTime, setFreeAgentRequestStartTime] = useState("");
    // const [freeAgentRequestFinishTime, setFreeAgentRequestFinishTime] = useState("");

    useEffect(() => {
        return () => {
            setFreeAgentRequestLeagueID("0");
        };
    }, []);


    if (!FreeAgentRequests || !submitHandler) {
        return null;
    }


    if (ActiveSeasonLeaguesError) {
        return <ErrorMessageComponent messageText={REQUEST_ERROR_MESSAGE_TEXT} />;
    }


    if (!ActiveSeasonLeagues) {
        return <SingleContentLoaderComponent />
    }


    const LeaguesIDsList = ActiveSeasonLeagues.map((League) => {

        const { league_id: leagueID, league_name: leagueName } = League;

        return { value: leagueID, label: leagueName }

    }).filter(({ value }) => {

        for (let request of FreeAgentRequests) {

            const {
                free_agent_request_league_data: { league_id: leagueID },
                free_agent_request_status: requestStatus
            } = request;

            if (value === leagueID && requestStatus === 1) {
                return false;
            }

        }

        return true;
    });


    if (!LeaguesIDsList.length) {
        return <MessageComponent messageText={NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT} cssClasses={"bordered padding-15 margin-top-10"} />;
    }


    const freeAgentRequestSubmitHandler = () => {

        const FormErrors: FormErrorsInterface = {};

        if (!freeAgentRequestLeagueID || freeAgentRequestLeagueID === "0") {
            FormErrors.freeAgentRequestLeagueID = { errorMessage: NO_LEAGUE_SELECTED_PHRASE_TEXT };
        }

        setFreeAgentRequestErrors(FormErrors);

        if (Object.entries(FormErrors).length) {
            return;
        }

        submitHandler(freeAgentRequestLeagueID);

    };


    return (
        <Col xs={12} className={"free-agent-requests__create-request__form"}>
            <Row className={"free-agent-requests__create-request__form__content"}>

                <FormComponent>

                    <Col xs={12} className={"free-agent-requests__create-request__form__header"}>
                        <span>{ADD_TO_TRANSFER_LIST_PHRASE_TEXT}</span>
                    </Col>

                    <Col xs={12} lg={6} className={"free-agent-requests__create-request__form__field select-field"}>

                        <FormSelectFieldComponent
                            fieldOptions={[ { value: "0", label: `- ${CHOOSE_LEAGUE_PHRASE_TEXT} -` }, ...LeaguesIDsList ]}
                            fieldValue={freeAgentRequestLeagueID}
                            fieldValueHandler={(e) => setFreeAgentRequestLeagueID(e.target.value)}
                            fieldLabel={LEAGUE_WORD_TEXT}
                            fieldError={FreeAgentRequestErrors.freeAgentRequestLeagueID}
                        />

                    </Col>

                    <Col xs={12} lg={6} className={"free-agent-requests__create-request__form__field submit-field"}>
                        <FormSubmitFieldComponent
                            buttonText={ADD_WORD_TEXT}
                            buttonClickHandler={freeAgentRequestSubmitHandler}
                        />
                    </Col>

                </FormComponent>

            </Row>
        </Col>
    );

};


export default FreeAgentRequestFormComponent;

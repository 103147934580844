import React, {useEffect, useState} from "react";


import {
    Container, Row
} from "react-bootstrap";


import SeasonsAndLeaguesComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/SeasonsAndLeagues";
import LeagueTablesComponent from "@LeaguesPages/Tables/Leagues/Tables";
import LeagueStatisticsComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Statistics";

import MappedLeaguesBySeasonsInterface from "@LeaguesPages/_Types/Leagues/BySeasons";
import MappedLeagueInterface from "@LeaguesPages/_Types/League";


export interface LeaguesTablesLeaguesComponentProps {
    MappedLeaguesBySeasons: MappedLeaguesBySeasonsInterface;
    inheritedSelectedSeasonID?: number;
    inheritedSelectedLeagueID?: number;
}


export const LeaguesTablesLeaguesComponent: React.FC<LeaguesTablesLeaguesComponentProps> = ({
    MappedLeaguesBySeasons,
    inheritedSelectedSeasonID = 0, inheritedSelectedLeagueID = 0,
}) : JSX.Element | null => {


    const [selectedSeasonID, setSelectedSeasonID] = useState<number>(0);
    const [selectedLeagueID, setSelectedLeagueID] = useState<number>(0);
    const [selectedLeague, setSelectedLeague] = useState<MappedLeagueInterface | null>(null);

    const selectedSeasonIDHandler = (seasonID: number) => {
        setSelectedSeasonID(seasonID);
        localStorage.setItem("PLKA_SELECTED_LEAGUES_SEASON_ID", seasonID.toString());
    };

    const selectedLeagueIDHandler = (leagueID: number) => {
        setSelectedLeagueID(leagueID);
        localStorage.setItem("PLKA_SELECTED_LEAGUES_LEAGUE_ID", leagueID.toString());
    };


    useEffect(() => {
        const SelectedLeague = MappedLeaguesBySeasons[selectedSeasonID]?.seasonLeagues[selectedLeagueID];
        setSelectedLeague(SelectedLeague || null);
    }, [selectedLeagueID]);


    const findSelectedSeason = (
        MappedLeaguesBySeasons: MappedLeaguesBySeasonsInterface,
        inheritedSelectedSeasonID: number, inheritedSelectedLeagueID: number,
        selectedSeasonIDHandler: (seasonID: number) => void, selectedLeagueIDHandler: (seasonID: number) => void
    ) => {

        if (!MappedLeaguesBySeasons) {
            return;
        }

        let selectedSeasonID = 0;

        const storedSelectedSeasonID = localStorage.getItem("PLKA_SELECTED_LEAGUES_SEASON_ID");

        if (storedSelectedSeasonID) {
            selectedSeasonID = +storedSelectedSeasonID;
        }

        if (inheritedSelectedSeasonID) {
            selectedSeasonID = inheritedSelectedSeasonID;
        }

        if (!selectedSeasonID) {
            const Season = Object.values(MappedLeaguesBySeasons).filter((
                Season) => Season.seasonActiveStatus
            )[0];
            if (Season) {
                selectedSeasonID = Season.seasonID;
            }
        }

        let selectedLeagueID = 0;

        const SelectedSeason = MappedLeaguesBySeasons[selectedSeasonID];

        if (SelectedSeason) {

            const storedSelectedLeagueID = localStorage.getItem("PLKA_SELECTED_LEAGUES_LEAGUE_ID");

            if (storedSelectedLeagueID) {
                selectedLeagueID = +storedSelectedLeagueID;
            }

            if (inheritedSelectedLeagueID) {
                selectedLeagueID = inheritedSelectedLeagueID;
            }

            const SelectedLeague = SelectedSeason.seasonLeagues[selectedLeagueID];

            if (!SelectedLeague) {
                const FirstLeagueFromLeaguesList = Object.values(SelectedSeason.seasonLeagues)[0];
                if (FirstLeagueFromLeaguesList) {
                    selectedLeagueID = FirstLeagueFromLeaguesList.leagueID;
                } else {
                    selectedLeagueID = 0;
                }

            }

        }

        selectedSeasonIDHandler(selectedSeasonID);
        selectedLeagueIDHandler(selectedLeagueID);

    };

    useEffect(() => {
        findSelectedSeason(
            MappedLeaguesBySeasons,
            inheritedSelectedSeasonID, inheritedSelectedLeagueID,
            selectedSeasonIDHandler, selectedLeagueIDHandler,
        );
    }, []);


    useEffect(() => {

        if (!selectedSeasonID) {
            return;
        }

        const SelectedSeason = MappedLeaguesBySeasons[selectedSeasonID];

        if (!SelectedSeason) {
            selectedSeasonIDHandler(0);
            selectedLeagueIDHandler(0);
            setSelectedLeague(null);
            return;
        }

        const SelectedLeague = SelectedSeason.seasonLeagues[selectedLeagueID];

        if (SelectedLeague) {
            setSelectedLeague(SelectedLeague);
        } else {
            selectedLeagueIDHandler(0);
            setSelectedLeague(null);
        }

    }, [selectedSeasonID]);


    return (
        <>

            <SeasonsAndLeaguesComponent
                leaguesBySeasons={MappedLeaguesBySeasons}
                selectedSeasonID={selectedSeasonID}
                selectedSeasonIDHandler={selectedSeasonIDHandler}
                selectedLeagueID={selectedLeagueID}
                selectedLeagueIDHandler={selectedLeagueIDHandler}
            />

            <Container fluid={true} id={"league-table-component"} className={"league-table league-table-component page-section-component main-section-component"}>
                <Container className={"main-section-container page-section-container"}>
                    <Row className={"main-section-content page-section-row"}>

                        <LeagueTablesComponent
                            selectedLeagueID={selectedLeagueID}
                        />

                        {/*<LeagueTableComponent*/}
                        {/*    selectedLeagueID={selectedLeagueID}*/}
                        {/*    selectedLeague={selectedLeague}*/}
                        {/*/>*/}

                        <LeagueStatisticsComponent
                            selectedLeagueID={selectedLeagueID}
                        />

                    </Row>
                </Container>
            </Container>

        </>
    );


};


export default LeaguesTablesLeaguesComponent;

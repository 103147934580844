export const LOGIN_PAGE_PAGE_TITLE_PL = "Logowanie";
export const LOGIN_PAGE_PAGE_TITLE_EN = "Login";
export const LOGIN_PAGE_BUTTON_TEXT_PL = "Zaloguj";
export const LOGIN_PAGE_BUTTON_TEXT_EN = "Zaloguj";
export const LOGIN_PAGE_RESET_PASSWORD_TEXT_PL = "Reset Hasła";
export const LOGIN_PAGE_RESET_PASSWORD_TEXT_EN = "Password Reset";
export const INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT_PL = "Nieprawidłowe dane logowania";
export const INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT_EN = "Incorrect login data";

export const PASSWORD_RESET_PAGE_TITLE_PL = "Zresetuj Hasło";
export const PASSWORD_RESET_PAGE_TITLE_EN = "Reset Password";
export const PASSWORD_RESET_PAGE_MESSAGE_TEXT_PL = "Aby zresetować hasło podaj swój adres email";
export const PASSWORD_RESET_PAGE_MESSAGE_TEXT_EN = "To Reset password input Your Email Address";
export const PASSWORD_RESET_PAGE_BUTTON_TEXT_PL = "Resetuj";
export const PASSWORD_RESET_PAGE_BUTTON_TEXT_EN = "Reset";
export const PASSWORD_RESET_SUCCESS_MESSAGE_TEXT_PL = "Dziękujemy - na Twój adres email została wysłana wiadomość z linkiem do ustawienia nowego hasła";
export const PASSWORD_RESET_SUCCESS_MESSAGE_TEXT_EN = "Thank you - a message with a link to set a new password has been sent to your e-mail address";


export const PASSWORD_SET_PAGE_TITLE_PL = "Ustaw hasło";
export const PASSWORD_SET_PAGE_TITLE_EN = "Set password";
export const PASSWORD_SET_PAGE_MESSAGE_TEXT_PL = "Podaj nowe hasło logowania do systemu PLKA";
export const PASSWORD_SET_PAGE_MESSAGE_TEXT_EN = "Input new password for login to PLKA systems";
export const PASSWORD_SET_PAGE_BUTTON_TEXT_PL = "Ustaw hasło";
export const PASSWORD_SET_PAGE_BUTTON_TEXT_EN = "Set password";

import React from "react";


import { Col, Row } from "react-bootstrap";

import { BiDownArrow, BiUpArrow } from "react-icons/all";


import UseGameQuarter from "@GameResultPageHooks/GameQuarter";


import "./index.scss";


export interface GameProgressQuarterHeaderComponentProps {
    quarterNumber: number;
    showQuarterEventsStatus: boolean;
    showQuarterEventsStatusHandler: (status: boolean) => void;
}


export const GameProgressQuarterHeaderComponent: React.FC<GameProgressQuarterHeaderComponentProps> = ({
    quarterNumber,
    showQuarterEventsStatus, showQuarterEventsStatusHandler
}) => {


    if (!quarterNumber) {
        return null;
    }


    // @ts-ignore
    const Quarter = UseGameQuarter(quarterNumber);

    if (!Quarter) {
        return null;
    }

    let quarterName = "";

    if (Quarter.quarterNumberLabel) {
        quarterName += Quarter.quarterNumberLabel + " ";
    }

    if (Quarter.quarterName.singular) {
        quarterName += Quarter.quarterName.singular;
    }


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__header" + (showQuarterEventsStatus ? " opened" : "")} onClick={() => showQuarterEventsStatusHandler(!showQuarterEventsStatus)}>
            <Row className={"game-result-details__game-progress__quarters__quarter__header__content"}>
                <span className={"quarter-number"}>{quarterName}</span>
                {showQuarterEventsStatus ? <BiUpArrow /> : <BiDownArrow />}
            </Row>
        </Col>
    );

};


export default GameProgressQuarterHeaderComponent;

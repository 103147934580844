import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import LeaguesTablesGroupTableTeamComponent from "@Pages/Common/HomePage/Leagues/Tables/Tables/Group/Table/Team";


import {
    LeagueTablesTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeaguesTablesGroupTableComponentProps {
    GroupTable: LeagueTablesTeamInterface[];
}


export const LeaguesTablesGroupTableComponent: React.FC<LeaguesTablesGroupTableComponentProps> = ({
    GroupTable
}) : JSX.Element | null => {


    if (!GroupTable) {
        return null;
    }


    const GroupTableTeams = GroupTable.map((Team, i) =>
        <LeaguesTablesGroupTableTeamComponent key={i} tablePosition={i + 1} Team={Team} />
    );


    return (
        <Col xs={12} className={"home-page__leagues__tables__tables__group__table"}>
            <Row className={"home-page__leagues__tables__tables__group__table__content"}>

                {GroupTableTeams}

            </Row>
        </Col>
    );

};


export default LeaguesTablesGroupTableComponent;

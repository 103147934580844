import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import DefaultMessageComponent from "@Components/Elements/Messages/Message/_Default";


export interface SetNewPasswordMessageComponentProps {}


export const SetNewPasswordMessageComponent: React.FC<SetNewPasswordMessageComponentProps> = (): JSX.Element | null => {


    const SET_NEW_PASSWORD_MESSAGE_TEXT = `Twoje konto jest nieaktywne. Na Twój adres email została wysłana wiadomość z linkiem pozwalającym nadać nowe hasło.`;


    return (
        <ContainerSectionComponent>
            <DefaultMessageComponent messageText={SET_NEW_PASSWORD_MESSAGE_TEXT} />
        </ContainerSectionComponent>
    );

};


export default SetNewPasswordMessageComponent;

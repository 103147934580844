import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Col, Row} from "react-bootstrap";


import NumberFormat from "react-number-format";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";

import MessageComponent from "@Components/Elements/Messages/Content";


import sortPlayerGamesByLeagues from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Utilities/Games/SortBySeasons";
import createPlayerGamesStatistics from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Utilities/Statistics/Games/CreatePlayerGamesStatistics";


import LeagueGamePlayerInterface from "@Interfaces/Api/Leagues/Games/Game/Player";
import {UseGamesDictionaryPhrasesSet, UseLeaguesDictionaryPhrasesSet} from "@Hooks/Texts";


const TEXTS: any = {
    PL: {
        no_player_games_text: "Brak rozegranych meczów",
        no_player_statistics_text: "Brak statystyk",
    },
    EN: {
        no_player_games_text: "No played games",
        no_player_statistics_text: "No statistics"
    },
};


export interface PlayerInformationStatisticsComponentProps {
    playerID: number;
}


export const PlayerInformationStatisticsComponent: React.FC<PlayerInformationStatisticsComponentProps> = ({
    playerID
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        no_player_games_text: NO_PLAYER_GAMES_TEXT, no_player_statistics_text: NO_PLAYER_STATISTICS_TEXT
    } = TEXTS[activeLanguage];

    const NO_GAMES_STATISTICS_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_GAMES_STATISTICS_MESSAGE_TEXT");


    const [playerPlayedGamesData, setPlayerPlayedGamesData] = useState<LeagueGamePlayerInterface[] | null>(null);
    const [playerPlayedGamesDataError, setPlayerPlayedGamesDataError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!playerID) return;

        const PLAYER_GAMES_API_URL: string = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL + "/leagues/games/teams/players";

        const getPlayerPlayedGamesDataError = async () => {

            try {

                const response = await _restApiRequest(`${PLAYER_GAMES_API_URL}/?league_game_team_player_player=${playerID}&all_results=true`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setPlayerPlayedGamesData(responseContent);
                } else {
                    setPlayerPlayedGamesDataError({errorCode: response.status, errorMessage: ""});
                }

            } catch (e) {

                setPlayerPlayedGamesDataError({errorCode: 500, errorMessage: ""});
            }

        };

        getPlayerPlayedGamesDataError();

    }, [playerID]);


    useEffect(() => {
        return () => {
            setPlayerPlayedGamesData(null);
            setPlayerPlayedGamesDataError(null);
        };
    }, []);


    if (!playerID) return null;


    if (!!playerPlayedGamesDataError) return <ErrorMessageComponent lg={{span: 3, offset: 2}} messageText={playerPlayedGamesDataError.errorMessage} />;


    if (!playerPlayedGamesData) return <SingleContentLoaderComponent loaderColor={"#FFF"} xs={12} lg={{span: 3, offset: 2}} cssClasses={"bordered white"} />;


    const PlayerGames = sortPlayerGamesByLeagues(playerPlayedGamesData);

    if (!PlayerGames?.length) return null;

    const PlayerStatistics = createPlayerGamesStatistics(PlayerGames);

    if (!PlayerStatistics?.length) return null


    const {
        PLAYER_POINTS: playerPPG, PLAYER_TOTAL_REBOUNDS: playerRPG,
        PLAYER_ASSISTS: playerAPG, PLAYER_EVAL: playerEVAL
    } = PlayerStatistics[0].MEDIUM;


    return (
        <Col xs={6} lg={{span: 4, offset: 2}} className={"player-information-statistics-component player-information-statistics"}>
            <Row className={"player-information-statistics-content"}>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`PPG`}</span>
                        <span className={"information-value"}>
                            {(!!playerPPG || playerPPG === 0) && <NumberFormat value={playerPPG} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`RPG`}</span>
                        <span className={"information-value"}>
                            {(!!playerRPG || playerRPG === 0) && <NumberFormat value={playerRPG} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`APG`}</span>
                        <span className={"information-value"}>
                            {(!!playerAPG || playerAPG === 0)&& <NumberFormat value={playerAPG} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`EVAL`}</span>
                        <span className={"information-value"}>
                            {(!!playerEVAL || playerEVAL === 0) && <NumberFormat value={playerEVAL} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerInformationStatisticsComponent;

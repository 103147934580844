import React, { useEffect, useState } from "react";


import UseLanguage from "@LocalizationStates/Language";


import Dictionary from "@Dictionary";


import { NavLink } from "react-router-dom";


import { Button, Col, Row } from "react-bootstrap";

import { AiOutlineCheck } from "react-icons/all";


import dateFormat from "dateformat";


import RouteInterface from "@RouteType";


export interface UserFormalsFormalAgreementComponentProps {
    formalAcceptationDate: string | Date | null | undefined;
    formalRoute: RouteInterface;
    formalAcceptationLabelText: string;
    formalAcceptationButtonText: string;
    formalAcceptationHandler: () => void;
}


export const UserFormalsFormalAgreementComponent: React.FC<UserFormalsFormalAgreementComponentProps> = ({
    formalAcceptationDate,
    formalRoute,
    formalAcceptationLabelText, formalAcceptationButtonText,
    formalAcceptationHandler
}) : JSX.Element | null => {


    const ActiveLanguage = UseLanguage();

    const {
        formals_date_of_acceptation_text: DATE_OF_ACCEPTATION_TEXT,
        formals_not_accepted_phrase: NOT_ACCEPTED_TEXT,
        formals_i_accept_phrase: ACCEPT_TEXT,
        link_click_word_text: CLICK_WORD_TEXT,
    } = Dictionary[ActiveLanguage];


    const [formalAgreementAcceptationBoxDisplayStatus, setFormalAgreementAcceptationBoxDisplayStatus] = useState(false);

    useEffect(() => {
        return () => {
            setFormalAgreementAcceptationBoxDisplayStatus(false);
        };
    }, []);

    useEffect(() => {
        if (formalAcceptationDate) {
            setFormalAgreementAcceptationBoxDisplayStatus(false);
        }
    }, [formalAcceptationDate]);


    const {
        routeTitle: formalTitle,
        routeLabel: formalLabel,
        routeURL: formalTextUrl
    } = formalRoute;


    return (
        <Col xs={12} className={"user-formals-data section-data"}>
            <Row className={"user-formals-data-content section-data-content"}>

                {!!formalTitle &&
                <Col as={"h3"} xs={12} className={"formals-data-header section-data-header"}>
                    <span>{formalTitle}</span>
                </Col>
                }

                {!!formalRoute &&
                <Col xs={12} className={"formals-data-link section-data-row"}>
                    <span className={"data-label"}>{formalLabel}:</span>
                    <NavLink to={formalTextUrl} title={formalTitle} className={"data-value"} target={"_blank"}>
                        <span>{CLICK_WORD_TEXT}</span>
                    </NavLink>
                </Col>
                }

                <Col xs={12} className={"formals-data-acceptation-date section-data-row"}>
                    <span className={"data-label"}>{DATE_OF_ACCEPTATION_TEXT}:</span>
                    <span className={"data-value"}>{!!formalAcceptationDate ? dateFormat(formalAcceptationDate, "dd-mm-yyyy HH:MM") : NOT_ACCEPTED_TEXT}</span>
                </Col>

                {!formalAcceptationDate &&
                <Col xs={12} className={"formals-data-accept-button section-data-row"}>
                    <span className={"data-label"}>{ACCEPT_TEXT}</span>
                    <span className={"data-checkbox" + (formalAgreementAcceptationBoxDisplayStatus ? " checked" : "")} onClick={() => setFormalAgreementAcceptationBoxDisplayStatus(!formalAgreementAcceptationBoxDisplayStatus)}>
                        {formalAgreementAcceptationBoxDisplayStatus && <AiOutlineCheck />}
                    </span>
                </Col>
                }

                {formalAgreementAcceptationBoxDisplayStatus &&
                <Col xs={12} className={"formals-data-acceptation-box section-data-row"}>
                    <span className={"data-label"}>{formalAcceptationLabelText}</span>
                    <Button variant={"primary"} className={"small-button"} onClick={formalAcceptationHandler}>
                        <span>{formalAcceptationButtonText}</span>
                    </Button>
                </Col>
                }

            </Row>
        </Col>
    );

};


export default UserFormalsFormalAgreementComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";


import UseGameQuarter from "@GameResultPageHooks/GameQuarter";


import "./index.scss";


export interface GameProgressQuarterFooterComponentProps {
    quarterNumber: number;
}


export const GameProgressQuarterFooterComponent: React.FC<GameProgressQuarterFooterComponentProps> = ({
    quarterNumber
}) => {


    const FINISH_WORD = UseCommonDictionaryPhrasesSet("FINISH_WORD")


    if (!quarterNumber) {
        return null;
    }

    // @ts-ignore
    const Quarter = UseGameQuarter(quarterNumber);

    if (!Quarter) {
        return null;
    }

    let quarterName = "";

    if (Quarter.quarterNumberLabel) {
        quarterName += Quarter.quarterNumberLabel + " ";
    }

    if (Quarter.quarterName.singular) {
        quarterName += Quarter.quarterName.genitive;
    }




    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__footer"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__footer__content"}>
                <span className={"quarter-number"}>{FINISH_WORD} {quarterName}</span>
            </Row>
        </Col>
    );

};


export default GameProgressQuarterFooterComponent;

import { useEffect, useState } from "react";


import _getDataFromApi from "@Services/API/Requests";

import {
    API_HOME_PAGE_SLIDES_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    HomePageSlideInterface
} from "@Interfaces/Api";


export const UseHomePageSlides = () : [HomePageSlideInterface[] | null, ErrorMessageInterface | null] => {


    const [HomePageSlides, setHomePageSlides] = useState<HomePageSlideInterface[] | null>(null);
    const [HomePageSlidesError, setHomePageSlidesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        const getHomePageSlides = async () => {
            await _getDataFromApi(
                API_HOME_PAGE_SLIDES_ENDPOINT_URL,
                setHomePageSlides, setHomePageSlidesError
            );
        };

        getHomePageSlides();

        return () => {
            setHomePageSlides(null);
            setHomePageSlidesError(null);
        };

    }, []);


    return [HomePageSlides, HomePageSlidesError];

};


export default UseHomePageSlides;

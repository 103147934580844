import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import TeamLeaguesSwitcherComponent from "@TeamsPages/Team/_Elements/TeamLeaguesSwitcher";


import {
    TeamLeagueInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamPlayersLeaguesSwitcherComponentProps {
    TeamLeaguesWithSquads?: TeamLeagueInterface[];
    selectedTeamLeagueID: number;
    selectedTeamLeagueIDHandler: (leagueID: number) => void;
}


export const TeamPlayersLeaguesSwitcherComponent: React.FC<TeamPlayersLeaguesSwitcherComponentProps> = ({
    TeamLeaguesWithSquads,
    selectedTeamLeagueID, selectedTeamLeagueIDHandler
}) : JSX.Element | null => {


    const LEAGUE_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("LEAGUE_PLAYERS_PHRASE_TEXT");


    if (!TeamLeaguesWithSquads?.length) {
        return null;
    }


    return (
        <Col xs={12} className={"team-details__team-players__team-leagues"}>
            <Row className={"team-details__team-players__team-leagues__content"}>

                <Col xs={6} lg={"auto"} className={"team-details__team-players__team-leagues__label"}>
                    <span>{LEAGUE_PLAYERS_PHRASE_TEXT}</span>
                </Col>

                <TeamLeaguesSwitcherComponent
                    xs={6} lg={"auto"}
                    TeamLeagues={TeamLeaguesWithSquads}
                    selectedTeamLeagueID={selectedTeamLeagueID}
                    selectedTeamLeagueIDHandler={selectedTeamLeagueIDHandler}
                />

            </Row>
        </Col>
    );
};


export default TeamPlayersLeaguesSwitcherComponent;

import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamCaptainTeamPlayersHeaderComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/Header";
import TeamCaptainTeamPlayersListComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayersList";
import TeamCaptainTeamPlayersAddComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayerAdd";


import UseTeamPlayers from "@Hooks/Api/Requests/Games/Teams/Players";


import "./index.scss";


export interface TeamCaptainTeamPlayersComponentProps {
    teamID: number;
}


export const TeamCaptainTeamPlayersComponent: React.FC<TeamCaptainTeamPlayersComponentProps> = ({
    teamID
}) : JSX.Element | null => {


    const [updateTeamPlayers, setUpdateTeamPlayers] = useState(false);

    useEffect(() => {
        if (!updateTeamPlayers) {
            return
        }
        setUpdateTeamPlayers(false);
    }, [updateTeamPlayers]);


    const [TeamPlayers, TeamPlayersError] = UseTeamPlayers(teamID, updateTeamPlayers);


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players"}>
            <Row className={"player-profile__captain-zone__team__players__content"}>

                <TeamCaptainTeamPlayersHeaderComponent />

                <TeamCaptainTeamPlayersListComponent
                    TeamPlayers={TeamPlayers}
                    TeamPlayersError={TeamPlayersError}
                    updateTeamPlayersTrigger={setUpdateTeamPlayers}
                />

                <TeamCaptainTeamPlayersAddComponent
                    teamID={teamID}
                    updateTeamPlayersTrigger={setUpdateTeamPlayers}
                />

            </Row>
        </Col>
    )

};


export default TeamCaptainTeamPlayersComponent;

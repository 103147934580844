import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import _ from "lodash";


import GameInformationSummaryTableComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable";
import GameSummaryTableHeaderComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table/Header";
import GameSummaryTableDataComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table";
import GameSummaryTableRowComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table/Row";


import {
    countSumOfAnArrayElements
} from "@Services/Utilities/Tools";


import { LeagueGameEventInterface } from "@Interfaces/Api";

import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";
import { ApiGameSummaryQuarterType } from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter";
import { ApiGameSummaryEventType } from "@GameResultPageTypes/Summary/API/GameSummary/Events";

import TeamsSummaryInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams";

import GameSummaryPossessionsInterface from "@GameResultPageTypes/Summary/GUI/Possesions";
import GameSummaryPossessionInterface from "@GameResultPageTypes/Summary/GUI/Possesions/Possesion";


import {
    LeagueGameEventPlayerTurnoverTypes, LeagueGameEventPlayerTurnoverKinds,
    LeagueGameEventPlayerFoulTypes,
    LeagueGameEventTeamTurnoverTypes, LeagueGameEventTeamTurnoverKinds
} from "@GuiData/Games/Games/Game/Events";


interface GameInformationPossessionsComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface;
    TeamsSummaryData: TeamsSummaryInterface | null;
}


export const GameInformationPossessionsComponent: React.FC<GameInformationPossessionsComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics, TeamsSummaryData
}) : JSX.Element | null => {


    const POSSESSIONS_NUMBER = UseGamesDictionaryPhrasesSet("POSSESSIONS_NUMBER");
    const TOTAL_POSSESSIONS = UseGamesDictionaryPhrasesSet("TOTAL_POSSESSIONS");
    const MEDIUM_POSSESSION_TIME = UseGamesDictionaryPhrasesSet("MEDIUM_POSSESSION_TIME");
    const POSSESSIONS_FINISHED_BY_THROW = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_THROW");
    const POSSESSIONS_FINISHED_BY_ACCURATE_THROW = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_ACCURATE_THROW");
    const POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW");
    const POSSESSIONS_FINISHED_BY_FAULT = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_FAULT");
    const POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT");
    const POSSESSIONS_FINISHED_BY_PASS_FAULT = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_PASS_FAULT");
    const POSSESSIONS_FINISHED_BY_TIME_FAULT = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_TIME_FAULT");
    const POSSESSIONS_FINISHED_BY_OUT_FAULT = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_OUT_FAULT");
    const POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT = UseGamesDictionaryPhrasesSet("POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT");


    if (!SelectedQuarters || !GameSummaryStatistics || !TeamsSummaryData) return null;


    const {
        squads: GameSquads, quarters: GameQuarters
    } = GameSummaryStatistics;


    if (!GameQuarters || !GameSquads) return null;


    const HomeTeam = GameSquads[homeTeamID];
    const AwayTeam = GameSquads[awayTeamID];


    if (!HomeTeam || !AwayTeam) return null;


    const Possessions: GameSummaryPossessionsInterface = {
        [homeTeamID]: { "QE": [], "TO": [], "AT": [], "NAT": [] },
        [awayTeamID]: { "QE": [], "TO": [], "AT": [], "NAT": [] }
    };


    let PreviousEvent: LeagueGameEventInterface | null = null;

    for (let quarterNumber of SelectedQuarters) {

        let actualPossessionTeamID: number | null = null;
        let lastPossessionChangeTime = 0;

        if (!quarterNumber || !GameQuarters[quarterNumber]) continue;

        const QuarterData = GameQuarters[quarterNumber];

        const { events_list: QuarterEvents } = QuarterData;

        const Events = _.sortBy(QuarterEvents, ["league_game_event_idx"]);

        for (let Event of Events) {

            const {
                league_game_event_type: eventType,
                league_game_event_time: eventTime,
                team_id: eventTeamID
            } = Event;

            if (!eventTeamID) continue;

            const PossessionChangeActualTeamsEvents: ApiGameSummaryEventType[] = [
                "Q_END", "TO", "TTO", "FOUL", "FOUL_NS", "FOUL_DSQ", "FOUL_TECH",
                "1PT", "1PTA", "2PT", "2PTA", "3PT", "3PTA",
            ];

            const PossessionChangeOppositeTeamEvents: ApiGameSummaryEventType[] = [
                "PLAYER_O_REB", "PLAYER_D_REB", "TEAM_REB", "STL"
            ]

            const PossessionChangeEvents: ApiGameSummaryEventType[] =
                PossessionChangeActualTeamsEvents.concat(PossessionChangeOppositeTeamEvents)
            ;

            if (!PossessionChangeEvents.includes(eventType)) continue;


            let previousEventType: ApiGameSummaryEventType | null = null;
            let previousEventTeamID: number | null = null;

            if (PreviousEvent) {

                const {
                    league_game_event_type: eventType, team_id: eventTeamID
                } = PreviousEvent;

                if (!eventType || !eventTeamID) {
                    continue;
                }

                previousEventType = eventType;
                previousEventTeamID = eventTeamID;

            }

            PreviousEvent = Object.assign({}, Event);

            if (eventType === "1PT" || eventType === "1PTA") {
                if (previousEventType !== "1PT" && previousEventType !== "1PTA") {
                    continue;
                }
            }

            if (actualPossessionTeamID === null) {
                actualPossessionTeamID = eventTeamID;
            }

            const Possession: GameSummaryPossessionInterface = {
                possessionQuarter: quarterNumber,
                possessionStart: lastPossessionChangeTime,
                possessionTeamID: actualPossessionTeamID
            };

            let parsedEventTime = 0;

            if (eventTime) {
                const eventMinute = Math.floor(+eventTime.split(":")[0]);
                const eventSecond = Math.floor(+eventTime.split(":")[1]);
                parsedEventTime = 60 * eventMinute + eventSecond;
            }

            Possession.possessionFinish = parsedEventTime;
            lastPossessionChangeTime = parsedEventTime;

            if (eventType === "1PT") {

                if (actualPossessionTeamID === homeTeamID) actualPossessionTeamID = awayTeamID;
                else if (actualPossessionTeamID === awayTeamID) actualPossessionTeamID = homeTeamID;
                else actualPossessionTeamID = null;

            }
            if (["2PT", "3PT"].includes(eventType)) {

                Possession.possessionFinishType = "AT";

                if (actualPossessionTeamID === homeTeamID) actualPossessionTeamID = awayTeamID;
                else if (actualPossessionTeamID === awayTeamID) actualPossessionTeamID = homeTeamID;
                else actualPossessionTeamID = null;

            } else if (eventType === "1PTA") {

                actualPossessionTeamID = null;

            } else if (["2PTA", "3PTA"].includes(eventType)) {

                Possession.possessionFinishType = "NAT";
                actualPossessionTeamID = null;

            } else if (PossessionChangeOppositeTeamEvents.includes(eventType)) {

                actualPossessionTeamID = eventTeamID;

            } else if (["TO", "TTO"].includes(eventType)) {

                Possession.possessionFinishType = "TO";

                if (actualPossessionTeamID === homeTeamID) actualPossessionTeamID = awayTeamID;
                else if (actualPossessionTeamID === awayTeamID) actualPossessionTeamID = homeTeamID;
                else actualPossessionTeamID = null;

            } else if (["FOUL", "FOUL_NS", "FOUL_DSQ", "FOUL_TECH"].includes(eventType)) {

            }
            else if (eventType === "Q_END") {

                Possession.possessionFinishType = "QE";
                Possession.possessionFinish = 60 * 9;
                lastPossessionChangeTime = 0;
                actualPossessionTeamID = null;

            }

            if (!Possession.possessionFinishType || !Possession.possessionTeamID) continue;

            if (Possession.possessionTeamID !== homeTeamID && Possession.possessionTeamID !== awayTeamID) {
                continue;
            }

            Possessions[Possession.possessionTeamID][Possession.possessionFinishType].push(Possession);

        }

        if (actualPossessionTeamID) {

            const Possession: GameSummaryPossessionInterface = {
                possessionTeamID: actualPossessionTeamID,
                possessionStart: lastPossessionChangeTime,
                possessionFinish: 9 * 60,
                possessionFinishType: "QE",
                possessionQuarter: quarterNumber
            }

            Possessions[actualPossessionTeamID].QE.push(Possession);

            actualPossessionTeamID = null;

        }

    }


    let homeTeamDribbleFault = 0
    let awayTeamDribbleFault = 0

    let homeTeamPassFault = 0;
    let awayTeamPassFault = 0;

    let homeTeamTimeFault = 0;
    let awayTeamTimeFault = 0;

    let homeTeamAutFault = 0;
    let awayTeamAutFault = 0;

    let homeTeamFoulFault = 0;
    let awayTeamFoulFault = 0;


    for (let quarterNumber of SelectedQuarters) {

        if (!quarterNumber || !GameQuarters[quarterNumber]) continue;

        const QuarterData = GameQuarters[quarterNumber];

        const { events_list: QuarterEvents } = QuarterData;

        if (!QuarterEvents) continue;

        const Events = _.sortBy(QuarterEvents, ["league_game_event_idx"]);

        for (let Event of Events) {

            const {
                league_game_event_type: eventType,
                league_game_event_description: eventDescription,
                team_id: eventTeamID
            } = Event;

            switch (eventType) {

                case LeagueGameEventPlayerTurnoverTypes.TURNOVER:

                    if (!eventDescription) continue;

                    switch (eventDescription?.text) {

                        case LeagueGameEventPlayerTurnoverKinds.AUT_PL:
                        case LeagueGameEventPlayerTurnoverKinds.AUT_EN:
                            if (eventTeamID === homeTeamID) homeTeamAutFault += 1;
                            if (eventTeamID === awayTeamID) awayTeamAutFault += 1;
                            break;

                        case LeagueGameEventPlayerTurnoverKinds.PASS_PL:
                        case LeagueGameEventPlayerTurnoverKinds.PASS_EN:
                            if (eventTeamID === homeTeamID) homeTeamPassFault += 1;
                            if (eventTeamID === awayTeamID) awayTeamPassFault += 1;
                            break;

                        case LeagueGameEventPlayerTurnoverKinds.DRIBBLE_PL:
                        case LeagueGameEventPlayerTurnoverKinds.DRIBBLE_EN:
                        case LeagueGameEventPlayerTurnoverKinds.DOUBLE_DRIBBLE_PL:
                        case LeagueGameEventPlayerTurnoverKinds.DOUBLE_DRIBBLE_EN:
                        case LeagueGameEventPlayerTurnoverKinds.STEPS_PL:
                        case LeagueGameEventPlayerTurnoverKinds.STEPS_EN:
                            if (eventTeamID === homeTeamID) homeTeamDribbleFault += 1;
                            if (eventTeamID === awayTeamID) awayTeamDribbleFault += 1;
                            break;

                        case LeagueGameEventPlayerTurnoverKinds.OFFENSE_FOUL_PL:
                        case LeagueGameEventPlayerTurnoverKinds.OFFENSE_FOUL_EN:
                        case LeagueGameEventPlayerTurnoverKinds.BALL_CARRIED_PL:
                        case LeagueGameEventPlayerTurnoverKinds.BALL_CARRIED_EN:
                            if (eventTeamID === homeTeamID) homeTeamFoulFault += 1;
                            if (eventTeamID === awayTeamID) awayTeamFoulFault += 1;
                            break;

                    }

                    break;

                case LeagueGameEventTeamTurnoverTypes.TURNOVER:

                    if (!eventDescription) continue;

                    switch (eventDescription?.text) {

                        case LeagueGameEventTeamTurnoverKinds.TIME_3_SECONDS_PL:
                        case LeagueGameEventTeamTurnoverKinds.TIME_3_SECONDS_EN:
                        case LeagueGameEventTeamTurnoverKinds.TIME_5_SECONDS_PL:
                        case LeagueGameEventTeamTurnoverKinds.TIME_5_SECONDS_EN:
                        case LeagueGameEventTeamTurnoverKinds.TIME_8_SECONDS_PL:
                        case LeagueGameEventTeamTurnoverKinds.TIME_8_SECONDS_EN:
                        case LeagueGameEventTeamTurnoverKinds.TIME_24_SECONDS_PL:
                        case LeagueGameEventTeamTurnoverKinds.TIME_24_SECONDS_EN:
                            if (eventTeamID === homeTeamID) homeTeamTimeFault += 1;
                            if (eventTeamID === awayTeamID) awayTeamTimeFault += 1;
                            break;

                    }

                    break;

                case LeagueGameEventPlayerFoulTypes.FOUL:
                case LeagueGameEventPlayerFoulTypes.TECHNICAL_FOUL:
                case LeagueGameEventPlayerFoulTypes.UNSPORTSMANLIKE_FOUL:
                case LeagueGameEventPlayerFoulTypes.DISQUALIFICATION_FOUL:
                    break;

            }

        }

    }


    const HomeTeamPossessions = Possessions[homeTeamID];
    const AwayTeamPossessions = Possessions[awayTeamID];

    const homeTeamPossessionsFinishedByThrowNumber = HomeTeamPossessions.NAT.length + HomeTeamPossessions.AT.length;
    const awayTeamPossessionsFinishedByThrowNumber = AwayTeamPossessions.NAT.length + AwayTeamPossessions.AT.length;

    const homeTeamPossessionsNumber = HomeTeamPossessions.QE.length + HomeTeamPossessions.TO.length + HomeTeamPossessions.NAT.length + HomeTeamPossessions.AT.length;
    const awayTeamPossessionsNumber = AwayTeamPossessions.QE.length + AwayTeamPossessions.TO.length + AwayTeamPossessions.NAT.length + AwayTeamPossessions.AT.length;


    const homeTeamQePossessionsTime = countSumOfAnArrayElements(
        HomeTeamPossessions.QE.map((p: any) => p.possessionFinish - p.possessionStart)
    );
    const homeTeamATPossessionsTime = countSumOfAnArrayElements(
        HomeTeamPossessions.AT.map((p: any) => p.possessionFinish - p.possessionStart)
    );
    const homeTeamNATPossessionsTime = countSumOfAnArrayElements(
        HomeTeamPossessions.NAT.map((p: any) => p.possessionFinish - p.possessionStart)
    );
    const homeTeamToPossessionsTime = countSumOfAnArrayElements(
        HomeTeamPossessions.TO.map((p: any) => p.possessionFinish - p.possessionStart)
    );

    const homeTeamTotalPossessionsTime = homeTeamQePossessionsTime + homeTeamATPossessionsTime + homeTeamNATPossessionsTime + homeTeamToPossessionsTime;

    const homeTeamMediumPossessionTime = Math.round(homeTeamTotalPossessionsTime / homeTeamPossessionsNumber);


    const awayTeamQePossessionsTime = countSumOfAnArrayElements(
        AwayTeamPossessions.QE.map((p: any) => p.possessionFinish - p.possessionStart)
    );
    const awayTeamATPossessionsTime = countSumOfAnArrayElements(
        AwayTeamPossessions.AT.map((p: any) => p.possessionFinish - p.possessionStart)
    );
    const awayTeamNATPossessionsTime = countSumOfAnArrayElements(
        AwayTeamPossessions.NAT.map((p: any) => p.possessionFinish - p.possessionStart)
    );
    const awayTeamToPossessionsTime = countSumOfAnArrayElements(
        AwayTeamPossessions.TO.map((p: any) => p.possessionFinish - p.possessionStart)
    );

    const awayTeamTotalPossessionsTime = awayTeamQePossessionsTime + awayTeamATPossessionsTime + awayTeamNATPossessionsTime + awayTeamToPossessionsTime;

    const awayTeamMediumPossessionTime = Math.round(awayTeamTotalPossessionsTime / homeTeamPossessionsNumber);


    return (
        <GameInformationSummaryTableComponent
            gameHomeTeam={HomeTeam}
            gameAwayTeam={AwayTeam}
        >

            <GameSummaryTableDataComponent
                tableTitle={POSSESSIONS_NUMBER}
            >

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TOTAL_POSSESSIONS}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamPossessionsNumber
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamPossessionsNumber
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={MEDIUM_POSSESSION_TIME}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamMediumPossessionTime} sec`
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamMediumPossessionTime} sec`
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_THROW}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamPossessionsFinishedByThrowNumber}`
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamPossessionsFinishedByThrowNumber}`
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_ACCURATE_THROW}
                    summaryStatisticHomeTeamData={{
                        primaryValue: HomeTeamPossessions.AT.length
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: AwayTeamPossessions.AT.length
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW}
                    summaryStatisticHomeTeamData={{
                        primaryValue: HomeTeamPossessions.NAT.length
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: AwayTeamPossessions.NAT.length
                    }}
                />

                <GameSummaryTableHeaderComponent
                    tableTitle={POSSESSIONS_FINISHED_BY_FAULT}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamDribbleFault
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamDribbleFault
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_PASS_FAULT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamPassFault
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamPassFault
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_TIME_FAULT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamTimeFault
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamTimeFault
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_OUT_FAULT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamAutFault
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamAutFault
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamFoulFault
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamFoulFault
                    }}
                />

            </GameSummaryTableDataComponent>

        </GameInformationSummaryTableComponent>
    );


};


export default GameInformationPossessionsComponent;

import React, {useEffect, useRef} from "react";


import { Col, Row } from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import "./index.scss";


export interface TeamHeaderNameComponentProps {
    teamName: string;
    teamCity?: string | null;
}


export const TeamHeaderNameComponent: React.FC<TeamHeaderNameComponentProps> = ({
    teamName, teamCity
}) : JSX.Element | null => {


    const componentRef = useRef(null);

    useEffect(() => {
        if (componentRef?.current) {
            scrollToDomElement(componentRef.current);
        }
    }, []);


    return (
        <Col ref={componentRef} xs={8} lg={{span: 4, offset: 1}} className={"team-page__team-header__team-name"}>
            <Row className={"team-name-content"}>

                <Col xs={12} className={"team-name"}>
                    <span>{teamName}</span>
                </Col>

                {!!teamCity &&
                <Col xs={12} className={"team-city"}>
	                <span>{teamCity}</span>
                </Col>
                }

            </Row>
        </Col>
    );

};


export default TeamHeaderNameComponent;

import React, {useEffect, useState} from "react";


import { UseLoggedUser } from "@States";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { Redirect } from "react-router-dom";


import { MainContentLoaderComponent } from "@LoadersElements";

import {
    DefaultPageHeaderComponent, PageContainerComponent, PageContentComponent
} from "@ContentElements";

import ErrorMessageComponent from "@MessagesElements/Message/Error";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import GameDetailsAdminHeaderComponent from "@GameResultPage/__Admin/Header";
import GameDetailsAdminDataUploaderComponent from "@GameResultPage/__Admin/Uploader";


import { LeagueGameInterface } from "@Interfaces/Api";


import "./index.scss";


export interface GameDetailsAdminComponentProps {
    match: any;
}


export const GameDetailsAdminComponent: React.FC<GameDetailsAdminComponentProps> = ({
    match
}) : JSX.Element | null => {


    const User = UseLoggedUser();


    const GAME_ID = match?.params?.gameId;


    if (!GAME_ID || (!User || User.userAdminStatus !== true)) {
        return <Redirect to={`/`} />;
    }


    const [GameDetails, setGameDetails] = useState<LeagueGameInterface | null>(null);
    const [GameDetailsError, setGameDetailsError] = useState<ErrorMessageInterface | null>(null);

    const [updateGameDetailsTrigger, setUpdateGameDetailsTrigger] = useState(false);

    useEffect(() => {
        return () => {
            setGameDetails(null);
            setGameDetailsError(null);
            setUpdateGameDetailsTrigger(false);
        };
    }, []);

    useEffect(() => {

        if (!GAME_ID || (!User || User.userAdminStatus !== true)) return;

        if (GameDetails && !updateGameDetailsTrigger) {
            return;
        } else if (updateGameDetailsTrigger === true) {
            setUpdateGameDetailsTrigger(false);
        }

        const API_URL: string = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const getGameDetails = async () => {

            try {

                const response = await _restApiRequest(`${API_URL}/leagues/games/${GAME_ID}/`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setGameDetails(responseContent);
                } else {
                    setGameDetailsError({ errorCode: response.status, errorMessage: "Wystąpił błąd pobierania danych meczu" });
                }

            } catch (e) {
                setGameDetailsError({ errorCode: 500, errorMessage: "Wystąpił błąd pobierania danych meczu" });
            }

        };

        getGameDetails();

    }, [GAME_ID, updateGameDetailsTrigger]);


    if (GameDetailsError) {
        return <ErrorMessageComponent messageText={GameDetailsError.errorMessage} />;
    }


    if (!GameDetails) {
        return <MainContentLoaderComponent />;
    }


    return (
        <PageContainerComponent cssClasses={"game-details-admin"}>

            <DefaultPageHeaderComponent headerText={"Edycja Meczu"} />

            <PageContentComponent cssClasses={"game-details-admin__content"}>

                <GameDetailsAdminHeaderComponent
                    GameDetails={GameDetails}
                />

                <GameDetailsAdminDataUploaderComponent
                    GameDetails={GameDetails}
                    updateGameDetailsTriggerHandler={setUpdateGameDetailsTrigger}
                />

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default GameDetailsAdminComponent;

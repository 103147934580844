import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


export interface TeamCaptainTeamLeaguesHeaderComponentProps {}


export const TeamCaptainTeamLeaguesHeaderComponent: React.FC<TeamCaptainTeamLeaguesHeaderComponentProps> = ({}) : JSX.Element | null => {


    const LEAGUES_WORD_TEXT = UseCommonDictionaryPhrasesSet("LEAGUES_WORD_TEXT");


    return (
        <Col as={"h2"} xs={12} className={"player-profile__captain-zone__team__leagues__header"}>
            <span>{LEAGUES_WORD_TEXT}</span>
        </Col>
    );

};


export default TeamCaptainTeamLeaguesHeaderComponent;

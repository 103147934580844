import React from "react";


import { Row, Col } from "react-bootstrap";


import GameTablesTeamHeaderComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Header";
import GameTablesTeamTableComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables/Team/Table";


import "./index.scss";


import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";
import TeamSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams/Team";


interface GameInformationTablesTeamComponentProps {
    teamID: number;
    GameSummary: ApiGameSummaryInterface;
    TeamSummaryStatistics: TeamSummaryInterface;
}


export const GameInformationTablesTeamComponent: React.FC<GameInformationTablesTeamComponentProps> = ({
    teamID,
    GameSummary, TeamSummaryStatistics
}) : JSX.Element | null => {


    if (!teamID || !GameSummary || !TeamSummaryStatistics) return null;


    const {
        squads: GameSquads, players: GamePlayers
    } = GameSummary;

    const TeamSquad = GameSquads[teamID];


    if (!TeamSquad) return null;


    const {
        team_data: TeamData, team_crew: TeamCrew
    } = TeamSquad;


    const {
        team_captain_id: teamCaptainID
    } = TeamData;


    return (
        <Col xs={12} className={"game-information-tables-team game-information-tables-team-component"}>
            <Row className={"game-information-tables-team-content"}>

                {!!TeamData &&
                <GameTablesTeamHeaderComponent
                    TeamData={TeamData}
                    TeamCrew={TeamCrew}
                />
                }

                <GameTablesTeamTableComponent
                    teamID={teamID}
                    GamePlayers={GamePlayers}
                    teamCaptainID={teamCaptainID}
                    TeamSummaryData={TeamSummaryStatistics}
                />

            </Row>
        </Col>
    );

};


export default GameInformationTablesTeamComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import TeamPlayersListTeamPlayersPlayerComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayersList/ListPlayers/ListPlayer";


import {
    TeamPlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamPlayersListTeamPlayersComponentProps {
    TeamPlayers: TeamPlayerInterface[];
    updateTeamPlayersTrigger: (status: boolean) => void;
}

export const TeamPlayersListTeamPlayersComponent: React.FC<TeamPlayersListTeamPlayersComponentProps> = ({
    TeamPlayers, updateTeamPlayersTrigger
}) : JSX.Element | null => {


    const NO_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_PLAYERS_PHRASE_TEXT");


    if (!TeamPlayers) {
        return null;
    }


    if (!TeamPlayers?.length) {
        return <MessageComponent messageText={NO_PLAYERS_PHRASE_TEXT} cssClasses={"padding-10"} />;
    }


    const TeamPlayersList = TeamPlayers.map((TeamPlayer, i) =>
        <TeamPlayersListTeamPlayersPlayerComponent
            key={i}
            TeamPlayer={TeamPlayer}
            updateTeamPlayersTrigger={updateTeamPlayersTrigger}
        />
    );


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__players-list__players"}>
            <Row className={"player-profile__captain-zone__team__players__players-list__players__content"}>

                {TeamPlayersList}

            </Row>
        </Col>
    );

};


export default TeamPlayersListTeamPlayersComponent;

import LeadersInformationPlayerScoresInterface from "@GameResultPage/_Utitlities/Summary/Leaders/Types/Scores";


export const LeadersInformationPlayerScoresDefaults: LeadersInformationPlayerScoresInterface = {
    playerOfTheGame: null,
    mostPoints: null,
    gameThrowsEffectivity: null,
    pt3ThrowsEffectivity: null,
    pt2ThrowsEffectivity: null,
    pt1ThrowsEffectivity: null,
    mostRebounds: null,
    mostOffensiveRebounds: null,
    mostDefensiveRebounds: null,
    mostAssists: null,
    mostBlocks: null,
    mostSteals: null,
    mostTurnovers: null,
    greatestPlayTime: null,
};


export default LeadersInformationPlayerScoresDefaults;

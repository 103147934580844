import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import DEFAULT_LEAGUE_LOGO from "@Images/plka_league_home.png";


import "./index.scss";


interface HeaderLeagueLogoComponentProps {
    globalData: any;
}


import REST_API_ENDPOINTS_URLS from "@ApiData";


export const HeaderLeagueLogoComponent: React.FC<HeaderLeagueLogoComponentProps> = ({
    globalData = null
}) : JSX.Element | null => {


    if (!globalData) return null;


    const restApiUrl = REST_API_ENDPOINTS_URLS.API_ROOT_URL;


    const {league_name: leagueName, league_logo: leagueLogo} = globalData.settings;


    return (
        <Col xs={3} lg={1} className={"header-logo-component"}>

	        <NavLink to={"/"} title={leagueName?.value || ""} className={"league-logo"}>
		        <img src={leagueLogo ? `${restApiUrl}${leagueLogo?.image}` : DEFAULT_LEAGUE_LOGO} alt={leagueName?.value || ""}/>
	        </NavLink>

        </Col>
    );

};


export default HeaderLeagueLogoComponent;

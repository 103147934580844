export const countAgeByDate = (date?: string | Date | null) => {

    if (!date) return null;

    return Math.floor(
        ((new Date()).getTime() - (new Date(date)).getTime()) / 1000 / 3600 / 24 / 365
    );

};


export default countAgeByDate;

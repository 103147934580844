import React from "react";

import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


import InformationSectionTypes from "@Components/Views/Pages/Games/Games/Game/_Types/Sections";


type GameInformationSwitcherProps = {
    SelectedSection: InformationSectionTypes;
    SelectedSectionHandler(section: InformationSectionTypes): void;
};


export const GameInformationSwitcherComponent: React.FC<GameInformationSwitcherProps> = ({
    SelectedSection, SelectedSectionHandler = null
}) : JSX.Element | null => {


    const TABLES = UseGamesDictionaryPhrasesSet("TABLES");
    const SUMMARY = UseGamesDictionaryPhrasesSet("SUMMARY");
    const ARRANGE = UseGamesDictionaryPhrasesSet("ARRANGE");
    const POSSESSIONS = UseGamesDictionaryPhrasesSet("POSSESSIONS");
    const LEADERS = UseGamesDictionaryPhrasesSet("LEADERS");
    const THROWS = UseGamesDictionaryPhrasesSet("THROWS");
    const DIAGRAMS = UseGamesDictionaryPhrasesSet("DIAGRAMS");
    const PROGRESS = UseGamesDictionaryPhrasesSet("PROGRESS");


    if (!SelectedSection) return null;


    const changeSelectedSection = (section: InformationSectionTypes) : void => {
        if (!SelectedSectionHandler) return;
        SelectedSectionHandler(section);
    };


    return (
        <Col xs={12} className={"game-result-information-switcher game-result-information-switcher-component"}>
            <Row className={"game-result-information-switcher-content"}>

                <Col xs={4} lg={"auto"} onClick={() => changeSelectedSection(InformationSectionTypes.TABLES)} className={"information-switcher-element" + (SelectedSection === InformationSectionTypes.TABLES ? " active" : "")}>
                    <span>{TABLES}</span>
                </Col>

                <Col xs={4} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.SUMMARY ? InformationSectionTypes.TABLES : InformationSectionTypes.SUMMARY)} className={"information-switcher-element" + (SelectedSection === InformationSectionTypes.SUMMARY ? " active" : "")}>
                    <span>{SUMMARY}</span>
                </Col>

                <Col xs={4} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.ARRANGE ? InformationSectionTypes.TABLES : InformationSectionTypes.ARRANGE)} className={"information-switcher-element" + (SelectedSection === InformationSectionTypes.ARRANGE ? " active" : "")}>
                    <span>{ARRANGE}</span>
                </Col>

                <Col xs={3} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.POSSESSION ? InformationSectionTypes.TABLES : InformationSectionTypes.POSSESSION)} className={"information-switcher-element possessions-element" + (SelectedSection === InformationSectionTypes.POSSESSION ? " active" : "")}>
                    <span>{POSSESSIONS}</span>
                </Col>

                <Col xs={2} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.LEADERS ? InformationSectionTypes.TABLES : InformationSectionTypes.LEADERS)} className={"information-switcher-element leaders-element" + (SelectedSection === InformationSectionTypes.LEADERS ? " active" : "")}>
                    <span>{LEADERS}</span>
                </Col>

                <Col xs={2} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.THROWS ? InformationSectionTypes.TABLES : InformationSectionTypes.THROWS)} className={"information-switcher-element throws-element" + (SelectedSection === InformationSectionTypes.THROWS ? " active" : "")}>
                    <span>{THROWS}</span>
                </Col>

                <Col xs={3} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.DIAGRAMS ? InformationSectionTypes.TABLES : InformationSectionTypes.DIAGRAMS)} className={"information-switcher-element diagrams-element" + (SelectedSection === InformationSectionTypes.DIAGRAMS ? " active" : "")}>
                    <span>{DIAGRAMS}</span>
                </Col>

                <Col xs={2} lg={"auto"} onClick={() => changeSelectedSection(SelectedSection === InformationSectionTypes.EVENTS ? InformationSectionTypes.TABLES : InformationSectionTypes.EVENTS)} className={"information-switcher-element progress-element" + (SelectedSection === InformationSectionTypes.EVENTS ? " active" : "")}>
                    <span>{PROGRESS}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameInformationSwitcherComponent;

import React from "react";

import {useDispatch} from "react-redux";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import { Col, Form, Row } from "react-bootstrap";


const LANGUAGES: LanguageCodeType[] = ["PL", "EN"];


import LanguageCodeType from "@GlobalData/Localization/Languages";
import LocalizationActionTypes from "@Action-Types/Localization";


import "./index.scss";


interface HeaderLanguagesComponentProps {}


export const HeaderLanguagesComponent: React.FC<HeaderLanguagesComponentProps> = ({}) : JSX.Element | null => {


    const dispatcher = useDispatch();


    const activeLanguage = UseLanguage();


    const LanguagesList = LANGUAGES.map((language) =>
        <option key={language} value={language}>{language}</option>
    );


    const changeActiveLanguageCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const language = e.target.value;
        dispatcher({
            type: LocalizationActionTypes.CHANGE_LANGUAGE,
            language: language
        });
    };


    return (
        <Col xs={2} lg={1} className={"header-languages-switcher header-languages-switcher-component"}>
            <Row className={"header-languages-switcher-content"}>

                <Form.Control as={"select"} value={activeLanguage} onChange={changeActiveLanguageCode}>
                    {LanguagesList}
                </Form.Control>

            </Row>
        </Col>
    );

};


export default HeaderLanguagesComponent;

export const countEval = (
    pts: number = 0,
    treb: number = 0,
    ast: number = 0,
    stl: number = 0,
    blk: number = 0,
    to: number = 0,
    pt2a: number = 0,
    pt3a: number = 0,
    pt2: number = 0,
    pt3: number = 0,
    pt1a: number = 0,
    pt1: number = 0
) : number => {

    return (
        (pts + treb + ast + stl + blk)
        -
        to
        -
        (pt2a + pt3a - pt2 - pt3)
        -
        (pt1a - pt1)
    );

};


export default countEval;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


export interface FreeAgentRequestsListHeaderComponentProps {}


export const FreeAgentRequestsListHeaderComponent: React.FC<FreeAgentRequestsListHeaderComponentProps> = ({}) : JSX.Element | null => {


    const STATUS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATUS_WORD_TEXT");
    const LEAGUE_WORD_TEXT = UseCommonDictionaryPhrasesSet("LEAGUE_WORD_TEXT");
    const SEASON_WORD_TEXT = UseCommonDictionaryPhrasesSet("SEASON_WORD_TEXT");
    const DATE_OF_CREATION_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DATE_OF_CREATION_PHRASE_TEXT");


    return (
        <Col xs={12} className={"free-agent-requests__list__header free-agent-requests__list__element free-agent-requests__list__element--header"}>
            <Row className={"free-agent-requests__list__header__content"}>
                <Col xs={2} className={"free-agent-requests__list__element__request-status"}>
                    <span>{STATUS_WORD_TEXT}</span>
                </Col>
                <Col xs={3} className={"free-agent-requests__list__element__request-league"}>
                    <span>{LEAGUE_WORD_TEXT}</span>
                </Col>
                <Col xs={3} className={"free-agent-requests__list__element__request-season"}>
                    <span>{SEASON_WORD_TEXT}</span>
                </Col>
                <Col xs={2} className={"free-agent-requests__list__element__request-date"}>
                    <span>{DATE_OF_CREATION_PHRASE_TEXT}</span>
                </Col>
                <Col xs={2} className={"free-agent-requests__list__element__request-actions"}></Col>
            </Row>
        </Col>
    );

};


export default FreeAgentRequestsListHeaderComponent;

import LeagueRoundsLabelsType from "@Texts/Games/Schedule/Rounds/_Type";


import {
    LEAGUE_ROUND_ROUND_LABEL_PL, LEAGUE_ROUND_ROUND_LABEL_EN,
    LEAGUE_ROUND_ROUND_PLAYOFFS_LABEL_PL, LEAGUE_ROUND_ROUND_PLAYOFFS_LABEL_EN,
    LEAGUE_ROUND_ROUND_QUARTER_FINALS_LABEL_PL, LEAGUE_ROUND_ROUND_QUARTER_FINALS_LABEL_EN,
    LEAGUE_ROUND_ROUND_SEMI_FINALS_LABEL_PL, LEAGUE_ROUND_ROUND_SEMI_FINALS_LABEL_EN,
    LEAGUE_ROUND_ROUND_SMALL_FINAL_LABEL_PL, LEAGUE_ROUND_ROUND_SMALL_FINAL_LABEL_EN,
    LEAGUE_ROUND_ROUND_FINAL_LABEL_PL, LEAGUE_ROUND_ROUND_FINAL_LABEL_EN,
} from "@Texts/Games/Schedule/Rounds/Round";


export const LeagueRoundsLabels: LeagueRoundsLabelsType = {
    1: { PL: LEAGUE_ROUND_ROUND_LABEL_PL, EN: LEAGUE_ROUND_ROUND_LABEL_EN },
    2: { PL: LEAGUE_ROUND_ROUND_PLAYOFFS_LABEL_PL, EN: LEAGUE_ROUND_ROUND_PLAYOFFS_LABEL_EN },
    3: { PL: LEAGUE_ROUND_ROUND_QUARTER_FINALS_LABEL_PL, EN: LEAGUE_ROUND_ROUND_QUARTER_FINALS_LABEL_EN },
    4: { PL: LEAGUE_ROUND_ROUND_SEMI_FINALS_LABEL_PL, EN: LEAGUE_ROUND_ROUND_SEMI_FINALS_LABEL_EN },
    5: { PL: LEAGUE_ROUND_ROUND_SMALL_FINAL_LABEL_PL, EN: LEAGUE_ROUND_ROUND_SMALL_FINAL_LABEL_EN },
    6: { PL: LEAGUE_ROUND_ROUND_FINAL_LABEL_PL, EN: LEAGUE_ROUND_ROUND_FINAL_LABEL_EN },
};


export default LeagueRoundsLabels;




import React from "react";


import {Col, Form, Row} from "react-bootstrap";

import ColumnProps from "@BootstrapInterfaces/Column";

import { AiOutlineCheck } from "react-icons/all";


import "./index.scss";


import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


export interface FormCheckboxFieldComponentProps extends ColumnProps  {
    fieldName: string;
    fieldLabel?: string;
    fieldCheckedStatus: boolean;
    fieldCheckedStatusHandler: (...args: any[]) => void;
    fieldLegendText?: string | null;
    fieldError?: FormFieldValueErrorInterface | null;
    fieldCssClasses?: string | [];
}


export const FormCheckboxFieldComponent: React.FC<FormCheckboxFieldComponentProps> = ({
    fieldName, fieldError,
    fieldLabel, fieldLegendText,
    fieldCheckedStatus, fieldCheckedStatusHandler,
    fieldCssClasses = "",
    xs = 12, lg = 12
}) => {


    if (typeof fieldCssClasses === "object") {
        fieldCssClasses = fieldCssClasses.join(" ");
    }

    fieldCssClasses = `form-field form-field-component checkbox-form-field` + (!!fieldCssClasses ? ` ${fieldCssClasses}` : fieldCssClasses);


    return (
        <Form.Group as={Col} xs={xs} lg={lg} className={fieldCssClasses}>
            <Row className={"form-field__content checkbox-form-field__content"}>

                <Col xs={"auto"} className={"checkbox-form-field__input"}>
                    <span className={"checkbox"} onClick={fieldCheckedStatusHandler}>
                        {fieldCheckedStatus && <AiOutlineCheck />}
                    </span>
                </Col>

                <Col xs={"auto"} className={"checkbox-form-field__label"}>
                    <Form.Label>{fieldLabel}</Form.Label>
                </Col>

                {!!fieldError &&
                <Form.Label className={"error"}>{fieldError.errorMessage}</Form.Label>
                }

                {!!fieldLegendText &&
                <Col xs={12} className={"checkbox-form-field__legend"}>
                    {fieldLegendText}
                </Col>
                }

            </Row>
        </Form.Group>
    )

};


export default FormCheckboxFieldComponent;

import React from "react";


import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import _ from "lodash";


import {Container, Row} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";


import SeasonsAndLeaguesSeasonsComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/SeasonsAndLeagues/Seasons";
import SeasonsAndLeaguesLeaguesComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/SeasonsAndLeagues/Leagues";


const TEXTS: any = {
    PL: {
        no_season_selected_message_text: "Nie wybrano sezonu",
        no_leagues_in_season_message_text: "Brak Lig w sezonie",
    },
    EN: {
        no_season_selected_message_text: "No season selected",
        no_leagues_in_season_message_text: "No leagues in season",
    },
};


interface SeasonLeagueInterface {
    leagueID: number;
    leagueName: string;
    leagueShortName?: string;
}

interface LeaguesSeasonInterface {
    [key: number]: {
        seasonID: number;
        seasonName: string | null;
        seasonLeagues: {
            [key: string]: SeasonLeagueInterface
        }
    }
}


export interface SeasonsAndLeaguesComponentProps {
    leaguesBySeasons: LeaguesSeasonInterface;
    selectedSeasonID: number;
    selectedSeasonIDHandler(id: number): void;
    selectedLeagueID: number;
    selectedLeagueIDHandler(ids: number): void
}


export const SeasonsAndLeaguesComponent: React.FC<SeasonsAndLeaguesComponentProps> = ({
    leaguesBySeasons,
    selectedSeasonID, selectedSeasonIDHandler,
    selectedLeagueID, selectedLeagueIDHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        no_season_selected_message_text: NO_SEASON_SELECTED_MESSAGE_TEXT,
        no_leagues_in_season_message_text: NO_LEAGUES_IN_SEASON_MESSAGE_TEXT
    } = TEXTS[activeLanguage];


    if (!leaguesBySeasons) return null;


    if (!selectedSeasonID) return <MessageComponent messageText={NO_SEASON_SELECTED_MESSAGE_TEXT} />;


    const SelectedSeason = leaguesBySeasons[selectedSeasonID];

    if (!SelectedSeason) return <MessageComponent messageText={NO_SEASON_SELECTED_MESSAGE_TEXT} />;


    const SeasonLeagues = SelectedSeason.seasonLeagues;

    if (!SeasonLeagues || !Object.values(SeasonLeagues).length) return <MessageComponent messageText={NO_LEAGUES_IN_SEASON_MESSAGE_TEXT} />;


    const SeasonLeaguesList = _.sortBy(
        Object.values(SeasonLeagues), ["leagueActiveStatus", "leagueOrderNr", "leagueName"]
    );


    return (
        <Container fluid={true} id={"leagues-and-seasons-component"} className={"leagues-and-seasons leagues-and-seasons-component page-section-component main-section-component"}>
            <Container className={"main-section-container page-section-container"}>
                <Row className={"main-section-content page-section-row"}>

                    <SeasonsAndLeaguesLeaguesComponent
                        leaguesList={Object.values(SeasonLeaguesList)}
                        selectedLeagueID={selectedLeagueID}
                        selectedLeagueIDHandler={selectedLeagueIDHandler}
                    />

                    <SeasonsAndLeaguesSeasonsComponent
                        seasonsList={Object.values(leaguesBySeasons)}
                        selectedSeasonID={selectedSeasonID}
                        selectedSeasonIDHandler={selectedSeasonIDHandler}
                    />


                </Row>
            </Container>
        </Container>
    );


};


export default SeasonsAndLeaguesComponent;

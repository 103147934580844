import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import RoundBestPlayerPlayerNameComponent from "@ResultsPages/Rounds/Round/BestPlayer/Player/Information/Name";
import RoundBestPlayerPlayerStatsComponent from "@ResultsPages/Rounds/Round/BestPlayer/Player/Information/Statistics";


import {
    LeagueRoundBestPlayerInterface,
} from "@Interfaces/Api";


import "./index.scss";


export interface RoundBestPlayerPlayerInformationComponentProps {
    BestPlayer: LeagueRoundBestPlayerInterface | null;
}


export const RoundBestPlayerPlayerInformationComponent: React.FC<RoundBestPlayerPlayerInformationComponentProps> = ({
    BestPlayer
}) : JSX.Element | null => {


    if (!BestPlayer) {
        return null;
    }


    const {
        player_team: playerTeam, player_data: playerData, player_stats: playerStats
    } = BestPlayer;


    return (
        <Col xs={9} lg={12} className={"leagues-schedules__league-rounds-list__league_round__best-player__player__information"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__player__name__information"}>

                <RoundBestPlayerPlayerNameComponent Player={playerData} Team={playerTeam} />

                <RoundBestPlayerPlayerStatsComponent Statistics={playerStats} />

            </Row>
        </Col>
    );

};


export default RoundBestPlayerPlayerInformationComponent;

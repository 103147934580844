import React from "react";


import {
    ListByLeaguesType
} from "@Interfaces/Api";


import DefaultLeaguesSwitcherComponent from "@Elements/Games/Leagues/Switchers/DefaultLeaguesSwitcher";


export interface TransfersListLeaguesComponentProps {
    TransfersList:  ListByLeaguesType;
    selectedLeagueID: number;
    selectedLeagueIDHandler: (leagueID: number) => void;
}


export const TransfersListLeaguesComponent: React.FC<TransfersListLeaguesComponentProps> = ({
    TransfersList,
    selectedLeagueID, selectedLeagueIDHandler
}) : JSX.Element | null => {


    if (!TransfersList) {
        return null;
    }


    const TransfersListLeagues = TransfersList.map((League) => {
        const { league_id, league_name, league_short_name } = League;
        return { league_id, league_name, league_short_name };
    });


    return (
        <DefaultLeaguesSwitcherComponent
            Leagues={TransfersListLeagues}
            selectedLeagueID={selectedLeagueID}
            selectedLeagueIDHandler={selectedLeagueIDHandler}
        />
    );

};


export default TransfersListLeaguesComponent;

import {
    UseLanguage
} from "@States";


export const FreeAgentRequestStatusLabels = {
    PL: {
        1: "Otwarty", 2: "Zawieszony", 3: "Zakończony", 4: "Odrzucony"
    },
    EN: {
        1: "Opened", 2: "Suspended", 3: "Finished", 4: "Rejected"
    }
};


export const UseFreeAgentRequestStatusLabel = (status: 1 | 2 | 3 | 4) => {

    const activeLanguage = UseLanguage();

    return FreeAgentRequestStatusLabels[activeLanguage][status];

};


export default UseFreeAgentRequestStatusLabel;

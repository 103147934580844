import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { UseTeamsDictionaryPhrasesSet } from "@Hooks/Texts";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


export interface PlayerTeamComponentProps {
    Team?: LeagueTeamInterface;
}


export const PlayerTeamComponent: React.FC<PlayerTeamComponentProps> = ({
    Team
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    const GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT = UseTeamsDictionaryPhrasesSet("GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT");


    if (!Team) return null;


    const {
        team_slug: playerTeamSlug,
        team_logo: playerTeamLogo,
        team_name: playerTeamName,
    } = Team;


    const teamPageServiceURL = "/" + TeamsRoute.routeURL + "/" + playerTeamSlug;


    return (
        <Col xs={12} lg={{ span: 2, offset: 2 }} className={"player-team-logo"}>
            {playerTeamSlug && playerTeamLogo &&
            <NavLink to={teamPageServiceURL} title={GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT}>
                <img src={playerTeamLogo} alt={playerTeamName}/>
            </NavLink>
            }
        </Col>
    );

};


export default PlayerTeamComponent;

import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";

import ColumnProps from "@BootstrapInterfaces/Column";


import {
    AiFillCaretDown, AiFillCaretUp
} from "react-icons/all";


import TeamLeaguesSwitcherLeagueComponent from "@TeamsPages/Team/_Elements/TeamLeaguesSwitcher/League";


import {
    TeamLeagueInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamLeaguesSwitcherComponentProps extends ColumnProps{
    TeamLeagues: TeamLeagueInterface[];
    selectedTeamLeagueID: number;
    selectedTeamLeagueIDHandler: (leagueID: number) => void;
}

export const TeamLeaguesSwitcherComponent: React.FC<TeamLeaguesSwitcherComponentProps> = ({
    TeamLeagues,
    selectedTeamLeagueID, selectedTeamLeagueIDHandler,
    xs, sm, lg, xl
}) : JSX.Element | null => {


    const [showAvailableLeaguesList, setShowAvailableLeaguesList] = useState(false);

    useEffect(() => {
        const outsideClickListener = (e: MouseEvent) => {
            const target = (e.target as Element);
            const classes = target.classList;
            const parentClasses = target.parentElement?.classList || classes;
            if (!classes && !parentClasses) {
                return;
            }
            if (classes.contains("team-leagues-switcher__button__icon") || parentClasses.contains("team-leagues-switcher__button__icon") || classes.contains("team-leagues-switcher__leagues-list__league")) {
                return;
            }
            setShowAvailableLeaguesList(false);
        };
        document.addEventListener("click", outsideClickListener);
        return () => {
            setShowAvailableLeaguesList(false);
            document.removeEventListener("click", outsideClickListener);
        };
    }, []);


    if (!TeamLeagues?.length) {
        return null;
    }

    const SelectedLeague = TeamLeagues.filter((League) => {
        const { id: leagueID } = League;
        return leagueID === selectedTeamLeagueID;
    })[0];


    const selectedLeagueIDHandler = (leagueID: number) => {
        selectedTeamLeagueIDHandler(leagueID);
        setShowAvailableLeaguesList(false);
    };


    const AvailableLeagues = TeamLeagues.map((League) => {

        const { id: leagueID } = League;

        if (leagueID === selectedTeamLeagueID) {
            return null;
        }

        return (<TeamLeaguesSwitcherLeagueComponent
            key={leagueID}
            TeamLeague={League}
            selectedTeamLeagueIDHandler={selectedLeagueIDHandler}
        />);

    });


    return (
        <Col xs={xs} sm={sm} lg={lg} xl={xl} className={"team-leagues-switcher"}>
            <Row className={"team-leagues-switcher__content"}>

                <TeamLeaguesSwitcherLeagueComponent
                    xs={"auto"}
                    TeamLeague={SelectedLeague}
                    selectedLeagueStatus={true}
                />

                {AvailableLeagues?.length > 1 &&
                <Col xs={"auto"} className={"team-leagues-switcher__button"}>
                    <span onClick={() => setShowAvailableLeaguesList(!showAvailableLeaguesList)} className={"team-leagues-switcher__button__icon"}>
                        {showAvailableLeaguesList ? <AiFillCaretUp className={"team-leagues-switcher__button__icon"} /> : <AiFillCaretDown className={"team-leagues-switcher__button__icon"} />}
                    </span>
                </Col>
                }

                {showAvailableLeaguesList && AvailableLeagues?.length &&
                <Col xs={12} className={"team-leagues-switcher__leagues-list"}>
                    <Row className={"team-leagues-switcher__leagues-list__content"}>
                        {AvailableLeagues}
                    </Row>
                </Col>
                }

            </Row>
        </Col>
    )

};


export default TeamLeaguesSwitcherComponent;

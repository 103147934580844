import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


import TeamsAndPlayersPageComponent from "@Components/Views/Pages/Games/Teams/List";
import TeamPageComponent from "@Components/Views/Pages/Games/Teams/Team";


export interface TeamsPageComponentProps {}


export const TeamsPageComponent: React.FC<TeamsPageComponentProps> = () : JSX.Element | null => {


    return (
        <Switch>

            <Route path={"/druzyny"} exact render={() =>
                <TeamsAndPlayersPageComponent />
            }
            />

            <Route path={"/druzyny/:teamSlug"} render={props =>
                <TeamPageComponent {...props} />
            }
            />

            <Redirect to={`/404`} />

        </Switch>
    );

};


export default TeamsPageComponent;

import React, { useEffect, useState } from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { UseCommonDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import TeamFilesListComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/List";


import { LeagueTeamFileInterface } from "@Interfaces/Api";

import TeamFileInterface from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/_Types/TeamFile";


import "./index.scss";


export interface TeamCaptainTeamFilesComponentProps {
    teamApiURL: string;
}


export const TeamCaptainTeamFilesComponent: React.FC<TeamCaptainTeamFilesComponentProps> = ({
    teamApiURL
}) : JSX.Element | null => {


    const DEALS_AND_DOCUMENTS_TEXT = UseCommonDictionaryPhrasesSet("DEALS_AND_DOCUMENTS_TEXT");


    const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;


    const [TeamFiles, setTeamFiles] = useState<LeagueTeamFileInterface[] | null>(null);
    const [TeamFilesError, setTeamFilesError] = useState<ErrorMessageInterface | null>(null);

    const [teamFilesListUpdateTrigger, setTeamFilesListUpdateTrigger] = useState<boolean>(false);

    useEffect(() => {

        if (!teamApiURL) {
            return setTeamFilesError({ errorCode: 400, errorMessage: "Brak ID zespołu" });
        }

        if (teamFilesListUpdateTrigger) {
            return setTeamFilesListUpdateTrigger(false);
        }

        const API_EP_URL = `${teamApiURL}get-team-files/`;

        const getTeamFiles = async () => {

            try {

                const response = await _restApiRequest(API_EP_URL);

                if (response.ok) {
                    const responseContent = await response.json();
                    setTeamFiles(responseContent);
                } else {
                    setTeamFilesError({ errorCode: response.status, errorMessage: "Wystąpił błąd pobierania danych" });
                }

            } catch (e) {
                setTeamFilesError({ errorCode: 500, errorMessage: "Wystąpił błąd pobierania danych" });
            }

        };

        getTeamFiles();

    }, [teamApiURL, teamFilesListUpdateTrigger]);

    useEffect(() => {
        return () => {
            setTeamFiles(null);
            setTeamFilesError(null);
            setTeamFilesListUpdateTrigger(false);
        };
    }, []);


    const fileUploadHandler = async (File: TeamFileInterface) : Promise<boolean> => {

        if (!File || !teamApiURL|| !File.fileFile) {
            return false;
        }

        const formData = new FormData();

        if (File.fileName) {
            formData.append("team_file_name", File.fileName);
        }

        formData.append("team_file_team", teamApiURL);
        formData.append("team_file_file", File.fileFile, File.fileFile.name);

        const API_EP_URL = `${API_URL}/teams/files/`;

        try {

            const response = await _restApiRequest(
                API_EP_URL, formData, "POST"
            );

            if (response.ok) {
                setTeamFilesListUpdateTrigger(true);
                return true;
            }

        } catch (e) {}

        return false;
    };


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__team-files"}>
            <Row className={"player-profile__captain-zone__team__team-files__content"}>

                <Col as={"h2"} xs={12} className={"player-profile__captain-zone__team__team-files__header captain-zone-team-files__header"}>
                    <span>{DEALS_AND_DOCUMENTS_TEXT}</span>
                </Col>

                <TeamFilesListComponent
                    TeamFiles={TeamFiles}
                    TeamFilesError={TeamFilesError}
                    fileUploadHandler={fileUploadHandler}
                />

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamFilesComponent;

import React from "react";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import PlayerInformationDescriptionComponent from "@Components/Views/Pages/Games/Players/Player/InformationData/Description";
import PlayerInformationStatisticsComponent from "@Components/Views/Pages/Games/Players/Player/InformationData/Statistics";


import countAgeByDate from "@Utilities/Counting/Dates/AgeByDate";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayerInformationDataComponentProps {
    player: LeaguePlayerInterface;
}


export const PlayerInformationDataComponent: React.FC<PlayerInformationDataComponentProps> = ({
    player
}) : JSX.Element | null => {


    if (!player) return null;


    const {
        player_id: playerID,
        player_height: playerHeight, player_weight: playerWeight,
        player_birthdate: playerBirthdate, player_nationality: playerNationality,
    } = player;


    const playerAge = countAgeByDate(playerBirthdate);


    return (
        <ContainerSectionComponent id={"player-information-data-component"} classes={"player-information-data-component player-information-data"}>

            <PlayerInformationStatisticsComponent
                playerID={playerID}
            />

            <PlayerInformationDescriptionComponent
                playerHeight={playerHeight} playerWeight={playerWeight}
                playerAge={playerAge} playerNationality={playerNationality}
            />

        </ContainerSectionComponent>
        );

};


export default PlayerInformationDataComponent;

import { Dispatch } from "redux";

import ApplicationActionTypes from "@ApplicationAction-Types";
import ApplicationAction from "@ApplicationActions";


const UseChangeApiRequestStatus = (status: boolean) => {

    return (dispatch: Dispatch<ApplicationAction>) => {
        dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: status
        });
    };

};


export default UseChangeApiRequestStatus;

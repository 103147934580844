import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamCaptainTeamLeaguesHeaderComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/Header";
import TeamCaptainTeamLeaguesListComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList";


import UseTeamLeaguesWithSquads from "@Hooks/Api/Requests/Games/Teams/Leagues";


import "./index.scss";


export interface TeamCaptainTeamLeaguesComponentProps {
    teamID: number;
}


export const TeamCaptainTeamLeaguesComponent: React.FC<TeamCaptainTeamLeaguesComponentProps> = ({
    teamID
}) : JSX.Element | null => {


    const [updateTeamLeaguesTrigger, setUpdateTeamLeaguesTrigger] = useState(false);

    useEffect(() => {

        if (!updateTeamLeaguesTrigger) {
            return;
        }

        setUpdateTeamLeaguesTrigger(false);

    }, [updateTeamLeaguesTrigger]);

    useEffect(() => {
        return () => {
            setUpdateTeamLeaguesTrigger(false);
        };
    }, []);


    const [TeamLeagues, TeamLeaguesError] = UseTeamLeaguesWithSquads(teamID, updateTeamLeaguesTrigger);


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues"}>
            <Row className={"player-profile__captain-zone__team__leagues__content"}>

                <TeamCaptainTeamLeaguesHeaderComponent />

                <TeamCaptainTeamLeaguesListComponent
                    teamID={teamID}
                    TeamLeagues={TeamLeagues}
                    TeamLeaguesError={TeamLeaguesError}
                    updateTeamLeaguesTriggerHandler={setUpdateTeamLeaguesTrigger}
                />

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamLeaguesComponent;

import React, {useEffect, useRef} from "react";


import parse from "html-react-parser";


import { Col } from "react-bootstrap";


import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import "./index.scss";
import scrollToDomElement from "@Utilities/Scrolling";


export interface TextPageComponentProps {
    pageTitle: string;
    pageContent: string;
}


export const TextPageComponent: React.FC<TextPageComponentProps> = ({
    pageTitle = "", pageContent = ""
}) => {


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef?.current) return;
        scrollToDomElement(ComponentRef.current, 200);
    }, [ComponentRef]);


    return (
        <PageContainerComponent forwardRef={ComponentRef}>

            <DefaultPageHeaderComponent headerText={pageTitle} />

            <PageContentComponent cssClasses={"text-page"}>
                <Col xs={12} className={"text-page__content privacy-policy-page__content"}>
                    {parse(pageContent)}
                </Col>
            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default TextPageComponent;

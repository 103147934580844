import React from "react";


import {
    UseErrorMessage
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";

import MessageComponent from "@Components/Elements/Messages/Content";


import {
    UseLeagueTables
} from "@Hooks/Api/Requests";

import LeaguesTablesGroupComponent from "@Pages/Common/HomePage/Leagues/Tables/Tables/Group";
import LeaguesTablesFullTableLinkComponent from "@Pages/Common/HomePage/Leagues/Tables/Tables/FullTableLink";


import "./index.scss";


export interface LeaguesTablesTableComponentProps {
    selectedLeagueID: number | null;
    selectedSeasonID: number | null;
    noLeagues: boolean;
}


export const LeaguesTablesTablesComponent: React.FC<LeaguesTablesTableComponentProps> = ({
    selectedLeagueID = null, selectedSeasonID, noLeagues = false
}) : JSX.Element | null => {


    const REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");


    const [LeagueTables, LeagueTablesError] = UseLeagueTables(selectedLeagueID);


    if (LeagueTablesError) {
        return <MessageComponent xs={9} lg={9} messageText={REQUEST_ERROR_MESSAGE} messageType={"error"} cssClasses={"align-content-top"} />;
    }

    if (noLeagues) {
        return null;
    }

    if (!LeagueTables) {
        return <SingleContentLoaderComponent xs={9} lg={9} cssClasses={"no-margin-top start"} />;
    }


    const {
        league_groups: LeagueGroups
    } = LeagueTables;


    const LeagueGroupsList = LeagueGroups.map((Group, i) =>
        <LeaguesTablesGroupComponent key={i} Group={Group} />
    );


    return (
        <Col xs={9} lg={9} className={"home-page__leagues__tables__tables"}>
            <Row className={"home-page__leagues__tables__tables__content"}>

                {LeagueGroupsList}

                <LeaguesTablesFullTableLinkComponent
                    selectedSeasonID={selectedSeasonID}
                    selectedLeagueID={selectedLeagueID}
                />

            </Row>

        </Col>
    );

};


export default LeaguesTablesTablesComponent;

import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface TeamCaptainTeamPlayersHeaderComponentProps {}


export const TeamCaptainTeamPlayersHeaderComponent: React.FC<TeamCaptainTeamPlayersHeaderComponentProps> = ({}) : JSX.Element | null => {


    const ALL_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("ALL_PLAYERS_PHRASE_TEXT");
    const INCLUDING_HISTORICAL_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("INCLUDING_HISTORICAL_PHRASE_TEXT");


    return (
        <Col as={"h2"} xs={12} className={"player-profile__captain-zone__team__players__header"}>
            <span>{ALL_PLAYERS_PHRASE_TEXT} ({INCLUDING_HISTORICAL_PHRASE_TEXT})</span>
        </Col>
    );

};


export default TeamCaptainTeamPlayersHeaderComponent;

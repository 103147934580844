import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import StatisticsType from "@GlobalData/Statistics/Types/Type";


import "./index.scss";


export interface TeamTop5PlayersListHeaderComponentProps {
    selectedStatisticsType: StatisticsType;
    selectedStatisticsTypeHandler: (type: StatisticsType) => void;
}

export const TeamTop5PlayersListHeaderComponent: React.FC<TeamTop5PlayersListHeaderComponentProps> = ({
    selectedStatisticsType, selectedStatisticsTypeHandler
}) : JSX.Element | null => {


    const STATISTICS_POINTS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_POINTS_WORD_TEXT");
    const STATISTICS_ASSISTS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ASSISTS_WORD_TEXT");
    const STATISTICS_REBOUNDS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_REBOUNDS_WORD_TEXT");
    const STATISTICS_STEALS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_STEALS_WORD_TEXT");
    const STATISTICS_BLOCKS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_BLOCKS_WORD_TEXT");
    const STATISTICS_EVAL_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EVAL_TEXT");


    return (
        <Col xs={12} className={"team-details__team-information__team-top-players__list__header"}>
            <Row className={"team-details__team-information__team-top-players__list__header__content"}>

                <Col xs={"auto"} className={"team-details__team-information__team-top-players__list__header__option" + (selectedStatisticsType === "TPTS" ? " active" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("TPTS")}>{STATISTICS_POINTS_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"team-details__team-information__team-top-players__list__header__option" + (selectedStatisticsType === "EVAL" ? " active" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("EVAL")}>{STATISTICS_EVAL_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"team-details__team-information__team-top-players__list__header__option" + (selectedStatisticsType === "AST" ? " active" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("AST")}>{STATISTICS_ASSISTS_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"team-details__team-information__team-top-players__list__header__option" + (selectedStatisticsType === "TREB" ? " active" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("TREB")}>{STATISTICS_REBOUNDS_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"team-details__team-information__team-top-players__list__header__option" + (selectedStatisticsType === "STL" ? " active" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("STL")}>{STATISTICS_STEALS_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"team-details__team-information__team-top-players__list__header__option" + (selectedStatisticsType === "BLK" ? " active" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("BLK")}>{STATISTICS_BLOCKS_WORD_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamTop5PlayersListHeaderComponent;

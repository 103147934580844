import React, {useEffect} from "react";

import {useDispatch} from "react-redux";
import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";


import {Col, Container, Row} from "react-bootstrap";

import TagManager from 'react-gtm-module'


import GlobalApiRequestLoaderComponent from "@/UI/Loaders/GlobalApiRequest";


import ApplicationHeaderComponent from "@Components/Views/Parts/Header";
import ApplicationResultsBarComponent from "@Components/Views/Parts/Results";
import ApplicationSponsorsComponent from "@Components/Views/Parts/Sponsors";
import ApplicationFooterComponent from "@Components/Views/Parts/Footer";
import ApplicationCopyrightsComponent from "@Components/Views/Parts/Copyrights";


import Error404PageComponent from "@Components/Views/Pages/_Errors/404";

import TextPageComponent from "@Components/Views/Pages/Common/_Templates/TextPage";
import ArticlesPageComponent from "@Components/Views/Pages/Common/ArticlesPage";
import ArticleDetailPageComponent from "@Components/Views/Pages/Common/ArticlesPage/ArticleDetail";

import HomePageComponent from "@Components/Views/Pages/Common/HomePage";

import ContactPageComponent from "@Components/Views/Pages/Common/ContactPage";
import PartnersPageComponent from "@Components/Views/Pages/Common/PartnersPage";
import FilesForDownloadPageComponent from "@Components/Views/Pages/Common/DownloadPage";

import LoginPageComponent from "@Components/Views/Pages/User/NotAuthenticated/Login";

import ApplicationGamesComponent from "@Components/Views/Pages/Games";

import LeagueResultsPageComponent from "@Components/Views/Pages/Games/Results";
import GamesLeaguesComponent from "@Components/Views/Pages/Games/Leagues";


import {ApplicationActionTypes} from "@Action-Types";
import UserProfilePageComponent from "@Components/Views/Pages/User/Authenticated/Profile";
import PasswordSetPageComponent from "@Components/Views/Pages/User/NotAuthenticated/PasswordSet";
import {AiFillCloseCircle} from "react-icons/all";

import usePopupMessage from "@Store/_Hooks/_States/Application/PopupMessage";
import UseActions from "@Store/_Hooks/UseActions";
import {UseSetPopupMessage} from "@Action-Creators/Application";
import GlobalPopupComponent from "@ContentElements/Popups/GlobalPopup";
import RegulationsPageComponent from "@Pages/Common/FormalsPages/RegulationsPage";
import PrivacyPolicyPageComponent from "@Pages/Common/FormalsPages/PrivacyPolicyPage";
import {CookieConsent} from "react-cookie-consent";
import {COOKIE_MESSAGE_EN} from "@Dictionary/Globals";
import {UseCommonDictionaryPhrasesSet, UseGlobalsDictionaryPhrasesSet} from "@Hooks/Texts";
import PasswordResetComponent from "@Pages/User/NotAuthenticated/PasswordReset";


import "./Application.scss";
import LeagueScheduleAdminComponent from "@ResultsPages/_Admin";
import {UseGlobalData} from "@ApplicationStates";
import TransfersListPageComponent from "@GamesPages/Transfers";
import RegisterPageComponent from "@Pages/User/NotAuthenticated/Register";


import "@Assets/@Styles/Application.scss";


export const Application = () => {


    const COOKIE_MESSAGE = UseGlobalsDictionaryPhrasesSet("COOKIE_MESSAGE");
    const I_UNDERSTAND_PHRASE = UseCommonDictionaryPhrasesSet("I_UNDERSTAND_PHRASE");


    const dispatcher = useDispatch();


    const {
        apiRequestStatus, globalBlackoutStatus
    } = useTypedSelector((state) => state.app);


    const { UseSetPopupMessage } = UseActions();
    const PopupMessage = usePopupMessage();


    const API_ROOT_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;


    useEffect(() => {

        if (!API_ROOT_URL) return;

        const getApplicationData = async () => {

            const DATA_API_URL = `${API_ROOT_URL}/application/data/`;

            try {

                const RESPONSE = await _restApiRequest(DATA_API_URL);

                if (RESPONSE.ok) {
                    const RESPONSE_CONTENT = await RESPONSE.json();
                    dispatcher({
                        type: ApplicationActionTypes.GLOBAL_DATA_ACTION,
                        data: RESPONSE_CONTENT
                    });
                } else {}

            } catch (e) {}

        };

        getApplicationData();

    }, [API_ROOT_URL]);


    return (
        <Router>

            <Route path={`/`} render={() => <ApplicationHeaderComponent />} />
            <Route path={`/`} exact render={() => <ApplicationResultsBarComponent />} />

            <Switch>

                <Route path={`/`} exact render={(props) => <HomePageComponent {...props} />} />

                <Route path={`/aktualnosci`} exact render={(props) => <ArticlesPageComponent {...props} />} />
                <Route path={`/aktualnosci/:articleSlug`} exact render={(props) => <ArticleDetailPageComponent {...props} />} />

                <Route path={`/kontakt`} exact render={() => <ContactPageComponent />} />
                <Route path={`/partnerzy`} exact render={() => <PartnersPageComponent />} />
                <Route path={`/do-pobrania`} exact render={() => <FilesForDownloadPageComponent />} />

                <Route path={`/polityka-prywatnosci`} exact render={() => <PrivacyPolicyPageComponent />} />
                <Route path={`/regulamin`} exact render={() => <RegulationsPageComponent />} />

                <Route path={`/terminarz/admin`} exact render={(props) => <LeagueScheduleAdminComponent {...props} />} />
                <Route path={`/terminarz`} exact render={(props) => <LeagueResultsPageComponent {...props} />} />

                <Route path={`/tabele`} render={(props) => <GamesLeaguesComponent {...props} />} />

                <Route path={`/wolni-agenci`} render={(props) => <TransfersListPageComponent {...props} />} />

                <Route exact path={`/logowanie/ustaw-haslo`} render={(props) => <PasswordSetPageComponent {...props} />} />
                <Route exact path={`/logowanie/zresetuj-haslo`} render={(props) => <PasswordResetComponent {...props} />} />
                <Route exact path={`/logowanie/rejestracja`} render={(props) => <RegisterPageComponent {...props} />} />
                <Route exact path={`/logowanie`} render={(props) => <LoginPageComponent {...props} />} />
                <Route path={`/profil`} render={(props) => <UserProfilePageComponent />} />

                <Route path={`/`} render={(props) => <ApplicationGamesComponent {...props} />} />

                <Route path={`/404`} render={() => <Error404PageComponent />} />

                <Redirect to={`/404`} />

            </Switch>

            <Route path={`/`} render={() => <ApplicationSponsorsComponent />} />
            <Route path={`/`} render={(props) => <ApplicationFooterComponent {...props} />} />
            <Route path={`/`} render={() => <ApplicationCopyrightsComponent />} />

            {globalBlackoutStatus &&
			<Container
				fluid={true}
				className={"global-blackout-component"}
				style={{
                    position: "fixed", top: 0, left: 0,
                    width: "100%", height: "100%",
                    background: "rgba(0, 0, 0, 0.4)",
                    zIndex: 1000
                }}
			/>
            }

            {!!PopupMessage &&
            <GlobalPopupComponent
                text={PopupMessage.text}
                type={PopupMessage.type}
            />
            }

            {!!apiRequestStatus &&
			<GlobalApiRequestLoaderComponent />
            }

            <CookieConsent
                location={"bottom"}
                buttonText={I_UNDERSTAND_PHRASE}
                style={{ height: 70, fontSize: 11, background: "#17366E", zIndex: 999999, }}
                buttonStyle={{ color: "#FFF", fontSize: "12px", background: "#1D4288" }}
                expires={150}
            >
                {COOKIE_MESSAGE}
            </CookieConsent>

        </Router>
    );

};


export default Application;

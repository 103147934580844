import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import NumberFormat from "react-number-format";


import {
    LeagueTeamSeasonStatisticsInterface
} from "@Interfaces/Api";


export interface TeamStatisticsStatisticsComponentProps {
    TeamStatistics:  LeagueTeamSeasonStatisticsInterface | null | undefined;
}


export const TeamStatisticsStatisticsComponent: React.FC<TeamStatisticsStatisticsComponentProps> = ({
    TeamStatistics
}) : JSX.Element | null => {


    const STATISTICS_TEAM_POINTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TEAM_POINTS_PHRASE_TEXT");
    const STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT");
    const STATISTICS_REBOUNDS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_REBOUNDS_WORD_TEXT");
    const STATISTICS_ASSISTS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ASSISTS_WORD_TEXT");
    const STATISTICS_BALANCE_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_BALANCE_WORD_TEXT");

    const {
        team_statistics: teamStatistics, team_results: teamResults
    } = TeamStatistics || {}

    const {
        AST: teamAssists, PLAYER_O_REB: teamOffRebounds, PLAYER_D_REB: teamDefRebounds
    } = teamStatistics || {};

    const {
        team_games: teamGames, team_wins: teamWins, team_defeats: teamDefeats, team_small_points: teamSmallPoints
    } = teamResults || {};

    const {
        team_small_points_in: teamSmallPointsIn, team_small_points_out: teamSmallPointsOut
    } = teamSmallPoints || {};


    let teamRebounds: number | undefined = undefined;

    if (teamOffRebounds || teamDefRebounds) {
        teamRebounds = 0;
        if (teamOffRebounds) {
            teamRebounds += teamOffRebounds;
        }
        if (teamDefRebounds) {
            teamRebounds += teamDefRebounds;
        }
    }


    let teamBalance = "-";

    if (teamWins !== undefined && teamDefeats !== undefined) {
        teamBalance = `${teamWins} - ${teamDefeats}`;
    }


    return (
        <Col xs={12} lg={8} className={"team-details__team-statistics__statistics"}>
            <Row className={"team-details__team-statistics__statistics__content"}>

                <Col xs={3} lg={`auto`} className={"team-details__team-statistics__statistics__statistic"}>
                    <span className={"statistic-label"}>{STATISTICS_TEAM_POINTS_PHRASE_TEXT}</span>
                    <span className={"statistic-value"}>{teamSmallPointsIn !== undefined && teamGames ? <NumberFormat value={teamSmallPointsIn / teamGames} displayType={"text"} decimalScale={0} /> : "-"}</span>
                </Col>

                <Col xs={3} lg={`auto`} className={"team-details__team-statistics__statistics__statistic"}>
                    <span className={"statistic-label"}>{STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT}</span>
                    <span className={"statistic-value"}>{teamSmallPointsOut !== undefined && teamGames ? <NumberFormat value={teamSmallPointsOut / teamGames} displayType={"text"} decimalScale={0} /> : "-"}</span>
                </Col>

                <Col xs={2} lg={`auto`} className={"team-details__team-statistics__statistics__statistic"}>
                    <span className={"statistic-label"}>{STATISTICS_REBOUNDS_WORD_TEXT}</span>
                    <span className={"statistic-value"}>{teamRebounds !== undefined && teamGames ? <NumberFormat value={teamRebounds / teamGames} displayType={"text"} decimalScale={0} /> : "-"}</span>
                </Col>

                <Col xs={2} lg={`auto`} className={"team-details__team-statistics__statistics__statistic"}>
                    <span className={"statistic-label"}>{STATISTICS_ASSISTS_WORD_TEXT}</span>
                    <span className={"statistic-value"}>{teamAssists !== undefined && teamGames ? <NumberFormat value={teamAssists / teamGames} displayType={"text"} decimalScale={0} /> : "-"}</span>
                </Col>

                <Col xs={2} lg={`auto`} className={"team-details__team-statistics__statistics__statistic"}>
                    <span className={"statistic-label"}>{STATISTICS_BALANCE_WORD_TEXT}</span>
                    <span className={"statistic-value"}>{teamBalance}</span>
                </Col>

            </Row>
        </Col>
    )

};


export default TeamStatisticsStatisticsComponent;

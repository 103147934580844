import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";



export interface QuarterDefaultEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterDefaultEventDataComponent: React.FC<QuarterDefaultEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.DEFAULT) {
        return null;
    }

    if (!Event.eventPlayerID || !Event.eventTeamID) {
        return null;
    }


    const DefaultEvent = UseProgressEvent(Event.eventSubType);

    if (!DefaultEvent) {
        return null;
    }


    const ThrowEventPlayer = GamePlayers[Event.eventPlayerID];

    if (!ThrowEventPlayer) {
        return null;
    }


    const {
        player_first_name: eventPlayerFirstName,
        player_last_name: eventPlayerLastName,
        player_nick_name: eventPlayerNickName,
        player_pseudonymization_status: eventPlayerPseudonymizationStatus,
        player_slug: eventPlayerSlug
    } = ThrowEventPlayer;


    let eventPlayerName = "";

    if (Event.eventPlayerNumber) {
        eventPlayerName += `${Event.eventPlayerNumber} `;
    }

    if (eventPlayerPseudonymizationStatus) {
        eventPlayerName += eventPlayerNickName;
    } else {
        eventPlayerName += `${eventPlayerFirstName[0]}. ${eventPlayerLastName}`
    }


    const {
        eventName: { singular: eventName, label: eventLabel },
        eventIcon
    } = DefaultEvent;


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--default-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} style={{ order: homeTeamID === Event.eventTeamID ? 1 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {eventIcon && <img src={eventIcon} className={"event-icon"} alt={""} />}
                        <NavLink to={`/${PlayersRoute.routeURL}/${eventPlayerSlug}`} title={PlayersRoute.routeTitle} className={"event-player-name"}>
                            <span>{eventPlayerName}</span>
                        </NavLink>
                        {eventName && <span className={"event-label"}>{eventName}</span>}
                        {Event.eventRepeatsNumber && <span className={"event-number"}>({Event.eventRepeatsNumber} {eventLabel})</span>}
                        {Event?.eventDescription?.text && <span className={"event-description"}>{Event?.eventDescription?.text}</span>}
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={homeTeamID === Event.eventTeamID ? ["order-2"] : ["offset-4"]}
                />

            </Row>
        </Col>
    );

};


export default QuarterDefaultEventDataComponent;

import React from "react";

import parse from "html-react-parser";


import {
    Col, Row
} from "react-bootstrap";


import {
    ArticleInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ArticleShortTextComponentProps {
    Article: ArticleInterface;
}

export const ArticleShortTextComponent: React.FC<ArticleShortTextComponentProps> = ({
    Article
}) : JSX.Element | null => {


    if (!Article) {
        return null;
    }


    const {
        article_short_content: articleShortText,
    } = Article;


    return (
        <Col xs={12} lg={4} className={"article-detail__content__header__short-text"}>
            <Row className={"article-detail__content__header__short-text__content"}>

                {!!articleShortText && parse(articleShortText)}

            </Row>
        </Col>
    )


};


export default ArticleShortTextComponent;

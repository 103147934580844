import {
    AccuratePT1ThrowIcon, AccuratePT2ThrowIcon, AccuratePT3ThrowIcon,
    BlockIcon, SubstitutionIcon, WhistleIcon
} from "@Icons";


import GameProgressEventsInterface from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/ProgressEvents";


export const GameProgressEvents: GameProgressEventsInterface = {
    "1PT": {
        eventName: {
            PL: { singular: "Rzut osobisty CELNY", plural_to_4: "", plural: "", label: "PKT" },
            EN: { singular: "Accurate free throw", plural_to_4: "", plural: "", label: "PTS" },
        },
        eventIcon: AccuratePT1ThrowIcon,
    },
    "2PT": {
        eventName: {
            PL: { singular: "Rzut za 2 CELNY", plural_to_4: "", plural: "", label: "PKT" },
            EN: { singular: "Accurate 2 points throw", plural_to_4: "", plural: "", label: "PTS" },
        },
        eventIcon: AccuratePT2ThrowIcon,
    },
    "3PT": {
        eventName: {
            PL: { singular: "Rzut za 3 CELNY", plural_to_4: "", plural: "", label: "PKT" },
            EN: { singular: "Accurate 3 points throw", plural_to_4: "", plural: "", label: "PTS" },
        },
        eventIcon: AccuratePT3ThrowIcon,
    },
    "1PTA": {
        eventName: {
            PL: { singular: "Rzut osobisty niecelny", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Not accurate free throw", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: null,
    },
    "2PTA": {
        eventName: {
            PL: { singular: "Rzut za 2 niecelny", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Not Accurate 2 points throw", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: null,
    },
    "3PTA": {
        eventName: {
            PL: { singular: "Rzut za 3 niecelny", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Not accurate 3 points throw", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: null,
    },
    "AST": {
        eventName: {
            PL: { singular: "Asysta", plural_to_4: "", plural: "", label: "AST" },
            EN: { singular: "Assist", plural_to_4: "", plural: "", label: "AST" },
        },
        eventIcon: null,
    },
    "BLK": {
        eventName: {
            PL: { singular: "Blok", plural_to_4: "", plural: "", label: "BLK" },
            EN: { singular: "Block", plural_to_4: "", plural: "", label: "BLK" },
        },
        eventIcon: BlockIcon,
    },
    "BLK VIC": {
        eventName: {
            PL: { singular: "Zablokowany", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Blocked", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: null,
    },
    "STL": {
        eventName: {
            PL: { singular: "Przechwyt", plural_to_4: "", plural: "", label: "PRZ" },
            EN: { singular: "Steal", plural_to_4: "", plural: "", label: "STL" },
        },
        eventIcon: null,
    },
    "TO": {
        eventName: {
            PL: { singular: "Strata", plural_to_4: "", plural: "", label: "STR" },
            EN: { singular: "Turnover", plural_to_4: "", plural: "", label: "TO" },
        },
        eventIcon: null,
    },
    "PLAYER_D_REB": {
        eventName: {
            PL: { singular: "Zbiórka w obronie", plural_to_4: "", plural: "", label: "O" },
            EN: { singular: "Defensive rebound", plural_to_4: "", plural: "", label: "D" },
        },
        eventIcon: null,
    },
    "PLAYER_O_REB": {
        eventName: {
            PL: { singular: "Zbiórka w ataku", plural_to_4: "", plural: "", label: "A" },
            EN: { singular: "Offensive robound", plural_to_4: "", plural: "", label: "O" },
        },
        eventIcon: null,
    },
    "TEAM_D_REB": {
        eventName: {
            PL: { singular: "Zbiórka zespołowa w obronie", plural_to_4: "", plural: "", label: "O" },
            EN: { singular: "Defensive team rebound", plural_to_4: "", plural: "", label: "D" },
        },
        eventIcon: null,
    },
    "TEAM_O_REB": {
        eventName: {
            PL: { singular: "Zbiórka zespołowa w ataku", plural_to_4: "", plural: "", label: "A" },
            EN: { singular: "Offensive team robound", plural_to_4: "", plural: "", label: "O" },
        },
        eventIcon: null,
    },
    "FOUL": {
        eventName: {
            PL: { singular: "Faul", plural_to_4: "", plural: "", label: "FAUL" },
            EN: { singular: "Foul", plural_to_4: "", plural: "", label: "FOUL" },
        },
        eventIcon: null,
    },
    "FOULED": {
        eventName: {
            PL: { singular: "Faul otrzymany", plural_to_4: "", plural: "", label: "FAULOW" },
            EN: { singular: "Fouled", plural_to_4: "", plural: "", label: "FOULED" },
        },
        eventIcon: WhistleIcon,
    },
    "SUB_IN": {
        eventName: {
            PL: { singular: "Zmiana", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Substitution", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: SubstitutionIcon,
    },
    "SUB_OUT": {
        eventName: {
            PL: { singular: "Zmiana", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Substitution", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: SubstitutionIcon,
    },
    "SUBSTITUTION": {
        eventName: {
            PL: { singular: "Zmiana", plural_to_4: "", plural: "", label: "zmieniony przez" },
            EN: { singular: "Substitution", plural_to_4: "", plural: "", label: "changed by" },
        },
        eventIcon: SubstitutionIcon,
    },
    "TIME_OUT": {
        eventName: {
            PL: { singular: "Czas", plural_to_4: "", plural: "", label: "" },
            EN: { singular: "Time Out", plural_to_4: "", plural: "", label: "" },
        },
        eventIcon: null,
    },
};


export default GameProgressEvents;

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";



export const UseGlobalData = () : any => {

    const { globalData } = useTypedSelector(
        (state) => state.app
    );

    return globalData;

};


export default UseGlobalData;

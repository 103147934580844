import FormFieldsDictionaryInterface from "@Dictionary/Sets/Forms/types";

import {
    NAME_PL, SHORT_NAME_PL, FIRST_NAME_PL, LAST_NAME_PL, NICK_NAME_PL,
    EMAIL_ADDRESS_PL, WEBSITE_PL, SOCIAL_ID_PL,
    CITY_PL,
    NAME_EN, SHORT_NAME_EN, FIRST_NAME_EN, LAST_NAME_EN, NICK_NAME_EN,
    EMAIL_ADDRESS_EN, WEBSITE_EN, SOCIAL_ID_EN,
    CITY_EN,
    NATIONALITY_PL, DESCRIPTION_PL, BIRTHDATE_PL, WEIGHT_PL, HEIGHT_PL,
    NATIONALITY_EN, DESCRIPTION_EN, BIRTHDATE_EN, WEIGHT_EN, HEIGHT_EN,
    COLOR_PL, FIRST_COLOR_PL, SECOND_COLOR_PL, NUMBER_PL, POSITION_PL,
    COLOR_EN, FIRST_COLOR_EN, SECOND_COLOR_EN, NUMBER_EN, POSITION_EN,
    USER_TYPE_LABEL_TEXT_PL, USER_TYPE_LABEL_TEXT_EN,
    PASSWORD_LABEL_TEXT_PL, PASSWORD_LABEL_TEXT_EN, PASSWORD_NEW_LABEL_TEXT_PL, PASSWORD_NEW_LABEL_TEXT_EN, PASSWORD_REPEAT_LABEL_TEXT_PL, PASSWORD_REPEAT_LABEL_TEXT_EN,
    EDIT_PL, EDIT_EN, SAVE_PL, SAVE_EN, ABORT_PL, ABORT_EN
} from "@Dictionary/Sets/Forms/consts";


export const FormsFieldsDictionary: FormFieldsDictionaryInterface = {
    PL: {
        NAME: NAME_PL, SHORT_NAME: SHORT_NAME_PL, FIRST_NAME: FIRST_NAME_PL, LAST_NAME: LAST_NAME_PL, NICK_NAME: NICK_NAME_PL,
        EMAIL_ADDRESS: EMAIL_ADDRESS_PL, WEBSITE: WEBSITE_PL, SOCIAL_ID: SOCIAL_ID_PL,
        NATIONALITY: NATIONALITY_PL, DESCRIPTION: DESCRIPTION_PL, BIRTHDATE: BIRTHDATE_PL, WEIGHT: WEIGHT_PL, HEIGHT: HEIGHT_PL,
        POSITION: POSITION_PL,
        CITY: CITY_PL,
        COLOR: COLOR_PL, FIRST_COLOR: FIRST_COLOR_PL, SECOND_COLOR: SECOND_COLOR_PL, NUMBER: NUMBER_PL,
        USER_TYPE: PASSWORD_LABEL_TEXT_PL,
        PASSWORD: PASSWORD_LABEL_TEXT_PL, PASSWORD_NEW: PASSWORD_NEW_LABEL_TEXT_PL, PASSWORD_REPEAT: PASSWORD_REPEAT_LABEL_TEXT_PL,
        EDIT: EDIT_PL, SAVE: SAVE_PL, ABORT: ABORT_PL,
    },
    EN: {
        NAME: NAME_EN, SHORT_NAME: SHORT_NAME_EN, FIRST_NAME: FIRST_NAME_EN, LAST_NAME: LAST_NAME_EN, NICK_NAME: NICK_NAME_EN,
        EMAIL_ADDRESS: EMAIL_ADDRESS_EN, WEBSITE: WEBSITE_EN, SOCIAL_ID: SOCIAL_ID_EN,
        NATIONALITY: NATIONALITY_EN, DESCRIPTION: DESCRIPTION_EN, BIRTHDATE: BIRTHDATE_EN, WEIGHT: WEIGHT_EN, HEIGHT: HEIGHT_EN,
        POSITION: POSITION_EN,
        CITY: CITY_EN,
        COLOR: COLOR_EN, FIRST_COLOR: FIRST_COLOR_EN, SECOND_COLOR: SECOND_COLOR_EN, NUMBER: NUMBER_EN,
        USER_TYPE: PASSWORD_LABEL_TEXT_EN,
        PASSWORD: PASSWORD_LABEL_TEXT_EN, PASSWORD_NEW: PASSWORD_NEW_LABEL_TEXT_EN, PASSWORD_REPEAT: PASSWORD_REPEAT_LABEL_TEXT_EN,
        EDIT: EDIT_EN, SAVE: SAVE_EN, ABORT: ABORT_EN,
    }
};


export default FormsFieldsDictionary;

import React from "react";


import { UseExternalURL } from "@Services/Hooks";


import { Col, Row } from "react-bootstrap";


import { ContainerSectionComponent } from "@ContainersElements";


import { PLKA_FULL_COLOR_LOGO } from "@Images";


import "./index.scss";


export interface ColorProjectLogoWithLineSeparatorComponentProps {}


export const ColorProjectLogoWithLineSeparatorComponent: React.FC<ColorProjectLogoWithLineSeparatorComponentProps> = () : JSX.Element | null => {


    const PROJECT_URL = UseExternalURL("PLKA_PROJECT_SITE");


    return (
        <ContainerSectionComponent classes={"plka-line-and-logo-separator"}>

            <Col xs={12} className={"plka-line-and-logo"}>
                <Row className={"plka-line-and-logo-content"}>

                    <span className={"line-separator"}></span>

                    <a href={PROJECT_URL} title={""} target={"_blank"} rel={"noreferrer noopener"} className={"logo-link"}>
                        <img src={PLKA_FULL_COLOR_LOGO} alt={`PLKA`} />
                    </a>

                </Row>
            </Col>

        </ContainerSectionComponent>
    )

};


export default ColorProjectLogoWithLineSeparatorComponent;

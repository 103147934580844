import React from "react";


import {Col} from "react-bootstrap";


import stringTruncate from "truncate";


type ResultFooterProps = {
    gameInformation?: string | null;
};


export const ResultFooterComponent: React.FC<ResultFooterProps> = ({
    gameInformation = ""
}): JSX.Element | null => {


    return (
        <Col xs={12} className={"results-bar-item-footer"}>
            {!!gameInformation &&
	        <span>{stringTruncate(gameInformation, 30)}</span>
            }
        </Col>
    );

};


export default ResultFooterComponent;
